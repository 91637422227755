import { FieldsEnum, FormValuesT } from './constants';
import { UpdateSpotCarrierLanesSidebarDataT } from 'carrier/layouts/SideBars/UpdateSpotCarrierLanesSidebarContent/models';
import { SpotCarrierLanesTrailerVariantEnum } from 'carrier/store/spot-carrier-lane-changes/models';

const getInitialValues = (sidebarData: UpdateSpotCarrierLanesSidebarDataT): FormValuesT => {
    const values: FormValuesT = {
        [FieldsEnum.trailerVariant]: sidebarData?.initialTrailerType || SpotCarrierLanesTrailerVariantEnum.all,
        [FieldsEnum.fromCountryCodes]: sidebarData?.initialFromCodes || [],
        [FieldsEnum.toCountryCodes]: sidebarData?.initialToCodes || [],
    };

    return values;
};

export default getInitialValues;
