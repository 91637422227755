import * as React from 'react';
import { CarrierSidebarDataT } from './models';
import { CarrierSidebarsTypeEnum } from './constants';
import { GetSidebarCloseConfirmationT, RenderSidebarContentT } from 'common/layouts/SideBars/models';
import SpotRequestsFiltersSidebarContent from 'carrier/layouts/SideBars/SpotRequestsFiltersSidebarContent/SpotRequestsFiltersSidebarContent';
import SpotRequestDetailsSidebarContent from 'carrier/layouts/SideBars/SpotRequestDetailsSidebarContent/SpotRequestDetailsSidebarContent';
import UpdateSpotCarrierLanesSidebarContent from 'carrier/layouts/SideBars/UpdateSpotCarrierLanesSidebarContent/UpdateSpotCarrierLanesSidebarContent';

export const renderSidebarContent: RenderSidebarContentT<CarrierSidebarDataT> = (props) => {
    const { data, ...restProps } = props;

    switch (data.type) {
        case CarrierSidebarsTypeEnum.spotRequestDetails: {
            return <SpotRequestDetailsSidebarContent data={data} {...restProps} />;
        }

        case CarrierSidebarsTypeEnum.spotRequestsFilter: {
            return <SpotRequestsFiltersSidebarContent data={data} {...restProps} />;
        }

        case CarrierSidebarsTypeEnum.updateSpotCarrierLanes: {
            return <UpdateSpotCarrierLanesSidebarContent data={data} {...restProps} />;
        }

        default: {
            return null;
        }
    }
};

export const getSidebarCloseConfirmation: GetSidebarCloseConfirmationT<CarrierSidebarDataT> = (t, data) => {
    return null;
};
