import {
    DELETE_PAPER_REQUEST,
    DELETE_PAPER_REQUEST_BEGIN,
    DELETE_PAPER_REQUEST_ERROR,
    DELETE_PAPER_REQUEST_SUCCESS,
    DeletePaperActionT,
    DeletePaperBeginActionT,
    DeletePaperErrorActionT,
    DeletePaperSuccessActionT,
    FETCH_PAPERS_REQUEST,
    FETCH_PAPERS_REQUEST_BEGIN,
    FETCH_PAPERS_REQUEST_ERROR,
    FETCH_PAPERS_REQUEST_SUCCESS,
    FetchPapersActionT,
    FetchPapersBeginActionT,
    FetchPapersErrorActionT,
    FetchPapersSuccessActionT,
    RESET_PAPERS,
    ResetPapersActionT,
    UPLOAD_PAPER_REQUEST,
    UPLOAD_PAPER_REQUEST_BEGIN,
    UPLOAD_PAPER_REQUEST_ERROR,
    UPLOAD_PAPER_REQUEST_SUCCESS,
    UploadPaperActionT,
    UploadPaperBeginActionT,
    UploadPaperErrorActionT,
    UploadPaperSuccessActionT,
} from './types';
import { PaperDataT } from 'common/store/papers/models';

export const fetchPapers = (tourId: TourIdT, isBroker: boolean, options?: FetchOptionsT): FetchPapersActionT => ({
    type: FETCH_PAPERS_REQUEST,
    tourId,
    isBroker,
    options,
});

export const fetchPapersBegin = (hash: PapersHashT): FetchPapersBeginActionT => ({
    type: FETCH_PAPERS_REQUEST_BEGIN,
    hash,
});

export const fetchPapersSuccess = (hash: PapersHashT, list: Array<PaperDataT>): FetchPapersSuccessActionT => ({
    type: FETCH_PAPERS_REQUEST_SUCCESS,
    hash,
    list,
});

export const fetchPapersError = (error: Error, hash: PapersHashT): FetchPapersErrorActionT => ({
    type: FETCH_PAPERS_REQUEST_ERROR,
    error,
    hash,
});

export const uploadPaper = (tourId: TourIdT, isBroker: boolean, file: File): UploadPaperActionT => ({
    type: UPLOAD_PAPER_REQUEST,
    isBroker,
    file,
    tourId,
});

export const uploadPaperBegin = (hash: PapersHashT): UploadPaperBeginActionT => ({
    type: UPLOAD_PAPER_REQUEST_BEGIN,
    hash,
});

export const uploadPaperSuccess = (hash: PapersHashT): UploadPaperSuccessActionT => ({
    type: UPLOAD_PAPER_REQUEST_SUCCESS,
    hash,
});

export const uploadPaperError = (error: Error, hash: PapersHashT): UploadPaperErrorActionT => ({
    type: UPLOAD_PAPER_REQUEST_ERROR,
    error,
    hash,
});

export const deletePaper = (tourId: TourIdT, isBroker: boolean, paperId: PapersIdT): DeletePaperActionT => ({
    type: DELETE_PAPER_REQUEST,
    tourId,
    isBroker,
    paperId,
});

export const deletePaperBegin = (hash: PapersHashT, paperId: PapersIdT): DeletePaperBeginActionT => ({
    type: DELETE_PAPER_REQUEST_BEGIN,
    hash,
    paperId,
});

export const deletePaperSuccess = (hash: PapersHashT): DeletePaperSuccessActionT => ({
    type: DELETE_PAPER_REQUEST_SUCCESS,
    hash,
});

export const deletePaperError = (error: Error, hash: PapersHashT): DeletePaperErrorActionT => ({
    type: DELETE_PAPER_REQUEST_ERROR,
    error,
    hash,
});

export const resetPapers = (): ResetPapersActionT => ({
    type: RESET_PAPERS,
});
