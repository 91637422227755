import {
    FETCH_SPOT_INBOX_REQUESTS_STATS_REQUEST,
    FETCH_SPOT_INBOX_REQUESTS_STATS_REQUEST_BEGIN,
    FETCH_SPOT_INBOX_REQUESTS_STATS_REQUEST_ERROR,
    FETCH_SPOT_INBOX_REQUESTS_STATS_REQUEST_SUCCESS,
    FetchSpotInboxRequestsStatsActionT,
    FetchSpotInboxRequestsStatsBeginActionT,
    FetchSpotInboxRequestsStatsErrorActionT,
    FetchSpotInboxRequestsStatsSuccessActionT,
} from './types';
import { ApiInboxRequestsStatsT } from 'carrier/utils/api/spot-carrier-tranziit/models';

export const fetchSpotInboxRequestsStats = (options?: FetchOptionsT): FetchSpotInboxRequestsStatsActionT => ({
    type: FETCH_SPOT_INBOX_REQUESTS_STATS_REQUEST,
    options,
});

export const fetchSpotInboxRequestsStatsBegin = (): FetchSpotInboxRequestsStatsBeginActionT => ({
    type: FETCH_SPOT_INBOX_REQUESTS_STATS_REQUEST_BEGIN,
});

export const fetchSpotInboxRequestsStatsSuccess = (
    statistics: ApiInboxRequestsStatsT | null,
): FetchSpotInboxRequestsStatsSuccessActionT => ({
    type: FETCH_SPOT_INBOX_REQUESTS_STATS_REQUEST_SUCCESS,
    statistics,
});

export const fetchSpotInboxRequestsStatsError = (error: Error): FetchSpotInboxRequestsStatsErrorActionT => ({
    type: FETCH_SPOT_INBOX_REQUESTS_STATS_REQUEST_ERROR,
    error,
});
