import * as React from 'react';
import { SidebarContentPropsT } from 'common/layouts/SideBars/models';
import { UpdateSpotCarrierLanesSidebarDataT } from './models';
import { CarrierSidebarDataT } from 'carrier/layouts/SideBars/models';
import HeaderSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarLayout/HeaderSideBarLayout';
import HeaderSideBarContent from 'common/layouts/LeftMenuLayout/SideBarLayout/HeaderSideBarContent/HeaderSideBarContent';
import SideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/SideBarLayout';
import FieldGroup from 'common/components/FieldGroup/FieldGroup';
import FormikField from 'common/components/forms/FormikField/FormikField';
import { FieldsEnum, FormValuesT } from './constants';
import FooterSideBarLayout from 'common/layouts/LeftMenuLayout/SideBarLayout/FooterSideBarLayout/FooterSideBarLayout';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useTranslation } from 'react-i18next';
import getInitialValues from './get-initial-values';
import { useFormik } from 'formik';
import getSpotLanesChanges from './get-spot-lanes-changes';
import classNames from 'classnames/bind';
import styles from './UpdateSpotCarrierLanesSidebarContent.scss';
import CountriesCheckboxOptionsList from 'common/components/checkbox-options-lists/CountriesCheckboxOptionsList/CountriesCheckboxOptionsList';
import TrailerVariantDropdown from 'carrier/layouts/SideBars/UpdateSpotCarrierLanesSidebarContent/TrailerVariantDropdown/TrailerVariantDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { selectSpotCarrierLaneChangesRequest } from 'carrier/store/spot-carrier-lane-changes/selectors';
import { createSpotCarrierLanes, updateSpotCarrierLanes } from 'carrier/store/spot-carrier-lane-changes/actions';
import useOnlyChangesEffect from 'common/utils/hooks/useOnlyChangesEffect';
import validateForm from './validate-form';
import InfoIcon from 'common/icons/InfoIcon';
import { StyleGuideColorsEnum } from 'common/constants';
import Alert, { AlertSizeEnum, AlertThemeEnum } from 'common/components/Alert/Alert';

const cx = classNames.bind(styles);

type PropsT = SidebarContentPropsT<UpdateSpotCarrierLanesSidebarDataT, CarrierSidebarDataT>;

const UpdateSpotCarrierLanesSidebarContent: React.FC<PropsT> = React.memo((props) => {
    const { onClose, data } = props;
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const changesRequest = useSelector(selectSpotCarrierLaneChangesRequest);

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = getInitialValues(data);
        const errors = validateForm(t, values);

        return [values, errors];
    }, [data]);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            const changes = getSpotLanesChanges(values);

            if (data.isCreateAction) {
                dispatch(createSpotCarrierLanes(changes));
            } else {
                dispatch(updateSpotCarrierLanes(changes));
            }

            formikHelpers.setTouched({});
        },
    });

    useOnlyChangesEffect(() => {
        if (!onClose) {
            return;
        }

        if (!changesRequest.loading) {
            onClose();
        }
    }, [changesRequest.loading]);

    const title = data.isCreateAction ? t('spot-carrier-lanes.creation.title') : t('spot-carrier-lanes.update.titles');

    const countLanes = Math.max(
        formik.values[FieldsEnum.fromCountryCodes].length,
        formik.values[FieldsEnum.toCountryCodes].length,
    );

    const actionTitle = data.isCreateAction
        ? t('spot-carrier-lanes.creation.submit', {
              postProcess: 'interval',
              count: countLanes,
          })
        : t('spot-carrier-lanes.update.submit', {
              postProcess: 'interval',
              count: countLanes,
          });

    return (
        <form onSubmit={formik.handleSubmit} className={cx('form')}>
            <HeaderSideBarLayout>
                <HeaderSideBarContent title={title} onClose={onClose} />
            </HeaderSideBarLayout>
            <SideBarLayout>
                <FormikField
                    className={cx('field', 'field--trailer-type')}
                    name={FieldsEnum.trailerVariant}
                    error={formik.errors[FieldsEnum.trailerVariant]}
                    meta={formik.getFieldMeta(FieldsEnum.trailerVariant)}
                    label={t('spot-carrier-lanes.update.fields.trailer-variant.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <TrailerVariantDropdown
                            value={formik.values[FieldsEnum.trailerVariant]}
                            onChange={props.onChange}
                            hasWarning={props.hasWarning}
                            hasError={props.hasError}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                        />
                    )}
                </FormikField>
                <Alert
                    icon={<InfoIcon fillColor={StyleGuideColorsEnum.light} />}
                    size={AlertSizeEnum.small}
                    theme={AlertThemeEnum.gray}
                    className={cx('alert')}
                >
                    {t('spot-carrier-lanes.update.alert')}
                </Alert>
                <FieldGroup>
                    <FormikField
                        name={FieldsEnum.fromCountryCodes}
                        error={formik.errors[FieldsEnum.fromCountryCodes]}
                        meta={formik.getFieldMeta(FieldsEnum.fromCountryCodes)}
                        label={t('spot-carrier-lanes.update.fields.from-country-codes.label', {
                            count: formik.values[FieldsEnum.fromCountryCodes].length,
                        })}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <CountriesCheckboxOptionsList
                                isShowSelectAllTrigger
                                selectedValues={formik.values[FieldsEnum.fromCountryCodes]}
                                hasWarning={props.hasWarning}
                                hasError={props.hasError}
                                onSelect={props.onChange}
                                testSelectorPrefix="from_country_option"
                            />
                        )}
                    </FormikField>
                    <FormikField
                        name={FieldsEnum.toCountryCodes}
                        error={formik.errors[FieldsEnum.toCountryCodes]}
                        meta={formik.getFieldMeta(FieldsEnum.toCountryCodes)}
                        label={t('spot-carrier-lanes.update.fields.to-country-codes.label', {
                            count: formik.values[FieldsEnum.toCountryCodes].length,
                        })}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <CountriesCheckboxOptionsList
                                isShowSelectAllTrigger
                                selectedValues={formik.values[FieldsEnum.toCountryCodes]}
                                hasWarning={props.hasWarning}
                                hasError={props.hasError}
                                onSelect={props.onChange}
                                testSelectorPrefix="to_country_option"
                            />
                        )}
                    </FormikField>
                </FieldGroup>
            </SideBarLayout>
            <FooterSideBarLayout hasPaddings>
                <Button
                    theme={ButtonThemeEnum.primary}
                    isDisabled={changesRequest.loading}
                    isLoading={changesRequest.loading}
                    className={cx('button')}
                    type="submit"
                >
                    {actionTitle}
                </Button>
            </FooterSideBarLayout>
        </form>
    );
});

export default UpdateSpotCarrierLanesSidebarContent;
