import {
    FETCH_SPOT_LOAD_BOARD_REQUESTS_PAGE_REQUEST,
    FETCH_SPOT_LOAD_BOARD_REQUESTS_PAGE_REQUEST_BEGIN,
    FETCH_SPOT_LOAD_BOARD_REQUESTS_PAGE_REQUEST_ERROR,
    FETCH_SPOT_LOAD_BOARD_REQUESTS_PAGE_REQUEST_SUCCESS,
    FetchSpotLoadBoardRequestsPageActionT,
    FetchSpotLoadBoardRequestsPageBeginActionT,
    FetchSpotLoadBoardRequestsPageErrorActionT,
    FetchSpotLoadBoardRequestsPageSuccessActionT,
    RESET_SPOT_LOAD_BOARD_REQUESTS,
    ResetSpotLoadBoardRequestsActionT,
} from './types';
import { SpotLoadBoardRequestT, FetchSpotLoadBoardRequestsPageQueryT } from './models';
import { PageResponseT } from 'common/utils/pagination/models';

export const fetchSpotLoadBoardRequestsPage = (
    pageNumber: PageNumberT,
    query: FetchSpotLoadBoardRequestsPageQueryT,
    options?: FetchOptionsT,
): FetchSpotLoadBoardRequestsPageActionT => ({
    type: FETCH_SPOT_LOAD_BOARD_REQUESTS_PAGE_REQUEST,
    query,
    pageNumber,
    options,
});

export const fetchSpotLoadBoardRequestsPageBegin = (
    query: FetchSpotLoadBoardRequestsPageQueryT,
    pageNumber: PageNumberT,
): FetchSpotLoadBoardRequestsPageBeginActionT => ({
    type: FETCH_SPOT_LOAD_BOARD_REQUESTS_PAGE_REQUEST_BEGIN,
    query,
    pageNumber,
});

export const fetchSpotLoadBoardRequestsPageSuccess = (
    query: FetchSpotLoadBoardRequestsPageQueryT,
    pageNumber: PageNumberT,
    pageResponse: PageResponseT<SpotLoadBoardRequestT> | null,
): FetchSpotLoadBoardRequestsPageSuccessActionT => ({
    type: FETCH_SPOT_LOAD_BOARD_REQUESTS_PAGE_REQUEST_SUCCESS,
    query,
    pageResponse,
    pageNumber,
});

export const fetchSpotLoadBoardRequestsPageError = (
    query: FetchSpotLoadBoardRequestsPageQueryT,
    pageNumber: PageNumberT,
    error: Error,
): FetchSpotLoadBoardRequestsPageErrorActionT => ({
    type: FETCH_SPOT_LOAD_BOARD_REQUESTS_PAGE_REQUEST_ERROR,
    query,
    error,
    pageNumber,
});

export const resetSpotLoadBoardRequests = (
    mutationListOptions: MutationListOptionsT,
): ResetSpotLoadBoardRequestsActionT => ({
    type: RESET_SPOT_LOAD_BOARD_REQUESTS,
    mutationListOptions,
});
