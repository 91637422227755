import { put, takeEvery, select } from 'redux-saga/effects';

import { FETCH_DRIVERS_REQUEST, FetchDriversActionT } from './types';
import { fetchDriversBegin, fetchDriversError, fetchDriversSuccess } from './actions';
import { selectSearchDriverQuery } from './selectors';
import carrierTranziitApi from 'carrier/utils/api/carrier-tranziit/api';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import { checkIsDefaultCompanyId } from 'common/utils';
import { checkIsSameQuery } from 'common/utils/pagination/utils';

function* fetchDriversSaga(action: FetchDriversActionT): WrapGeneratorT<void> {
    const { query } = action;

    const prevQuery: ReturnType<typeof selectSearchDriverQuery> = yield select(selectSearchDriverQuery);
    if (checkIsSameQuery(prevQuery, action.query)) {
        return;
    }

    yield put(fetchDriversBegin(query));

    let response: ReturnApiT<typeof carrierTranziitApi.searchDriver | typeof brokerTranziitApi.searchDriver>;
    if (query?.partnerId && !checkIsDefaultCompanyId(query.partnerId)) {
        response = yield brokerTranziitApi.searchDriver(query?.partnerId, {
            name: query?.name,
            page: 0,
        });
    } else {
        response = yield carrierTranziitApi.searchDriver({
            name: query?.name,
            page: 0,
        });
    }
    const [error, driversPage] = response;

    if (error) {
        yield put(fetchDriversError(query, error));
        return;
    }

    const drivers = driversPage?.content || [];

    yield put(fetchDriversSuccess(query, drivers));
}

function* driverSearchSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_DRIVERS_REQUEST, fetchDriversSaga);
}

export default driverSearchSaga;
