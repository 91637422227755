import { AsyncFormErrorsT, FieldsEnum } from './constants';
import { RequestStatusT } from 'common/utils/request-status';
import {
    checkIsTranziitApiRequestError,
    TranziitApiRequestErrorSubTypeEnum,
} from 'common/utils/api/tranziit/errors/tranziit-api-errors';

const asyncValidate = (requestError: RequestStatusT): AsyncFormErrorsT => {
    if (!checkIsTranziitApiRequestError(requestError.error)) {
        return {};
    }

    if (requestError?.error?.subType === TranziitApiRequestErrorSubTypeEnum.phoneAlreadyExist) {
        return {
            [FieldsEnum.phone]: TranziitApiRequestErrorSubTypeEnum.phoneAlreadyExist,
        };
    }

    if (requestError?.error?.message) {
        return {
            [FieldsEnum.phone]: requestError.error.message,
        };
    }

    return {};
};

export default asyncValidate;
