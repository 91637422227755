import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './TransportationOrderMileagAndPaymentChangedCard.scss';
import { Trans } from 'react-i18next';
import CityName from 'common/components/notification-cards/entities/CityName/CityName';
import TransportationOrderId from '../entities/TransportationOrderId/TransportationOrderId';

const cx = classNames.bind(styles);

type PropsT = {
    number: string;
    cityA: string;
    cityB: string;
};

const TransportationOrderMileagAndPaymentChangedCard: React.FC<PropsT> = React.memo((props) => {
    const { number, cityA, cityB } = props;

    return (
        <div className={cx('inner')}>
            <Trans
                i18nKey="notifications.transportation-order-mileage-and-payment-changed"
                components={{
                    id: <TransportationOrderId number={number} />,
                    cityA: <CityName cityName={cityA} />,
                    cityB: <CityName cityName={cityB} />,
                }}
            />
        </div>
    );
});

export default TransportationOrderMileagAndPaymentChangedCard;
