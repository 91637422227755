import { TransportOrderDetailsStateT } from './types';
import { RequestStatusT } from 'common/utils/request-status';
import { initialTransportOrderDetailsState } from './reducer';
import { SharableStateT } from 'common/store/models';

export const selectTransportOrderDetails =
    (id: TransportOrderIdT | null | undefined) =>
    (state: SharableStateT): TransportOrderDetailsStateT['details'] =>
        state.transportOrderDetails[id as string]?.details || initialTransportOrderDetailsState.details;

export const selectTransportOrderDetailsRequestStatus =
    (id: TransportOrderIdT | null | undefined) =>
    (state: SharableStateT): RequestStatusT =>
        state.transportOrderDetails[id as string]?.requestStatus || initialTransportOrderDetailsState.requestStatus;
