import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SpotRequestsQuickTabEnum } from 'carrier/layouts/SpotRequestsPage/SpotRequestListPage/constants';
import ButtonGroup from 'design-system/components/ButtonGroup/ButtonGroup';
import ButtonGroupItem from 'design-system/components/ButtonGroup/ButtonGroupItem/ButtonGroupItem';

type ValueT = string | null;

type PropsT = {
    className?: string;
    selectedTab: ValueT;
    onSelectTab: (value: ValueT) => void;
};

const ALLOWED_TABS: Array<SpotRequestsQuickTabEnum> = [
    SpotRequestsQuickTabEnum.loadBoard,
    SpotRequestsQuickTabEnum.inbox,
];

const TAB_I18N_MAP: Record<SpotRequestsQuickTabEnum, string> = {
    [SpotRequestsQuickTabEnum.inbox]: 'spot-requests.list.tabs.inbox',
    [SpotRequestsQuickTabEnum.loadBoard]: 'spot-requests.list.tabs.load-board',
};

const SourceButtonGroup: React.FC<PropsT> = React.memo((props) => {
    const { className, selectedTab, onSelectTab } = props;

    const { t } = useTranslation();

    return (
        <ButtonGroup className={className}>
            {ALLOWED_TABS.map((tab) => {
                return (
                    <ButtonGroupItem key={tab} isActive={selectedTab === tab} value={tab} onClick={onSelectTab}>
                        {t(TAB_I18N_MAP[tab])}
                    </ButtonGroupItem>
                );
            })}
        </ButtonGroup>
    );
});

export default SourceButtonGroup;
