import requestStatus from 'common/utils/request-status';
import {
    ASSIGNMENT_REQUEST_BEGIN,
    ASSIGNMENT_REQUEST_ERROR,
    ASSIGNMENT_REQUEST_SUCCESS,
    CHANGE_USER_SELECTION,
    FIND_TRAILER_REQUEST_BEGIN,
    FIND_TRAILER_REQUEST_ERROR,
    FIND_TRAILER_REQUEST_SUCCESS,
    FIND_TRUCK_REQUEST_BEGIN,
    FIND_TRUCK_REQUEST_ERROR,
    FIND_TRUCK_REQUEST_SUCCESS,
    SpotAssetsAssignmentActionT,
    SpotAssetsAssignmentStateT,
} from './types';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';
import { checkIsSameQuery } from 'common/utils/pagination/utils';
import { getAssignmentVehicleLinks, getTrailerById, getTruckById } from './assignment';

const initialUserSelectionValues: SpotAssetsAssignmentStateT['userSelection'] = {
    truckId: null,
    trailerId: null,
};

const initialState: SpotAssetsAssignmentStateT = {
    userSelection: initialUserSelectionValues,
    trailerById: {},
    truckById: {},
    truckSuggest: {
        query: null,
        requestStatus: requestStatus.initial,
        ids: [],
    },
    trailerSuggest: {
        query: null,
        requestStatus: requestStatus.initial,
        ids: [],
    },
    links: {
        byTruckId: {},
        byTrailerId: {},
    },
    assigmentStatus: requestStatus.initial,
};

export default (
    state = initialState,
    action: SpotAssetsAssignmentActionT | DestroySessionActionT,
): SpotAssetsAssignmentStateT => {
    switch (action.type) {
        case CHANGE_USER_SELECTION: {
            const { changes } = action;
            const { userSelection: prevUserSelection } = state;

            const newUserSelection = {
                ...prevUserSelection,
                ...changes,
            };

            const isPrevSelectedLink =
                state.links.byTruckId[prevUserSelection.truckId as string] &&
                state.links.byTrailerId[prevUserSelection.trailerId as string];
            if (isPrevSelectedLink && 'trailerId' in changes && !('truckId' in changes)) {
                newUserSelection.truckId = null;
            }

            if (isPrevSelectedLink && !('trailerId' in changes) && 'truckId' in changes) {
                newUserSelection.trailerId = null;
            }

            return {
                ...state,
                userSelection: newUserSelection,
            };
        }

        case FIND_TRAILER_REQUEST_BEGIN: {
            const { query } = action;

            return {
                ...state,
                trailerSuggest: {
                    ...state.trailerSuggest,
                    query,
                    requestStatus: requestStatus.loading,
                },
            };
        }

        case FIND_TRAILER_REQUEST_ERROR: {
            const { error, query } = action;

            if (!checkIsSameQuery(state.trailerSuggest.query, query)) {
                return state;
            }

            return {
                ...state,
                trailerSuggest: {
                    ...state.trailerSuggest,
                    requestStatus: requestStatus.setError(error),
                },
            };
        }

        case FIND_TRAILER_REQUEST_SUCCESS: {
            const { trailersSuggestItems, query } = action;

            if (!checkIsSameQuery(state.trailerSuggest.query, query)) {
                return state;
            }

            const ids = trailersSuggestItems.reduce((result, trailersSuggestItem) => {
                const trailerId = trailersSuggestItem.trailer?.id;
                if (trailerId) {
                    result.push(trailerId);
                }

                return result;
            }, [] as TrailerIdT[]);

            const trailerById = getTrailerById(trailersSuggestItems);
            const truckById = getTruckById(trailersSuggestItems);
            const links = getAssignmentVehicleLinks(trailersSuggestItems);

            return {
                ...state,
                links: {
                    byTruckId: {
                        ...state.links.byTruckId,
                        ...links.byTruckId,
                    },
                    byTrailerId: {
                        ...state.links.byTrailerId,
                        ...links.byTrailerId,
                    },
                },
                trailerById: {
                    ...state.trailerById,
                    ...trailerById,
                },
                truckById: {
                    ...state.truckById,
                    ...truckById,
                },
                trailerSuggest: {
                    ...state.trailerSuggest,
                    requestStatus: requestStatus.ok,
                    ids,
                },
            };
        }

        case FIND_TRUCK_REQUEST_BEGIN: {
            const { query } = action;

            return {
                ...state,
                truckSuggest: {
                    ...state.truckSuggest,
                    query,
                    requestStatus: requestStatus.loading,
                },
            };
        }

        case FIND_TRUCK_REQUEST_ERROR: {
            const { error, query } = action;

            if (!checkIsSameQuery(state.truckSuggest.query, query)) {
                return state;
            }

            return {
                ...state,
                truckSuggest: {
                    ...state.truckSuggest,
                    requestStatus: requestStatus.setError(error),
                },
            };
        }

        case FIND_TRUCK_REQUEST_SUCCESS: {
            const { trucksSuggestItems, query } = action;

            if (!checkIsSameQuery(state.truckSuggest.query, query)) {
                return state;
            }

            const ids = trucksSuggestItems.reduce((result, trucksSuggestItem) => {
                const truckId = trucksSuggestItem.truck?.id;
                if (truckId) {
                    result.push(truckId);
                }

                return result;
            }, [] as TruckIdT[]);

            const trailerById = getTrailerById(trucksSuggestItems);
            const truckById = getTruckById(trucksSuggestItems);
            const links = getAssignmentVehicleLinks(trucksSuggestItems);

            return {
                ...state,
                links: {
                    byTruckId: {
                        ...state.links.byTruckId,
                        ...links.byTruckId,
                    },
                    byTrailerId: {
                        ...state.links.byTrailerId,
                        ...links.byTrailerId,
                    },
                },
                trailerById: {
                    ...state.trailerById,
                    ...trailerById,
                },
                truckById: {
                    ...state.truckById,
                    ...truckById,
                },
                truckSuggest: {
                    ...state.truckSuggest,
                    requestStatus: requestStatus.ok,
                    ids,
                },
            };
        }

        case ASSIGNMENT_REQUEST_BEGIN: {
            return {
                ...state,
                assigmentStatus: requestStatus.loading,
            };
        }

        case ASSIGNMENT_REQUEST_SUCCESS: {
            return {
                ...state,
                assigmentStatus: requestStatus.ok,
            };
        }

        case ASSIGNMENT_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                assigmentStatus: requestStatus.setError(error),
            };
        }

        case DESTROY_SESSION: {
            return initialState;
        }

        default:
            return state;
    }
};
