import * as React from 'react';
import FilterPills, { FilterPillsConfigT } from 'common/components/FilterPills/FilterPills';
import { Trans, useTranslation } from 'react-i18next';
import { FilterPillThemeEnum } from 'common/components/FilterPills/FilterPill/FilterPill';
import { QueryFiltersKeysEnum, QueryFiltersT } from '../query-models';
import CountriesFilterPill from 'common/components/filter-pills/CountriesFilterPill/CountriesFilterPill';

type PropsT = {
    isCompact?: boolean;
    isHideClearAll?: boolean;
    className?: string;
    queryFilters: QueryFiltersT;
    setQueryFilters: (queryFilters: QueryFiltersT) => void;
};

const SelectedFilters: React.FC<PropsT> = React.memo((props) => {
    const { isCompact, queryFilters, isHideClearAll, setQueryFilters } = props;

    const { t } = useTranslation();

    const filterPillsConfig = React.useMemo(
        (): FilterPillsConfigT<QueryFiltersT> => [
            {
                theme: FilterPillThemeEnum.brandAccent,
                render: (queryFilters) => {
                    const countryCode = queryFilters[QueryFiltersKeysEnum.fromCountryCode];
                    if (!countryCode) {
                        return null;
                    }

                    return (
                        <Trans
                            i18nKey="spot-carrier-lanes.list.filters.pills.from"
                            components={{
                                value: <CountriesFilterPill countryCodes={[countryCode]} />,
                            }}
                        />
                    );
                },
                clearKeys: [QueryFiltersKeysEnum.fromCountryCode],
            },
            {
                theme: FilterPillThemeEnum.brandAccent,
                render: (queryFilters) => {
                    const countryCode = queryFilters[QueryFiltersKeysEnum.toCountryCode];
                    if (!countryCode) {
                        return null;
                    }

                    return (
                        <Trans
                            i18nKey="spot-carrier-lanes.list.filters.pills.to"
                            components={{
                                value: <CountriesFilterPill countryCodes={[countryCode]} />,
                            }}
                        />
                    );
                },
                clearKeys: [QueryFiltersKeysEnum.toCountryCode],
            },
        ],
        [t],
    );

    return (
        <FilterPills<QueryFiltersT>
            isCompact={isCompact}
            queryFilters={queryFilters}
            setQueryFilters={setQueryFilters}
            config={filterPillsConfig}
            isHideClearAll={isHideClearAll}
        />
    );
});

export default SelectedFilters;
