import { put, select, takeEvery } from 'redux-saga/effects';
import { FETCH_CARRIER_CONTRACT_DETAILS_REQUEST, FetchCarrierContractDetailsActionT } from './types';
import { selectCarrierContractStateById } from './selectors';
import {
    fetchCarrierContractDetailsBegin,
    fetchCarrierContractDetailsError,
    fetchCarrierContractDetailsSuccess,
} from './actions';
import carrierTranziitApi from 'carrier/utils/api/carrier-tranziit/api';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import { checkIsDefaultCompanyId } from 'common/utils';
import checkNeedRequest from 'common/utils/check-need-request';

function* fetchCarrierContractDetailsSaga(action: FetchCarrierContractDetailsActionT): WrapGeneratorT<void> {
    const { partnerId, contractId, options } = action;

    const carrierContractState: ReReturnT<typeof selectCarrierContractStateById> = yield select(
        selectCarrierContractStateById(contractId),
    );
    if (!checkNeedRequest(carrierContractState.fetchRequest, options)) {
        return;
    }

    yield put(fetchCarrierContractDetailsBegin(partnerId));

    let result: ReturnApiT<
        typeof carrierTranziitApi.fetchCarrierContractDetails | typeof brokerTranziitApi.fetchCarrierContractDetails
    >;

    if (checkIsDefaultCompanyId(partnerId)) {
        result = yield carrierTranziitApi.fetchCarrierContractDetails(contractId);
    } else {
        result = yield brokerTranziitApi.fetchCarrierContractDetails(partnerId, contractId);
    }

    const [error, contractDetails] = result;

    if (error) {
        yield put(fetchCarrierContractDetailsError(contractId, error));
        return;
    }

    yield put(fetchCarrierContractDetailsSuccess(contractId, contractDetails || null));
}

function* carrierContractDetailsSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_CARRIER_CONTRACT_DETAILS_REQUEST, fetchCarrierContractDetailsSaga);
}

export default carrierContractDetailsSaga;
