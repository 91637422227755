import { FetchSpotCarrierLanesPageQueryT } from 'carrier/store/spot-carrier-lanes/models';
import { QueryFiltersKeysEnum, QueryFiltersT } from './query-models';

const prepareFetchQuery = (queryFilters: QueryFiltersT | null): FetchSpotCarrierLanesPageQueryT => {
    return {
        from: queryFilters?.[QueryFiltersKeysEnum.fromCountryCode] || undefined,
        to: queryFilters?.[QueryFiltersKeysEnum.toCountryCode] || undefined,
    };
};

export { prepareFetchQuery };
