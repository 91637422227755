import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import { fetchCountriesDict } from 'common/store/countries-dict/actions';
import { selectCountriesByCode, selectCountriesDictRequest } from 'common/store/countries-dict/selectors';
import { CountryCodeT } from 'common/utils/api/models';

type PropsT = {
    countryCodes: Array<CountryCodeT> | null | undefined;
};

const CountriesFilterPill: React.FC<PropsT> = React.memo((props) => {
    const { countryCodes } = props;

    const dispatch = useDispatch();

    const countryByCode = useSelector(selectCountriesByCode);
    const requestStatus = useSelector(selectCountriesDictRequest);

    React.useEffect(() => {
        dispatch(fetchCountriesDict());
    }, []);

    if (!countryCodes || !countryCodes.length) {
        return null;
    }

    if (requestStatus.loading) {
        return <ControlLoaderIcon fillColor={StyleGuideColorsEnum.white} size={DEFAULT_ICON_SIZE} />;
    }

    const label = countryCodes
        .map((countryCode) => {
            const country = countryByCode[countryCode];
            if (!country) {
                return countryCode;
            }

            return country.userLangDisplayName;
        })
        .join(', ');

    return <span>{label}</span>;
});

export default CountriesFilterPill;
