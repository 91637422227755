import * as React from 'react';
import styles from './SearchForm.scss';
import { QueryFiltersKeysEnum, QueryFiltersT } from '../query-models';
import CountryDropdown from 'common/components/dropdowns/CountryDropdown/CountryDropdown';
import { CountryCodeT } from 'common/store/countries-dict/models';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

type PropsT = {
    queryFilters: QueryFiltersT;
    updateQueryFilters: (queryFilters: QueryFiltersT) => void;
};

const SearchForm: React.FC<PropsT> = React.memo((props) => {
    const { queryFilters, updateQueryFilters } = props;

    return (
        <>
            <CountryDropdown
                className={cx('country-codes')}
                value={queryFilters[QueryFiltersKeysEnum.fromCountryCode]}
                onChange={(countryCode) => {
                    updateQueryFilters({
                        ...queryFilters,
                        [QueryFiltersKeysEnum.fromCountryCode]: countryCode as CountryCodeT,
                    });
                }}
            />
            <CountryDropdown
                className={cx('country-codes')}
                value={queryFilters[QueryFiltersKeysEnum.toCountryCode]}
                onChange={(countryCode) => {
                    updateQueryFilters({
                        ...queryFilters,
                        [QueryFiltersKeysEnum.toCountryCode]: countryCode as CountryCodeT,
                    });
                }}
            />
        </>
    );
});

export default SearchForm;
