import {
    FETCH_DRIVERS_REQUEST,
    FETCH_DRIVERS_REQUEST_BEGIN,
    FETCH_DRIVERS_REQUEST_ERROR,
    FETCH_DRIVERS_REQUEST_SUCCESS,
    FetchDriversActionT,
    FetchDriversBeginActionT,
    FetchDriversErrorActionT,
    FetchDriversSuccessActionT,
} from './types';

import { DriverT, SearchDriverQueryT } from './models';

export const fetchDrivers = (query: SearchDriverQueryT): FetchDriversActionT => ({
    type: FETCH_DRIVERS_REQUEST,
    query,
});

export const fetchDriversBegin = (query: SearchDriverQueryT): FetchDriversBeginActionT => ({
    type: FETCH_DRIVERS_REQUEST_BEGIN,
    query,
});

export const fetchDriversSuccess = (query: SearchDriverQueryT, drivers: DriverT[]): FetchDriversSuccessActionT => ({
    type: FETCH_DRIVERS_REQUEST_SUCCESS,
    drivers,
    query,
});

export const fetchDriversError = (query: SearchDriverQueryT, error: Error): FetchDriversErrorActionT => ({
    type: FETCH_DRIVERS_REQUEST_ERROR,
    error,
    query,
});
