import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './ButtonGroup.scss';
import cs from 'classnames';

const cx = classNames.bind(styles);

export type PropsT = {
    className?: string;
};

const ButtonGroup: React.FC<PropsT> = React.memo((props) => {
    const { className, children } = props;

    return (
        <div className={cs(cx('button-group'), className)} data-test-selector="button-group">
            {children}
        </div>
    );
});

export default ButtonGroup;
