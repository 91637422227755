export enum FieldsEnum {
    pickupAddress = 'pickupAddress',
    dropoffAddress = 'dropoffAddress',

    pickupDates = 'pickupDates',
    dropoffDates = 'dropoffDates',

    createDates = 'createDates',
    dictTrailerTypeId = 'dictTrailerTypeId',
}

export type FormValuesT = {
    [FieldsEnum.pickupAddress]: string | null;
    [FieldsEnum.dropoffAddress]: string | null;

    [FieldsEnum.pickupDates]: DateRangeT | null;
    [FieldsEnum.dropoffDates]: DateRangeT | null;

    [FieldsEnum.createDates]: DateRangeT | null;
    [FieldsEnum.dictTrailerTypeId]: string | null;
};
