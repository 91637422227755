import {
    BidsActionT,
    BidsStateT,
    RESET_BIDS,
    UPDATE_BID_REQUEST_BEGIN,
    UPDATE_BID_REQUEST_ERROR,
    UPDATE_BID_REQUEST_SUCCESS,
} from './types';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';
import requestStatus from 'common/utils/request-status';

const initialState: BidsStateT = {
    updateRequest: requestStatus.initial,
};

export default (state = initialState, action: BidsActionT | DestroySessionActionT): BidsStateT => {
    switch (action.type) {
        case UPDATE_BID_REQUEST_BEGIN: {
            return {
                ...state,
                updateRequest: requestStatus.loading,
            };
        }

        case UPDATE_BID_REQUEST_SUCCESS: {
            return {
                ...state,
                updateRequest: requestStatus.ok,
            };
        }

        case UPDATE_BID_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                updateRequest: requestStatus.setError(error),
            };
        }

        case DESTROY_SESSION:
        case RESET_BIDS: {
            return {
                ...initialState,
            };
        }
        default:
            return state;
    }
};
