import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './ButtonGroupItem.scss';
import cs from 'classnames';

const cx = classNames.bind(styles);

export type PropsT<T> = {
    className?: string;
    isActive?: boolean;
    testSelector?: string;
    value: T;
    onClick: (value: T) => void;
    children?: React.ReactNode;
};

const ButtonGroupItem = <T,>(props: PropsT<T>): React.ReactElement | null => {
    const { testSelector, isActive, className, children, onClick, value } = props;

    const [isHovered, setHovered] = React.useState<boolean>(false);

    return (
        <div
            className={cs(
                cx('button-group-item', {
                    'button-group-item--isHovered': isHovered,
                    'button-group-item--isActive': isActive,
                }),
                className,
            )}
            data-test-selector={`button-group-item-${testSelector || ''}`}
            onMouseMove={() => {
                if (!isHovered) {
                    setHovered(true);
                }
            }}
            onMouseLeave={() => {
                if (isHovered) {
                    setHovered(false);
                }
            }}
            onClick={() => {
                onClick(value);
            }}
        >
            {children}
        </div>
    );
};

export default React.memo(ButtonGroupItem) as typeof ButtonGroupItem;
