import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ApiSpotBidStatusT, SpotBidStatusEnum } from 'common/utils/api/models';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import { UNIT } from 'common/constants';

type PropsT = {
    className?: string;
    status: ApiSpotBidStatusT | null | undefined;
    children: React.ReactElement;
};

export const SPOT_BID_STATUS_T_MAP: Partial<Record<ApiSpotBidStatusT, string>> = {
    [SpotBidStatusEnum.canceled]: 'spot-bid-status-description.cancelled',
};

const SpotBidStatusDescriptionTooltip: React.FC<PropsT> = React.memo((props) => {
    const { status, children } = props;

    const { t } = useTranslation();

    if (!status) {
        return children;
    }

    const tKey = SPOT_BID_STATUS_T_MAP[status];
    if (!tKey) {
        return children;
    }

    return (
        <Tooltip
            position={TooltipPositionEnum.topCenter}
            theme={TooltipThemeEnum.black}
            tooltipNode={
                <TooltipContent isCenter width={22 * UNIT} theme={TooltipContentThemeEnum.black}>
                    {t(tKey)}
                </TooltipContent>
            }
        >
            {() => <>{children}</>}
        </Tooltip>
    );
});

export default SpotBidStatusDescriptionTooltip;
