import { RequestStatusT } from 'common/utils/request-status';
import { ChangeBidDetailsT } from './models';

export const ACCEPT_BID = 'carrier/bids/ACCEPT_BID';
export const PLACE_BID = 'carrier/bids/PLACE_BID';
export const REJECT_BID = 'carrier/bids/REJECT_BID';

export const UPDATE_BID_REQUEST_BEGIN = 'carrier/bids/UPDATE_BID_REQUEST_BEGIN';
export const UPDATE_BID_REQUEST_ERROR = 'carrier/bids/UPDATE_BID_REQUEST_ERROR';
export const UPDATE_BID_REQUEST_SUCCESS = 'carrier/bids/UPDATE_BID_REQUEST_SUCCESS';

export const RESET_BIDS = 'carrier/bids/RESET_BIDS';

export type BidsStateT = {
    updateRequest: RequestStatusT;
};

export type PlaceBidActionT = {
    type: typeof PLACE_BID;
    details: ChangeBidDetailsT;
    spotRequestId: SpotRequestIdT;
};

export type AcceptBidActionT = {
    type: typeof ACCEPT_BID;
    bidId: BidIdT;
    spotRequestId: SpotRequestIdT;
    details: ChangeBidDetailsT;
};

export type RejectBidActionT = {
    type: typeof REJECT_BID;
    bidId: BidIdT;
    spotRequestId: SpotRequestIdT;
};

export type UpdateBidBeginActionT = {
    type: typeof UPDATE_BID_REQUEST_BEGIN;
};

export type UpdateBidSuccessActionT = {
    type: typeof UPDATE_BID_REQUEST_SUCCESS;
};

export type UpdateBidErrorActionT = {
    type: typeof UPDATE_BID_REQUEST_ERROR;
    error: Error;
};

export type ResetBidsActionT = {
    type: typeof RESET_BIDS;
};

export type BidsActionT =
    | PlaceBidActionT
    | RejectBidActionT
    | UpdateBidBeginActionT
    | UpdateBidSuccessActionT
    | UpdateBidErrorActionT
    | ResetBidsActionT;
