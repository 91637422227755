import { put, select, takeEvery } from 'redux-saga/effects';
import {
    fetchTransportOrdersStatsBegin,
    fetchTransportOrdersStatsError,
    fetchTransportOrdersStatsSuccess,
} from './actions';
import { FETCH_ORDERS_STATS_REQUEST } from './types';
import checkNeedRequest from 'common/utils/check-need-request';
import { selectTransportOrdersStatsRequest } from './selectors';
import { FETCH_ORDERS_PAGE_REQUEST } from 'common/store/transport-orders/types';
import commonTranziitApi from 'common/utils/api/tranziit/common-tranziit-api';

function* fetchTransportOrdersStatsSaga(): WrapGeneratorT<void> {
    const requestStatus: ReturnType<typeof selectTransportOrdersStatsRequest> = yield select(
        selectTransportOrdersStatsRequest,
    );

    const isNeedRequest = checkNeedRequest(requestStatus);
    if (!isNeedRequest) {
        return;
    }

    yield put(fetchTransportOrdersStatsBegin());

    const [error, response]: ReturnApiT<typeof commonTranziitApi.fetchTransportOrdersStatistics> =
        yield commonTranziitApi.fetchTransportOrdersStatistics();
    if (error) {
        yield put(fetchTransportOrdersStatsError(error));
        return;
    }

    yield put(fetchTransportOrdersStatsSuccess(response));
}

function* transportOrdersStatsSaga(): WrapGeneratorT<void> {
    yield takeEvery([FETCH_ORDERS_STATS_REQUEST, FETCH_ORDERS_PAGE_REQUEST], fetchTransportOrdersStatsSaga);
}

export default transportOrdersStatsSaga;
