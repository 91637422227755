import {
    AssignActionT,
    AssignBeginActionT,
    AssignErrorActionT,
    ASSIGNMENT_REQUEST,
    ASSIGNMENT_REQUEST_BEGIN,
    ASSIGNMENT_REQUEST_ERROR,
    ASSIGNMENT_REQUEST_SUCCESS,
    AssignSuccessActionT,
    RE_ASSIGNMENT_REQUEST,
    RE_ASSIGNMENT_REQUEST_BEGIN,
    RE_ASSIGNMENT_REQUEST_ERROR,
    RE_ASSIGNMENT_REQUEST_SUCCESS,
    ReAssignActionT,
    ReAssignBeginActionT,
    ReAssignErrorActionT,
    ReAssignSuccessActionT,
    RESET,
    ResetActionT,
} from './types';

export const assign = (transportationOrderId: TransportOrderIdT, driverIds: DriverIdT[]): AssignActionT => ({
    type: ASSIGNMENT_REQUEST,
    driverIds,
    transportationOrderId,
});

export const assignBegin = (): AssignBeginActionT => ({
    type: ASSIGNMENT_REQUEST_BEGIN,
});

export const assignSuccess = (): AssignSuccessActionT => ({
    type: ASSIGNMENT_REQUEST_SUCCESS,
});

export const assignError = (error: Error): AssignErrorActionT => ({
    type: ASSIGNMENT_REQUEST_ERROR,
    error,
});

export const reAssign = (
    transportationOrderId: TransportOrderIdT | undefined,
    driverIds: DriverIdT[],
): ReAssignActionT => ({
    type: RE_ASSIGNMENT_REQUEST,
    driverIds,
    transportationOrderId,
});

export const reAssignBegin = (): ReAssignBeginActionT => ({
    type: RE_ASSIGNMENT_REQUEST_BEGIN,
});

export const reAssignSuccess = (): ReAssignSuccessActionT => ({
    type: RE_ASSIGNMENT_REQUEST_SUCCESS,
});

export const reAssignError = (error: Error): ReAssignErrorActionT => ({
    type: RE_ASSIGNMENT_REQUEST_ERROR,
    error,
});

export const resetDriverAssignment = (): ResetActionT => ({
    type: RESET,
});
