import { TranziitApiRequestErrorSubTypeEnum } from 'common/utils/api/tranziit/errors/tranziit-api-errors';

export enum FieldsEnum {
    phone = 'phone',
}

export type FormValuesT = {
    [FieldsEnum.phone]: string;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;

export type AsyncFormErrorsT = Partial<{
    [FieldsEnum.phone]: TranziitApiRequestErrorSubTypeEnum.phoneAlreadyExist | string;
}>;
