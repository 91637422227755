import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './SpotRequestsTable.scss';
import { useTranslation } from 'react-i18next';
import Table, { TableColumnT, TableRowMetaT, TableRowModsEnum } from 'common/components/Table/Table';
import MemoizedTable from 'common/components/Table/MemoizedTable/MemoizedTable';
import { SpotInboxRequestT } from 'carrier/store/spot-inbox-requests/models';
import LocationCell from 'common/components/Table/cell-renderers/LocationCell/LocationCell';
import AssetCell from 'carrier/layouts/SpotRequestsPage/SpotRequestListPage/SpotRequestsTable/cell-renderers/AssetCell/AssetCell';
import { formatTimeInterval } from 'common/utils/time';
import BidCell from './cell-renderers/BidCell/BidCell';
import HumanReadableDateCell from 'common/components/Table/cell-renderers/HumanReadableDateCell/HumanReadableDateCell';
import { isNonNil } from 'common/utils';
import SpotBidStatusPill from 'common/components/status-pill/SpotBidStatusPill/SpotBidStatusPill';
import DateCell from 'common/components/Table/cell-renderers/DateCell/DateCell';
import { SpotLoadBoardRequestT } from 'carrier/store/spot-load-board-requests/models';
import LocationLabel from 'common/components/LocationLabel/LocationLabel';

const cx = classNames.bind(styles);

const PLACED_DATE_FORMAT = 'DD MMM YYYY';
const EXPIRATION_DATE_FORMAT = 'DD MMM YYYY, HH:mm';

export type SpotRequestItemsT = SpotInboxRequestT | SpotLoadBoardRequestT;

type PropsT = {
    spotRequests: Array<SpotRequestItemsT>;
    className: string;
    goToSpotRequestDetails: (spotRequestId: SpotRequestIdT) => void;
    isLoading: boolean;
    isLoadBoard: boolean;
};

const SpotRequestsTable: React.FC<PropsT> = React.memo((props) => {
    const { spotRequests, className, goToSpotRequestDetails, isLoading, isLoadBoard } = props;

    const { t } = useTranslation();

    const columns: Array<TableColumnT<SpotRequestItemsT, void>> = React.useMemo(
        () =>
            [
                {
                    renderHeader: () => <span>{t('spot-requests.list.table.columns.origin')}</span>,
                    headerClassName: cx('table__header', 'table__header--origin'),
                    render: (spotRequest: SpotRequestItemsT) => {
                        const firstStop = spotRequest?.stops?.[0] || null;
                        return (
                            <LocationCell
                                address={<LocationLabel format="zip_city_country" location={firstStop?.address} />}
                                time={formatTimeInterval(firstStop?.timeSlotFrom, firstStop?.timeSlotTo)}
                                isSmallTimeFont
                            />
                        );
                    },
                    className: cx('table__column', 'table__column--origin'),
                    testSelector: 'origin',
                },
                {
                    renderHeader: () => <span>{t('spot-requests.list.table.columns.destination')}</span>,
                    headerClassName: cx('table__header'),
                    render: (spotRequest: SpotRequestItemsT) => {
                        const lastStop = spotRequest?.stops?.[(spotRequest?.stops?.length || 0) - 1] || null;

                        return (
                            <LocationCell
                                address={<LocationLabel format="zip_city_country" location={lastStop?.address} />}
                                time={formatTimeInterval(lastStop?.timeSlotFrom, lastStop?.timeSlotTo)}
                                isSmallTimeFont
                            />
                        );
                    },
                    className: cx('table__column', 'table__column--destination'),
                    testSelector: 'destination',
                },
                {
                    renderHeader: () => <span>{t('spot-requests.list.table.columns.placed')}</span>,
                    headerClassName: cx('table__header'),
                    render: (spotRequest: SpotRequestItemsT) => {
                        return (
                            <HumanReadableDateCell
                                isBold
                                date={spotRequest.placedDate}
                                dateFormat={PLACED_DATE_FORMAT}
                            />
                        );
                    },
                    className: cx('table__column'),
                    testSelector: 'placed',
                },
                {
                    renderHeader: () => <span>{t('spot-requests.list.table.columns.expiration')}</span>,
                    headerClassName: cx('table__header'),
                    render: (spotRequest: SpotRequestItemsT) => {
                        return <DateCell isBold date={spotRequest.expiration} dateFormat={EXPIRATION_DATE_FORMAT} />;
                    },
                    className: cx('table__column'),
                    testSelector: 'expiration',
                },
                isLoadBoard
                    ? null
                    : {
                          renderHeader: () => <span>{t('spot-requests.list.table.columns.bid')}</span>,
                          headerClassName: cx('table__header'),
                          render: (spotRequest: SpotRequestItemsT) => {
                              if ('bidPrice' in spotRequest) {
                                  return <BidCell price={spotRequest?.bidPrice} />;
                              }

                              return null;
                          },
                          className: cx('table__column'),
                          testSelector: 'bids',
                      },
                {
                    renderHeader: () => <span>{t('spot-requests.list.table.columns.trailer')}</span>,
                    headerClassName: cx('table__header'),
                    render: (spotRequest: SpotRequestItemsT) => {
                        return <AssetCell spotRequest={spotRequest} />;
                    },
                    className: cx('table__column'),
                    testSelector: 'asset',
                },
                isLoadBoard
                    ? null
                    : {
                          renderHeader: () => <span>{t('spot-requests.list.table.columns.status')}</span>,
                          headerClassName: cx('table__header', 'table__header--status'),
                          render: (spotRequest: SpotRequestItemsT) => {
                              if ('bidStatus' in spotRequest) {
                                  return <SpotBidStatusPill isSymmetrical status={spotRequest.bidStatus} />;
                              }

                              return null;
                          },
                          className: cx('table__column', 'table__column--status'),
                          testSelector: 'status',
                      },
            ].filter(isNonNil),
        [t, isLoadBoard],
    );

    const handleSelectRow = (event: React.MouseEvent, spotRequest: SpotRequestItemsT) => {
        if (!spotRequest.id) {
            return;
        }

        goToSpotRequestDetails(spotRequest.id);
    };

    const getRowMods = (meta: TableRowMetaT, spotRequest: SpotRequestItemsT) => {
        return {
            [TableRowModsEnum.isHighlighted]: 'unreadByCarrier' in spotRequest && !!spotRequest?.unreadByCarrier,
        };
    };

    return (
        <div className={cx('table')}>
            <MemoizedTable<SpotRequestItemsT> tableName="spot-requests" isLoading={isLoading} rows={spotRequests}>
                {(rows, isUsedPrevRows) => (
                    <Table<SpotRequestItemsT, void>
                        testSelector="spot-requests"
                        className={className}
                        columns={columns}
                        rows={rows}
                        getRowMods={getRowMods}
                        onSelectRow={handleSelectRow}
                        isLoading={isLoading}
                        isUsedPrevRows={isUsedPrevRows}
                    />
                )}
            </MemoizedTable>
        </div>
    );
});

export default SpotRequestsTable;
