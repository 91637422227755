import * as React from 'react';
import NotificationLabel, {
    NotificationLabelThemeEnum,
} from 'common/components/notifications/NotificationLabel/NotificationLabel';
import { selectTrailersStats } from 'common/store/trailers/selectors';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import { useSelector } from 'react-redux';

type PropsT = {
    className?: string;
};

const ActiveFleetCount: React.FC<PropsT> = React.memo((props) => {
    const { className } = props;

    const { partnerId } = usePartnerContext();

    const trailersStats = useSelector(selectTrailersStats(partnerId));
    if (!trailersStats || !trailersStats?.actualTotalCount) {
        return null;
    }
    return (
        <NotificationLabel
            className={className}
            count={trailersStats?.actualTotalCount}
            theme={NotificationLabelThemeEnum.charcoal}
        />
    );
});

export default ActiveFleetCount;
