import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    className?: string;
    strokeColor: StyleGuideColorsEnum;
};

const AttachIcon: React.FC<PropsT> = (props) => {
    const { className, strokeColor, ...restProps } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={20}
            height={20}
            viewBox="0 0 26 26"
            className={className}
            {...restProps}
        >
            <g fill="none" fillRule="evenodd">
                <path d="M13 .272L25.728 13 13 25.728.272 13z" />
                <path
                    fill={strokeColor}
                    d="M7.432 19.099a4.123 4.123 0 005.833 0L19.364 13l-.796-.795-6.098 6.098a3 3 0 11-4.243-4.242l6.63-6.63a1.876 1.876 0 012.65 2.652l-5.568 5.569a.752.752 0 01-1.06 0 .752.752 0 010-1.061l5.038-5.038-.796-.796-5.038 5.038a1.876 1.876 0 002.652 2.652l5.568-5.568a3 3 0 10-4.242-4.243l-6.63 6.63a4.123 4.123 0 000 5.833z"
                />
            </g>
        </svg>
    );
};

AttachIcon.displayName = 'AttachIcon';

const storyProps: PropsT = {
    strokeColor: StyleGuideColorsEnum.charcoal,
};

export { storyProps };
export default AttachIcon;
