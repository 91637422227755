import { FetchSpotLoadBoardRequestsPageQueryT, SpotLoadBoardRequestT } from './models';

import { PageResponseT, PaginationStateT } from 'common/utils/pagination/models';

export const FETCH_SPOT_LOAD_BOARD_REQUESTS_PAGE_REQUEST =
    'carrier/sport-requests/FETCH_SPOT_LOAD_BOARD_REQUESTS_PAGE_REQUEST';
export const FETCH_SPOT_LOAD_BOARD_REQUESTS_PAGE_REQUEST_BEGIN =
    'carrier/sport-requests/FETCH_SPOT_LOAD_BOARD_REQUESTS_PAGE_REQUEST_BEGIN';
export const FETCH_SPOT_LOAD_BOARD_REQUESTS_PAGE_REQUEST_ERROR =
    'carrier/sport-requests/FETCH_SPOT_LOAD_BOARD_REQUESTS_PAGE_REQUEST_ERROR';
export const FETCH_SPOT_LOAD_BOARD_REQUESTS_PAGE_REQUEST_SUCCESS =
    'carrier/sport-requests/FETCH_SPOT_LOAD_BOARD_REQUESTS_PAGE_REQUEST_SUCCESS';

export const RESET_SPOT_LOAD_BOARD_REQUESTS = 'carrier/sport-requests/RESET_SPOT_LOAD_BOARD_REQUESTS';

export type SpotLoadBoardRequestsStateT = PaginationStateT<SpotLoadBoardRequestT, FetchSpotLoadBoardRequestsPageQueryT>;

export type FetchSpotLoadBoardRequestsPageActionT = {
    type: typeof FETCH_SPOT_LOAD_BOARD_REQUESTS_PAGE_REQUEST;
    pageNumber: PageNumberT;
    query: FetchSpotLoadBoardRequestsPageQueryT;
    options?: FetchOptionsT;
};

export type FetchSpotLoadBoardRequestsPageBeginActionT = {
    type: typeof FETCH_SPOT_LOAD_BOARD_REQUESTS_PAGE_REQUEST_BEGIN;
    pageNumber: PageNumberT;
    query: FetchSpotLoadBoardRequestsPageQueryT;
};

export type FetchSpotLoadBoardRequestsPageSuccessActionT = {
    type: typeof FETCH_SPOT_LOAD_BOARD_REQUESTS_PAGE_REQUEST_SUCCESS;
    pageNumber: PageNumberT;
    pageResponse: PageResponseT<SpotLoadBoardRequestT> | null;
    query: FetchSpotLoadBoardRequestsPageQueryT;
};

export type FetchSpotLoadBoardRequestsPageErrorActionT = {
    type: typeof FETCH_SPOT_LOAD_BOARD_REQUESTS_PAGE_REQUEST_ERROR;
    query: FetchSpotLoadBoardRequestsPageQueryT;
    pageNumber: PageNumberT;
    error: Error;
};

export type ResetSpotLoadBoardRequestsActionT = {
    type: typeof RESET_SPOT_LOAD_BOARD_REQUESTS;
    mutationListOptions: MutationListOptionsT;
};

export type SpotLoadBoardRequestsActionT =
    | FetchSpotLoadBoardRequestsPageActionT
    | FetchSpotLoadBoardRequestsPageBeginActionT
    | FetchSpotLoadBoardRequestsPageSuccessActionT
    | FetchSpotLoadBoardRequestsPageErrorActionT
    | ResetSpotLoadBoardRequestsActionT;
