import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { TransportOrdersRoutesEnum } from 'carrier/constants';
import TransportOrderListPage from 'carrier/layouts/TransportOrdersPage/TransportOrderListPage/TransportOrderListPage';
import TransportOrderDetailsPage from 'carrier/layouts/TransportOrdersPage/TransportOrderDetailsPage/TransportOrderDetailsPage';

type PropsT = {};

type QueryT = {};

const TransportOrdersPage: React.FC<PropsT> = React.memo((props) => {
    return (
        <Switch>
            <Route path={TransportOrdersRoutesEnum.details}>
                <TransportOrderDetailsPage />
            </Route>
            <Route path={TransportOrdersRoutesEnum.list}>
                <TransportOrderListPage />
            </Route>
        </Switch>
    );
});

export default TransportOrdersPage;
