import { TFunction } from 'i18next';

import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import { checkNotEmpty } from 'common/utils/form-validators';

const validate = (t: TFunction, values: FormValuesT, isTeamDrive: boolean): FormErrorsT => {
    const requiredFields = isTeamDrive ? [FieldsEnum.driverId, FieldsEnum.teamDriverId] : [FieldsEnum.driverId];

    const errors: FormErrorsT = {
        ...checkNotEmpty(t, requiredFields, values),
    };

    const driverId = values[FieldsEnum.driverId];
    const teamDriverId = values[FieldsEnum.teamDriverId];
    if (driverId && teamDriverId && driverId === teamDriverId) {
        errors[FieldsEnum.teamDriverId] = t('common:driver-assignment.errors.same-drivers');
    }

    return errors;
};

export default validate;
