import history from 'common/utils/history';
import { useLocation } from 'react-router-dom';

export const useStateSafeHistory = () => {
    const location = useLocation<{}>();

    const replace = (nextLocation: Partial<Location>) => {
        history.replace({
            ...location,
            ...nextLocation,
        });
    };

    const push = (nextLocation: Partial<Location>) => {
        history.push({
            ...location,
            ...nextLocation,
        });
    };

    return {
        stateSafeHistory: {
            replace,
            push,
        },
    };
};
