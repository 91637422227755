import { SpotInboxRequestsStateT } from './types';
import { StateT } from '../models';

export const selectSpotInboxRequestsPages = (state: StateT): SpotInboxRequestsStateT['pages'] =>
    state.spotInboxRequests.pages;

export const selectSpotInboxRequestsQuery = (state: StateT): SpotInboxRequestsStateT['query'] =>
    state.spotInboxRequests.query;

export const selectSpotInboxRequestsTotal = (state: StateT): SpotInboxRequestsStateT['total'] =>
    state.spotInboxRequests.total;

export const selectSpotInboxRequestsByIds = (state: StateT): SpotInboxRequestsStateT['byId'] =>
    state.spotInboxRequests.byId;
