import { RequestStatusT } from 'common/utils/request-status';
import { SpotCarrierLanesChangesT } from './models';

export const DELETE_SPOT_CARRIER_LANES = 'common/spot-carrier-lane-changes/DELETE_SPOT_CARRIER_LANES';
export const CREATE_SPOT_CARRIER_LANES = 'common/spot-carrier-lane-changes/CREATE_SPOT_CARRIER_LANES';
export const UPDATE_SPOT_CARRIER_LANES = 'common/spot-carrier-lane-changes/UPDATE_SPOT_CARRIER_LANES';

export const CHANGE_SPOT_CARRIER_LANE_REQUEST_BEGIN =
    'common/spot-carrier-lane-changes/CHANGE_SPOT_CARRIER_LANE_REQUEST_BEGIN';
export const CHANGE_SPOT_CARRIER_LANE_REQUEST_ERROR =
    'common/spot-carrier-lane-changes/CHANGE_SPOT_CARRIER_LANE_REQUEST_ERROR';
export const CHANGE_SPOT_CARRIER_LANE_REQUEST_SUCCESS =
    'common/spot-carrier-lane-changes/CHANGE_SPOT_CARRIER_LANE_REQUEST_SUCCESS';

export type SpotCarrierLaneChangesStateT = {
    request: RequestStatusT;
    changes: SpotCarrierLanesChangesT | null;
};

export type DeleteSpotCarrierLanesActionT = {
    type: typeof DELETE_SPOT_CARRIER_LANES;
    changes: SpotCarrierLanesChangesT;
};

export type CreateSpotCarrierLanesActionT = {
    type: typeof CREATE_SPOT_CARRIER_LANES;
    changes: SpotCarrierLanesChangesT;
};

export type UpdateSpotCarrierLanesActionT = {
    type: typeof UPDATE_SPOT_CARRIER_LANES;
    changes: SpotCarrierLanesChangesT;
};

export type ChangeSpotCarrierLaneRequestBeginActionT = {
    type: typeof CHANGE_SPOT_CARRIER_LANE_REQUEST_BEGIN;
    changes: SpotCarrierLanesChangesT;
};

export type ChangeSpotCarrierLaneRequestSuccessActionT = {
    type: typeof CHANGE_SPOT_CARRIER_LANE_REQUEST_SUCCESS;
    changes: SpotCarrierLanesChangesT;
};

export type ChangeSpotCarrierLaneRequestErrorActionT = {
    type: typeof CHANGE_SPOT_CARRIER_LANE_REQUEST_ERROR;
    changes: SpotCarrierLanesChangesT;
    error: Error;
};

export type SpotCarrierLaneChangesActionT =
    | CreateSpotCarrierLanesActionT
    | UpdateSpotCarrierLanesActionT
    | DeleteSpotCarrierLanesActionT
    | ChangeSpotCarrierLaneRequestBeginActionT
    | ChangeSpotCarrierLaneRequestSuccessActionT
    | ChangeSpotCarrierLaneRequestErrorActionT;
