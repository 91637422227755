import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './TourPapersCard.scss';
import { StyleGuideColorsEnum } from 'common/constants';
import AttachIcon from 'common/icons/AttachIcon';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import CloseIcon from 'common/icons/CloseIcon';
import ControlLoaderWithShadow from 'common/components/ControlLoaderWithShadow/ControlLoaderWithShadow';
import ImageGalleryModal from './ImageGalleryModal/ImageGalleryModal';
import { deletePaper, fetchPapers, uploadPaper } from 'common/store/papers/actions';
import { useDispatch, useSelector } from 'react-redux';
import { logWarning } from 'common/utils/logger';
import {
    selectDeletePaperQuery,
    selectDeletePaperRequest,
    selectParersList,
    selectUploadPaperRequest,
} from 'common/store/papers/selectors';
import ImageLoader from './ImageLoader/ImageLoader';
import keyBy from 'lodash/keyBy';
import { PaperDataT } from 'common/store/papers/models';
import useModalDialog from 'common/utils/hooks/useModalDialog';
import DeletePaperConfirmation, {
    DeletePaperConfirmationDataT,
} from './dialogs/DeletePaperConfirmation/DeletePaperConfirmation';
import { pickFile } from 'common/utils/pick-file';
import useCloseModalDialogAfterRequest from 'common/utils/hooks/useCloseModalDialogAfterRequest';
import Card from 'design-system/components/Card/Card';
import PillLabel, { PillLabelThemeEnum } from '../PillLabel/PillLabel';
import { useTranslation } from 'react-i18next';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';

export type PropsT = {
    title: React.ReactNode;
    tourId: TourIdT | null;
    isBroker: boolean;
    className?: string;
};

const cx = classNames.bind(styles);

const getAlt = (paperData: PaperDataT | null) => (paperData ? `${paperData.comment} ${paperData.createdDate}` : null);

const urlFactory = {
    getContentUrl: (isBroker: boolean, tourId: TourIdT | null, documentId: DocumentIdT | undefined): string | null => {
        if (!tourId || !documentId) {
            return null;
        }

        if (isBroker) {
            return `/api/v1/broker/tour/${tourId}/documents/${documentId}/content`;
        }

        return `/api/v1/carrier/tour/${tourId}/documents/${documentId}/content`;
    },

    getThumbnailUrl: (
        isBroker: boolean,
        tourId: TourIdT | null,
        documentId: DocumentIdT | undefined,
    ): string | null => {
        if (!tourId || !documentId) {
            return null;
        }

        if (isBroker) {
            return `/api/v1/broker/tour/${tourId}/documents/${documentId}/thumbnail`;
        }

        return `/api/v1/carrier/tour/${tourId}/documents/${documentId}/thumbnail`;
    },
};

const TourPapersCard: React.FC<PropsT> = React.memo((props) => {
    const { title, tourId, isBroker, className } = props;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [paperId, setPaperId] = React.useState<PapersIdT | null>(null);

    const handleOpen = (id: string | null, event: React.MouseEvent<HTMLElement>): void => {
        event.stopPropagation();

        setPaperId(id);
    };

    const handleDelete = (paperId: PapersIdT | undefined, event: React.MouseEvent<HTMLElement>): void => {
        event.stopPropagation();

        if (!paperId) {
            logWarning('failed delete paper, empty paperId');
            return;
        }

        if (!tourId) {
            logWarning('failed delete paper, empty tourId');
            return;
        }

        deletePaperConfirmationDialog.setData({
            tourId,
            isBroker,
            paperId,
        });
    };

    const handleDeletePaperConfirmation = () => {
        if (!deletePaperConfirmationDialog.data) {
            return;
        }

        const { tourId, isBroker, paperId } = deletePaperConfirmationDialog.data;
        dispatch(deletePaper(tourId, isBroker, paperId));
    };

    const handleAttach = (event: React.MouseEvent<HTMLElement>): void => {
        event.stopPropagation();

        pickFile('image/*', (file) => {
            if (!tourId) {
                logWarning('failed upload paper, empty tourId');
                return;
            }

            dispatch(uploadPaper(tourId, isBroker, file));
        });
    };

    React.useEffect(() => {
        if (!tourId) {
            logWarning(`skip fetch tour papers, empty tourId: ${tourId}`);
            return;
        }

        dispatch(fetchPapers(tourId, isBroker));
    }, [tourId, isBroker]);

    const list = useSelector(selectParersList(tourId));
    const uploadRequest = useSelector(selectUploadPaperRequest(tourId));
    const deleteRequest = useSelector(selectDeletePaperRequest(tourId));
    const deleteQuery = useSelector(selectDeletePaperQuery(tourId));

    const deletePaperConfirmationDialog = useModalDialog<DeletePaperConfirmationDataT>();
    useCloseModalDialogAfterRequest(deleteRequest, [deletePaperConfirmationDialog]);

    const byId = React.useMemo(() => {
        return keyBy(list, 'id');
    }, [list]);

    const modalPaperImage = paperId ? byId[paperId] : null;

    return (
        <>
            <Card
                titleNode={title}
                rightNode={
                    <>
                        <PillLabel theme={PillLabelThemeEnum.slate} isSymmetrical>
                            {list?.length || 0}
                        </PillLabel>
                        <Tooltip
                            position={TooltipPositionEnum.topCenter}
                            theme={TooltipThemeEnum.black}
                            tooltipNode={
                                <TooltipContent isNoWrap theme={TooltipContentThemeEnum.black}>
                                    {t('common:tooltips.attach-paper')}
                                </TooltipContent>
                            }
                        >
                            {() => (
                                <TransparentTrigger
                                    onClick={handleAttach}
                                    reflectionTheme={ReflectionThemeEnum.light}
                                    leftIcon={<AttachIcon strokeColor={StyleGuideColorsEnum.charcoal} />}
                                    testSelector="attach"
                                />
                            )}
                        </Tooltip>
                    </>
                }
                className={className}
                hasHeaderBottomBorder
            >
                <div className={cx('content')}>
                    {list.map((paperData, index) => {
                        const isDeleting = deleteQuery?.id === paperData?.id;

                        return (
                            <div
                                key={paperData.id}
                                className={cx('content__thumbnail', 'thumbnail')}
                                onClick={(event) => {
                                    handleOpen(paperData.id || null, event);
                                }}
                            >
                                <ImageLoader
                                    className={cx('thumbnail__img')}
                                    alt={getAlt(paperData)}
                                    src={urlFactory.getThumbnailUrl(isBroker, tourId, paperData.id)}
                                />
                                {!isDeleting && (
                                    <Tooltip
                                        className={cx('thumbnail__control')}
                                        position={TooltipPositionEnum.topCenter}
                                        theme={TooltipThemeEnum.black}
                                        tooltipNode={
                                            <TooltipContent isNoWrap theme={TooltipContentThemeEnum.black}>
                                                {t('common:tooltips.remove')}
                                            </TooltipContent>
                                        }
                                    >
                                        {() => (
                                            <TransparentTrigger
                                                onClick={(event) => {
                                                    handleDelete(paperData.id, event);
                                                }}
                                                reflectionTheme={ReflectionThemeEnum.light}
                                                leftIcon={<CloseIcon fillColor={StyleGuideColorsEnum.charcoal} />}
                                                testSelector="attach"
                                                spaces="xs"
                                                isPressed
                                            />
                                        )}
                                    </Tooltip>
                                )}
                                {isDeleting && (
                                    <div className={cx('loader__container', 'loader__container--is-paranja')}>
                                        <ControlLoaderWithShadow
                                            className={cx('loader')}
                                            fillColor={StyleGuideColorsEnum.light}
                                        />
                                    </div>
                                )}
                            </div>
                        );
                    })}
                    {!list?.length && !uploadRequest.loading && (
                        <div className={cx('placeholder')}>{t('common:papers-card.no-photos')}</div>
                    )}
                    {uploadRequest.loading && (
                        <div key="add" className={cx('content__thumbnail', 'thumbnail')}>
                            <div className={cx('loader__container')}>
                                <ControlLoaderWithShadow
                                    className={cx('loader')}
                                    fillColor={StyleGuideColorsEnum.light}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </Card>
            <ImageGalleryModal
                alt={getAlt(modalPaperImage)}
                src={urlFactory.getContentUrl(isBroker, tourId, modalPaperImage?.id)}
                onClose={() => {
                    setPaperId(null);
                }}
            />
            <DeletePaperConfirmation
                data={deletePaperConfirmationDialog.data}
                onConfirm={handleDeletePaperConfirmation}
                onCancel={deletePaperConfirmationDialog.onCancel}
                onClose={deletePaperConfirmationDialog.onClose}
                requestStatus={deleteRequest}
            />
        </>
    );
});
export default TourPapersCard;
