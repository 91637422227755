import { RequestStatusT } from 'common/utils/request-status';

import { DriverT, SearchDriverQueryT } from './models';

export const FETCH_DRIVERS_REQUEST = 'common/driver-search/FETCH_DRIVERS_REQUEST';
export const FETCH_DRIVERS_REQUEST_BEGIN = 'common/driver-search/FETCH_DRIVERS_REQUEST_BEGIN';
export const FETCH_DRIVERS_REQUEST_ERROR = 'common/driver-search/FETCH_DRIVERS_REQUEST_ERROR';
export const FETCH_DRIVERS_REQUEST_SUCCESS = 'common/driver-search/FETCH_DRIVERS_REQUEST_SUCCESS';

type ManagerIdT = string;

export type DriverSearchStateT = {
    request: RequestStatusT;
    query: SearchDriverQueryT;
    allIds: Array<DriverIdT>;
    byId: Record<DriverIdT, DriverT>;
};

export type FetchDriversActionT = {
    type: typeof FETCH_DRIVERS_REQUEST;
    query: SearchDriverQueryT;
};

export type FetchDriversBeginActionT = {
    type: typeof FETCH_DRIVERS_REQUEST_BEGIN;
    query: SearchDriverQueryT;
};

export type FetchDriversSuccessActionT = {
    type: typeof FETCH_DRIVERS_REQUEST_SUCCESS;
    drivers: DriverT[];
    query: SearchDriverQueryT;
};

export type FetchDriversErrorActionT = {
    type: typeof FETCH_DRIVERS_REQUEST_ERROR;
    error: Error;
    query: SearchDriverQueryT;
};

export type DriverSearchActionT =
    | FetchDriversActionT
    | FetchDriversBeginActionT
    | FetchDriversSuccessActionT
    | FetchDriversErrorActionT;
