import { put, takeEvery } from 'redux-saga/effects';
import {
    changeSpotCarrierLaneRequestBegin,
    changeSpotCarrierLaneRequestError,
    changeSpotCarrierLaneRequestSuccess,
} from './actions';
import {
    CREATE_SPOT_CARRIER_LANES,
    CreateSpotCarrierLanesActionT,
    DELETE_SPOT_CARRIER_LANES,
    DeleteSpotCarrierLanesActionT,
    UPDATE_SPOT_CARRIER_LANES,
    UpdateSpotCarrierLanesActionT,
} from './types';
import spotCarrierTranziitApi from 'carrier/utils/api/spot-carrier-tranziit/api';
import { addAlert } from 'common/store/alerts/actions';
import { CommonAlertTypeEnum, CommonAnyAlert } from 'common/components/toasts/AlertToastsManager/models';
import { spotCarrierLanesRefreshChannel } from 'carrier/store/spot-carrier-lanes/channels';

function* deleteSpotCarrierLanesSaga(action: DeleteSpotCarrierLanesActionT): WrapGeneratorT<void> {
    const { changes } = action;

    yield put(changeSpotCarrierLaneRequestBegin(changes));

    const [error]: ReturnApiT<typeof spotCarrierTranziitApi.deleteCarrierLanes> =
        yield spotCarrierTranziitApi.deleteCarrierLanes(changes);
    if (error) {
        yield put(changeSpotCarrierLaneRequestError(changes, error));
        return;
    }

    yield put(changeSpotCarrierLaneRequestSuccess(changes));

    yield put(
        addAlert(
            new CommonAnyAlert({
                type: CommonAlertTypeEnum.carrierSpotLanesDeleteSuccess,
                data: {
                    count: changes.length,
                },
            }),
        ),
    );

    spotCarrierLanesRefreshChannel.emit({});
}

function* updateSpotCarrierLanesSaga(
    action: CreateSpotCarrierLanesActionT | UpdateSpotCarrierLanesActionT,
): WrapGeneratorT<void> {
    const { changes } = action;

    yield put(changeSpotCarrierLaneRequestBegin(changes));

    const [error]: ReturnApiT<typeof spotCarrierTranziitApi.updateCarrierLanes> =
        yield spotCarrierTranziitApi.updateCarrierLanes(changes);
    if (error) {
        yield put(changeSpotCarrierLaneRequestError(changes, error));
        return;
    }

    yield put(changeSpotCarrierLaneRequestSuccess(changes));

    yield put(
        addAlert(
            new CommonAnyAlert({
                type: CommonAlertTypeEnum.carrierSpotLanesUpdateSuccess,
                data: {
                    count: changes.length,
                },
            }),
        ),
    );

    spotCarrierLanesRefreshChannel.emit({});
}

function* spotCarrierLaneChangesSaga(): WrapGeneratorT<void> {
    yield takeEvery(DELETE_SPOT_CARRIER_LANES, deleteSpotCarrierLanesSaga);
    yield takeEvery([UPDATE_SPOT_CARRIER_LANES, CREATE_SPOT_CARRIER_LANES], updateSpotCarrierLanesSaga);
}

export default spotCarrierLaneChangesSaga;
