import * as React from 'react';
import { ApiTransportationOrderTypeT, TransportationOrderTypeEnum } from 'common/utils/api/models';
import { useTranslation } from 'react-i18next';

type PropsT = {
    transportOrderType?: ApiTransportationOrderTypeT | null;
};

const i18nMap: Record<ApiTransportationOrderTypeT, string> = {
    [TransportationOrderTypeEnum.train]: 'common:asset-type.truck-and-trailer',
    [TransportationOrderTypeEnum.trailer]: 'common:asset-type.trailer',
    [TransportationOrderTypeEnum.truck]: 'common:asset-type.truck',
};

const TransportOrderTypeFormatter: React.FC<PropsT> = React.memo((props) => {
    const { transportOrderType } = props;

    const { t } = useTranslation();

    if (!transportOrderType) {
        return null;
    }

    return <span>{t(i18nMap[transportOrderType])}</span>;
});

export default TransportOrderTypeFormatter;
