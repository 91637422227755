import * as React from 'react';
import { CommonSidebarsTypeEnum, SidebarContentPropsT } from 'common/layouts/SideBars/models';
import { SpotRequestDetailsSidebarDataT } from './models';
import BaseSpotRequestDetailsSidebarContent from 'carrier/components/BaseSpotRequestDetailsSidebarContent/BaseSpotRequestDetailsSidebarContent';
import { urlFactory } from 'carrier/utils/urls';
import history from 'common/utils/history';
import { CarrierSidebarDataT } from 'carrier/layouts/SideBars/models';
import { logWarning } from 'common/utils/logger';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';

type PropsT = SidebarContentPropsT<SpotRequestDetailsSidebarDataT, CarrierSidebarDataT>;

const SpotRequestDetailsSidebarContent: React.FC<PropsT> = React.memo((props) => {
    const { data, onClose, onOpenNextSidebar } = props;

    const { partnerType, partnerId } = usePartnerContext();

    const handleOpenMap = () => {
        const url = urlFactory.spotRequestDetails(data.id);
        history.push(url);
    };

    const handleOpenUserDetails = (userId: UserIdT | null) => {
        if (!userId) {
            logWarning('failed to open user details in spot request details sidebar, empty userId');
            return;
        }

        onOpenNextSidebar({
            type: CommonSidebarsTypeEnum.contact,
            partnerId,
            partnerType,
            userId,
        });
    };

    return (
        <BaseSpotRequestDetailsSidebarContent
            id={data.id}
            onClose={onClose}
            toggleMap={handleOpenMap}
            onOpenUserDetails={handleOpenUserDetails}
        />
    );
});

export default SpotRequestDetailsSidebarContent;
