import { SpotCarrierLaneT } from 'carrier/store/spot-carrier-lanes/models';
import {
    SpotCarrierLanesChangesT,
    SpotCarrierLanesTrailerVariantEnum,
} from 'carrier/store/spot-carrier-lane-changes/models';

const prepareDeleteChanges = (selectedLanesSet: Set<SpotCarrierLaneT>): SpotCarrierLanesChangesT => {
    const selectedLanes = [...selectedLanesSet];

    const changes: SpotCarrierLanesChangesT = [];

    selectedLanes.forEach((lane) => {
        changes.push({
            from: lane.from,
            to: lane.to,
            trailerType: lane.trailerType,
        });
    });

    return changes;
};

const prepareChangeTrailerVariantChanges = (
    selectedLanesSet: Set<SpotCarrierLaneT>,
    trailerVariant: SpotCarrierLanesTrailerVariantEnum,
): SpotCarrierLanesChangesT => {
    const selectedLanes = [...selectedLanesSet];

    const changes: SpotCarrierLanesChangesT = [];

    selectedLanes.forEach((lane) => {
        changes.push({
            from: lane.from,
            to: lane.to,
            trailerType: trailerVariant,
        });
    });

    return changes;
};

export { prepareDeleteChanges, prepareChangeTrailerVariantChanges };
