import React from 'react';
import MapIcon from 'common/icons/MapIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';

export type MapTriggerPropsT = {
    isActiveMapTrigger?: boolean;
    toggleMap: () => void;
};

const MapTrigger: React.FC<MapTriggerPropsT> = (props) => {
    const { toggleMap, isActiveMapTrigger } = props;

    return (
        <TransparentTrigger
            onClick={toggleMap}
            isToggled={isActiveMapTrigger}
            leftIcon={
                <MapIcon
                    size={DEFAULT_ICON_SIZE}
                    fillColor={StyleGuideColorsEnum.gray}
                    strokeColor={StyleGuideColorsEnum.slate}
                />
            }
            reflectionTheme={ReflectionThemeEnum.light}
        />
    );
};

export default MapTrigger;
