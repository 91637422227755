export type ApiSpotInboxRequestT = SpotApi.Definitions.CarrierSpotInboxListDto;
export type ApiSpotLoadBoardRequestT = SpotApi.Definitions.CarrierSpotLoadBoardListDto;

export type ApiBidStatusT = NonNullable<SpotApi.Definitions.CarrierSpotInboxListDto['bidStatus']>;
export enum BidStatusEnum {
    new = 'NEW',
    open = 'OPEN',
    accepted = 'ACCEPTED',
    rejected = 'REJECTED',
    expired = 'EXPIRED',
    won = 'WON',
    lost = 'LOST',
    missed = 'MISSED',
    canceled = 'CANCELED',
}

export type ApiInboxRequestsStatsT = Array<SpotApi.Definitions.BidStatusesDto>;

export enum SpotRequestsSortEnum {
    expirationTime = 'deadline',
    placedTime = 'createdDate',
    originTime = 'originTimeSlotFrom',
    destinationTime = 'destinationTimeSlotFrom',
}

export type ApiSpotRequestDetailsT = SpotApi.Definitions.CarrierSpotDto;

export type ApiSpotTourDetailsT = Api2.Definitions.SpotTourInfo;
export type ApiSpotStopT = SpotApi.Definitions.StopDto;

export type ApiSpotCarrierLaneT = SpotApi.Definitions.CarrierLaneDto;
export type ApiSpotCarrierLanesRequestT = SpotApiPaths.SpotApiV1CarrierLane.Get.QueryParameters;
export type ApiSpotCarrierLanesChangesT = Array<SpotApi.Definitions.CreateOrUpdateLaneDto>;
export type ApiSpotCarrierLanesTrailerVariantT = SpotApi.Definitions.CreateOrUpdateLaneDto['trailerType'];
