import { authRoutesEnum, CommonRoutesEnum } from 'common/constants';

export enum CarrierProfileRoutesEnum {
    spotLanes = '/profile/spot-lanes',
}

export const routesEnum = {
    home: authRoutesEnum.home,
    signIn: authRoutesEnum.signIn,
    signUp: authRoutesEnum.signUp,
    continueSignUp: authRoutesEnum.continueSignUp,
    forgotPassword: authRoutesEnum.forgotPassword,
    changePassword: authRoutesEnum.changePassword,
    confirmEmail: authRoutesEnum.confirmEmail,
    selfCreateCompany: authRoutesEnum.selfCreateCompany,

    accessDenied: CommonRoutesEnum.accessDenied,
    notFound: CommonRoutesEnum.notFound,
    profile: CommonRoutesEnum.profile,
    account: CommonRoutesEnum.account,
    team: CommonRoutesEnum.team,

    spotRequests: '/spot',
    archive: '/archive',
    trucks: '/trucks',
    trailers: '/trailers',
    drivers: '/drivers',
    transportOrders: CommonRoutesEnum.transportOrders,
    orders: '/orders',
};

export enum TransportOrdersRoutesEnum {
    list = '/transport-orders',
    details = '/transport-orders/order/:transportOrderId',
}

export enum TrucksRoutesEnum {
    list = '/trucks/list',
    details = '/trucks/:truckId/details',
}

export enum TrailersRoutesEnum {
    list = '/trailers/list',
    details = '/trailers/:trailerId/details',
}

export enum SpotRoutesEnum {
    list = '/spot',
    spotRequestDetails = '/spot/:spotRequestId',
}

export default {};
