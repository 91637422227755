import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './TransportOrderDetailsPage.scss';
import TransportOrderDetailsPageLayout from 'common/layouts/TransportOrderDetailsPageLayout/TransportOrderDetailsPageLayout';
import { urlFactory } from 'carrier/utils/urls';
import history from 'common/utils/history';
import { logWarning } from 'common/utils/logger';
import { useSelector } from 'react-redux';
import { selectTransportOrderDetails } from 'common/store/transport-order-details/selectors';
import PageTitle from 'common/components/PageTitle/PageTitle';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useOpenLeftSidebar } from 'carrier/layouts/SideBars/hooks';
import { CommonSidebarsTypeEnum } from 'common/layouts/SideBars/models';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import SideBars from 'carrier/layouts/SideBars/SideBars';
import { CarrierSidebarsTypeEnum } from 'carrier/layouts/SideBars/constants';

const cx = classNames.bind(styles);

type PropsT = {};

const TransportOrderDetailsPage: React.FC<PropsT> = React.memo(() => {
    const { t } = useTranslation();

    const params = useParams<{ transportOrderId: string }>();
    const { transportOrderId } = params;

    const { partnerId } = usePartnerContext();

    const openLeftSidebar = useOpenLeftSidebar();

    const transportOrderDetails = useSelector(selectTransportOrderDetails(transportOrderId));

    const goToBack = React.useCallback(() => {
        const url = urlFactory.transportOrders();
        history.push(url);
    }, []);

    const goToTrailerDetails = React.useCallback(
        (trailerId: TrailerIdT | null) => {
            if (!trailerId) {
                logWarning('empty trailerId');
                return;
            }

            openLeftSidebar(
                {
                    type: CommonSidebarsTypeEnum.trailerDetails,
                    trailerId,
                    partnerId,
                },
                {
                    isForceShowBackAction: true,
                },
            );
        },
        [partnerId],
    );

    const goToTruckDetails = React.useCallback((truckId: TruckIdT | null) => {
        if (!truckId) {
            logWarning('empty truckId');
            return;
        }

        openLeftSidebar(
            {
                type: CommonSidebarsTypeEnum.truckDetails,
                partnerId,
                truckId,
            },
            {
                isForceShowBackAction: true,
            },
        );
    }, []);

    const goToDriverDetails = React.useCallback(
        (driverId: DriverIdT | null) => {
            if (!driverId) {
                logWarning('empty driverId');
                return;
            }

            openLeftSidebar(
                {
                    type: CommonSidebarsTypeEnum.driverDetails,
                    driverId,
                    partnerId,
                },
                {
                    isForceShowBackAction: true,
                },
            );
        },
        [partnerId],
    );

    const goToSpotRequestDetails = React.useCallback((spotRequestId: SpotRequestIdT | null) => {
        if (!spotRequestId) {
            logWarning('empty spotRequestId');
            return;
        }

        openLeftSidebar(
            {
                type: CarrierSidebarsTypeEnum.spotRequestDetails,
                id: spotRequestId,
            },
            {
                isForceShowBackAction: true,
            },
        );
    }, []);

    return (
        <>
            <PageTitle
                title={t('page-titles.transport-order-details', {
                    number: transportOrderDetails?.number || '',
                })}
            />
            <TransportOrderDetailsPageLayout
                isBroker={false}
                goToBack={goToBack}
                goToTrailerDetails={goToTrailerDetails}
                goToTruckDetails={goToTruckDetails}
                goToDriverDetails={goToDriverDetails}
                goToSpotRequestDetails={goToSpotRequestDetails}
            />
            <SideBars />
        </>
    );
});

export default TransportOrderDetailsPage;
