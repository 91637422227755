import { ChangeBidDetailsT } from 'carrier/store/bids/models';
import { FieldsEnum, FormValuesT } from './constants';
import moment from 'moment';
import { parseSimpleNumber } from 'common/utils/input-parsers';
import isNumber from 'lodash/isNumber';

export const prepareDetails = (formValues: FormValuesT): ChangeBidDetailsT | null => {
    const price = parseSimpleNumber(formValues[FieldsEnum.price]);
    if (!isNumber(price)) {
        return null;
    }

    return {
        price,
        deadline: moment(formValues[FieldsEnum.deadlineTimeWindow]?.[1]).format(),
        comment: formValues[FieldsEnum.comment],
    };
};
