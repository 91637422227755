import * as React from 'react';
import { useMemo } from 'react';
import BaseProfileLayout, { ProfileAdditionalTabT } from 'common/layouts/BaseProfileLayout/BaseProfileLayout';
import { CarrierProfileRoutesEnum } from 'carrier/constants';
import { useTranslation } from 'react-i18next';
import SpotLaneListPage from 'carrier/layouts/ProfilePage/SpotLaneListPage/SpotLaneListPage';
import SideBars from 'carrier/layouts/SideBars/SideBars';

type PropsT = {};

const ProfilePage: React.FC<PropsT> = React.memo(() => {
    const { t } = useTranslation();

    const additionalTabs = useMemo((): Array<ProfileAdditionalTabT> => {
        return [
            {
                pathname: CarrierProfileRoutesEnum.spotLanes,
                hasWarning: false,
                renderTitle: () => t('profile-page.tabs.spot-lanes'),
                testSelector: 'spot-lanes',
                node: <SpotLaneListPage />,
            },
        ];
    }, [t]);

    return (
        <>
            <BaseProfileLayout additionalTabs={additionalTabs} />
            <SideBars />
        </>
    );
});

export default ProfilePage;
