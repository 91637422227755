import {
    DELETE_PAPER_REQUEST_BEGIN,
    DELETE_PAPER_REQUEST_ERROR,
    DELETE_PAPER_REQUEST_SUCCESS,
    FETCH_PAPERS_REQUEST_BEGIN,
    FETCH_PAPERS_REQUEST_ERROR,
    FETCH_PAPERS_REQUEST_SUCCESS,
    HashPapersStateT,
    PapersActionT,
    PapersStateT,
    RESET_PAPERS,
    UPLOAD_PAPER_REQUEST_BEGIN,
    UPLOAD_PAPER_REQUEST_ERROR,
    UPLOAD_PAPER_REQUEST_SUCCESS,
} from './types';
import requestStatus from 'common/utils/request-status';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

export const initialHashPapersState: HashPapersStateT = {
    list: [],
    fetchRequest: requestStatus.initial,
    uploadRequest: requestStatus.initial,
    deleteQuery: null,
    deleteRequest: requestStatus.initial,
};

const initialState: PapersStateT = {};

export default (state = initialState, action: PapersActionT | DestroySessionActionT): PapersStateT => {
    switch (action.type) {
        case FETCH_PAPERS_REQUEST_BEGIN: {
            const { hash } = action;

            const hashPapersState = state[hash] || initialHashPapersState;

            return {
                ...state,
                [hash]: {
                    ...hashPapersState,
                    fetchRequest: requestStatus.loading,
                },
            };
        }

        case FETCH_PAPERS_REQUEST_SUCCESS: {
            const { hash, list } = action;

            const hashPapersState = state[hash] || initialHashPapersState;

            return {
                ...state,
                [hash]: {
                    ...hashPapersState,
                    list,
                    fetchRequest: requestStatus.ok,
                },
            };
        }

        case FETCH_PAPERS_REQUEST_ERROR: {
            const { hash, error } = action;

            const hashPapersState = state[hash] || initialHashPapersState;

            return {
                ...state,
                [hash]: {
                    ...hashPapersState,
                    fetchRequest: requestStatus.setError(error),
                },
            };
        }

        case UPLOAD_PAPER_REQUEST_BEGIN: {
            const { hash } = action;

            const hashPapersState = state[hash] || initialHashPapersState;

            return {
                ...state,
                [hash]: {
                    ...hashPapersState,
                    uploadRequest: requestStatus.loading,
                },
            };
        }

        case UPLOAD_PAPER_REQUEST_SUCCESS: {
            const { hash } = action;

            const hashPapersState = state[hash] || initialHashPapersState;

            return {
                ...state,
                [hash]: {
                    ...hashPapersState,
                    uploadRequest: requestStatus.ok,
                },
            };
        }

        case UPLOAD_PAPER_REQUEST_ERROR: {
            const { hash, error } = action;

            const hashPapersState = state[hash] || initialHashPapersState;

            return {
                ...state,
                [hash]: {
                    ...hashPapersState,
                    uploadRequest: requestStatus.setError(error),
                },
            };
        }

        case DELETE_PAPER_REQUEST_BEGIN: {
            const { hash, paperId } = action;

            const hashPapersState = state[hash] || initialHashPapersState;

            return {
                ...state,
                [hash]: {
                    ...hashPapersState,
                    deleteQuery: {
                        id: paperId,
                    },
                    deleteRequest: requestStatus.loading,
                },
            };
        }

        case DELETE_PAPER_REQUEST_SUCCESS: {
            const { hash } = action;

            const hashPapersState = state[hash] || initialHashPapersState;

            return {
                ...state,
                [hash]: {
                    ...hashPapersState,
                    deleteRequest: requestStatus.ok,
                },
            };
        }

        case DELETE_PAPER_REQUEST_ERROR: {
            const { hash, error } = action;

            const hashPapersState = state[hash] || initialHashPapersState;

            return {
                ...state,
                [hash]: {
                    ...hashPapersState,
                    deleteRequest: requestStatus.setError(error),
                },
            };
        }

        case DESTROY_SESSION:
        case RESET_PAPERS: {
            return {
                ...initialState,
            };
        }

        default:
            return state;
    }
};
