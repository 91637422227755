import { FieldsEnum, FormValuesT } from './constants';
import { SpotCarrierLanesChangesT } from 'carrier/store/spot-carrier-lane-changes/models';

const getSpotLanesChanges = (values: FormValuesT): SpotCarrierLanesChangesT => {
    const changes: SpotCarrierLanesChangesT = [];

    values[FieldsEnum.fromCountryCodes].forEach((from) => {
        values[FieldsEnum.toCountryCodes].forEach((to) => {
            changes.push({
                from,
                to,
                trailerType: values[FieldsEnum.trailerVariant],
            });
        });
    });

    return changes;
};

export default getSpotLanesChanges;
