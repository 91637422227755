import React from 'react';
import classNames from 'classnames/bind';

import styles from './InviteDriverForm.scss';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { AsyncFormErrorsT, FieldsEnum, FormValuesT } from './constants';
import validateForm from './validate-form';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { ApiInviteDriverRequestT } from 'common/utils/api/models';
import FormikField from 'common/components/forms/FormikField/FormikField';
import { RequestStatusT } from 'common/utils/request-status';
import PhoneNumberInput from 'common/components/PhoneNumberInput/PhoneNumberInput';
import { PHONE_NUMBER_PREFIX } from 'common/constants';
import useAsyncFormErrors from 'common/utils/hooks/useAsyncFormErrors';
import asyncValidate from './async-validations';
import { TranziitApiRequestErrorSubTypeEnum } from 'common/utils/api/tranziit/errors/tranziit-api-errors';

const cx = classNames.bind(styles);

type PropsT = {
    requestStatus: RequestStatusT;
    onInviteUser: (newDriver: ApiInviteDriverRequestT) => void;
};

export const INITIAL_VALUES: FormValuesT = {
    [FieldsEnum.phone]: PHONE_NUMBER_PREFIX,
};

const InviteDriverForm: React.FC<PropsT> = (props) => {
    const { onInviteUser, requestStatus } = props;

    const { t } = useTranslation();

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values = {
            ...INITIAL_VALUES,
        };

        const errors = validateForm(t, values);

        return [values, errors];
    }, []);

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            onInviteUser({
                phone: values[FieldsEnum.phone],
            });

            formikHelpers.setTouched({});
        },
    });

    const renderPhoneNumberAsyncError = (asyncError: AsyncFormErrorsT[FieldsEnum.phone]): string | null => {
        if (asyncError === TranziitApiRequestErrorSubTypeEnum.phoneAlreadyExist) {
            return t('common:auth-errors.phone-already-exist');
        }

        return asyncError || null;
    };

    const asyncErrors = React.useMemo(() => {
        return asyncValidate(requestStatus);
    }, [requestStatus.error]);

    const { asyncFormErrors, resetAsyncFormErrors } = useAsyncFormErrors(asyncErrors);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className={cx('wrap')}>
                <div className={cx('image')} />
                <div className={cx('title')}>{t('common:drivers-page.invite-modal.title')}</div>
                <div className={cx('form')}>
                    <FormikField
                        name={FieldsEnum.phone}
                        error={formik.errors[FieldsEnum.phone]}
                        meta={formik.getFieldMeta(FieldsEnum.phone)}
                        label={t('common:drivers-page.invite-modal.fields.labels.phone')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                        asyncError={renderPhoneNumberAsyncError(asyncFormErrors[FieldsEnum.phone])}
                        resetAsyncError={resetAsyncFormErrors}
                    >
                        {(props) => (
                            <PhoneNumberInput
                                name={FieldsEnum.phone}
                                value={formik.values[FieldsEnum.phone]}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                                autoFocus
                            />
                        )}
                    </FormikField>
                </div>
                <Button
                    theme={ButtonThemeEnum.primary}
                    isLoading={requestStatus.loading}
                    className={cx('button')}
                    type="submit"
                >
                    {t('common:drivers-page.invite-modal.submit')}
                </Button>
            </div>
        </form>
    );
};

export default InviteDriverForm;
