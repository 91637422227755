import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    strokeColor: StyleGuideColorsEnum;
};

const LinkPickUpIcon: React.FC<PropsT> = (props) => {
    const { strokeColor } = props;

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path
                fill={strokeColor}
                d="M14.75 6.25c2.07 0 3.75 1.68 3.75 3.75 0 2.07-1.68 3.75-3.75 3.75h-3v-1.425h3A2.327 2.327 0 0 0 17.075 10a2.327 2.327 0 0 0-2.325-2.325h-3V6.25zm-.75 3v1.5h-3.001v-1.5H14zM2.552 8.194h1.192v3.75c0 .413.338.75.75.75h3c.413 0 .75-.337.75-.75v-3.75h1.193c.667 0 1.005-.81.532-1.282L6.527 3.469a.747.747 0 0 0-1.058 0L2.027 6.912c-.473.472-.143 1.282.525 1.282zm4.942 6.056c.41 0 .744.331.75.739v1.955c0 .413-.337.75-.75.75h-3a.752.752 0 0 1-.75-.75V15c0-.412.338-.75.75-.75h3z"
                fillRule="evenodd"
            />
        </svg>
    );
};

LinkPickUpIcon.displayName = 'LinkPickUpIcon';

const storyProps: PropsT = {
    strokeColor: StyleGuideColorsEnum.brandAccent,
};

/**
 *  @deprecated create/use ...Props class
 * */
export const getLinkPickUpIconProps = (
    preset:
        | 'default'
        | 'assignment-normal'
        | 'assignment-selected'
        | 'assignment-input-normal'
        | 'assignment-input-selected',
): PropsT => {
    switch (preset) {
        case 'assignment-normal': {
            return {
                strokeColor: StyleGuideColorsEnum.light,
            };
        }
        case 'assignment-selected': {
            return {
                strokeColor: StyleGuideColorsEnum.brandDark,
            };
        }
        case 'assignment-input-normal': {
            return {
                strokeColor: StyleGuideColorsEnum.light,
            };
        }
        case 'assignment-input-selected': {
            return {
                strokeColor: StyleGuideColorsEnum.brandDark,
            };
        }
        case 'default':
        default: {
            return {
                strokeColor: StyleGuideColorsEnum.light,
            };
        }
    }
};

export { storyProps };
export default LinkPickUpIcon;
