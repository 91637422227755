import * as React from 'react';
import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './TimelineAutoSizer.scss';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    children: (height: number, width: number) => React.ReactNode;
};

const TimelineAutoSizer: React.FC<PropsT> = React.memo((props) => {
    const { className, children } = props;

    const [containerSize, setContainerSize] = React.useState<DivSizeT | null>(null);

    const [containerNode, setContainerNode] = React.useState<HTMLDivElement | null>(null);
    const containerRef = React.useCallback((containerNode) => {
        if (containerNode !== null) {
            setContainerNode(containerNode);
            setContainerSize({
                height: containerNode.offsetHeight,
                width: containerNode.offsetWidth,
            });
        }
    }, []);

    React.useLayoutEffect(() => {
        const measureContainer = () => {
            if (!containerNode) {
                return;
            }

            setContainerSize({
                height: containerNode.offsetHeight,
                width: containerNode.offsetWidth,
            });
        };

        window.addEventListener('resize', measureContainer);

        return () => {
            window.removeEventListener('resize', measureContainer);
        };
    }, [containerNode]);

    return (
        <div className={cs(cx('wrap'), className)} ref={containerRef}>
            {containerSize && children(containerSize.height, containerSize.width)}
        </div>
    );
});

export default TimelineAutoSizer;
