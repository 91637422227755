import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './ReceivedCell.scss';
import { TransportOrderT } from 'common/store/transport-orders/models';
import HumanReadableDateCell from 'common/components/Table/cell-renderers/HumanReadableDateCell/HumanReadableDateCell';
import { clearDateTimezone } from 'common/utils/time';

type PropsT = {
    order: TransportOrderT;
};

const cx = classNames.bind(styles);

const ReceivedCell: React.FC<PropsT> = React.memo((props) => {
    const { order } = props;

    return (
        <HumanReadableDateCell className={cx('wrap')} isBold isBigFont date={clearDateTimezone(order.receiveDate)} />
    );
});

export default ReceivedCell;
