import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    className?: string;
    size: number;
    fillColor: StyleGuideColorsEnum;
};

const LockIcon: React.FC<PropsT> = (props) => {
    const { className, size, fillColor } = props;

    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    fill={fillColor}
                    d="M14.5 7h-.75V5.5c0-2.07-1.68-3.75-3.75-3.75-2.07 0-3.75 1.68-3.75 3.75V7H5.5C4.675 7 4 7.675 4 8.5V16c0 .825.675 1.5 1.5 1.5h9c.825 0 1.5-.675 1.5-1.5V8.5c0-.825-.675-1.5-1.5-1.5zM10 13.75c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5 1.5.675 1.5 1.5-.675 1.5-1.5 1.5zM12.325 7h-4.65V5.5A2.327 2.327 0 0110 3.175 2.327 2.327 0 0112.325 5.5V7z"
                />
            </g>
        </svg>
    );
};

LockIcon.displayName = 'LockIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.tomatoRed,
    size: DEFAULT_ICON_SIZE,
};

export { storyProps };
export default LockIcon;
