import { put, select, takeEvery } from 'redux-saga/effects';
import {
    ASSIGN,
    AssignActionT,
    FIND_TRAILER_REQUEST,
    FIND_TRUCK_REQUEST,
    FindTrailerActionT,
    FindTruckActionT,
    UNASSIGN,
    UnAssignActionT,
} from './types';
import {
    assignBegin,
    assignError,
    assignSuccess,
    findTrailerBegin,
    findTrailerError,
    findTrailerSuccess,
    findTruckBegin,
    findTruckError,
    findTruckSuccess,
} from './actions';
import { selectAssignmentRequestStatus, selectTrailerSuggestQuery, selectTruckSuggestQuery } from './selectors';
import isEqual from 'lodash/isEqual';
import carrierTranziitApi from 'carrier/utils/api/carrier-tranziit/api';
import { addAlert } from 'common/store/alerts/actions';
import { CommonAlertTypeEnum, CommonAnyAlert } from 'common/components/toasts/AlertToastsManager/models';
import { transportOrdersRefreshChannel } from 'common/store/transport-orders/channels';
import { transportOrderRefreshChannel } from 'common/store/transport-order-details/channels';
import { stateMachineRefreshChannel } from 'common/store/state-machine/channels';

function* findTruckSaga(action: FindTruckActionT): WrapGeneratorT<void> {
    const { query } = action;

    const prevQuery: ReturnType<typeof selectTruckSuggestQuery> = yield select(selectTruckSuggestQuery);
    const isSameQuery = isEqual(query, prevQuery);
    if (isSameQuery) {
        return;
    }

    yield put(findTruckBegin(query));

    const [error, apiTrucksSuggestItems]: ReturnApiT<typeof carrierTranziitApi.findTrucksForTourByPlateNumber> =
        yield carrierTranziitApi.findTrucksForTourByPlateNumber(query.transportOrderId, {
            plateNumber: query.plateNumber,
        });

    if (error) {
        yield put(findTruckError(query, error));
        return;
    }

    const limitedTrucksSuggestItems = (apiTrucksSuggestItems || []).slice(0, 10);

    yield put(findTruckSuccess(query, limitedTrucksSuggestItems));
}

function* findTrailerSaga(action: FindTrailerActionT): WrapGeneratorT<void> {
    const { query } = action;

    const prevQuery: ReturnType<typeof selectTrailerSuggestQuery> = yield select(selectTrailerSuggestQuery);
    const isSameQuery = isEqual(query, prevQuery);
    if (isSameQuery) {
        return;
    }

    yield put(findTrailerBegin(query));

    const [error, apiTrailersSuggestItems]: ReturnApiT<typeof carrierTranziitApi.findTrailersForTourByPlateNumber> =
        yield carrierTranziitApi.findTrailersForTourByPlateNumber(query.transportOrderId, {
            plateNumber: query.plateNumber,
        });

    if (error) {
        yield put(findTrailerError(query, error));
        return;
    }

    const limitedTrailersSuggestItems = (apiTrailersSuggestItems || []).slice(0, 10);

    yield put(findTrailerSuccess(query, limitedTrailersSuggestItems));
}

function* assignSaga(action: AssignActionT): WrapGeneratorT<void> {
    const { transportOrderId, truckId, trailerId, prohibitedCountries } = action;

    const requestStatus: ReturnType<typeof selectAssignmentRequestStatus> = yield select(selectAssignmentRequestStatus);
    if (requestStatus.loading) {
        return;
    }

    yield put(assignBegin());

    const [error]: ReturnApiT<typeof carrierTranziitApi.assignVehiclesToTransportOrder> =
        yield carrierTranziitApi.assignVehiclesToTransportOrder(transportOrderId, {
            truckId,
            trailerId,
            prohibitedCountries,
        });
    if (error) {
        yield put(assignError(error));
    } else {
        yield put(assignSuccess());

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.spotAssetsAssigned,
                    data: {},
                }),
            ),
        );
    }

    transportOrderRefreshChannel.emit({});
    transportOrdersRefreshChannel.emit({});
    stateMachineRefreshChannel.emit({});
}

function* unassignSaga(action: UnAssignActionT): WrapGeneratorT<void> {
    const { transportOrderId } = action;

    const requestStatus: ReturnType<typeof selectAssignmentRequestStatus> = yield select(selectAssignmentRequestStatus);
    if (requestStatus.loading) {
        return;
    }

    yield put(assignBegin());

    const [error]: ReturnApiT<typeof carrierTranziitApi.unAssignVehicles> = yield carrierTranziitApi.unAssignVehicles(
        transportOrderId,
    );
    if (error) {
        yield put(assignError(error));
    } else {
        yield put(assignSuccess());

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.spotAssetsUnassigned,
                    data: {},
                }),
            ),
        );
    }

    transportOrderRefreshChannel.emit({});
    transportOrdersRefreshChannel.emit({});
    stateMachineRefreshChannel.emit({});
}

function* spotAssetsAssigmentSaga(): WrapGeneratorT<void> {
    yield takeEvery(FIND_TRUCK_REQUEST, findTruckSaga);
    yield takeEvery(FIND_TRAILER_REQUEST, findTrailerSaga);
    yield takeEvery(ASSIGN, assignSaga);
    yield takeEvery(UNASSIGN, unassignSaga);
}

export default spotAssetsAssigmentSaga;
