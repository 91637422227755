import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './SpotLaneListPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from 'common/components/Table/Pagination/Pagination';
import TableMessage, { TableMessageIconsEnum } from 'common/components/Table/TableMessage/TableMessage';
import { createJsonParams, createPageNumberParam } from 'common/utils/query';
import StickyFooter from 'common/layouts/LeftMenuLayout/StickyFooter/StickyFooter';
import TableError from 'common/components/Table/TableError/TableError';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import ListPageHeaderLayout from 'common/layouts/ListPage/ListPageHeaderLayout/ListPageHeaderLayout';
import ListPageLayout from 'common/layouts/ListPage/ListPageLayout/ListPageLayout';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { QueryFiltersKeysEnum, QueryFiltersT, QueryKeysEnum } from './query-models';
import { prepareFetchQuery } from './prepare-fetch-query';
import { useOpenLeftSidebar } from 'carrier/layouts/SideBars/hooks';
import SelectedFilters from './SelectedFilters/SelectedFilters';
import { useQueryParams } from 'use-query-params';
import {
    selectSpotCarrierLanesByIds,
    selectSpotCarrierLanesPages,
    selectSpotCarrierLanesTotal,
} from 'carrier/store/spot-carrier-lanes/selectors';
import { fetchSpotCarrierLanesPage } from 'carrier/store/spot-carrier-lanes/actions';
import { SpotCarrierLaneT } from 'carrier/store/spot-carrier-lanes/models';
import SearchForm from './SearchForm/SearchForm';
import SpotLanesTable from 'carrier/layouts/ProfilePage/SpotLaneListPage/SpotLanesTable/SpotLanesTable';
import { useTranslation } from 'react-i18next';
import { CommonSidebarsTypeEnum } from 'common/layouts/SideBars/models';
import { logWarning } from 'common/utils/logger';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import { CarrierSidebarsTypeEnum } from 'carrier/layouts/SideBars/constants';
import { deleteSpotCarrierLanes, updateSpotCarrierLanes } from 'carrier/store/spot-carrier-lane-changes/actions';
import {
    prepareChangeTrailerVariantChanges,
    prepareDeleteChanges,
} from 'carrier/layouts/ProfilePage/SpotLaneListPage/prepare-change-query';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import DeleteIcon from 'common/icons/DeleteIcon';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import { SpotCarrierLanesTrailerVariantEnum } from 'carrier/store/spot-carrier-lane-changes/models';
import ChangeTrailerVariantControl from 'carrier/layouts/ProfilePage/SpotLaneListPage/ChangeTrailerVariantControl/ChangeTrailerVariantControl';
import PlusSignIcon from 'common/icons/PlusSignIcon';
import { selectSpotCarrierLaneChangesRequest } from 'carrier/store/spot-carrier-lane-changes/selectors';
import UpdateLanesConfirmation, {
    UpdateLanesConfirmationDataT,
} from './dialogs/UpdateLanesConfirmation/UpdateLanesConfirmation';
import DeleteLanesConfirmation, {
    DeleteLanesConfirmationDataT,
} from './dialogs/DeleteLanesConfirmation/DeleteLanesConfirmation';
import useModalDialog from 'common/utils/hooks/useModalDialog';
import useCloseModalDialogAfterRequest from 'common/utils/hooks/useCloseModalDialogAfterRequest';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import {
    spotCarrierLanesPaginationChannel,
    spotCarrierLanesRefreshChannel,
} from 'carrier/store/spot-carrier-lanes/channels';
import { InferChannelEventT } from 'common/utils/view-event-channel';
import { checkNeedRenderEmptyState } from 'common/components/Table/utils/check-need-render-empty-state';
import { checkFilters } from 'common/components/Table/utils/check-filters';

const cx = classNames.bind(styles);

type PropsT = {};

const SpotLaneListPage: React.FC<PropsT> = React.memo((props) => {
    const { t } = useTranslation();

    const { partnerId, partnerType } = usePartnerContext();

    const [selectedLanesSet, setSelectedLanesSet] = React.useState<Set<SpotCarrierLaneT>>(new Set());

    const updateLanesConfirmation = useModalDialog<UpdateLanesConfirmationDataT>();
    const deleteLanesConfirmation = useModalDialog<DeleteLanesConfirmationDataT>();

    const updateRequestStatus = useSelector(selectSpotCarrierLaneChangesRequest);
    useCloseModalDialogAfterRequest(updateRequestStatus, [updateLanesConfirmation, deleteLanesConfirmation]);

    const dispatch = useDispatch();

    const total = useSelector(selectSpotCarrierLanesTotal);
    const spotLanesPages = useSelector(selectSpotCarrierLanesPages);
    const spotLanesById = useSelector(selectSpotCarrierLanesByIds);

    const openLeftSidebar = useOpenLeftSidebar();

    const [query, changeQuery] = useQueryParams({
        [QueryKeysEnum.spotLanesFilters]: createJsonParams<QueryFiltersT>({}),
        [QueryKeysEnum.spotLanesPage]: createPageNumberParam(),
    });

    const queryFilters = query[QueryKeysEnum.spotLanesFilters];
    const pageNumber = query[QueryKeysEnum.spotLanesPage];

    const page = spotLanesPages[pageNumber];
    const { ids, requestStatus } = page || {};

    React.useEffect(() => {
        setSelectedLanesSet(new Set());
    }, [ids]);

    const documentVisibilityChangeHandler = React.useCallback(() => {
        const query = prepareFetchQuery(queryFilters);
        dispatch(fetchSpotCarrierLanesPage(pageNumber, query, { isForceUpdate: false }));
    }, [pageNumber, queryFilters]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    React.useEffect(() => {
        const query = prepareFetchQuery(queryFilters);
        dispatch(fetchSpotCarrierLanesPage(pageNumber, query, { isForceUpdate: false }));

        return (): void => {
            // TODO reset
        };
    }, [pageNumber, queryFilters]);

    const spotLanes = React.useMemo(() => {
        return (ids || []).map((id): SpotCarrierLaneT => spotLanesById[id]);
    }, [ids, spotLanesById]);

    const refreshPageHandler = React.useCallback(() => {
        const query = prepareFetchQuery(queryFilters);
        dispatch(fetchSpotCarrierLanesPage(pageNumber, query, { isForceUpdate: true }));
    }, [pageNumber, queryFilters]);
    useChannelSubscribe(spotCarrierLanesRefreshChannel, refreshPageHandler);

    const goToPage = React.useCallback(
        (pageNumber: PageNumberT) => {
            setSelectedLanesSet(new Set());

            changeQuery({
                [QueryKeysEnum.spotLanesPage]: pageNumber,
            });
        },
        [setSelectedLanesSet, changeQuery],
    );

    const setPageHandler = React.useCallback(
        ({ pageNumber }: InferChannelEventT<typeof spotCarrierLanesPaginationChannel>) => {
            goToPage(pageNumber);
        },
        [goToPage],
    );
    useChannelSubscribe(spotCarrierLanesPaginationChannel, setPageHandler);

    const updateQueryFilters = (queryFiltersChanges: QueryFiltersT) => {
        const newQueryFilters = {
            ...queryFilters,
            ...queryFiltersChanges,
        };

        changeQuery({
            [QueryKeysEnum.spotLanesPage]: 0,
            [QueryKeysEnum.spotLanesFilters]: newQueryFilters,
        });
    };

    const handleSetQueryFilters = (selectedQueryFilters: QueryFiltersT) => {
        changeQuery({
            [QueryKeysEnum.spotLanesPage]: 0,
            [QueryKeysEnum.spotLanesFilters]: selectedQueryFilters,
        });
    };

    const showCreateLanesForm = () => {
        openLeftSidebar({
            type: CarrierSidebarsTypeEnum.updateSpotCarrierLanes,
            isCreateAction: true,
        });
    };

    const handleDeleteSelectedSpotLanes = () => {
        const deleteChanges = prepareDeleteChanges(selectedLanesSet);
        deleteLanesConfirmation.setData({ changes: deleteChanges });
    };

    const handleConfirmDeleteLanes = ({ changes }: DeleteLanesConfirmationDataT) => {
        dispatch(deleteSpotCarrierLanes(changes));

        setSelectedLanesSet(new Set());
    };

    const handleDeleteSpotLane = (spotLine: SpotCarrierLaneT) => {
        dispatch(
            deleteSpotCarrierLanes([
                {
                    to: spotLine.to,
                    from: spotLine.from,
                    trailerType: spotLine.trailerType,
                },
            ]),
        );
    };

    const handleChangeTrailerVariant = (
        spotLane: SpotCarrierLaneT,
        trailerVariant: SpotCarrierLanesTrailerVariantEnum,
    ) => {
        dispatch(
            updateSpotCarrierLanes([
                {
                    from: spotLane.from,
                    to: spotLane.to,
                    trailerType: trailerVariant,
                },
            ]),
        );
    };

    const handleChangeTrailerVariantSelectedSpotLanes = (trailerVariant: SpotCarrierLanesTrailerVariantEnum) => {
        const trailerVariantChanges = prepareChangeTrailerVariantChanges(selectedLanesSet, trailerVariant);

        updateLanesConfirmation.setData({ changes: trailerVariantChanges });
    };

    const handleConfirmUpdateLanes = ({ changes }: UpdateLanesConfirmationDataT) => {
        dispatch(updateSpotCarrierLanes(changes));
    };

    const hasQueryFilters = !!Object.keys(queryFilters).length;

    const renderTableMessage = () => {
        if (!checkNeedRenderEmptyState(spotLanes, requestStatus)) {
            return null;
        }

        if (requestStatus?.error) {
            return <TableError />;
        }

        const { hasSecondaryQueryFilters } = checkFilters([], [query[QueryKeysEnum.spotLanesFilters]]);
        if (requestStatus?.ok && hasSecondaryQueryFilters) {
            return (
                <TableMessage
                    iconType={TableMessageIconsEnum.notFound}
                    title={t('spot-carrier-lanes.list.table.messages.not-found.title')}
                    description={t('spot-carrier-lanes.list.table.messages.not-found.description')}
                    isShowAction
                    actionTitle={t('spot-carrier-lanes.list.table.messages.not-found.action')}
                    actionTheme={ButtonThemeEnum.secondary}
                    onActionClick={() => {
                        handleSetQueryFilters({});
                    }}
                    testSelector="not-found"
                />
            );
        }

        return (
            <TableMessage
                iconType={TableMessageIconsEnum.lane}
                title={t('spot-carrier-lanes.list.table.messages.empty.title')}
                description={t('spot-carrier-lanes.list.table.messages.empty.description')}
                isShowAction
                actionTitle={t('spot-carrier-lanes.list.table.messages.empty.action')}
                actionTheme={ButtonThemeEnum.secondary}
                onActionClick={() => {
                    const fromCountryCode = queryFilters[QueryFiltersKeysEnum.fromCountryCode];
                    const toCountryCode = queryFilters[QueryFiltersKeysEnum.toCountryCode];

                    openLeftSidebar({
                        type: CarrierSidebarsTypeEnum.updateSpotCarrierLanes,
                        isCreateAction: true,
                        initialFromCodes: fromCountryCode ? [fromCountryCode] : [],
                        initialToCodes: toCountryCode ? [toCountryCode] : [],
                    });
                }}
                testSelector="empty"
            />
        );
    };

    return (
        <>
            <ListPageLayout>
                <ListPageHeaderLayout
                    withTopPadding
                    leftToolsNode={<SearchForm queryFilters={queryFilters} updateQueryFilters={updateQueryFilters} />}
                    rightToolsNode={
                        <>
                            {!!selectedLanesSet.size && (
                                <>
                                    <Tooltip
                                        className={cx('delete-trigger')}
                                        position={TooltipPositionEnum.centerLeft}
                                        theme={TooltipThemeEnum.black}
                                        tooltipNode={
                                            <TooltipContent theme={TooltipContentThemeEnum.black} isNoWrap>
                                                {t('spot-carrier-lanes.list.actions.delete-lanes')}
                                            </TooltipContent>
                                        }
                                    >
                                        {() => (
                                            <TransparentTrigger
                                                onClick={handleDeleteSelectedSpotLanes}
                                                reflectionTheme={ReflectionThemeEnum.light}
                                                leftIcon={<DeleteIcon fillColor={StyleGuideColorsEnum.tomatoRed} />}
                                            />
                                        )}
                                    </Tooltip>
                                    <ChangeTrailerVariantControl
                                        className={cx('change-trailer-variant')}
                                        onChangeTrailerVariant={handleChangeTrailerVariantSelectedSpotLanes}
                                    />
                                </>
                            )}
                            <Button
                                theme={ButtonThemeEnum.primary}
                                onClick={showCreateLanesForm}
                                testSelector="add-lanes"
                                leftIcon={
                                    <PlusSignIcon size={DEFAULT_ICON_SIZE} strokeColor={StyleGuideColorsEnum.white} />
                                }
                            >
                                {t('spot-carrier-lanes.list.actions.add-lanes')}
                            </Button>
                        </>
                    }
                    filterTagsNode={
                        <SelectedFilters
                            isHideClearAll
                            isCompact
                            queryFilters={queryFilters}
                            setQueryFilters={handleSetQueryFilters}
                        />
                    }
                />
                {renderTableMessage()}
                <SpotLanesTable
                    spotLanes={spotLanes}
                    className={cx('table')}
                    selectedLanesSet={selectedLanesSet}
                    onSelectLanes={setSelectedLanesSet}
                    onDeleteSpotLane={handleDeleteSpotLane}
                    onChangeTrailerVariant={handleChangeTrailerVariant}
                    onOpenSpotLaneDetails={(spotLane) => {
                        openLeftSidebar({
                            type: CarrierSidebarsTypeEnum.updateSpotCarrierLanes,
                            initialToCodes: [spotLane.to],
                            initialFromCodes: [spotLane.from],
                            initialTrailerType: spotLane.trailerType,
                        });
                    }}
                    isLoading={requestStatus?.loading || updateRequestStatus?.loading}
                    onOpenUserDetails={(userId) => {
                        if (!userId) {
                            logWarning('failed to open contact details, empty userId');
                            return;
                        }

                        openLeftSidebar({
                            type: CommonSidebarsTypeEnum.contact,
                            partnerId,
                            partnerType,
                            userId,
                        });
                    }}
                />
            </ListPageLayout>
            <StickyFooter>
                <Pagination current={pageNumber} count={total?.pageCount} goToPage={goToPage} />
            </StickyFooter>
            <UpdateLanesConfirmation
                data={updateLanesConfirmation.data}
                onClose={updateLanesConfirmation.onClose}
                onCancel={updateLanesConfirmation.onCancel}
                onConfirm={handleConfirmUpdateLanes}
            />
            <DeleteLanesConfirmation
                data={deleteLanesConfirmation.data}
                onClose={deleteLanesConfirmation.onClose}
                onCancel={deleteLanesConfirmation.onCancel}
                onConfirm={handleConfirmDeleteLanes}
            />
        </>
    );
});

export default SpotLaneListPage;
