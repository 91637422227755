import BaseTranziitApi, { TranziitApiResultT } from 'common/utils/api/tranziit/base-tranziit-api';
import { authApi } from 'common/utils/api/auth/factory';
import { MS_IN_MIN } from 'common/utils/time';
import { OverwritePageRequestWithSortT } from 'common/utils/api/models';

class SpotCarrierTranziitApi extends BaseTranziitApi {
    private static instance: SpotCarrierTranziitApi;

    private constructor() {
        super(authApi, {
            basepath: '/spot/api',
            timeout: MS_IN_MIN,
        });
    }

    public static getInstance(): SpotCarrierTranziitApi {
        if (!SpotCarrierTranziitApi.instance) {
            SpotCarrierTranziitApi.instance = new SpotCarrierTranziitApi();
        }

        return SpotCarrierTranziitApi.instance;
    }

    acceptBid = async (
        id: SpotApiPaths.SpotApiV1Bid$BidIdAccept.Post.Parameters.BidId,
        data: SpotApiPaths.SpotApiV1Bid$BidIdAccept.Post.RequestBody,
    ): Promise<TranziitApiResultT<SpotApiPaths.SpotApiV1Bid$BidIdAccept.Post.Responses.$200>> => {
        return this.doFetch<SpotApiPaths.SpotApiV1Bid$BidIdAccept.Post.Responses.$200>({
            method: 'POST',
            path: `/v1/bid/${id}/accept`,
            data,
            expectedResponseContentType: 'unknown',
        });
    };

    placeBid = async (
        spotId: SpotApiPaths.SpotApiV1CarrierSpot$SpotIdPlaceBid.Post.Parameters.SpotId,
        data: SpotApiPaths.SpotApiV1CarrierSpot$SpotIdPlaceBid.Post.RequestBody,
    ): Promise<TranziitApiResultT<SpotApiPaths.SpotApiV1CarrierSpot$SpotIdPlaceBid.Post.Responses.$200>> => {
        return this.doFetch<SpotApiPaths.SpotApiV1CarrierSpot$SpotIdPlaceBid.Post.Responses.$200>({
            method: 'POST',
            path: `/v1/carrier/spot/${spotId}/place-bid`,
            data,
            expectedResponseContentType: 'json',
        });
    };

    rejectBid = async (
        id: SpotApiPaths.SpotApiV1Bid$BidIdReject.Post.Parameters.BidId,
    ): Promise<TranziitApiResultT<SpotApiPaths.SpotApiV1Bid$BidIdReject.Post.Responses.$200>> => {
        return this.doFetch<SpotApiPaths.SpotApiV1Bid$BidIdReject.Post.Responses.$200>({
            method: 'POST',
            path: `/v1/bid/${id}/reject`,
            expectedResponseContentType: 'json',
        });
    };

    fetchSpotLoadBoardRequests = async (
        query: OverwritePageRequestWithSortT<SpotApiPaths.SpotApiV1CarrierSpotLoadBoard.Get.QueryParameters>,
    ): Promise<TranziitApiResultT<SpotApiPaths.SpotApiV1CarrierSpotLoadBoard.Get.Responses.$200>> => {
        return this.doFetch<SpotApiPaths.SpotApiV1CarrierSpotLoadBoard.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/carrier/spot/load-board`,
            query,
            expectedResponseContentType: 'json',
        });
    };

    fetchSpotInboxRequests = async (
        query: OverwritePageRequestWithSortT<SpotApiPaths.SpotApiV1CarrierSpotInbox.Get.QueryParameters>,
    ): Promise<TranziitApiResultT<SpotApiPaths.SpotApiV1CarrierSpotInbox.Get.Responses.$200>> => {
        return this.doFetch<SpotApiPaths.SpotApiV1CarrierSpotInbox.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/carrier/spot/inbox`,
            query,
            expectedResponseContentType: 'json',
        });
    };

    fetchSpotInboxRequestStatusesStats = async (): Promise<
        TranziitApiResultT<SpotApiPaths.SpotApiV1CarrierSpotInboxStatuses.Get.Responses.$200>
    > => {
        return this.doFetch<SpotApiPaths.SpotApiV1CarrierSpotInboxStatuses.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/carrier/spot/inbox/statuses`,
            expectedResponseContentType: 'json',
        });
    };

    fetchSpotRequestDetails = async (
        id: SpotApiPaths.SpotApiV1CarrierSpot$SpotId.Get.Parameters.SpotId,
    ): Promise<TranziitApiResultT<SpotApiPaths.SpotApiV1CarrierSpot$SpotId.Get.Responses.$200>> => {
        return this.doFetch<SpotApiPaths.SpotApiV1CarrierSpot$SpotId.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/carrier/spot/${id}`,
            expectedResponseContentType: 'json',
        });
    };

    fetchCarrierLanes = async (
        query: OverwritePageRequestWithSortT<SpotApiPaths.SpotApiV1CarrierLane.Get.QueryParameters>,
    ): Promise<TranziitApiResultT<SpotApiPaths.SpotApiV1CarrierLane.Get.Responses.$200>> => {
        return this.doFetch<SpotApiPaths.SpotApiV1CarrierLane.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/carrier/lane`,
            query,
            expectedResponseContentType: 'json',
        });
    };

    deleteCarrierLanes = async (
        data: SpotApiPaths.SpotApiV1CarrierLane.Delete.RequestBody,
    ): Promise<TranziitApiResultT<SpotApiPaths.SpotApiV1CarrierLane.Delete.Responses.$200>> => {
        return this.doFetch<SpotApiPaths.SpotApiV1CarrierLane.Delete.Responses.$200>({
            method: 'DELETE',
            path: `/v1/carrier/lane`,
            data,
            expectedResponseContentType: 'unknown',
        });
    };

    updateCarrierLanes = async (
        data: SpotApiPaths.SpotApiV1CarrierLaneCreateOrUpdate.Post.RequestBody,
    ): Promise<TranziitApiResultT<SpotApiPaths.SpotApiV1CarrierLaneCreateOrUpdate.Post.Responses.$200>> => {
        return this.doFetch<SpotApiPaths.SpotApiV1CarrierLaneCreateOrUpdate.Post.Responses.$200>({
            method: 'POST',
            path: `/v1/carrier/lane/create-or-update`,
            data,
            expectedResponseContentType: 'unknown',
        });
    };

    fetchCurrentCarrierProfile = async (): Promise<
        TranziitApiResultT<SpotApiPaths.SpotApiV1CarrierProfileGetCurrentCompany.Get.Responses.$200>
    > => {
        return this.doFetch<SpotApiPaths.SpotApiV1CarrierProfileGetCurrentCompany.Get.Responses.$200>({
            method: 'GET',
            path: `/v1/carrier-profile/get-current-company`,
            expectedResponseContentType: 'json',
        });
    };
}

const spotCarrierTranziitApi = SpotCarrierTranziitApi.getInstance();

export default spotCarrierTranziitApi;
