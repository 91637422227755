import {
    FETCH_SPOT_CARRIER_LANES_PAGE_REQUEST_BEGIN,
    FETCH_SPOT_CARRIER_LANES_PAGE_REQUEST_ERROR,
    FETCH_SPOT_CARRIER_LANES_PAGE_REQUEST_SUCCESS,
    RESET_SPOT_CARRIER_LANES,
    SpotCarrierLanesActionT,
    SpotCarrierLanesStateT,
} from './types';
import {
    pageBeginReducer,
    pageErrorReducer,
    pageSuccessReducer,
    resetPagesCacheReducer,
} from 'common/utils/pagination/reducers';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

const initialState: SpotCarrierLanesStateT = {
    pages: [],
    query: null,
    total: null,
    byId: {},
};

export default (
    state = initialState,
    action: SpotCarrierLanesActionT | DestroySessionActionT,
): SpotCarrierLanesStateT => {
    switch (action.type) {
        case FETCH_SPOT_CARRIER_LANES_PAGE_REQUEST_BEGIN: {
            const { pageNumber, query } = action;

            return {
                ...state,
                ...pageBeginReducer(state, pageNumber, query),
            };
        }
        case FETCH_SPOT_CARRIER_LANES_PAGE_REQUEST_SUCCESS: {
            const { query, pageNumber, pageResponse } = action;

            return {
                ...state,
                ...pageSuccessReducer(state, pageNumber, query, pageResponse),
            };
        }
        case FETCH_SPOT_CARRIER_LANES_PAGE_REQUEST_ERROR: {
            const { query, error, pageNumber } = action;

            return {
                ...state,
                ...pageErrorReducer(state, pageNumber, query, error),
            };
        }

        case RESET_SPOT_CARRIER_LANES: {
            const { mutationListOptions } = action;

            return {
                ...initialState,
                ...resetPagesCacheReducer(initialState, state, mutationListOptions),
            };
        }

        case DESTROY_SESSION: {
            return {
                ...initialState,
            };
        }

        default:
            return state;
    }
};
