import {
    PLACE_BID,
    PlaceBidActionT,
    REJECT_BID,
    RejectBidActionT,
    RESET_BIDS,
    ResetBidsActionT,
    UPDATE_BID_REQUEST_BEGIN,
    UPDATE_BID_REQUEST_ERROR,
    UPDATE_BID_REQUEST_SUCCESS,
    UpdateBidBeginActionT,
    UpdateBidErrorActionT,
    UpdateBidSuccessActionT,
    ACCEPT_BID,
    AcceptBidActionT,
} from './types';
import { ChangeBidDetailsT } from './models';

export const placeBid = (spotRequestId: SpotRequestIdT, details: ChangeBidDetailsT): PlaceBidActionT => ({
    type: PLACE_BID,
    spotRequestId,
    details,
});

export const acceptBid = (
    spotRequestId: SpotRequestIdT,
    bidId: BidIdT,
    details: ChangeBidDetailsT,
): AcceptBidActionT => ({
    type: ACCEPT_BID,
    bidId,
    spotRequestId,
    details,
});

export const rejectBid = (spotRequestId: SpotRequestIdT, bidId: BidIdT): RejectBidActionT => ({
    type: REJECT_BID,
    bidId,
    spotRequestId,
});

export const updateBidBegin = (): UpdateBidBeginActionT => ({
    type: UPDATE_BID_REQUEST_BEGIN,
});

export const updateBidSuccess = (): UpdateBidSuccessActionT => ({
    type: UPDATE_BID_REQUEST_SUCCESS,
});

export const updateBidError = (error: Error): UpdateBidErrorActionT => ({
    type: UPDATE_BID_REQUEST_ERROR,
    error,
});

export const resetBids = (): ResetBidsActionT => ({
    type: RESET_BIDS,
});
