import React from 'react';
import styles from './TimeWindowPillLabel.scss';
import classNames from 'classnames/bind';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import moment from 'moment';

const cx = classNames.bind(styles);

type PropsT = {
    timeWindow: TimeWindowT | null | undefined;
    className?: string;
};

const TimeWindowPillLabel: React.FC<PropsT> = (props) => {
    const { timeWindow, className } = props;

    if (!timeWindow) {
        return null;
    }

    const formatter = moment(timeWindow[1]).format('DD MMM YYYY, HH:mm');

    return (
        <PillLabel isSymmetrical theme={PillLabelThemeEnum.charcoal} className={className}>
            <span className={cx('text')}>{formatter}</span>
        </PillLabel>
    );
};

export default TimeWindowPillLabel;
