import React from 'react';

import classNames from 'classnames/bind';
import styles from './TrailerVariantDropdown.scss';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownInput from 'design-system/components/dropdowns/DropdownInput/DropdownInput';
import TrailerIcon, { TrailerIconProps } from 'common/icons/TrailerIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import { useTranslation } from 'react-i18next';
import {
    SpotCarrierLanesTrailerVariantEnum,
    SpotCarrierLanesTrailerVariantT,
} from 'carrier/store/spot-carrier-lane-changes/models';

enum TrailerIconTypesEnum {
    default = 'default',
}

const cx = classNames.bind(styles);

type OptionValueT = SpotCarrierLanesTrailerVariantT | null;

type PropsT = {
    placeholder?: string;
    value: OptionValueT;
    isDisabled?: boolean;
    onChange: (value: OptionValueT) => void;
    hasWarning: boolean;
    hasError: boolean;
    hasChanges?: boolean;
    onBlur: () => void;
    onFocus: () => void;
    hasClearControl?: boolean;
};

type TrailerVariantDropdownOptionT = {
    value: OptionValueT;
    label: string;
    iconType: TrailerIconTypesEnum;
};

const ALLOWED_TRAILER_VARIANTS: Array<SpotCarrierLanesTrailerVariantEnum> = [
    SpotCarrierLanesTrailerVariantEnum.all,
    SpotCarrierLanesTrailerVariantEnum.box,
    SpotCarrierLanesTrailerVariantEnum.tilt,
];

const LABEL_MAP: Record<SpotCarrierLanesTrailerVariantEnum, string> = {
    [SpotCarrierLanesTrailerVariantEnum.all]: 'spot-carrier-lanes.list.table.trailer-type.all',
    [SpotCarrierLanesTrailerVariantEnum.box]: 'spot-carrier-lanes.list.table.trailer-type.box',
    [SpotCarrierLanesTrailerVariantEnum.tilt]: 'spot-carrier-lanes.list.table.trailer-type.tilt',
};

const ICONS_MAP: Record<TrailerIconTypesEnum, React.ReactNode> = {
    [TrailerIconTypesEnum.default]: (
        <TrailerIcon
            fillColor={StyleGuideColorsEnum.brandAccent}
            strokeColor={StyleGuideColorsEnum.brandDark}
            size={DEFAULT_ICON_SIZE}
        />
    ),
};

const TrailerVariantDropdown: React.FC<PropsT> = React.memo((props) => {
    const {
        value,
        placeholder,
        onChange,
        isDisabled,
        hasWarning,
        hasError,
        hasChanges,
        onBlur,
        onFocus,
        hasClearControl,
    } = props;

    const { t } = useTranslation();

    const options = React.useMemo((): TrailerVariantDropdownOptionT[] => {
        return ALLOWED_TRAILER_VARIANTS.map((trailerVariant) => ({
            value: trailerVariant || null,
            label: t(LABEL_MAP[trailerVariant]),
            iconType: TrailerIconTypesEnum.default,
        }));
    }, [t]);

    const renderTrigger = (option: TrailerVariantDropdownOptionT | null | undefined): React.ReactElement | null => {
        if (!option) {
            return null;
        }

        return (
            <div className={cx('option')} key={option.label}>
                {option.label}
            </div>
        );
    };

    const renderOption = (option: TrailerVariantDropdownOptionT | null | undefined): React.ReactElement | null => {
        if (!option) {
            return null;
        }

        return <span>{option.label}</span>;
    };

    const handleSelect = (value: OptionValueT): void => {
        onChange(value);
    };

    const getOptionValue = (option: TrailerVariantDropdownOptionT): OptionValueT => option.value;

    return (
        <DropdownInput<TrailerVariantDropdownOptionT, OptionValueT>
            selectedValue={value}
            placeholder={placeholder}
            options={options}
            onSelect={handleSelect}
            isDisabled={isDisabled}
            renderOption={renderOption}
            renderTriggerContent={renderTrigger}
            getOptionValue={getOptionValue}
            overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
            hasWarning={hasWarning}
            hasError={hasError}
            hasChanges={hasChanges}
            onBlur={onBlur}
            onFocus={onFocus}
            renderLeftIcon={(iconMeta) => <TrailerIcon {...TrailerIconProps.getControlProps(iconMeta)} />}
            testSelector="trailer-variant"
            hasClearControl={hasClearControl}
            onReset={() => {
                onChange(null);
            }}
        />
    );
});

export default TrailerVariantDropdown;
