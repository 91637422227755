import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './TypeCell.scss';
import { useTranslation } from 'react-i18next';
import { TrailerT } from 'common/store/trailers/models';

type PropsT = {
    trailer: TrailerT;
};

const cx = classNames.bind(styles);

const TypeCell: React.FC<PropsT> = React.memo((props) => {
    const { trailer } = props;

    const { t } = useTranslation();

    const type = trailer ? t(`common:trailer-type.${trailer.type}`) : null;

    const subtype = trailer
        ? t('common:trailer-subtype-template', {
              length: trailer.length,
              model: trailer.subtype,
              eur1Pallets: trailer.eur1Pallets,
              eur2Pallets: trailer.eur2Pallets,
          })
        : null;

    return (
        <>
            <div className={cx('type')}>{type}</div>
            <div className={cx('sub-type')}>{subtype}</div>
        </>
    );
});

export default TypeCell;
