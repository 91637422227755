import {
    FETCH_SPOT_REQUEST_DETAILS_REQUEST,
    FETCH_SPOT_REQUEST_DETAILS_REQUEST_BEGIN,
    FETCH_SPOT_REQUEST_DETAILS_REQUEST_ERROR,
    FETCH_SPOT_REQUEST_DETAILS_REQUEST_SUCCESS,
    FetchSpotRequestDetailsRequestActionT,
    FetchSpotRequestDetailsRequestBeginActionT,
    FetchSpotRequestDetailsRequestErrorActionT,
    FetchSpotRequestDetailsRequestSuccessActionT,
    FetchSpotTourDetailsRequestActionT,
    FetchSpotTourDetailsRequestBeginActionT,
    FetchSpotTourDetailsRequestErrorActionT,
    FetchSpotTourDetailsRequestSuccessActionT,
    FETCH_SPOT_TOUR_DETAILS_REQUEST,
    FETCH_SPOT_TOUR_DETAILS_REQUEST_BEGIN,
    FETCH_SPOT_TOUR_DETAILS_REQUEST_ERROR,
    FETCH_SPOT_TOUR_DETAILS_REQUEST_SUCCESS,
} from './types';
import { SpotTourDetailsT, SpotRequestDetailsT } from './models';

export const fetchSpotRequestDetails = (
    id: SpotRequestIdT,
    options?: FetchOptionsT,
): FetchSpotRequestDetailsRequestActionT => ({
    type: FETCH_SPOT_REQUEST_DETAILS_REQUEST,
    id,
    options,
});

export const fetchSpotRequestDetailsBegin = (id: SpotRequestIdT): FetchSpotRequestDetailsRequestBeginActionT => ({
    type: FETCH_SPOT_REQUEST_DETAILS_REQUEST_BEGIN,
    id,
});

export const fetchSpotRequestDetailsSuccess = (
    id: SpotRequestIdT,
    details: SpotRequestDetailsT | null,
): FetchSpotRequestDetailsRequestSuccessActionT => ({
    type: FETCH_SPOT_REQUEST_DETAILS_REQUEST_SUCCESS,
    details,
    id,
});

export const fetchSpotRequestDetailsError = (
    id: SpotRequestIdT,
    error: Error,
): FetchSpotRequestDetailsRequestErrorActionT => ({
    type: FETCH_SPOT_REQUEST_DETAILS_REQUEST_ERROR,
    error,
    id,
});

export const fetchSpotTourDetails = (
    spotRequestId: SpotRequestIdT,
    tourId: TourIdT,
    options?: FetchOptionsT,
): FetchSpotTourDetailsRequestActionT => ({
    type: FETCH_SPOT_TOUR_DETAILS_REQUEST,
    spotRequestId,
    tourId,
    options,
});

export const fetchSpotTourDetailsBegin = (spotRequestId: SpotRequestIdT): FetchSpotTourDetailsRequestBeginActionT => ({
    type: FETCH_SPOT_TOUR_DETAILS_REQUEST_BEGIN,
    spotRequestId,
});

export const fetchSpotTourDetailsSuccess = (
    spotRequestId: SpotRequestIdT,
    details: SpotTourDetailsT | null,
): FetchSpotTourDetailsRequestSuccessActionT => ({
    type: FETCH_SPOT_TOUR_DETAILS_REQUEST_SUCCESS,
    details,
    spotRequestId,
});

export const fetchSpotTourDetailsError = (
    spotRequestId: SpotRequestIdT,
    error: Error,
): FetchSpotTourDetailsRequestErrorActionT => ({
    type: FETCH_SPOT_TOUR_DETAILS_REQUEST_ERROR,
    error,
    spotRequestId,
});
