import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './StartDriversAssignmentFooterContent.scss';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useTranslation } from 'react-i18next';
import TimeLeftForStart from 'common/layouts/sidebar-footers/TimeLeftForStart/TimeLeftForStart';
import { TransportOrderDetailsT } from 'common/store/transport-order-details/models';
import { useDispatch, useSelector } from 'react-redux';
import { selectAssignmentRequestStatus } from 'common/store/spot-assets-assigment/selectors';
import useModalDialog from 'common/utils/hooks/useModalDialog';
import SpotAssetsUnassignmentConfirmation, {
    SpotAssetsUnassignmentConfirmationDataT,
} from 'common/layouts/TransportOrderDetailsPageLayout/dialogs/SpotAssetsUnassignmentConfirmation/SpotAssetsUnassignmentConfirmation';
import { unassign } from 'common/store/spot-assets-assigment/actions';
import useCloseModalDialogAfterRequest from 'common/utils/hooks/useCloseModalDialogAfterRequest';

const cx = classNames.bind(styles);

type PropsT = {
    transportOrderId: TransportOrderIdT;
    transportOrderDetails: TransportOrderDetailsT | null | undefined;
    onStartAssignment: () => void;
};

const StartDriversAssignmentFooterContent: React.FC<PropsT> = React.memo((props) => {
    const { transportOrderId, transportOrderDetails, onStartAssignment } = props;

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const deadline = transportOrderDetails?.waypoints?.[0]?.dateTimeFrom;

    const assignmentRequestStatus = useSelector(selectAssignmentRequestStatus);

    const spotAssetsUnassignmentConfirmation = useModalDialog<SpotAssetsUnassignmentConfirmationDataT>();

    const handleClearAssetsConfirm = (data: SpotAssetsUnassignmentConfirmationDataT) => {
        const { transportOrderId } = data;

        dispatch(unassign(transportOrderId));
    };

    useCloseModalDialogAfterRequest(assignmentRequestStatus, [spotAssetsUnassignmentConfirmation]);

    const handleClearAssets = () => {
        spotAssetsUnassignmentConfirmation.setData({
            transportOrderId,
        });
    };

    return (
        <>
            <TimeLeftForStart
                deadline={deadline}
                alreadyStartedLabel={t('common:transport-order-details.assignment.already-started')}
                deadlineLabel={t('common:transport-order-details.assignment.to-assign')}
                shouldDisableAfterDeadline
                renderActions={(isDisabled) => (
                    <>
                        {!!transportOrderDetails?.spotInfo?.id && (
                            <Button
                                className={cx('button', 'button--unassign')}
                                theme={ButtonThemeEnum.transparent}
                                onClick={handleClearAssets}
                                isDisabled={assignmentRequestStatus.loading}
                                isLoading={assignmentRequestStatus.loading}
                            >
                                {t('common:driver-assignment.actions.unassign-assets')}
                            </Button>
                        )}
                        <Button
                            className={cx('button')}
                            theme={ButtonThemeEnum.primary}
                            onClick={onStartAssignment}
                            isDisabled={isDisabled}
                        >
                            {t('common:driver-assignment.actions.assign-driver')}
                        </Button>
                    </>
                )}
            />
            <SpotAssetsUnassignmentConfirmation
                data={spotAssetsUnassignmentConfirmation.data}
                onCancel={spotAssetsUnassignmentConfirmation.onCancel}
                onClose={spotAssetsUnassignmentConfirmation.onClose}
                onConfirmation={handleClearAssetsConfirm}
            />
        </>
    );
});

export default StartDriversAssignmentFooterContent;
