import * as React from 'react';

import classNames from 'classnames/bind';

import styles from './CommentFormatter.scss';

const cx = classNames.bind(styles);

type PropsT = {
    comment: React.ReactNode | string | null;
};

const CommentFormatter: React.FC<PropsT> = React.memo((props) => {
    const { comment } = props;

    return <span className={cx('comment')}>{comment}</span>;
});

export default CommentFormatter;
