import { CountryCodeT } from 'common/store/countries-dict/models';
import { SpotCarrierLanesTrailerVariantT } from 'carrier/store/spot-carrier-lane-changes/models';

export enum FieldsEnum {
    trailerVariant = 'trailerVariant',
    fromCountryCodes = 'from-country-codes',
    toCountryCodes = 'to-country-codes',
}

export type FormValuesT = {
    [FieldsEnum.trailerVariant]: SpotCarrierLanesTrailerVariantT;
    [FieldsEnum.fromCountryCodes]: Array<CountryCodeT>;
    [FieldsEnum.toCountryCodes]: Array<CountryCodeT>;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
