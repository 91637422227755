import { FormValuesT, FieldsEnum } from './constants';
import { QueryFiltersT } from 'carrier/layouts/SpotRequestsPage/SpotRequestListPage/query-models';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getInitialValues = (queryFilters: QueryFiltersT): FormValuesT => {
    const values: FormValuesT = {
        [FieldsEnum.pickupAddress]: null,
        [FieldsEnum.dropoffAddress]: null,
        [FieldsEnum.dictTrailerTypeId]: null,
        [FieldsEnum.createDates]: null,
        [FieldsEnum.pickupDates]: null,
        [FieldsEnum.dropoffDates]: null,
    };

    return values;
};

export default getInitialValues;
