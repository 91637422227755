import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { QueryFiltersKeysEnum, QueryFiltersT } from '../query-models';
import WarningFilterOptionsDropdown, {
    WarningFilterOptionT,
} from 'common/components/Table/WarningFilterOptionsDropdown/WarningFilterOptionsDropdown';
import { SpecialOptionEnum } from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import { TransportOrderStatsT } from 'common/store/transport-orders-stats/models';
import { colorCodingNotificationLabelTheme } from 'common/components/notifications/NotificationLabel/NotificationLabel';

type PropsT = {
    className?: string;
    stat: TransportOrderStatsT | null;
    queryFilters: QueryFiltersT;
    onQueryFiltersChange: (queryFiltersChanges: Partial<QueryFiltersT>) => void;
};

const warningsQueryFiltersKeys = [
    QueryFiltersKeysEnum.runningOutOfTimeToAssignAssets,
    QueryFiltersKeysEnum.ranOutOfTimeToAssignAssets,
    QueryFiltersKeysEnum.runningOutOfTimeToAssignDriver,
    QueryFiltersKeysEnum.ranOutOfTimeToAssignDriver,
    QueryFiltersKeysEnum.delaying,
    QueryFiltersKeysEnum.expired,
    QueryFiltersKeysEnum.missed,
] as const;

const WarningsFilter: React.FC<PropsT> = React.memo((props) => {
    const { className, stat, queryFilters, onQueryFiltersChange } = props;

    const { t } = useTranslation();

    const options = React.useMemo(
        (): Array<WarningFilterOptionT<(typeof warningsQueryFiltersKeys)[number]> | SpecialOptionEnum> => [
            SpecialOptionEnum.separator,
            {
                id: QueryFiltersKeysEnum.runningOutOfTimeToAssignAssets,
                label: t('common:transport-orders-page.warning-filters.running-out-of-time-to-assign-assets'),
                notificationProps: {
                    count: stat?.runningOutOfTimeToAssignAssets,
                    theme: colorCodingNotificationLabelTheme.attention,
                },
            },
            {
                id: QueryFiltersKeysEnum.ranOutOfTimeToAssignAssets,
                label: t('common:transport-orders-page.warning-filters.ran-out-of-time-to-assign-assets'),
                notificationProps: {
                    count: stat?.runOutOfTimeToAssignAssets,
                    theme: colorCodingNotificationLabelTheme.warning,
                },
            },
            {
                id: QueryFiltersKeysEnum.runningOutOfTimeToAssignDriver,
                label: t('common:transport-orders-page.warning-filters.running-out-of-time-to-assign-driver'),
                notificationProps: {
                    count: stat?.runningOutOfTimeToAssignDriver,
                    theme: colorCodingNotificationLabelTheme.attention,
                },
            },
            {
                id: QueryFiltersKeysEnum.ranOutOfTimeToAssignDriver,
                label: t('common:transport-orders-page.warning-filters.ran-out-of-time-to-assign-driver'),
                notificationProps: {
                    count: stat?.runOutOfTimeToAssignDriver,
                    theme: colorCodingNotificationLabelTheme.warning,
                },
            },
            SpecialOptionEnum.separator,
            {
                id: QueryFiltersKeysEnum.delaying,
                label: t('common:transport-orders-page.warning-filters.tour-delaying'),
                notificationProps: {
                    count: stat?.delaying,

                    theme: colorCodingNotificationLabelTheme.attention,
                },
            },
            SpecialOptionEnum.separator,
            {
                id: QueryFiltersKeysEnum.expired,
                label: t('common:transport-orders-page.warning-filters.expired'),
                notificationProps: {
                    count: stat?.noShow,
                    theme: colorCodingNotificationLabelTheme.warning,
                },
            },

            {
                id: QueryFiltersKeysEnum.missed,
                label: t('common:transport-orders-page.warning-filters.shipment-missed'),
                notificationProps: {
                    count: stat?.missed,
                    theme: colorCodingNotificationLabelTheme.warning,
                },
            },
        ],
        [t, stat],
    );

    const value = React.useMemo(() => {
        return warningsQueryFiltersKeys.filter((queryFiltersKey) => queryFilters[queryFiltersKey]);
    }, [queryFilters]);

    const handleSelect = (values: Array<(typeof warningsQueryFiltersKeys)[number]>) => {
        const queryFiltersChanges = warningsQueryFiltersKeys.reduce<Partial<QueryFiltersT>>((acc, queryFiltersKey) => {
            acc[queryFiltersKey] = values.includes(queryFiltersKey) ? true : undefined;

            return acc;
        }, {});

        onQueryFiltersChange(queryFiltersChanges);
    };

    return (
        <WarningFilterOptionsDropdown<(typeof warningsQueryFiltersKeys)[number]>
            className={className}
            value={value}
            onSelect={handleSelect}
            options={options}
        />
    );
});

export default WarningsFilter;
