import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillColor?: StyleGuideColorsEnum;
    size: number;
};

const SmallCloseIcon: React.FC<PropsT> = (props) => {
    const { fillColor, size } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size}>
            <path
                fill={fillColor}
                fillRule="evenodd"
                d="M14.95 6.464L11.414 10l3.536 3.536-1.414 1.414-3.537-3.536-3.535 3.536-1.414-1.414 3.535-3.537L5.05 6.464 6.464 5.05 10 8.586l3.536-3.536 1.414 1.414z"
            />
        </svg>
    );
};

SmallCloseIcon.displayName = 'SmallCloseIcon';

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
    fillColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default SmallCloseIcon;
