import * as React from 'react';
import { CountryCodeT } from 'common/store/countries-dict/models';
import CountyFlagLabel from 'common/components/CountyFlagLabel/CountyFlagLabel';
import { useSelector } from 'react-redux';
import { selectCountriesByCode, selectCountriesDictRequest } from 'common/store/countries-dict/selectors';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    countryCode: CountryCodeT | null | undefined;
};

const CountryCell: React.FC<PropsT> = React.memo((props) => {
    const { countryCode } = props;

    const requestStatus = useSelector(selectCountriesDictRequest);
    const countryByCode = useSelector(selectCountriesByCode);

    if (!requestStatus || !countryByCode) {
        return <ControlLoaderIcon fillColor={StyleGuideColorsEnum.charcoal} size={DEFAULT_ICON_SIZE} />;
    }

    const country = countryCode ? countryByCode[countryCode] : undefined;

    return <CountyFlagLabel country={country} />;
});

export default CountryCell;
