import { SpotCarrierLanesStateT } from './types';
import { StateT } from '../models';

export const selectSpotCarrierLanesPages = (state: StateT): SpotCarrierLanesStateT['pages'] =>
    state.spotCarrierLanes.pages;

export const selectSpotCarrierLanesQuery = (state: StateT): SpotCarrierLanesStateT['query'] =>
    state.spotCarrierLanes.query;

export const selectSpotCarrierLanesTotal = (state: StateT): SpotCarrierLanesStateT['total'] =>
    state.spotCarrierLanes.total;

export const selectSpotCarrierLanesByIds = (state: StateT): SpotCarrierLanesStateT['byId'] =>
    state.spotCarrierLanes.byId;
