import { put, select, takeEvery } from 'redux-saga/effects';
import { checkRequestBegin, checkRequestError, checkRequestSuccess, reset } from './actions';

import { CHECK_GPS_STATUS_REQUEST, CheckGPSStatusRequestActionT } from './types';
import { selectGPSCheckQuery, selectGPSCheckStatus } from 'common/store/gps-tracking-status/selectors';
import checkNeedRequest from 'common/utils/check-need-request';
import { checkIsSameQuery } from 'common/utils/pagination/utils';
import { DEFAULT_CARRIER_COMPANY_ID, AssetTypeEnum } from 'common/constants';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import carrierTranziitApi from 'carrier/utils/api/carrier-tranziit/api';
import { GPSStatusT } from 'common/store/gps-tracking-status/models';

function* checkGPSStatusSaga(action: CheckGPSStatusRequestActionT): WrapGeneratorT<void> {
    const { query } = action;

    if (!query) {
        yield put(reset());
        return;
    }

    const prevQuery: ReturnType<typeof selectGPSCheckQuery> = yield select(selectGPSCheckQuery);
    const isSameRequest = checkIsSameQuery(prevQuery, query);
    const requestStatus: ReturnType<typeof selectGPSCheckStatus> = yield select(selectGPSCheckStatus);
    const isNeedRequest = checkNeedRequest(requestStatus);

    if (isSameRequest && !isNeedRequest) {
        return;
    }

    if (!query?.plateNumber) {
        return;
    }

    yield put(checkRequestBegin(query));

    let error: Error | null = null;
    let data: GPSStatusT | null = null;

    if (query?.companyId === DEFAULT_CARRIER_COMPANY_ID && query?.assetType === AssetTypeEnum.trailer) {
        const result: ReturnApiT<typeof carrierTranziitApi.checkTrailerGPSStatus> =
            yield carrierTranziitApi.checkTrailerGPSStatus(query.plateNumber);

        if (result?.[1]) {
            data = result?.[1];
        } else {
            error = result?.[0] || new Error(`gps status response: ${result?.[1]}`);
        }
    }

    if (query?.companyId === DEFAULT_CARRIER_COMPANY_ID && query?.assetType === AssetTypeEnum.truck) {
        const result: ReturnApiT<typeof carrierTranziitApi.checkTruckGPSStatus> =
            yield carrierTranziitApi.checkTruckGPSStatus(query.plateNumber);

        if (result?.[1]) {
            data = result?.[1];
        } else {
            error = result?.[0] || new Error(`gps status response: ${result?.[1]}`);
        }
    }

    if (query?.companyId !== DEFAULT_CARRIER_COMPANY_ID && query?.assetType === AssetTypeEnum.trailer) {
        const result: ReturnApiT<typeof brokerTranziitApi.checkPartnerTrailerGPSStatus> =
            yield brokerTranziitApi.checkPartnerTrailerGPSStatus(query.plateNumber);

        if (result?.[1]) {
            data = result?.[1];
        } else {
            error = result?.[0] || new Error(`gps status response: ${result?.[1]}`);
        }
    }

    if (query?.companyId !== DEFAULT_CARRIER_COMPANY_ID && query?.assetType === AssetTypeEnum.truck) {
        const result: ReturnApiT<typeof brokerTranziitApi.checkPartnerTruckGPSStatus> =
            yield brokerTranziitApi.checkPartnerTruckGPSStatus(query.plateNumber);

        if (result?.[1]) {
            data = result?.[1];
        } else {
            error = result?.[0] || new Error(`gps status response: ${result?.[1]}`);
        }
    }

    if (error) {
        yield put(checkRequestError(query, error));
        return;
    }

    if (data) {
        yield put(checkRequestSuccess(query, data));
    }
}

function* gpsTrackingStatusSaga(): WrapGeneratorT<void> {
    yield takeEvery(CHECK_GPS_STATUS_REQUEST, checkGPSStatusSaga);
}

export default gpsTrackingStatusSaga;
