import * as React from 'react';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';

type PropsT = {
    reason: React.ReactNode;
};

const ChangeBlockedStatusControlOptionLabel: React.FC<PropsT> = (props) => {
    const { reason } = props;

    return <DropdownControlOptionLabel isDisabled label={reason} />;
};

export default ChangeBlockedStatusControlOptionLabel;
