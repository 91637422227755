import * as React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import styles from './DriversListPage.scss';

import TopBar from 'common/layouts/LeftMenuLayout/TopBar/TopBar';
import ScrollableContent from 'common/layouts/LeftMenuLayout/ScrollableContent/ScrollableContent';
import DriversPageLayout from 'common/layouts/DriversPageLayout/DriversPageLayout';
import NotificationsBarTrigger from 'common/components/notifications/NotificationsBarTrigger/NotificationsBarTrigger';
import TopBarContent from 'common/layouts/LeftMenuLayout/TopBarContent/TopBarContent';
import ContentMargins from 'common/layouts/LeftMenuLayout/ContentMargins/ContentMargins';
import PageTitle from 'common/components/PageTitle/PageTitle';
import SideBars from 'carrier/layouts/SideBars/SideBars';

const cx = classNames.bind(styles);

type PropsT = {};

const DriversListPage: React.FC<PropsT> = React.memo(() => {
    const { t } = useTranslation();

    return (
        <ScrollableContent>
            <PageTitle title={t('page-titles.drivers')} />
            <ContentMargins>
                <TopBar>
                    <TopBarContent title={t('drivers-page.title')} rightNode={<NotificationsBarTrigger />} />
                </TopBar>
            </ContentMargins>
            <DriversPageLayout />
            <SideBars />
        </ScrollableContent>
    );
});

export default DriversListPage;
