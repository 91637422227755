import * as React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    className?: string;
    fillColor: StyleGuideColorsEnum;
    opacity?: number;
};

const WarningIcon: React.FC<PropsT> = (props) => {
    const { fillColor, opacity, className } = props;

    return (
        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path
                d="M10.333 2A8.336 8.336 0 0 0 2 10.333c0 4.6 3.733 8.334 8.333 8.334s8.334-3.734 8.334-8.334S14.933 2 10.333 2zm0 9.167a.836.836 0 0 1-.833-.834V7c0-.458.375-.833.833-.833.459 0 .834.375.834.833v3.333a.836.836 0 0 1-.834.834zm.834 3.333H9.5v-1.667h1.667V14.5z"
                fill={fillColor}
                opacity={opacity || 1}
                fillRule="evenodd"
            />
        </svg>
    );
};

WarningIcon.displayName = 'WarningIcon';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.tomatoRed,
};

export { storyProps };
export default WarningIcon;
