import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './StartSpotAssetsAssignmentFooterContent.scss';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useTranslation } from 'react-i18next';
import TimeLeftForStart from 'common/layouts/sidebar-footers/TimeLeftForStart/TimeLeftForStart';
import { TransportOrderDetailsT } from 'common/store/transport-order-details/models';

const cx = classNames.bind(styles);

type PropsT = {
    transportOrderDetails: TransportOrderDetailsT | null | undefined;
    onStartAssignment: () => void;
};

const StartSpotAssetsAssignmentFooterContent: React.FC<PropsT> = React.memo((props) => {
    const { transportOrderDetails, onStartAssignment } = props;

    const { t } = useTranslation();

    if (!transportOrderDetails || !transportOrderDetails?.spotInfo?.id) {
        return null;
    }

    const deadline = transportOrderDetails?.waypoints[0]?.dateTimeFrom;

    return (
        <TimeLeftForStart
            deadline={deadline}
            alreadyStartedLabel={t('common:transport-order-details.spot-assets-assignment.already-started')}
            deadlineLabel={t('common:transport-order-details.spot-assets-assignment.to-assign')}
            shouldDisableAfterDeadline
            renderActions={(isDisabled) => (
                <Button
                    className={cx('button')}
                    theme={ButtonThemeEnum.primary}
                    onClick={onStartAssignment}
                    isDisabled={isDisabled}
                >
                    {t('common:spot-asset-transport-order-assignment.action.assign-assets')}
                </Button>
            )}
        />
    );
});

export default StartSpotAssetsAssignmentFooterContent;
