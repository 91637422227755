import { generatePath } from 'react-router';
import { TrailersRoutesEnum, TransportOrdersRoutesEnum, TrucksRoutesEnum, SpotRoutesEnum } from 'carrier/constants';
import { getRePath } from 'common/utils/urls';
import { LocationDescriptor } from 'history';
import { ProfileDocumentsRoutesEnum, ProfileRoutesEnum } from 'common/constants';
import qs from 'qs';
import { CommonSidebarsTypeEnum, SidebarQueryKeysEnum } from 'common/layouts/SideBars/models';
import { JSONArrayParam } from 'common/utils/query';
import { CarrierSidebarDataT } from 'carrier/layouts/SideBars/models';
import { CarrierSidebarsTypeEnum } from 'carrier/layouts/SideBars/constants';
import { DocumentTypeT } from 'common/utils/api/models';
import { DocumentDetailsSidebarDataT } from 'common/layouts/SideBars/contents/DocumentDetailsSidebarContent/models';
import { ContractDetailsSidebarDataT } from 'common/layouts/SideBars/contents/ContractDetailsSidebarContent/models';

export const urlFactory = {
    profileBasic: () => {
        return ProfileRoutesEnum.basic;
    },
    profileDocuments: () => {
        return ProfileRoutesEnum.documents;
    },
    transportOrders: () => {
        return {
            pathname: TransportOrdersRoutesEnum.list,
        };
    },
    transportOrdersDetails: (transportOrderId: TransportOrderIdT) => {
        return {
            pathname: generatePath(TransportOrdersRoutesEnum.details, {
                transportOrderId,
            }),
            state: {
                rePath: getRePath(),
            },
        };
    },
    truckDetails: (partnerId: PartnerIdT, truckId: TruckIdT): LocationDescriptor<HistoryStateT> => {
        return {
            pathname: generatePath(TrucksRoutesEnum.details, { truckId }),
            state: {
                rePath: getRePath(),
            },
        };
    },
    trailerDetails: (partnerId: PartnerIdT, trailerId: TrailerIdT): LocationDescriptor<HistoryStateT> => {
        return {
            pathname: generatePath(TrailersRoutesEnum.details, { trailerId }),
            state: {
                rePath: getRePath(),
            },
        };
    },
    spotRequestDetails: (spotRequestId: SpotRequestIdT) => {
        return {
            pathname: generatePath(SpotRoutesEnum.spotRequestDetails, {
                spotRequestId,
            }),
            state: {
                rePath: getRePath(),
            },
        };
    },
    spotRequestsListWithDetails: (spotRequestId: SpotRequestIdT) => {
        return `${SpotRoutesEnum.list}?${qs.stringify({
            [SidebarQueryKeysEnum.leftSidebar]: JSONArrayParam.encode<CarrierSidebarDataT>([
                {
                    type: CarrierSidebarsTypeEnum.spotRequestDetails,
                    id: spotRequestId,
                },
            ]),
        })}`;

        return {
            pathname: generatePath(SpotRoutesEnum.spotRequestDetails, {
                spotRequestId,
            }),
            state: {
                rePath: getRePath(),
            },
        };
    },
    documentDetails: (documentType: DocumentTypeT) => {
        return `${ProfileDocumentsRoutesEnum.requiredDocuments}?${qs.stringify({
            [SidebarQueryKeysEnum.leftSidebar]: JSONArrayParam.encode<DocumentDetailsSidebarDataT>([
                {
                    type: CommonSidebarsTypeEnum.document,
                    documentType,
                    initIsEditing: false,
                },
            ]),
        })}`;
    },
    optionalDocuments: () => {
        return ProfileDocumentsRoutesEnum.optionalDocuments;
    },
    carrierContracts: () => {
        return ProfileDocumentsRoutesEnum.carrierContracts;
    },
    carrierContractDetails: (contractId: CarrierContractIdT) => {
        return `${ProfileDocumentsRoutesEnum.carrierContracts}?${qs.stringify({
            [SidebarQueryKeysEnum.leftSidebar]: JSONArrayParam.encode<ContractDetailsSidebarDataT>([
                {
                    type: CommonSidebarsTypeEnum.carrierContract,
                    contractId,
                    initIsEditing: false,
                },
            ]),
        })}`;
    },
};
