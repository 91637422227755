export enum FieldsEnum {
    truckId = 'truckId',
    trailerId = 'trailerId',
}

export type FormValuesT = {
    [FieldsEnum.truckId]: TruckIdT | null;
    [FieldsEnum.trailerId]: TrailerIdT | null;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
