import { FetchTransportOrdersPageQueryT } from 'common/store/transport-orders/models';
import { TransportOrderQuickFilterEnum, TransportOrderSortEnum } from 'common/utils/api/models';
import { PageSortT, SortDirectionEnum } from 'common/utils/query';
import { QueryFiltersKeysEnum, QueryFiltersT } from './query-models';

const getQueryFromQuickFilters = (quickFilter?: TransportOrderQuickFilterEnum): FetchTransportOrdersPageQueryT => {
    switch (quickFilter) {
        case TransportOrderQuickFilterEnum.new: {
            return {
                statusBar: 'NEW',
            };
        }
        case TransportOrderQuickFilterEnum.noAssets: {
            return {
                statusBar: 'ASSETS_UNASSIGNED',
            };
        }
        case TransportOrderQuickFilterEnum.noDriver: {
            return {
                statusBar: 'DRIVER_UNASSIGNED',
            };
        }
        case TransportOrderQuickFilterEnum.declined: {
            return {
                statusBar: 'DECLINED',
            };
        }
        case TransportOrderQuickFilterEnum.assigned: {
            return {
                statusBar: 'ASSIGNED',
            };
        }
        case TransportOrderQuickFilterEnum.inTransit: {
            return {
                statusBar: 'IN_TRANSIT',
            };
        }
        case TransportOrderQuickFilterEnum.done: {
            return {
                statusBar: 'DONE',
            };
        }
        case TransportOrderQuickFilterEnum.canceled: {
            return {
                statusBar: 'CANCELED',
            };
        }
        default:
            return {};
    }
};

const formatSort = (
    sort: PageSortT<TransportOrderSortEnum> | null,
): FetchTransportOrdersPageQueryT['sorting'] | null => {
    switch (sort?.value) {
        case TransportOrderSortEnum.dropOffDate: {
            if (sort?.direction === SortDirectionEnum.ASC) {
                return 'DESTINATION_DATE_ASC';
            }
            return 'DESTINATION_DATE_DESC';
        }
        case TransportOrderSortEnum.pickupDate: {
            if (sort?.direction === SortDirectionEnum.ASC) {
                return 'ORIGIN_DATE_ASC';
            }
            return 'ORIGIN_DATE_DESC';
        }
        case TransportOrderSortEnum.price: {
            if (sort?.direction === SortDirectionEnum.ASC) {
                return 'PRICE_ASC';
            }
            return 'PRICE_DESC';
        }
        case TransportOrderSortEnum.receiveDate: {
            if (sort?.direction === SortDirectionEnum.ASC) {
                return 'RECEIVED_DATE_ASC';
            }
            return 'RECEIVED_DATE_DESC';
        }
        default: {
            return null;
        }
    }
};

const prepareFetchPageQuery = (
    queryFilters: QueryFiltersT,
    sort: PageSortT<TransportOrderSortEnum> | null,
): FetchTransportOrdersPageQueryT => {
    const dictTrailerId = queryFilters[QueryFiltersKeysEnum.dictTrailerId];
    const maxPrice = queryFilters[QueryFiltersKeysEnum.maxPrice];
    const minPrice = queryFilters[QueryFiltersKeysEnum.minPrice];
    const driverId = queryFilters[QueryFiltersKeysEnum.driverId];

    let spot: boolean | undefined;

    if (queryFilters[QueryFiltersKeysEnum.spot]) {
        spot = true;
    }

    // WTF logic ?!?!?
    if (queryFilters[QueryFiltersKeysEnum.dedicated]) {
        spot = false;
    }

    return {
        type: queryFilters[QueryFiltersKeysEnum.orderType],

        dictTrailerId: typeof dictTrailerId === 'string' ? +dictTrailerId : undefined,
        plateNumber: queryFilters[QueryFiltersKeysEnum.plateNumber] || undefined,

        destinationAddress: queryFilters[QueryFiltersKeysEnum.destinationAddress] || undefined,
        originAddress: queryFilters[QueryFiltersKeysEnum.originAddress] || undefined,

        driverIds: driverId ? [driverId] : undefined,

        upperPrice: typeof maxPrice === 'string' ? parseInt(maxPrice, 10) : undefined,
        lowerPrice: typeof minPrice === 'string' ? parseInt(minPrice, 10) : undefined,

        receivedDateFrom: queryFilters[QueryFiltersKeysEnum.receiveDatesFrom] || undefined,
        receivedDateTo: queryFilters[QueryFiltersKeysEnum.receiveDatesTo] || undefined,

        originDateFrom: queryFilters[QueryFiltersKeysEnum.pickupDateFrom] || undefined,
        originDateTo: queryFilters[QueryFiltersKeysEnum.pickupDateTo] || undefined,

        destinationDateFrom: queryFilters[QueryFiltersKeysEnum.dropOffDateFrom] || undefined,
        destinationDateTo: queryFilters[QueryFiltersKeysEnum.dropOffDateTo] || undefined,

        textSearch: queryFilters[QueryFiltersKeysEnum.searchText] || undefined,

        spot,

        runningOutOfTimeToAssignAssets: queryFilters[QueryFiltersKeysEnum.runningOutOfTimeToAssignAssets] || undefined,
        runOutOfTimeToAssignAssets: queryFilters[QueryFiltersKeysEnum.ranOutOfTimeToAssignAssets] || undefined,
        runningOutOfTimeToAssignDriver: queryFilters[QueryFiltersKeysEnum.runningOutOfTimeToAssignDriver] || undefined,
        runOutOfTimeToAssignDriver: queryFilters[QueryFiltersKeysEnum.ranOutOfTimeToAssignDriver] || undefined,
        delaying: queryFilters[QueryFiltersKeysEnum.delaying] || undefined,
        noShow: queryFilters[QueryFiltersKeysEnum.expired] || undefined,
        missed: queryFilters[QueryFiltersKeysEnum.missed] || undefined,

        ...getQueryFromQuickFilters(queryFilters[QueryFiltersKeysEnum.quickFilter]),

        sorting: formatSort(sort) || undefined,
    };
};

export { prepareFetchPageQuery };
