import * as React from 'react';

import classNames from 'classnames/bind';

import styles from './SpotBidStatusPill.scss';
import { useTranslation } from 'react-i18next';
import PillLabel, { colorCodingPillLabelTheme, PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import { SPOT_BID_STATUS_T_MAP } from 'common/components/status/SpotBidStatus/SpotBidStatus';
import { ApiSpotBidStatusT, SpotBidStatusEnum } from 'common/utils/api/models';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import { DEFAULT_CARRIER_COMPANY_ID } from 'common/constants';

type PropsT = {
    status?: ApiSpotBidStatusT | null;
    isArchived?: boolean;
    isSymmetrical?: boolean;
    className?: string;
};

const cx = classNames.bind(styles);

const BROKER_PILL_THEME_BY_STATUS: Record<ApiSpotBidStatusT, PillLabelThemeEnum> = {
    [SpotBidStatusEnum.new]: colorCodingPillLabelTheme.notImportant,
    [SpotBidStatusEnum.open]: colorCodingPillLabelTheme.notImportant,
    [SpotBidStatusEnum.accepted]: colorCodingPillLabelTheme.attention,
    [SpotBidStatusEnum.rejected]: colorCodingPillLabelTheme.notImportant,
    [SpotBidStatusEnum.expired]: colorCodingPillLabelTheme.attention,
    [SpotBidStatusEnum.won]: colorCodingPillLabelTheme.success,
    [SpotBidStatusEnum.lost]: colorCodingPillLabelTheme.normal,
    [SpotBidStatusEnum.missed]: colorCodingPillLabelTheme.notImportant,
    [SpotBidStatusEnum.canceled]: colorCodingPillLabelTheme.normal,
};

const CARRIER_PILL_THEME_BY_STATUS: Record<ApiSpotBidStatusT, PillLabelThemeEnum> = {
    [SpotBidStatusEnum.new]: colorCodingPillLabelTheme.attention,
    [SpotBidStatusEnum.open]: colorCodingPillLabelTheme.attention,
    [SpotBidStatusEnum.accepted]: colorCodingPillLabelTheme.normal,
    [SpotBidStatusEnum.rejected]: colorCodingPillLabelTheme.normal,
    [SpotBidStatusEnum.expired]: colorCodingPillLabelTheme.attention,
    [SpotBidStatusEnum.won]: colorCodingPillLabelTheme.success,
    [SpotBidStatusEnum.lost]: colorCodingPillLabelTheme.warning,
    [SpotBidStatusEnum.missed]: colorCodingPillLabelTheme.warning,
    [SpotBidStatusEnum.canceled]: colorCodingPillLabelTheme.normal,
};

const SpotBidStatusPill: React.FC<PropsT> = React.memo((props) => {
    const { status, className, isSymmetrical } = props;

    const { partnerId } = usePartnerContext();

    const themeMap =
        partnerId === DEFAULT_CARRIER_COMPANY_ID ? CARRIER_PILL_THEME_BY_STATUS : BROKER_PILL_THEME_BY_STATUS;

    const { t } = useTranslation();
    if (!status) {
        return null;
    }

    const theme = themeMap[status] || PillLabelThemeEnum.charcoal;

    return (
        <>
            <PillLabel
                isNoWrap
                theme={theme}
                testSelector="spot-bid-status"
                className={className}
                isSymmetrical={isSymmetrical}
            >
                {t(SPOT_BID_STATUS_T_MAP[status])}
            </PillLabel>
        </>
    );
});

export default SpotBidStatusPill;
