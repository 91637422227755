import { ApiBidStatusT, BidStatusEnum } from 'carrier/utils/api/spot-carrier-tranziit/models';

export const missedBidStatusesSet = new Set<ApiBidStatusT>([
    BidStatusEnum.lost,
    BidStatusEnum.missed,
    BidStatusEnum.rejected,
]);

export const availableRejectBidStatusesSet = new Set<ApiBidStatusT>([
    BidStatusEnum.new,
    BidStatusEnum.open,
    BidStatusEnum.accepted,
]);

export const availableAcceptBidStatusesSet = new Set<ApiBidStatusT>([BidStatusEnum.new, BidStatusEnum.open]);
