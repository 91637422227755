import { SpotInboxRequestsStatsT } from './models';
import { RequestStatusT } from 'common/utils/request-status';
import { ApiInboxRequestsStatsT } from 'carrier/utils/api/spot-carrier-tranziit/models';

export const FETCH_SPOT_INBOX_REQUESTS_STATS_REQUEST =
    'carrier/spot-inbox-requests-stats/FETCH_SPOT_INBOX_REQUESTS_STATS_REQUEST';
export const FETCH_SPOT_INBOX_REQUESTS_STATS_REQUEST_BEGIN =
    'carrier/spot-inbox-requests-stats/FETCH_SPOT_INBOX_REQUESTS_STATS_REQUEST_BEGIN';
export const FETCH_SPOT_INBOX_REQUESTS_STATS_REQUEST_ERROR =
    'carrier/spot-inbox-requests-stats/FETCH_SPOT_INBOX_REQUESTS_STATS_REQUEST_ERROR';
export const FETCH_SPOT_INBOX_REQUESTS_STATS_REQUEST_SUCCESS =
    'carrier/spot-inbox-requests-stats/FETCH_SPOT_INBOX_REQUESTS_STATS_REQUEST_SUCCESS';

export type SpotInboxRequestsStatsStateT = {
    stats: SpotInboxRequestsStatsT | null;
    fetchRequest: RequestStatusT;
};

export type FetchSpotInboxRequestsStatsActionT = {
    type: typeof FETCH_SPOT_INBOX_REQUESTS_STATS_REQUEST;
    options?: FetchOptionsT;
};

export type FetchSpotInboxRequestsStatsBeginActionT = {
    type: typeof FETCH_SPOT_INBOX_REQUESTS_STATS_REQUEST_BEGIN;
};

export type FetchSpotInboxRequestsStatsSuccessActionT = {
    type: typeof FETCH_SPOT_INBOX_REQUESTS_STATS_REQUEST_SUCCESS;
    statistics: ApiInboxRequestsStatsT | null;
};

export type FetchSpotInboxRequestsStatsErrorActionT = {
    type: typeof FETCH_SPOT_INBOX_REQUESTS_STATS_REQUEST_ERROR;
    error: Error;
};

export type SpotInboxRequestsStatsActionT =
    | FetchSpotInboxRequestsStatsActionT
    | FetchSpotInboxRequestsStatsBeginActionT
    | FetchSpotInboxRequestsStatsSuccessActionT
    | FetchSpotInboxRequestsStatsErrorActionT;
