import * as React from 'react';
import { formatDate } from 'common/utils/time';

type PropsT = {
    startDate: Date | number | string | null | undefined;
    endDate: Date | number | string | null | undefined;
    format?: string;
};

const DateIntervalFormatter: React.FC<PropsT> = React.memo((props) => {
    const { startDate, endDate, format } = props;

    if (!startDate && !endDate) {
        return null;
    }

    const interval = React.useMemo(() => {
        return [startDate, endDate].map((date) => formatDate(format || 'DD MMM YYYY', date)).join(' - ');
    }, []);

    return <span>{interval}</span>;
});

export default DateIntervalFormatter;
