import {
    FETCH_SPOT_CARRIER_LANES_PAGE_REQUEST,
    FETCH_SPOT_CARRIER_LANES_PAGE_REQUEST_BEGIN,
    FETCH_SPOT_CARRIER_LANES_PAGE_REQUEST_ERROR,
    FETCH_SPOT_CARRIER_LANES_PAGE_REQUEST_SUCCESS,
    FetchSpotCarrierLanesPageActionT,
    FetchSpotCarrierLanesPageBeginActionT,
    FetchSpotCarrierLanesPageErrorActionT,
    FetchSpotCarrierLanesPageSuccessActionT,
    RESET_SPOT_CARRIER_LANES,
    ResetSpotCarrierLanesActionT,
} from './types';
import { FetchSpotCarrierLanesPageQueryT, SpotCarrierLaneT } from './models';
import { PageResponseT } from 'common/utils/pagination/models';

export const fetchSpotCarrierLanesPage = (
    pageNumber: PageNumberT,
    query: FetchSpotCarrierLanesPageQueryT,
    options?: FetchOptionsT,
): FetchSpotCarrierLanesPageActionT => ({
    type: FETCH_SPOT_CARRIER_LANES_PAGE_REQUEST,
    query,
    pageNumber,
    options,
});

export const fetchSpotCarrierLanesPageBegin = (
    query: FetchSpotCarrierLanesPageQueryT,
    pageNumber: PageNumberT,
): FetchSpotCarrierLanesPageBeginActionT => ({
    type: FETCH_SPOT_CARRIER_LANES_PAGE_REQUEST_BEGIN,
    query,
    pageNumber,
});

export const fetchSpotCarrierLanesPageSuccess = (
    query: FetchSpotCarrierLanesPageQueryT,
    pageNumber: PageNumberT,
    pageResponse: PageResponseT<SpotCarrierLaneT> | null,
): FetchSpotCarrierLanesPageSuccessActionT => ({
    type: FETCH_SPOT_CARRIER_LANES_PAGE_REQUEST_SUCCESS,
    query,
    pageResponse,
    pageNumber,
});

export const fetchSpotCarrierLanesPageError = (
    query: FetchSpotCarrierLanesPageQueryT,
    pageNumber: PageNumberT,
    error: Error,
): FetchSpotCarrierLanesPageErrorActionT => ({
    type: FETCH_SPOT_CARRIER_LANES_PAGE_REQUEST_ERROR,
    query,
    error,
    pageNumber,
});

export const resetSpotCarrierLanes = (mutationListOptions: MutationListOptionsT): ResetSpotCarrierLanesActionT => ({
    type: RESET_SPOT_CARRIER_LANES,
    mutationListOptions,
});
