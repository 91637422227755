import * as React from 'react';
import SkeletonFormatter from 'design-system/components/InfoTable/formatters/SkeletonFormatter/SkeletonFormatter';
import SimpleTrailerTypeFormatter from 'design-system/components/InfoTable/formatters/SimpleTrailerTypeFormatter/SimpleTrailerTypeFormatter';
import { ApiTrailerDictTypeT } from 'common/utils/api/models';
import isNumber from 'lodash/isNumber';
import { useTrailersDict } from 'common/utils/hooks/useTrailersDict';

type PropsT = {
    trailerDictId?: ApiTrailerDictTypeT['id'];
};

const AsyncTrailerTypeFormatter: React.FC<PropsT> = React.memo((props) => {
    const { trailerDictId } = props;

    const { trailersDictById, trailersDictRequest } = useTrailersDict();
    if (trailersDictRequest.loading) {
        return <SkeletonFormatter />;
    }

    if (!isNumber(trailerDictId)) {
        return null;
    }

    const trailerDictType = trailersDictById[trailerDictId] || null;

    return <SimpleTrailerTypeFormatter trailerDictType={trailerDictType} />;
});

export default AsyncTrailerTypeFormatter;
