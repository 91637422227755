import React from 'react';
import classNames from 'classnames/bind';

import styles from './ChangeBidForm.scss';
import { useTranslation } from 'react-i18next';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { FieldsEnum, FormValuesT } from './constants';
import validateForm from './validate-form';
import { useFormik } from 'formik';
import FormikField from 'common/components/forms/FormikField/FormikField';
import TimeWindowPicker from 'common/components/forms/TimeWindowPicker/TimeWindowPicker';
import { getRelativeStartDayTimeWindow, MS_IN_HOUR, roundTimestamp } from 'common/utils/time';
import { ALL_DAY_RANGE, UnitTypeEnum } from 'common/constants';
import Input from 'common/components/Input/Input';
import LinkDatePicker, {
    DatePickerOverlayPositionEnum,
} from 'design-system/components/date-pickers/LinkDatePicker/LinkDatePicker';
import { getDateStringToTimeWindow, mergeWithRelativeTimeWindow } from './utils';
import moment from 'moment';
import TimeWindowPillLabel from './TimeWindowPillLabel/TimeWindowPillLabel';
import isEqual from 'lodash/isEqual';
import { selectUpdateBidRequest } from 'carrier/store/bids/selectors';

import NumberInput from 'common/components/NumberInput/NumberInput';
import { getMoneyMask } from 'common/utils/input-masks';
import { SpotRequestDetailsT } from 'carrier/store/spot-request-details/models';
import { prepareDetails } from 'carrier/components/BaseSpotRequestDetailsSidebarContent/ChangeBidForm/prepare-details';
import { acceptBid, placeBid } from 'carrier/store/bids/actions';
import { useWatchFormAnyErrors } from 'common/utils/hooks/useWatchFormFormHasErrors';
import PlaceNextBidConfirmation, {
    PlaceNextBidConfirmationDataT,
} from 'carrier/components/BaseSpotRequestDetailsSidebarContent/dialogs/PlaceNextBidConfirmation/PlaceNextBidConfirmation';
import useModalDialog from 'common/utils/hooks/useModalDialog';
import { availableAcceptBidStatusesSet } from 'carrier/store/spot-request-details/constants';
import { BidStatusEnum } from 'carrier/utils/api/spot-carrier-tranziit/models';

const cx = classNames.bind(styles);

type PropsT = {
    details: SpotRequestDetailsT | null;
    onCancel: () => void;
};

const INITIAL_VALUES: FormValuesT = {
    [FieldsEnum.deadlineTimeWindow]: [Date.now(), Date.now() + 2 * MS_IN_HOUR],
    [FieldsEnum.comment]: '',
    [FieldsEnum.price]: '',
};

const ChangeBidForm: React.FC<PropsT> = (props) => {
    const { onCancel, details } = props;

    const { t } = useTranslation();

    const updateBidRequest = useSelector(selectUpdateBidRequest);
    const dispatch = useDispatch();

    const startValidTillTimeWindowTimestamp = React.useMemo(() => {
        return roundTimestamp(Date.now(), MS_IN_HOUR);
    }, []);

    const allowedRangeValidTill = React.useMemo((): TimeWindowT | null => {
        if (!details) {
            return null;
        }

        const expiresAt = details?.expiresAt;
        const expiresAtTimestamp = moment(expiresAt).valueOf();

        return [startValidTillTimeWindowTimestamp, expiresAtTimestamp];
    }, [startValidTillTimeWindowTimestamp, details]);

    const [minDateCalendarDate, maxDateCalendarDate] = React.useMemo(() => {
        if (!allowedRangeValidTill) {
            return [null, null];
        }

        const format = (timestamp: number) => moment(timestamp).format('YYYY-MM-DD');

        return [format(allowedRangeValidTill[0]), format(allowedRangeValidTill[1])];
    }, [allowedRangeValidTill]);

    const validate = React.useMemo(() => {
        return (values: FormValuesT) => validateForm(t, values);
    }, [t]);

    const [initialValues, initialErrors] = React.useMemo(() => {
        const values: FormValuesT = {
            ...INITIAL_VALUES,
            [FieldsEnum.deadlineTimeWindow]: allowedRangeValidTill,
            [FieldsEnum.comment]: '',
        };

        const errors = validateForm(t, values);

        return [values, errors];
    }, [allowedRangeValidTill]);

    const placeNextBidConfirmation = useModalDialog<PlaceNextBidConfirmationDataT>();

    const handleSubmit = (values: FormValuesT): void => {
        const apiDetails = prepareDetails(values);
        const id = details?.id || null;
        if (!id || !apiDetails) {
            return;
        }

        const needAcceptBid = details?.bids?.find((bid) => {
            return !!bid?.status && availableAcceptBidStatusesSet.has(bid.status);
        });
        const needAcceptBidId = needAcceptBid?.id || null;
        if (needAcceptBid && needAcceptBidId) {
            dispatch(acceptBid(id, needAcceptBidId, apiDetails));
            return;
        }

        const hasAcceptedBids = details?.bids?.find((bid) => {
            return bid?.status === BidStatusEnum.accepted;
        });
        if (hasAcceptedBids) {
            placeNextBidConfirmation.setData({
                spotRequestId: id,
                changeBidDetails: apiDetails,
            });
        } else {
            dispatch(placeBid(id, apiDetails));
        }
    };

    const formik = useFormik<FormValuesT>({
        enableReinitialize: true,
        validateOnBlur: false,
        initialErrors,
        initialValues,
        validate,
        onSubmit: (values, formikHelpers): void => {
            handleSubmit(values);
            formikHelpers.setTouched({});
        },
    });

    const handlePlaceBidConfirmation = (data: PlaceNextBidConfirmationDataT) => {
        const { spotRequestId, changeBidDetails } = data;
        dispatch(placeBid(spotRequestId, changeBidDetails));
    };

    const hasFormAnyErros = useWatchFormAnyErrors(formik.errors);

    const deadlineTimeWindow = formik.values[FieldsEnum.deadlineTimeWindow];

    const { selectedDate, selectedRelativeTimeWindow } = React.useMemo(() => {
        const selectedDate = getDateStringToTimeWindow(deadlineTimeWindow);
        const selectedRelativeTimeWindow = getRelativeStartDayTimeWindow(deadlineTimeWindow, selectedDate);

        return {
            selectedDate,
            selectedRelativeTimeWindow,
        };
    }, [deadlineTimeWindow]);

    const handleChangeDate = (value: Date | null) => {
        const startTimestamp = formik.values[FieldsEnum.deadlineTimeWindow]?.[0] || null;

        const endTimestamp = value ? moment(value).endOf('day').valueOf() - 1 : null;
        const limitedEndTimestamp =
            endTimestamp && allowedRangeValidTill ? Math.min(endTimestamp, allowedRangeValidTill?.[1]) : endTimestamp;

        formik.setTouched({
            [FieldsEnum.deadlineTimeWindow]: true,
        });

        formik.setFieldValue(FieldsEnum.deadlineTimeWindow, [startTimestamp, limitedEndTimestamp]);
    };

    const selectedCalendarDate = React.useMemo(() => {
        return selectedDate ? new Date(selectedDate) : null;
    }, [selectedDate]);

    const availableRelativeValues = React.useMemo((): TimeWindowT | null => {
        return getRelativeStartDayTimeWindow(allowedRangeValidTill, selectedDate);
    }, [allowedRangeValidTill, selectedDate]);

    const handleChangeTimeWindow = (name: FieldsEnum.deadlineTimeWindow, relativeTimeWindow: TimeWindowT): void => {
        const prevTimeWindow = formik.values[name];
        if (!prevTimeWindow) {
            return;
        }

        const timeWindow = mergeWithRelativeTimeWindow(prevTimeWindow, relativeTimeWindow);

        const isSameValue = isEqual(prevTimeWindow, timeWindow);
        if (isSameValue) {
            return;
        }

        formik.setFieldValue(name, timeWindow);
    };
    const moneyMask = React.useMemo(() => getMoneyMask(), []);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <FormikField
                    name={FieldsEnum.deadlineTimeWindow}
                    error={formik.errors[FieldsEnum.deadlineTimeWindow]}
                    meta={formik.getFieldMeta(FieldsEnum.deadlineTimeWindow)}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                    withoutLabel
                    className={cx('field', 'field--time-picker')}
                >
                    {(props) => (
                        <TimeWindowPicker
                            range={ALL_DAY_RANGE}
                            availableValues={availableRelativeValues || ALL_DAY_RANGE}
                            ranges={[]}
                            name={FieldsEnum.deadlineTimeWindow}
                            hasStartStep
                            hasEndStep
                            isDisableLeftRangeControl
                            values={selectedRelativeTimeWindow}
                            setFieldValue={handleChangeTimeWindow}
                            label={t('spot-request-details.change-bid.fields.deadline-time-window.label')}
                            duration={
                                <TimeWindowPillLabel
                                    className={cx('time-window-pill-label')}
                                    timeWindow={formik.values[FieldsEnum.deadlineTimeWindow]}
                                />
                            }
                            link={
                                <LinkDatePicker
                                    minDate={minDateCalendarDate}
                                    maxDate={maxDateCalendarDate}
                                    value={selectedCalendarDate}
                                    onChange={handleChangeDate}
                                    overlayPosition={DatePickerOverlayPositionEnum.bottomRight}
                                />
                            }
                        />
                    )}
                </FormikField>
                <FormikField
                    className={cx('field', 'field--comment')}
                    name={FieldsEnum.comment}
                    error={formik.errors[FieldsEnum.comment]}
                    meta={formik.getFieldMeta(FieldsEnum.comment)}
                    label={t('spot-request-details.change-bid.fields.comment.label')}
                    setFieldValue={formik.setFieldValue}
                    setFieldTouched={formik.setFieldTouched}
                >
                    {(props) => (
                        <Input
                            name={FieldsEnum.comment}
                            value={formik.values[FieldsEnum.comment]}
                            placeholder={t('spot-request-details.change-bid.fields.comment.placeholder')}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                            hasError={props.hasError}
                            hasWarning={props.hasWarning}
                        />
                    )}
                </FormikField>
                <div className={cx('footer')}>
                    <FormikField
                        className={cx('field')}
                        name={FieldsEnum.price}
                        error={formik.errors[FieldsEnum.price]}
                        meta={formik.getFieldMeta(FieldsEnum.price)}
                        label={t('spot-request-details.change-bid.fields.price.label')}
                        setFieldValue={formik.setFieldValue}
                        setFieldTouched={formik.setFieldTouched}
                    >
                        {(props) => (
                            <NumberInput
                                name={FieldsEnum.price}
                                unitType={UnitTypeEnum.euroAbbreviation}
                                placeholder={t('spot-request-details.change-bid.fields.price.placeholder')}
                                mask={moneyMask}
                                step={1}
                                value={formik.values[FieldsEnum.price]}
                                onChange={props.onChange}
                                onBlur={props.onBlur}
                                onFocus={props.onFocus}
                                hasError={props.hasError}
                                hasWarning={props.hasWarning}
                            />
                        )}
                    </FormikField>
                    <div className={cx('actions')}>
                        <Button
                            theme={ButtonThemeEnum.primary}
                            isDisabled={hasFormAnyErros || updateBidRequest.loading}
                            className={cx('action', 'action--send')}
                            isLoading={updateBidRequest.loading}
                            type="submit"
                        >
                            {t('spot-request-details.change-bid.actions.submit')}
                        </Button>
                        <Button
                            theme={ButtonThemeEnum.secondary}
                            className={cx('action', 'action--cancel')}
                            onClick={onCancel}
                        >
                            {t('spot-request-details.change-bid.actions.cancel')}
                        </Button>
                    </div>
                </div>
            </form>
            <PlaceNextBidConfirmation
                data={placeNextBidConfirmation.data}
                requestStatus={updateBidRequest}
                onClose={placeNextBidConfirmation.onClose}
                onCancel={placeNextBidConfirmation.onCancel}
                onConfirmation={handlePlaceBidConfirmation}
            />
        </>
    );
};

export default ChangeBidForm;
