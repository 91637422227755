import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import Modal from 'common/components/Modal/Modal';
import BigQuestionIcon from 'common/icons/BigQuestionIcon';
import TourStatus from 'common/components/status/TourStatus/TourStatus';
import { TourStatusEnum } from 'common/utils/api/models';
import { StyleGuideColorsEnum } from 'common/constants';
import { useSelector } from 'react-redux';
import { selectReAssignmentRequestStatus } from 'common/store/driver-assigment/selectors';

export type UnassignDriversConfirmationDataT = {};

type PropsT = {
    data: UnassignDriversConfirmationDataT | null;
    onConfirm: (data: UnassignDriversConfirmationDataT) => void;
    onCancel: () => void;
    onClose: () => void;
};

const TEST_SELECTOR = 'unassign-drivers-confirmation';

const UnassignDriversConfirmation: React.FC<PropsT> = React.memo((props) => {
    const { data, onConfirm, onCancel, onClose } = props;

    const reAssignRequest = useSelector(selectReAssignmentRequestStatus);

    const { t } = useTranslation();

    if (!data) {
        return null;
    }

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                <ModalDialog
                    width={DEFAULT_MODAL_DIALOG_WIDTH}
                    testSelector={TEST_SELECTOR}
                    actions={[
                        {
                            children: t('common:error-modal.actions.cancel'),
                            theme: ButtonThemeEnum.secondary,
                            testSelector: `${TEST_SELECTOR}_cancel`,
                            isDisabled: reAssignRequest.loading,
                            onClick: onCancel,
                        },
                        {
                            children: t('common:error-modal.actions.clear'),
                            theme: ButtonThemeEnum.danger,
                            testSelector: `${TEST_SELECTOR}_ok`,
                            isDisabled: reAssignRequest.loading,
                            isLoading: reAssignRequest.loading,
                            onClick: () => {
                                onConfirm(data);
                            },
                        },
                    ]}
                    icon={<BigQuestionIcon baseColor={StyleGuideColorsEnum.tomatoRed} />}
                    title={t('common:transport-order-details.dialogs.unassign-drivers.title')}
                    message={
                        <Trans
                            i18nKey="common:transport-order-details.dialogs.unassign-drivers.description"
                            components={{
                                status: <TourStatus status={TourStatusEnum.driverUnassigned} />,
                            }}
                        />
                    }
                />
            </ModalContent>
        </Modal>
    );
});

export default UnassignDriversConfirmation;
