import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    className?: string;
    fillColor: StyleGuideColorsEnum;
    strokeColor: StyleGuideColorsEnum;
    size: number;
};

const TruckPickUpIcon: React.FC<PropsT> = (props) => {
    const { size, className, fillColor, strokeColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" className={className}>
            <g fill="none" fillRule="evenodd">
                <path fill={fillColor} d="M16 5v8l1 1v2h-6V5h5z" />
                <path
                    fill={strokeColor}
                    d="M15.97 1.045l.716.453c.079.05.126.135.126.225v10.134h.296c.623 0 1.129.483 1.129 1.08v2.775h.48c.157 0 .283.121.283.27v1.493c0 .149-.126.27-.282.27H17.5v.715c0 .298-.253.54-.565.54H15.44a.553.553 0 01-.565-.54v-.715L11 17.744V8.688h4.157v-2.66H11V2.74l2.465.001c.771 0 1.467.428 1.79 1.088l.063.145.213.55V1.27c0-.215.25-.343.438-.225zm-3.051 9.561c.312 0 .564.242.564.54v3.421c0 .299-.252.54-.564.54h-1.92v-4.5h1.92zm3.396 4.35h-.964a.522.522 0 01-.534-.51v-.012c0-.282.239-.51.534-.51h.964c.294 0 .533.228.533.51v.013c0 .281-.239.51-.533.51zM2.552 8.195h1.192v3.75c0 .413.338.75.75.75h3c.413 0 .75-.337.75-.75v-3.75h1.193c.667 0 1.005-.81.532-1.282L6.527 3.469a.747.747 0 00-1.058 0L2.027 6.912c-.473.472-.143 1.282.525 1.282zm4.942 6.056c.41 0 .744.331.75.739v1.955c0 .413-.337.75-.75.75h-3a.752.752 0 01-.75-.75V15c0-.412.338-.75.75-.75h3z"
                />
            </g>
        </svg>
    );
};

TruckPickUpIcon.displayName = 'TruckPickUpIcon';

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
    fillColor: StyleGuideColorsEnum.brandAccent,
    strokeColor: StyleGuideColorsEnum.brandDark,
};

/**
 *  @deprecated create/use ...Props class
 * */
export const getTruckPickUpIconProps = (
    preset:
        | 'default'
        | 'selected'
        | 'assignment-events'
        | 'assignment-normal'
        | 'assignment-selected'
        | 'assignment-input-normal'
        | 'assignment-input-selected',
): PropsT => {
    switch (preset) {
        case 'selected': {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.brandAccent,
                strokeColor: StyleGuideColorsEnum.brandDark,
            };
        }
        case 'assignment-normal': {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.transparent,
                strokeColor: StyleGuideColorsEnum.white,
            };
        }
        case 'assignment-input-normal': {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.white,
                strokeColor: StyleGuideColorsEnum.light,
            };
        }
        case 'assignment-events': {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.transparent,
                strokeColor: StyleGuideColorsEnum.white,
            };
        }
        case 'assignment-selected': {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.brandAccent,
                strokeColor: StyleGuideColorsEnum.brandDark,
            };
        }
        case 'assignment-input-selected': {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.brandAccent,
                strokeColor: StyleGuideColorsEnum.brandDark,
            };
        }
        case 'default':
        default: {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.white,
                strokeColor: StyleGuideColorsEnum.light,
            };
        }
    }
};

export { storyProps };
export default TruckPickUpIcon;
