export enum FieldsEnum {
    driverId = 'driverId',
    teamDriverId = 'teamDriverId',
}

export type FormValuesT = {
    [FieldsEnum.driverId]: DriverIdT | null;
    [FieldsEnum.teamDriverId]: DriverIdT | null;
};

export type FormErrorsT = Partial<Record<FieldsEnum, string>>;
