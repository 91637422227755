import {
    FETCH_ORDERS_STATS_REQUEST,
    FETCH_ORDERS_STATS_REQUEST_BEGIN,
    FETCH_ORDERS_STATS_REQUEST_ERROR,
    FETCH_ORDERS_STATS_REQUEST_SUCCESS,
    FetchTransportOrdersStatsActionT,
    FetchTransportOrdersStatsBeginActionT,
    FetchTransportOrdersStatsErrorActionT,
    FetchTransportOrdersStatsSuccessActionT,
} from './types';
import { TransportOrderStatsT } from './models';

export const fetchTransportOrdersStats = (): FetchTransportOrdersStatsActionT => ({
    type: FETCH_ORDERS_STATS_REQUEST,
});

export const fetchTransportOrdersStatsBegin = (): FetchTransportOrdersStatsBeginActionT => ({
    type: FETCH_ORDERS_STATS_REQUEST_BEGIN,
});

export const fetchTransportOrdersStatsSuccess = (
    statistics: TransportOrderStatsT | null,
): FetchTransportOrdersStatsSuccessActionT => ({
    type: FETCH_ORDERS_STATS_REQUEST_SUCCESS,
    statistics,
});

export const fetchTransportOrdersStatsError = (error: Error): FetchTransportOrdersStatsErrorActionT => ({
    type: FETCH_ORDERS_STATS_REQUEST_ERROR,
    error,
});
