import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './AssetOptionLabel.scss';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

type PropsT = {
    model?: string;
    plateNumber?: string;
    ratePerKm?: number;
};

const AssetOptionLabel: React.FC<PropsT> = React.memo((props) => {
    const { model, plateNumber, ratePerKm } = props;

    const { t } = useTranslation();

    return (
        <div className={cx('option')}>
            <div>
                {plateNumber && <span className={cx('plate-number')}>{plateNumber}</span>}
                {model && <span className={cx('model')}>{model}</span>}
            </div>
            <div>
                {ratePerKm && (
                    <span className={cx('rate')}>
                        {t('common:spot-asset-transport-order-assignment.rate', {
                            rate: ratePerKm,
                        })}
                    </span>
                )}
            </div>
        </div>
    );
});

export default AssetOptionLabel;
