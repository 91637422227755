import * as React from 'react';
import PillLabel, { PillLabelThemeEnum } from 'common/components/PillLabel/PillLabel';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import { useTranslation } from 'react-i18next';

type PropsT = {};

const DriverWrongCheckPointPill: React.FC<PropsT> = React.memo(() => {
    const { t } = useTranslation();

    return (
        <Tooltip
            position={TooltipPositionEnum.centerLeft}
            theme={TooltipThemeEnum.black}
            tooltipNode={
                <TooltipContent isNoWrap theme={TooltipContentThemeEnum.black}>
                    {t('common:driver-wrong-checkpoint-pill.tooltip')}
                </TooltipContent>
            }
        >
            {(isShow, childrenClassName) => (
                <PillLabel
                    className={childrenClassName}
                    theme={PillLabelThemeEnum.charcoal}
                    testSelector="driver-wrong-checkpoint"
                >
                    {t('common:driver-wrong-checkpoint-pill.label')}
                </PillLabel>
            )}
        </Tooltip>
    );
});

export default DriverWrongCheckPointPill;
