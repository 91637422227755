import { SpotRequestDetailsT, SpotTourDetailsT } from './models';
import { RequestStatusT } from 'common/utils/request-status';

export const FETCH_SPOT_REQUEST_DETAILS_REQUEST = 'carrier/spot-request-details/FETCH_SPOT_REQUEST_DETAILS_REQUEST';
export const FETCH_SPOT_REQUEST_DETAILS_REQUEST_BEGIN =
    'carrier/spot-request-details/FETCH_SPOT_REQUEST_DETAILS_REQUEST_BEGIN';
export const FETCH_SPOT_REQUEST_DETAILS_REQUEST_ERROR =
    'carrier/spot-request-details/FETCH_SPOT_REQUEST_DETAILS_REQUEST_ERROR';
export const FETCH_SPOT_REQUEST_DETAILS_REQUEST_SUCCESS =
    'carrier/spot-request-details/FETCH_SPOT_REQUEST_DETAILS_REQUEST_SUCCESS';

export const FETCH_SPOT_TOUR_DETAILS_REQUEST = 'carrier/spot-request-details/FETCH_SPOT_TOUR_DETAILS_REQUEST';
export const FETCH_SPOT_TOUR_DETAILS_REQUEST_BEGIN =
    'carrier/spot-request-details/FETCH_SPOT_TOUR_DETAILS_REQUEST_BEGIN';
export const FETCH_SPOT_TOUR_DETAILS_REQUEST_ERROR =
    'carrier/spot-request-details/FETCH_SPOT_TOUR_DETAILS_REQUEST_ERROR';
export const FETCH_SPOT_TOUR_DETAILS_REQUEST_SUCCESS =
    'carrier/spot-request-details/FETCH_SPOT_TOUR_DETAILS_REQUEST_SUCCESS';

export type SpotRequestDetailsStateT = {
    details: SpotRequestDetailsT | null;
    fetchDetailsRequestStatus: RequestStatusT;
    spotTourDetails: SpotTourDetailsT | null;
    fetchSpotTourDetailsRequestStatus: RequestStatusT;
};

export type SpotRequestDetailsByIdStateT = Record<SpotRequestIdT, SpotRequestDetailsStateT>;

export type FetchSpotRequestDetailsRequestActionT = {
    type: typeof FETCH_SPOT_REQUEST_DETAILS_REQUEST;
    id: SpotRequestIdT;
    options?: FetchOptionsT;
};

export type FetchSpotRequestDetailsRequestBeginActionT = {
    type: typeof FETCH_SPOT_REQUEST_DETAILS_REQUEST_BEGIN;
    id: SpotRequestIdT;
};

export type FetchSpotRequestDetailsRequestErrorActionT = {
    type: typeof FETCH_SPOT_REQUEST_DETAILS_REQUEST_ERROR;
    error: Error;
    id: SpotRequestIdT;
};

export type FetchSpotRequestDetailsRequestSuccessActionT = {
    type: typeof FETCH_SPOT_REQUEST_DETAILS_REQUEST_SUCCESS;
    details: SpotRequestDetailsT | null;
    id: SpotRequestIdT;
};

export type FetchSpotTourDetailsRequestActionT = {
    type: typeof FETCH_SPOT_TOUR_DETAILS_REQUEST;
    spotRequestId: SpotRequestIdT;
    tourId: TourIdT;
    options?: FetchOptionsT;
};

export type FetchSpotTourDetailsRequestBeginActionT = {
    type: typeof FETCH_SPOT_TOUR_DETAILS_REQUEST_BEGIN;
    spotRequestId: SpotRequestIdT;
};

export type FetchSpotTourDetailsRequestErrorActionT = {
    type: typeof FETCH_SPOT_TOUR_DETAILS_REQUEST_ERROR;
    error: Error;
    spotRequestId: SpotRequestIdT;
};

export type FetchSpotTourDetailsRequestSuccessActionT = {
    type: typeof FETCH_SPOT_TOUR_DETAILS_REQUEST_SUCCESS;
    spotRequestId: SpotRequestIdT;
    details: SpotTourDetailsT | null;
};

export type SpotRequestDetailsActionT =
    | FetchSpotRequestDetailsRequestActionT
    | FetchSpotRequestDetailsRequestBeginActionT
    | FetchSpotRequestDetailsRequestErrorActionT
    | FetchSpotRequestDetailsRequestSuccessActionT
    | FetchSpotTourDetailsRequestActionT
    | FetchSpotTourDetailsRequestBeginActionT
    | FetchSpotTourDetailsRequestErrorActionT
    | FetchSpotTourDetailsRequestSuccessActionT;
