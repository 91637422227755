import * as React from 'react';

import classNames from 'classnames/bind';

import styles from './BidMessage.scss';
import cs from 'classnames';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';

type PropsT = {
    icon?: React.ReactNode;
    title?: React.ReactNode;
    description?: React.ReactNode;
    action?: React.ReactNode;
    onActionClick?: () => void;
    className?: string;
};

const cx = classNames.bind(styles);

const BidMessage: React.FC<PropsT> = React.memo((props) => {
    const { icon, title, description, className, action, onActionClick } = props;

    const hasAction = !!action;

    return (
        <div className={cs(cx('inner'), className)}>
            <div className={cx('icon')}>{icon}</div>
            <div className={cx('title')}>{title}</div>
            <div
                className={cx('description', {
                    'description--has-action': hasAction,
                })}
            >
                {description}
            </div>
            {hasAction && (
                <Button theme={ButtonThemeEnum.secondary} onClick={onActionClick}>
                    {action}
                </Button>
            )}
        </div>
    );
});

export default BidMessage;
