import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './RunOutOfTimeAssignDriversCard.scss';
import { Trans } from 'react-i18next';
import NotificationSubjectType from '../entities/NotificationSubjectType/NotificationSubjectType';
import { ApiNotificationSubjectTypeT } from 'common/utils/api/models';
import CityName from '../entities/CityName/CityName';
import SubjectLink from '../entities/SubjectLink/SubjectLink';

const cx = classNames.bind(styles);

export type RunOutOfTimeAssignDriversCardPropsT = {
    subjectType: ApiNotificationSubjectTypeT | null | undefined;
    subjectNumber: React.ReactNode;
    originCity: React.ReactNode;
    destinationCity: React.ReactNode;
};

const RunOutOfTimeAssignDriversCard: React.FC<RunOutOfTimeAssignDriversCardPropsT> = React.memo((props) => {
    const { subjectType, subjectNumber, originCity, destinationCity } = props;

    return (
        <div className={cx('wrap')}>
            <Trans
                i18nKey="common:notifications.run-out-of-time-assign-drivers"
                components={{
                    subjectType: <NotificationSubjectType isCapitalized subjectType={subjectType} />,
                    subjectLink: <SubjectLink number={subjectNumber} />,
                    cityFrom: <CityName cityName={originCity} />,
                    cityTo: <CityName cityName={destinationCity} />,
                }}
            />
        </div>
    );
});

export default RunOutOfTimeAssignDriversCard;
