import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './AssetDropOffLocationPin.scss';
import { AssetTypeEnum } from 'common/constants';
import LinkDropOffIcon, { getLinkDropOffIconProps } from 'common/icons/LinkDropOffIcon';
import TruckDropOffIcon, { getTruckDropOffIconProps } from 'common/icons/TruckDropOffIcon';
import TrailerDropOffIcon, { getTrailerDropOffIconProps } from 'common/icons/TrailerDropOffIcon';
import MapPin, { MapPinThemeEnum } from 'common/components/maps/MapPin/MapPin';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    lat: number | undefined;
    lng: number | undefined;
    assetType: AssetTypeEnum | null;
};

const AssetDropOffLocationPin: React.FC<PropsT> = React.memo((props) => {
    const { className, lat, lng, assetType } = props;

    let iconNode: React.ReactNode | undefined;

    if (!iconNode && !assetType) {
        iconNode = <LinkDropOffIcon {...getLinkDropOffIconProps('assignment-normal')} />;
    }

    if (!iconNode && assetType === AssetTypeEnum.trailer) {
        iconNode = <TrailerDropOffIcon {...getTrailerDropOffIconProps('assignment-normal')} />;
    }

    if (!iconNode && assetType === AssetTypeEnum.truck) {
        iconNode = <TruckDropOffIcon {...getTruckDropOffIconProps('assignment-normal')} />;
    }

    if (!iconNode) {
        return null;
    }

    return (
        <MapPin
            hasPaddings
            className={cs(cx('asset-tooltip'), className)}
            lat={lat}
            lng={lng}
            theme={MapPinThemeEnum.charcoal}
        >
            {iconNode}
        </MapPin>
    );
});

export default AssetDropOffLocationPin;
