import React from 'react';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    fillColor: StyleGuideColorsEnum;
};

const SmallDownArrow: React.FC<PropsT> = (props) => {
    const { fillColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={10} height={10} viewBox="0 0 10 10" {...props}>
            <path
                d="M8.75 3.144L5.884 6.288V0H4.116v6.288L1.25 3.144 0 4.515 5 10l5-5.485z"
                fill={fillColor}
                fillRule="evenodd"
            />
        </svg>
    );
};

SmallDownArrow.displayName = 'SmallDownArrow';

const storyProps: PropsT = {
    fillColor: StyleGuideColorsEnum.light,
};

export { storyProps };
export default SmallDownArrow;
