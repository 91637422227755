import * as React from 'react';

import commonTranziitApi from 'common/utils/api/tranziit/common-tranziit-api';
import ControlLoaderIcon from 'common/icons/ControlLoaderIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import classNames from 'classnames/bind';
import styles from './ImageLoader.scss';

const cx = classNames.bind(styles);

type StateT = {
    isLoaded: boolean;
};

type PropsT = {
    src: string | null;
    alt: string | null;
    className?: string;
    style?: React.CSSProperties;
};

class ImageLoader extends React.Component<PropsT, StateT> {
    imageNode: HTMLImageElement | null = null;

    constructor(props: PropsT) {
        super(props);

        this.state = {
            isLoaded: false,
        };
    }

    componentDidMount() {
        const { src } = this.props;

        if (src) {
            this.fetchImage(src);
        }
    }

    componentDidUpdate(prevProps: PropsT) {
        const { src } = this.props;

        if (src && prevProps.src !== src) {
            this.fetchImage(src);
        }
    }

    fetchImage(src: string) {
        commonTranziitApi
            .fetchImage(src)
            .then((response) => {
                const [error, blob] = response;

                if (!blob || error) {
                    return;
                }

                // @ts-expect-error
                const imageObjectURL = URL.createObjectURL(blob);

                if (this.imageNode) {
                    this.imageNode.src = imageObjectURL;
                }
            })
            .finally(() => {
                this.setState({
                    isLoaded: true,
                });
            });
    }

    render() {
        const { className, alt, style } = this.props;
        const { isLoaded } = this.state;

        return (
            <>
                <img
                    className={className}
                    src=""
                    style={{
                        ...style,
                        display: isLoaded ? 'block' : 'none',
                        opacity: isLoaded ? 1 : 0,
                    }}
                    alt={alt || undefined}
                    ref={(node) => {
                        if (node) {
                            this.imageNode = node;
                        }
                    }}
                />
                {!isLoaded && (
                    <ControlLoaderIcon
                        className={cx('placeholder')}
                        fillColor={StyleGuideColorsEnum.brandAccent}
                        size={DEFAULT_ICON_SIZE}
                    />
                )}
            </>
        );
    }
}

export default ImageLoader;
