import { RequestStatusT } from 'common/utils/request-status';
import { PaperDataT } from 'common/store/papers/models';

export const FETCH_PAPERS_REQUEST = 'common/papers/FETCH_PAPERS_REQUEST';
export const FETCH_PAPERS_REQUEST_BEGIN = 'common/PAPERS/FETCH_PAPERS_REQUEST_BEGIN';
export const FETCH_PAPERS_REQUEST_ERROR = 'common/papers/FETCH_PAPERS_REQUEST_ERROR';
export const FETCH_PAPERS_REQUEST_SUCCESS = 'common/papers/FETCH_PAPERS_REQUEST_SUCCESS';

export const UPLOAD_PAPER_REQUEST = 'common/papers/UPLOAD_PAPER_REQUEST';
export const UPLOAD_PAPER_REQUEST_BEGIN = 'common/papers/UPLOAD_PAPER_REQUEST_BEGIN';
export const UPLOAD_PAPER_REQUEST_ERROR = 'common/papers/UPLOAD_PAPER_REQUEST_ERROR';
export const UPLOAD_PAPER_REQUEST_SUCCESS = 'common/papers/UPLOAD_PAPER_REQUEST_SUCCESS';

export const DELETE_PAPER_REQUEST = 'common/papers/DELETE_PAPER_REQUEST';
export const DELETE_PAPER_REQUEST_BEGIN = 'common/papers/DELETE_PAPER_REQUEST_BEGIN';
export const DELETE_PAPER_REQUEST_ERROR = 'common/papers/DELETE_PAPER_REQUEST_ERROR';
export const DELETE_PAPER_REQUEST_SUCCESS = 'common/papers/DELETE_PAPER_REQUEST_SUCCESS';

export const RESET_PAPERS = 'common/papers/RESET_PAPERS';

export type FetchPapersActionT = {
    type: typeof FETCH_PAPERS_REQUEST;
    tourId: TourIdT;
    isBroker: boolean;
    options?: FetchOptionsT;
};

export type FetchPapersBeginActionT = {
    type: typeof FETCH_PAPERS_REQUEST_BEGIN;
    hash: PapersHashT;
};

export type FetchPapersSuccessActionT = {
    type: typeof FETCH_PAPERS_REQUEST_SUCCESS;
    hash: PapersHashT;
    list: Array<PaperDataT>;
};

export type FetchPapersErrorActionT = {
    type: typeof FETCH_PAPERS_REQUEST_ERROR;
    error: Error;
    hash: PapersHashT;
};

export type UploadPaperActionT = {
    type: typeof UPLOAD_PAPER_REQUEST;
    tourId: TourIdT;
    isBroker: boolean;
    file: File;
};

export type UploadPaperBeginActionT = {
    type: typeof UPLOAD_PAPER_REQUEST_BEGIN;
    hash: PapersHashT;
};

export type UploadPaperSuccessActionT = {
    type: typeof UPLOAD_PAPER_REQUEST_SUCCESS;
    hash: PapersHashT;
};

export type UploadPaperErrorActionT = {
    type: typeof UPLOAD_PAPER_REQUEST_ERROR;
    error: Error;
    hash: PapersHashT;
};

export type DeletePaperActionT = {
    type: typeof DELETE_PAPER_REQUEST;
    tourId: TourIdT;
    isBroker: boolean;
    paperId: PapersIdT;
};

export type DeletePaperBeginActionT = {
    type: typeof DELETE_PAPER_REQUEST_BEGIN;
    hash: PapersHashT;
    paperId: PapersIdT;
};

export type DeletePaperSuccessActionT = {
    type: typeof DELETE_PAPER_REQUEST_SUCCESS;
    hash: PapersHashT;
};

export type DeletePaperErrorActionT = {
    type: typeof DELETE_PAPER_REQUEST_ERROR;
    error: Error;
    hash: PapersHashT;
};

export type ResetPapersActionT = {
    type: typeof RESET_PAPERS;
};

export type PapersActionT =
    | FetchPapersActionT
    | FetchPapersBeginActionT
    | FetchPapersSuccessActionT
    | FetchPapersErrorActionT
    | DeletePaperActionT
    | DeletePaperBeginActionT
    | DeletePaperSuccessActionT
    | DeletePaperErrorActionT
    | UploadPaperActionT
    | UploadPaperBeginActionT
    | UploadPaperSuccessActionT
    | UploadPaperErrorActionT
    | ResetPapersActionT;

export type HashPapersStateT = {
    list: Array<PaperDataT>;
    fetchRequest: RequestStatusT;
    uploadRequest: RequestStatusT;
    deleteQuery: {
        id: PapersIdT;
    } | null;
    deleteRequest: RequestStatusT;
};

export type PapersStateT = Record<PapersHashT, HashPapersStateT>;
