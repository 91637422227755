import * as React from 'react';
import TopBar from 'common/layouts/LeftMenuLayout/TopBar/TopBar';
import classNames from 'classnames/bind';
import styles from './TrailersListPage.scss';
import { useTranslation } from 'react-i18next';
import TrailersPageLayout from 'common/layouts/TrailersPageLayout/TrailersPageLayout';
import ScrollableContent from 'common/layouts/LeftMenuLayout/ScrollableContent/ScrollableContent';
import NotificationsBarTrigger from 'common/components/notifications/NotificationsBarTrigger/NotificationsBarTrigger';
import ContentMargins from 'common/layouts/LeftMenuLayout/ContentMargins/ContentMargins';
import TopBarContent from 'common/layouts/LeftMenuLayout/TopBarContent/TopBarContent';
import PageTitle from 'common/components/PageTitle/PageTitle';
import { logWarning } from 'common/utils/logger';
import { CommonSidebarsTypeEnum } from 'common/layouts/SideBars/models';
import { useOpenLeftSidebar } from 'broker-admin/layouts/SideBars/hooks';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';

const cx = classNames.bind(styles);

type PropsT = {};

const TrailersListPage: React.FC<PropsT> = React.memo((props) => {
    const { partnerId, partnerType } = usePartnerContext();

    const { t } = useTranslation();

    const openLeftSidebar = useOpenLeftSidebar();
    const handleOpenUserDetails = (userId: UserIdT | null) => {
        if (!userId) {
            logWarning('failed to open user details in carrier trailers, empty userId');
            return;
        }

        openLeftSidebar({
            type: CommonSidebarsTypeEnum.contact,
            partnerId,
            partnerType,
            userId,
        });
    };

    return (
        <ScrollableContent>
            <PageTitle title={t('page-titles.trailers')} />
            <ContentMargins>
                <TopBar>
                    <TopBarContent title={t('trailers-page.title')} rightNode={<NotificationsBarTrigger />} />
                </TopBar>
            </ContentMargins>
            <TrailersPageLayout onOpenUserDetails={handleOpenUserDetails} />
        </ScrollableContent>
    );
});

export default TrailersListPage;
