import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './StatusCell.scss';
import { TruckT } from 'common/store/trucks/models';
import TruckStatusPill from 'common/components/status-pill/TruckStatusPill/TruckStatusPill';

type PropsT = {
    truck: TruckT;
    className?: string;
};

const cx = classNames.bind(styles);

const StatusCell: React.FC<PropsT> = React.memo((props) => {
    const { truck, className } = props;

    return (
        <div className={cx('status')}>
            <TruckStatusPill isSymmetrical className={className} status={truck?.status} />
        </div>
    );
});

export default StatusCell;
