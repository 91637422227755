import {
    ApiSpotCarrierLanesChangesT,
    ApiSpotCarrierLanesTrailerVariantT,
} from 'carrier/utils/api/spot-carrier-tranziit/models';

export type SpotCarrierLanesChangesT = ApiSpotCarrierLanesChangesT;

export type SpotCarrierLanesTrailerVariantT = ApiSpotCarrierLanesTrailerVariantT;
export enum SpotCarrierLanesTrailerVariantEnum {
    all = 'ALL',
    tilt = 'TILT',
    box = 'BOX',
}
