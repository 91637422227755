import {
    CHANGE_SPOT_CARRIER_LANE_REQUEST_BEGIN,
    CHANGE_SPOT_CARRIER_LANE_REQUEST_ERROR,
    CHANGE_SPOT_CARRIER_LANE_REQUEST_SUCCESS,
    ChangeSpotCarrierLaneRequestBeginActionT,
    ChangeSpotCarrierLaneRequestErrorActionT,
    ChangeSpotCarrierLaneRequestSuccessActionT,
    CREATE_SPOT_CARRIER_LANES,
    CreateSpotCarrierLanesActionT,
    DELETE_SPOT_CARRIER_LANES,
    DeleteSpotCarrierLanesActionT,
    UPDATE_SPOT_CARRIER_LANES,
    UpdateSpotCarrierLanesActionT,
} from './types';
import { SpotCarrierLanesChangesT } from 'carrier/store/spot-carrier-lane-changes/models';

export const deleteSpotCarrierLanes = (changes: SpotCarrierLanesChangesT): DeleteSpotCarrierLanesActionT => ({
    type: DELETE_SPOT_CARRIER_LANES,
    changes,
});

export const updateSpotCarrierLanes = (changes: SpotCarrierLanesChangesT): UpdateSpotCarrierLanesActionT => ({
    type: UPDATE_SPOT_CARRIER_LANES,
    changes,
});

export const createSpotCarrierLanes = (changes: SpotCarrierLanesChangesT): CreateSpotCarrierLanesActionT => ({
    type: CREATE_SPOT_CARRIER_LANES,
    changes,
});

export const changeSpotCarrierLaneRequestBegin = (
    changes: SpotCarrierLanesChangesT,
): ChangeSpotCarrierLaneRequestBeginActionT => ({
    type: CHANGE_SPOT_CARRIER_LANE_REQUEST_BEGIN,
    changes,
});

export const changeSpotCarrierLaneRequestSuccess = (
    changes: SpotCarrierLanesChangesT,
): ChangeSpotCarrierLaneRequestSuccessActionT => ({
    type: CHANGE_SPOT_CARRIER_LANE_REQUEST_SUCCESS,
    changes,
});

export const changeSpotCarrierLaneRequestError = (
    changes: SpotCarrierLanesChangesT,
    error: Error,
): ChangeSpotCarrierLaneRequestErrorActionT => ({
    type: CHANGE_SPOT_CARRIER_LANE_REQUEST_ERROR,
    error,
    changes,
});
