import { useMemo } from 'react';
import { ApiStopTypeT, ApiTransportationOrderWaypointT, StopEnum } from 'common/utils/api/models';

const useTransportOrderPayloadWaypoints = (waypoints: Array<ApiTransportationOrderWaypointT> | null | undefined) => {
    return useMemo(() => {
        if (!waypoints) {
            return {
                firstPayloadWaypoint: null,
                lastPayloadWaypoint: null,
            };
        }

        const payloadWaypointStopTypeSet = new Set<ApiStopTypeT>([
            StopEnum.pickupDeliveryShipment,
            StopEnum.driveThrough,
        ]);

        const payloadWaypoints = waypoints?.filter((dispatchWaypoint) => {
            return payloadWaypointStopTypeSet.has(dispatchWaypoint.type);
        });

        return {
            firstPayloadWaypoint: payloadWaypoints[0] || null,
            lastPayloadWaypoint: payloadWaypoints[payloadWaypoints.length - 1] || null,
        };
    }, [waypoints]);
};

export { useTransportOrderPayloadWaypoints };
