import { put, select, takeEvery } from 'redux-saga/effects';
import {
    fetchSpotInboxRequestsStatsBegin,
    fetchSpotInboxRequestsStatsError,
    fetchSpotInboxRequestsStatsSuccess,
} from './actions';
import { FETCH_SPOT_INBOX_REQUESTS_STATS_REQUEST, FetchSpotInboxRequestsStatsActionT } from './types';
import checkNeedRequest from 'common/utils/check-need-request';
import { selectSpotInboxRequestsStatsRequest } from './selectors';
import spotCarrierTranziitApi from 'carrier/utils/api/spot-carrier-tranziit/api';

function* fetchSpotInboxRequestsStatsSaga(action: FetchSpotInboxRequestsStatsActionT): WrapGeneratorT<void> {
    const { options } = action;

    const requestStatus: ReturnType<typeof selectSpotInboxRequestsStatsRequest> = yield select(
        selectSpotInboxRequestsStatsRequest,
    );

    const isNeedRequest = checkNeedRequest(requestStatus, options);
    if (!isNeedRequest) {
        return;
    }

    yield put(fetchSpotInboxRequestsStatsBegin());

    const [error, response]: ReturnApiT<typeof spotCarrierTranziitApi.fetchSpotInboxRequestStatusesStats> =
        yield spotCarrierTranziitApi.fetchSpotInboxRequestStatusesStats();
    if (error) {
        yield put(fetchSpotInboxRequestsStatsError(error));
        return;
    }

    yield put(fetchSpotInboxRequestsStatsSuccess(response));
}

function* spotInboxRequestsStatsSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_SPOT_INBOX_REQUESTS_STATS_REQUEST, fetchSpotInboxRequestsStatsSaga);
}

export default spotInboxRequestsStatsSaga;
