import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './PlateNumberCell.scss';

type PropsT = {
    plateNumber: string | undefined;
};

const cx = classNames.bind(styles);

const PlateNumberCell: React.FC<PropsT> = React.memo((props) => {
    const { plateNumber } = props;

    return <div className={cx('plate-number')}>{plateNumber}</div>;
});

export default PlateNumberCell;
