import {
    FETCH_SPOT_CARRIER_PROFILE_REQUEST,
    FETCH_SPOT_CARRIER_PROFILE_REQUEST_BEGIN,
    FETCH_SPOT_CARRIER_PROFILE_REQUEST_ERROR,
    FETCH_SPOT_CARRIER_PROFILE_REQUEST_SUCCESS,
    FetchSpotCarrierProfileActionT,
    FetchSpotCarrierProfileBeginActionT,
    FetchSpotCarrierProfileErrorActionT,
    FetchSpotCarrierProfileSuccessActionT,
} from './types';
import { SpotCarrierProfileT } from './models';

export const fetchSpotCarrierProfile = (
    carrierId: CarrierIdT,
    options?: FetchOptionsT,
): FetchSpotCarrierProfileActionT => ({
    type: FETCH_SPOT_CARRIER_PROFILE_REQUEST,
    carrierId,
    options,
});

export const fetchSpotCarrierProfileBegin = (carrierId: CarrierIdT): FetchSpotCarrierProfileBeginActionT => ({
    type: FETCH_SPOT_CARRIER_PROFILE_REQUEST_BEGIN,
    carrierId,
});

export const fetchSpotCarrierProfileSuccess = (
    carrierId: CarrierIdT,
    carrierProfile: SpotCarrierProfileT | null,
): FetchSpotCarrierProfileSuccessActionT => ({
    type: FETCH_SPOT_CARRIER_PROFILE_REQUEST_SUCCESS,
    carrierId,
    carrierProfile,
});

export const fetchSpotCarrierProfileError = (
    carrierId: CarrierIdT,
    error: Error,
): FetchSpotCarrierProfileErrorActionT => ({
    type: FETCH_SPOT_CARRIER_PROFILE_REQUEST_ERROR,
    error,
    carrierId,
});
