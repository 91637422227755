import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './BidCell.scss';
import { useTranslation } from 'react-i18next';
import Money from 'common/components/Money/Money';
import { CurrencyEnum } from 'common/constants';
import isNumber from 'lodash/isNumber';

type PropsT = {
    price: number | null | undefined;
};

const cx = classNames.bind(styles);

const BidCell: React.FC<PropsT> = React.memo((props) => {
    const { price } = props;

    const { t } = useTranslation();

    return (
        <div className={cx('wrap')}>
            {isNumber(price) ? (
                <div className={cx('money')}>
                    <Money amount={price} currency={CurrencyEnum.EUR} />
                </div>
            ) : (
                <div>{t('spot-requests.list.no-bid')}</div>
            )}
        </div>
    );
});

export default BidCell;
