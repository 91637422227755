import React from 'react';
import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './CheckboxOptionsList.scss';
import DoneIcon from 'common/icons/DoneIcon';
import { useTranslation } from 'react-i18next';
import Checkbox from 'design-system/components/Checkbox/Checkbox';
import { StyleGuideColorsEnum } from 'common/constants';
import ControlLoaderWithShadow from 'common/components/ControlLoaderWithShadow/ControlLoaderWithShadow';

const cx = classNames.bind(styles);

export type CheckboxOptionsListPropsT<OptionT, ValueT> = {
    selectedValues: Array<ValueT>;
    isLoading?: boolean;
    isDisabled?: boolean;
    hasWarning?: boolean;
    hasError?: boolean;
    options: Array<OptionT>;
    onSelect: (values: Array<ValueT>) => void;
    renderOption: (option: OptionT) => React.ReactElement;
    getOptionTestSelector: (option: OptionT) => string;
    getOptionValue: (option: OptionT) => ValueT;
    className?: string;
    isShowSelectAllTrigger?: boolean;
};

const CheckboxOptionsList = <OptionT, ValueT>(
    props: CheckboxOptionsListPropsT<OptionT, ValueT>,
): React.ReactElement => {
    const {
        selectedValues,
        onSelect,
        options,
        renderOption,
        getOptionValue,
        getOptionTestSelector,
        isLoading,
        isDisabled,
        hasWarning,
        hasError,
        className,
        isShowSelectAllTrigger,
    } = props;

    const { t } = useTranslation();

    const optionByValue = new Map<ValueT, OptionT>();
    options.forEach((option) => {
        const value = getOptionValue(option);

        optionByValue.set(value, option);
    });

    const selectedValuesSet = new Set<ValueT>(selectedValues);

    const handleChange = (value: ValueT): void => {
        if (isDisabled) {
            return;
        }

        const newSelectedValuesSet = new Set(selectedValuesSet);
        const isAlreadySelected = newSelectedValuesSet.has(value);
        if (isAlreadySelected) {
            newSelectedValuesSet.delete(value);
        } else {
            newSelectedValuesSet.add(value);
        }
        onSelect([...newSelectedValuesSet]);
    };

    const handleSelectAll = (): void => {
        const values = options.map(getOptionValue);

        const isSelectAll = values.length !== selectedValues?.length;
        if (isSelectAll) {
            onSelect(values);
        } else {
            onSelect([]);
        }
    };

    return (
        <>
            {isShowSelectAllTrigger && (
                <div className={cx('link')} onClick={handleSelectAll}>
                    <DoneIcon className={cx('link__icon')} size={14} fillColor={StyleGuideColorsEnum.brandDark} />
                    <span className={cx('link__text')}>{t('common:select-all')}</span>
                </div>
            )}
            <div
                className={cs(
                    cx('list', {
                        'list--hasWarning': hasWarning,
                        'list--hasError': hasError,
                    }),
                    className,
                )}
            >
                {options.map((option, index): React.ReactElement => {
                    const value = getOptionValue(option);

                    const isSelected = selectedValuesSet.has(value);

                    return (
                        <div key={index} className={cx('option')}>
                            <Checkbox
                                isFullWidth
                                checked={isSelected}
                                className={cx('option__checkbox')}
                                testSelector={getOptionTestSelector ? getOptionTestSelector(option) : ''}
                                label={
                                    <div
                                        className={cx('option__label')}
                                        onClick={() => {
                                            handleChange(value);
                                        }}
                                    >
                                        {renderOption(option)}
                                    </div>
                                }
                                onChange={() => {
                                    handleChange(value);
                                }}
                            />
                        </div>
                    );
                })}
                {isLoading && (
                    <div className={cx('loader__container')}>
                        <ControlLoaderWithShadow className={cx('loader')} fillColor={StyleGuideColorsEnum.light} />
                    </div>
                )}
            </div>
        </>
    );
};

export default React.memo(CheckboxOptionsList) as typeof CheckboxOptionsList;
