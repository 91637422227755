import { StateT } from 'carrier/store/models';
import { SpotAssetsAssignmentStateT } from './types';

export const selectUserSelection = (state: StateT): SpotAssetsAssignmentStateT['userSelection'] =>
    state.spotAssetsAssigment.userSelection;

export const selectVehicleLinks = (state: StateT): SpotAssetsAssignmentStateT['links'] =>
    state.spotAssetsAssigment.links;

export const selectTruckSuggestQuery = (state: StateT): SpotAssetsAssignmentStateT['truckSuggest']['query'] =>
    state.spotAssetsAssigment.truckSuggest.query;

export const selectTruckSuggestRequestStatus = (
    state: StateT,
): SpotAssetsAssignmentStateT['truckSuggest']['requestStatus'] => state.spotAssetsAssigment.truckSuggest.requestStatus;

export const selectTruckSuggestIds = (state: StateT): SpotAssetsAssignmentStateT['truckSuggest']['ids'] =>
    state.spotAssetsAssigment.truckSuggest.ids;

export const selectTruckAssignmentInfoById = (state: StateT): SpotAssetsAssignmentStateT['truckById'] =>
    state.spotAssetsAssigment.truckById;

export const selectTrailerSuggestQuery = (state: StateT): SpotAssetsAssignmentStateT['trailerSuggest']['query'] =>
    state.spotAssetsAssigment.trailerSuggest.query;

export const selectTrailerSuggestRequestStatus = (
    state: StateT,
): SpotAssetsAssignmentStateT['trailerSuggest']['requestStatus'] =>
    state.spotAssetsAssigment.trailerSuggest.requestStatus;

export const selectTrailerSuggestIds = (state: StateT): SpotAssetsAssignmentStateT['trailerSuggest']['ids'] =>
    state.spotAssetsAssigment.trailerSuggest.ids;

export const selectTrailerAssignmentInfoById = (state: StateT): SpotAssetsAssignmentStateT['trailerById'] =>
    state.spotAssetsAssigment.trailerById;

export const selectAssignmentRequestStatus = (state: StateT): SpotAssetsAssignmentStateT['assigmentStatus'] =>
    state.spotAssetsAssigment.assigmentStatus;
