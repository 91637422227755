import { TransportOrderStatsT } from './models';
import { RequestStatusT } from 'common/utils/request-status';

export const FETCH_ORDERS_STATS_REQUEST = 'common/transport-orders-stats/FETCH_ORDERS_STATS_REQUEST';
export const FETCH_ORDERS_STATS_REQUEST_BEGIN = 'common/transport-orders-stats/FETCH_ORDERS_STATS_REQUEST_BEGIN';
export const FETCH_ORDERS_STATS_REQUEST_ERROR = 'common/transport-orders-stats/FETCH_ORDERS_STATS_REQUEST_ERROR';
export const FETCH_ORDERS_STATS_REQUEST_SUCCESS = 'common/transport-orders-stats/FETCH_ORDERS_STATS_REQUEST_SUCCESS';

export type TransportOrdersStatsStateT = {
    stats: TransportOrderStatsT | null;
    fetchRequest: RequestStatusT;
};

export type FetchTransportOrdersStatsActionT = {
    type: typeof FETCH_ORDERS_STATS_REQUEST;
};

export type FetchTransportOrdersStatsBeginActionT = {
    type: typeof FETCH_ORDERS_STATS_REQUEST_BEGIN;
};

export type FetchTransportOrdersStatsSuccessActionT = {
    type: typeof FETCH_ORDERS_STATS_REQUEST_SUCCESS;
    statistics: TransportOrderStatsT | null;
};

export type FetchTransportOrdersStatsErrorActionT = {
    type: typeof FETCH_ORDERS_STATS_REQUEST_ERROR;
    error: Error;
};

export type TransportOrdersStatsActionT =
    | FetchTransportOrdersStatsActionT
    | FetchTransportOrdersStatsBeginActionT
    | FetchTransportOrdersStatsSuccessActionT
    | FetchTransportOrdersStatsErrorActionT;
