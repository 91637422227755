import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    className?: string;
    fillColor: StyleGuideColorsEnum;
    strokeColor: StyleGuideColorsEnum;
    size: number;
};

const TrailerPickUpIcon: React.FC<PropsT> = (props) => {
    const { size, className, fillColor, strokeColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" className={className}>
            <g fill="none" fillRule="evenodd">
                <path fill={fillColor} fillOpacity="0.3" d="M17 3v8l-1 1v2h-2l-3-2V3h6z" />
                <path
                    fill={strokeColor}
                    d="M11.182 13.462c1.45 0 2.625 1.197 2.625 2.671 0 1.474-1.175 2.67-2.625 2.67-.061 0-.122-.002-.182-.006l-.001-1.505a1.166 1.166 0 001.335-1.158A1.163 1.163 0 0011 14.977v-1.51c.061-.003.122-.006.183-.006zm6.266 1.999v.783h-2.93a2.969 2.969 0 00-.027-.783h2.957zM18 2v10.235h-.844v3.004h-2.7l-.13-.084c-.442-1.314-1.669-2.265-3.109-2.265-.073 0-.146.002-.218.007L11 11h5V4h-5V2h7zm-1.585 10.234h-.41v1.662h.41v-1.662zM2.552 8.194h1.192v3.75c0 .413.338.75.75.75h3c.413 0 .75-.337.75-.75v-3.75h1.193c.667 0 1.005-.81.532-1.282L6.527 3.469a.747.747 0 00-1.058 0L2.027 6.912c-.473.472-.143 1.282.525 1.282zm4.942 6.056c.41 0 .744.331.75.739v1.955c0 .413-.337.75-.75.75h-3a.752.752 0 01-.75-.75V15c0-.412.338-.75.75-.75h3z"
                />
            </g>
        </svg>
    );
};

TrailerPickUpIcon.displayName = 'TrailerPickUpIcon';

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
    fillColor: StyleGuideColorsEnum.brandAccent,
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default TrailerPickUpIcon;

/**
 *  @deprecated create/use ...Props class
 * */
export const getTrailerPickUpIconProps = (
    preset: 'default' | 'selected' | 'assignment-events' | 'assignment-normal' | 'assignment-selected',
): PropsT => {
    switch (preset) {
        case 'selected': {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.brandAccent,
                strokeColor: StyleGuideColorsEnum.brandDark,
            };
        }
        case 'assignment-normal': {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.transparent,
                strokeColor: StyleGuideColorsEnum.white,
            };
        }
        case 'assignment-events': {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.transparent,
                strokeColor: StyleGuideColorsEnum.white,
            };
        }
        case 'assignment-selected': {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.transparent,
                strokeColor: StyleGuideColorsEnum.brandAccent,
            };
        }
        default: {
            return {
                size: DEFAULT_ICON_SIZE,
                fillColor: StyleGuideColorsEnum.white,
                strokeColor: StyleGuideColorsEnum.light,
            };
        }
    }
};
