import { TFunction } from 'i18next';

import { FieldsEnum, FormErrorsT, FormValuesT } from './constants';
import { checkNotEmpty } from 'common/utils/form-validators';

const validate = (t: TFunction, values: FormValuesT): FormErrorsT => {
    const errors: FormErrorsT = {
        ...checkNotEmpty(
            t,
            [FieldsEnum.fromCountryCodes, FieldsEnum.toCountryCodes, FieldsEnum.trailerVariant],
            values,
        ),
    };

    return errors;
};

export default validate;
