import React, { CSSProperties, MouseEvent } from 'react';

import classNames from 'classnames/bind';
import styles from './Timeline.scss';

import times from 'lodash/times';
import SmallDownArrow from 'common/icons/SmallDownArrow';
import { StyleGuideColorsEnum } from 'common/constants';
import { ceilTimeBy, floorTimeBy, formatTimeZone, getCurrentTimeOffsetMin, MS_IN_MIN } from 'common/utils/time';
import { EVENT_PADDING, EVENT_WIDTH, EVENTS_LEVEL_WIDTH, LEFT_PADDING, RIGHT_PADDING } from './constants';

const cx = classNames.bind(styles);

enum DelimiterThemeEnum {
    step = 'step',
    halfStep = 'halfStep',
    now = 'now',
}

export type DelimiterPropsT = {
    label: React.ReactElement | string | null;
    topOffsetPx: number;
    utcOffset: number | null;
    theme: DelimiterThemeEnum;
};

const Delimiter: React.FC<DelimiterPropsT> = (props) => {
    const { label, topOffsetPx, theme, utcOffset } = props;

    return (
        <div
            className={cx('delimiter', {
                [`delimiter--${theme}`]: true,
            })}
            style={{
                top: `${topOffsetPx}px`,
            }}
        >
            {label && (
                <div
                    className={cx('delimiter__text', {
                        [`delimiter__text--${theme}`]: true,
                    })}
                    title={formatTimeZone(utcOffset)}
                >
                    {label}
                </div>
            )}
        </div>
    );
};

export enum TimeWindowThemeEnum {
    past = 'past',
    future = 'future',
    unavailable = 'unavailable',
    inProgress = 'in-progress',
    accent = 'accent',
}

export type TimeWindowT<T> = {
    id: string;
    data: T;
    start: number;
    end: number;
    label: string;
    icon: React.ReactNode;
    theme: TimeWindowThemeEnum;
    isTransparent: boolean;
};

export type TimeWindowPropsT = {
    start: number;
    getHeight: (height: number) => number;
    timeWindow: TimeWindowT<TODO>;
    onClick: (timeWindow: TimeWindowT<TODO>, event: MouseEvent<HTMLDivElement>) => void;
    onBlur: (timeWindow: TimeWindowT<TODO>, event: MouseEvent<HTMLDivElement>) => void;
    onFocus: (timeWindow: TimeWindowT<TODO>, event: MouseEvent<HTMLDivElement>) => void;
    style?: CSSProperties;
};

const SERVICE_HEIGHT = 40; // icon + paddings

const TimeWindow = React.forwardRef<HTMLDivElement, TimeWindowPropsT>((props, forwardedRef) => {
    const { start, timeWindow, getHeight, onClick, onBlur, onFocus, style } = props;

    const [isShowLabel, setShowLabel] = React.useState<boolean>(true);

    const wrapRef = React.useRef<HTMLDivElement>();
    const labelRef = React.createRef<HTMLDivElement>();

    const setRef = (node: HTMLDivElement): void => {
        if (forwardedRef && typeof forwardedRef === 'function') {
            forwardedRef(node);
        }
        wrapRef.current = node;
    };

    React.useEffect(() => {
        if (!labelRef.current || !wrapRef.current) {
            return;
        }

        setShowLabel(labelRef.current.offsetHeight + SERVICE_HEIGHT < wrapRef.current.offsetHeight);
    }, [wrapRef.current, labelRef.current]);

    return (
        <div
            className={cx('time-window', {
                [`time-window--${timeWindow.theme}`]: true,
                'time-window--isTransparent': timeWindow.isTransparent,
            })}
            onMouseLeave={(event) => {
                onBlur(timeWindow, event);
            }}
            onMouseEnter={(event) => {
                onFocus(timeWindow, event);
            }}
            onClick={(event) => onClick(timeWindow, event)}
            style={{
                top: `${getHeight(timeWindow.start - start)}px`,
                height: `${getHeight(timeWindow.end - timeWindow.start)}px`,
                ...style,
            }}
            ref={setRef}
            title={timeWindow.label}
        >
            <div className={cx('time-window__icon')}>{timeWindow.icon}</div>
            {isShowLabel && (
                <div className={cx('time-window__label-wrap')} ref={labelRef}>
                    <div
                        className={cx('time-window__label', {
                            [`time-window__label--${timeWindow.theme}`]: true,
                        })}
                    >
                        {timeWindow.label}
                    </div>
                </div>
            )}
        </div>
    );
});

export type TimelineLevelT = {
    label?: string;
    timeWindows: Array<TimeWindowT<TODO>>;
};

const timeWindowStyle: CSSProperties = {
    left: `${EVENT_PADDING}px`,
    minWidth: `${EVENT_WIDTH}px`,
};

export type PropsT = {
    className?: string;
    start: number | null;
    finish: number | null;
    utcOffset?: number | null;
    minStepCount: number;
    step: number;
    stepHeight: number;
    formatter: (value: number, utcOffset: number | null) => string;
    levels: Array<TimelineLevelT>;
    onTimeWindowClick: (timeWindow: TimeWindowT<TODO>, event: MouseEvent<HTMLDivElement>) => void;
    onTimeWindowBlur: (timeWindow: TimeWindowT<TODO>, event: MouseEvent<HTMLDivElement>) => void;
    onTimeWindowFocus: (timeWindow: TimeWindowT<TODO>, event: MouseEvent<HTMLDivElement>) => void;
    onChangeZoom: () => void;
    nowLabel: string;
};

class Timeline extends React.PureComponent<PropsT> {
    timeWindowRefById: Record<string, HTMLDivElement> = {};

    componentDidUpdate(prevProps: Readonly<PropsT>): void {
        const { step, onChangeZoom } = this.props;

        if (prevProps.step !== step) {
            onChangeZoom();
        }
    }

    setRef =
        (timeWindowId: TimeWindowIdT) =>
        (ref: HTMLDivElement): void => {
            this.timeWindowRefById[timeWindowId] = ref;
        };

    render(): React.ReactNode {
        const {
            finish: timeWindowsFinish,
            start: timeWindowsStart,
            minStepCount,
            step,
            stepHeight,
            formatter,
            levels,
            onTimeWindowClick,
            onTimeWindowBlur,
            onTimeWindowFocus,
            nowLabel,
        } = this.props;

        const now = Date.now();

        const defaultStart = now - 1 * step;
        const defaultRange = minStepCount * step;
        const defaultFinish = defaultStart + defaultRange;

        const inaccurateStart = timeWindowsStart ? timeWindowsStart - step / 2 : defaultStart;
        const inaccurateFinish = timeWindowsFinish ? timeWindowsFinish + step / 2 : defaultFinish;

        const utcOffsetMin = this.props.utcOffset || getCurrentTimeOffsetMin();
        const timezoneOffsetMs = utcOffsetMin * MS_IN_MIN;
        const stepTimezoneOffset = timezoneOffsetMs % step;
        const start = floorTimeBy(inaccurateStart, step) - stepTimezoneOffset;
        const finish = ceilTimeBy(inaccurateFinish, step) - stepTimezoneOffset;

        const range = finish - start;

        const isShowNowDelimiter = start <= now && now <= finish;

        const stepsCount = Math.max(minStepCount, Math.ceil(range / step));

        const getHeight = (height: number): number => {
            return (height * stepHeight) / step;
        };

        const fullTimeLineWidthPx = LEFT_PADDING + RIGHT_PADDING + EVENTS_LEVEL_WIDTH * levels.length;

        return (
            <div
                className={cx('timeline')}
                style={{
                    width: `${fullTimeLineWidthPx}px`,
                }}
            >
                <div
                    className={cx('inner')}
                    style={{
                        height: `${getHeight(range)}px`,
                    }}
                >
                    {times(stepsCount + 1).map((_, index) => {
                        if (!index) {
                            return null;
                        }

                        if (index === stepsCount) {
                            return null;
                        }

                        return (
                            <Delimiter
                                key={index}
                                theme={DelimiterThemeEnum.step}
                                utcOffset={utcOffsetMin}
                                label={formatter(start + step * index, utcOffsetMin)}
                                topOffsetPx={index * stepHeight}
                            />
                        );
                    })}
                    {times(stepsCount).map((_, index) => (
                        <Delimiter
                            key={index}
                            theme={DelimiterThemeEnum.halfStep}
                            utcOffset={null}
                            label={null}
                            topOffsetPx={index * stepHeight + stepHeight / 2}
                        />
                    ))}
                    {isShowNowDelimiter && (
                        <Delimiter
                            key="now"
                            theme={DelimiterThemeEnum.now}
                            utcOffset={utcOffsetMin}
                            label={
                                <div className={cx('now')}>
                                    <span className={cx('now__text')}>{nowLabel}</span>
                                    <SmallDownArrow fillColor={StyleGuideColorsEnum.brandAccent} />
                                </div>
                            }
                            topOffsetPx={getHeight(now - start)}
                        />
                    )}
                    {levels.map((level, levelIndex) => {
                        return (
                            <>
                                <div
                                    key={`level-${levelIndex}`}
                                    className={cx('level')}
                                    style={{
                                        left: `${LEFT_PADDING + EVENTS_LEVEL_WIDTH * levelIndex}px`,
                                        width: `${EVENTS_LEVEL_WIDTH - EVENT_PADDING}px`,
                                    }}
                                >
                                    {level.timeWindows.map((timeWindow, index) => {
                                        return (
                                            <TimeWindow
                                                ref={this.setRef(timeWindow.id)}
                                                key={`${timeWindow.id}-${step}-${index}`}
                                                timeWindow={timeWindow}
                                                start={start}
                                                getHeight={getHeight}
                                                onClick={onTimeWindowClick}
                                                onBlur={onTimeWindowBlur}
                                                onFocus={onTimeWindowFocus}
                                                style={timeWindowStyle}
                                            />
                                        );
                                    })}
                                </div>
                            </>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default Timeline;
