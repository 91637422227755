import {
    AssignActionT,
    AssignBeginActionT,
    AssignErrorActionT,
    ASSIGN,
    ASSIGNMENT_REQUEST_BEGIN,
    ASSIGNMENT_REQUEST_ERROR,
    ASSIGNMENT_REQUEST_SUCCESS,
    AssignSuccessActionT,
    CHANGE_USER_SELECTION,
    ChangeUserSelectionActionT,
    FIND_TRAILER_REQUEST,
    FIND_TRAILER_REQUEST_BEGIN,
    FIND_TRAILER_REQUEST_ERROR,
    FIND_TRAILER_REQUEST_SUCCESS,
    FIND_TRUCK_REQUEST,
    FIND_TRUCK_REQUEST_BEGIN,
    FIND_TRUCK_REQUEST_ERROR,
    FIND_TRUCK_REQUEST_SUCCESS,
    FindTrailerActionT,
    FindTrailerBeginActionT,
    FindTrailerErrorActionT,
    FindTrailerSuccessActionT,
    FindTruckActionT,
    FindTruckBeginActionT,
    FindTruckErrorActionT,
    FindTruckSuccessActionT,
    UnAssignActionT,
    UNASSIGN,
} from './types';

import { FindTrailerQueryT, FindTruckQueryT, UserSelectionT } from './models';
import { AssignmentVehiclesSuggestItemT } from './assignment';
import { CountryCodeT } from 'common/utils/api/models';

export const changeUserSelection = (
    transportOrderId: TransportOrderIdT | null,
    changes: Partial<UserSelectionT>,
): ChangeUserSelectionActionT => ({
    type: CHANGE_USER_SELECTION,
    transportOrderId,
    changes,
});

export const findTrailer = (query: FindTrailerQueryT): FindTrailerActionT => ({
    type: FIND_TRAILER_REQUEST,
    query,
});

export const findTrailerBegin = (query: FindTrailerQueryT): FindTrailerBeginActionT => ({
    type: FIND_TRAILER_REQUEST_BEGIN,
    query,
});

export const findTrailerSuccess = (
    query: FindTrailerQueryT,
    trailersSuggestItems: AssignmentVehiclesSuggestItemT[],
): FindTrailerSuccessActionT => ({
    type: FIND_TRAILER_REQUEST_SUCCESS,
    trailersSuggestItems,
    query,
});

export const findTrailerError = (query: FindTrailerQueryT, error: Error): FindTrailerErrorActionT => ({
    type: FIND_TRAILER_REQUEST_ERROR,
    error,
    query,
});

export const findTruck = (query: FindTruckQueryT): FindTruckActionT => ({
    type: FIND_TRUCK_REQUEST,
    query,
});

export const findTruckBegin = (query: FindTruckQueryT): FindTruckBeginActionT => ({
    type: FIND_TRUCK_REQUEST_BEGIN,
    query,
});

export const findTruckSuccess = (
    query: FindTruckQueryT,
    trucksSuggestItems: AssignmentVehiclesSuggestItemT[],
): FindTruckSuccessActionT => ({
    type: FIND_TRUCK_REQUEST_SUCCESS,
    trucksSuggestItems,
    query,
});

export const findTruckError = (query: FindTruckQueryT, error: Error): FindTruckErrorActionT => ({
    type: FIND_TRUCK_REQUEST_ERROR,
    error,
    query,
});

export const assign = (
    transportOrderId: TransportOrderIdT,
    truckId: TruckIdT,
    trailerId: TrailerIdT,
    prohibitedCountries?: Array<CountryCodeT>,
): AssignActionT => ({
    type: ASSIGN,
    trailerId,
    truckId,
    transportOrderId,
    prohibitedCountries,
});

export const unassign = (transportOrderId: TransportOrderIdT): UnAssignActionT => ({
    type: UNASSIGN,
    transportOrderId,
});

export const assignBegin = (): AssignBeginActionT => ({
    type: ASSIGNMENT_REQUEST_BEGIN,
});

export const assignSuccess = (): AssignSuccessActionT => ({
    type: ASSIGNMENT_REQUEST_SUCCESS,
});

export const assignError = (error: Error): AssignErrorActionT => ({
    type: ASSIGNMENT_REQUEST_ERROR,
    error,
});
