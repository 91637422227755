import { AnyAlert, AnyAlertPayloadT } from 'common/store/alerts/models';

export enum CarrierAlertTypeEnum {
    unknown = 'unknown',
}

export type CarrierAnyAlertPayloadT = AnyAlertPayloadT<
    CarrierAlertTypeEnum.unknown,
    {
        // TODO
    }
>;

export class CarrierAnyAlert extends AnyAlert<CarrierAnyAlertPayloadT> {}
