import {
    FETCH_SPOT_TOUR_DETAILS_REQUEST_BEGIN,
    FETCH_SPOT_TOUR_DETAILS_REQUEST_ERROR,
    FETCH_SPOT_TOUR_DETAILS_REQUEST_SUCCESS,
    FETCH_SPOT_REQUEST_DETAILS_REQUEST_BEGIN,
    FETCH_SPOT_REQUEST_DETAILS_REQUEST_ERROR,
    FETCH_SPOT_REQUEST_DETAILS_REQUEST_SUCCESS,
    SpotRequestDetailsActionT,
    SpotRequestDetailsByIdStateT,
    SpotRequestDetailsStateT,
} from './types';
import requestStatus from 'common/utils/request-status';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

const initialState: SpotRequestDetailsByIdStateT = {};

export const initialSpotRequestDetailsState: SpotRequestDetailsStateT = {
    details: null,
    fetchDetailsRequestStatus: requestStatus.initial,
    spotTourDetails: null,
    fetchSpotTourDetailsRequestStatus: requestStatus.initial,
};

export default (
    state = initialState,
    action: SpotRequestDetailsActionT | DestroySessionActionT,
): SpotRequestDetailsByIdStateT => {
    switch (action.type) {
        case FETCH_SPOT_REQUEST_DETAILS_REQUEST_BEGIN: {
            const { id } = action;

            const spotRequestDetailsState = state[id] || initialSpotRequestDetailsState;

            return {
                ...state,
                [id]: {
                    ...spotRequestDetailsState,
                    fetchDetailsRequestStatus: requestStatus.loading,
                },
            };
        }

        case FETCH_SPOT_REQUEST_DETAILS_REQUEST_ERROR: {
            const { error, id } = action;

            const spotRequestDetailsState = state[id] || initialSpotRequestDetailsState;

            return {
                ...state,
                [id]: {
                    ...spotRequestDetailsState,
                    fetchDetailsRequestStatus: requestStatus.setError(error),
                },
            };
        }

        case FETCH_SPOT_REQUEST_DETAILS_REQUEST_SUCCESS: {
            const { details, id } = action;

            const spotRequestDetailsState = state[id] || initialSpotRequestDetailsState;

            return {
                ...state,
                [id]: {
                    ...spotRequestDetailsState,
                    fetchDetailsRequestStatus: requestStatus.ok,
                    details,
                },
            };
        }

        case FETCH_SPOT_TOUR_DETAILS_REQUEST_BEGIN: {
            const { spotRequestId: id } = action;

            const spotRequestDetailsState = state[id] || initialSpotRequestDetailsState;

            return {
                ...state,
                [id]: {
                    ...spotRequestDetailsState,
                    fetchSpotTourDetailsRequestStatus: requestStatus.loading,
                },
            };
        }

        case FETCH_SPOT_TOUR_DETAILS_REQUEST_ERROR: {
            const { error, spotRequestId: id } = action;

            const spotRequestDetailsState = state[id] || initialSpotRequestDetailsState;

            return {
                ...state,
                [id]: {
                    ...spotRequestDetailsState,
                    fetchSpotTourDetailsRequestStatus: requestStatus.setError(error),
                },
            };
        }

        case FETCH_SPOT_TOUR_DETAILS_REQUEST_SUCCESS: {
            const { details, spotRequestId: id } = action;

            const spotRequestDetailsState = state[id] || initialSpotRequestDetailsState;

            return {
                ...state,
                [id]: {
                    ...spotRequestDetailsState,
                    fetchSpotTourDetailsRequestStatus: requestStatus.ok,
                    spotTourDetails: details,
                },
            };
        }

        case DESTROY_SESSION: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};
