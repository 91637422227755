import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './ChangeBidFormFooterContent.scss';
import ChangeBidForm from '../../ChangeBidForm/ChangeBidForm';
import { SpotRequestDetailsT } from 'carrier/store/spot-request-details/models';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    details: SpotRequestDetailsT | null;
    onCancel: () => void;
};

const ChangeBidFormFooterContent: React.FC<PropsT> = React.memo((props) => {
    const { details, onCancel } = props;
    if (!details) {
        return null;
    }

    return (
        <div className={cx('content')}>
            <ChangeBidForm details={details} onCancel={onCancel} />
        </div>
    );
});

export default ChangeBidFormFooterContent;
