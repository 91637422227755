import React, { memo, useCallback } from 'react';
import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './TimeWindowPicker.scss';
import RangeSelector, { RangeSelectorRangeT } from 'common/components/RangeSelector/RangeSelector';
import { getTimeRelativeStartDay, MS_IN_DAY, MS_IN_HOUR } from 'common/utils/time';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipIconTrigger from 'design-system/components/Tooltip/TooltipIconTrigger/TooltipIconTrigger';
import TimeWindowIcon from 'common/icons/TimeWindowIcon';
import { StyleGuideColorsEnum } from 'common/constants';

const cx = classNames.bind(styles);

type PropsT<NameT> = {
    name: NameT;
    isDisabled?: boolean;
    isDisableLeftRangeControl?: boolean;
    isDisableRightRangeControl?: boolean;
    icon?: React.ReactNode;
    label: React.ReactNode;
    values: TimeWindowT | null;
    className?: string;
    setFieldValue: (name: NameT, value: TimeWindowT) => void;
    range: TimeWindowT;
    step?: number;
    minRangeWidth?: number;
    maxRangeWidth?: number;
    availableValues: TimeWindowT;
    ranges: RangeSelectorRangeT[];
    duration?: React.ReactElement;
    link?: React.ReactElement;
    hasStartStep?: boolean;
    hasEndStep?: boolean;
    testSelector?: string;
    tooltipNode?: string;
};

const SHOW_TOOLTIP_DELAY = 300;

const BaseTimeWindowPicker = <NameT extends string>(props: PropsT<NameT>): React.ReactElement | null => {
    const {
        label,
        values,
        name,
        icon,
        setFieldValue,
        isDisabled,
        isDisableLeftRangeControl,
        isDisableRightRangeControl,
        className,
        range,
        duration,
        minRangeWidth,
        maxRangeWidth,
        step: forceStep,
        link,
        availableValues,
        ranges,
        hasStartStep,
        hasEndStep,
        testSelector,
        tooltipNode,
    } = props;

    const handleSelect = useCallback(
        (selectedValue: TimeWindowT) => {
            if (isDisabled) {
                return;
            }

            setFieldValue(name, selectedValue);
        },
        [isDisabled, name, setFieldValue],
    );

    const step = forceStep || MS_IN_HOUR / 2;

    const shownStepCount = MS_IN_DAY / (step * 24);
    const shownLabelCount = shownStepCount * 3;

    const labelsConfig = React.useMemo(() => {
        return {
            shownStepCount,
            shownLabelCount,
            hasStartStep,
            hasEndStep,
        };
    }, [shownStepCount, shownLabelCount, hasStartStep, hasEndStep]);

    return (
        <div className={cs(cx('time-picker'), className)}>
            <div className={cx('head')}>
                <div className={cx('label')}>
                    {icon || (
                        <TimeWindowIcon
                            className={cx('label__icon')}
                            fillColor={StyleGuideColorsEnum.brandAccent}
                            strokeColor={StyleGuideColorsEnum.brandDark}
                        />
                    )}
                    <div className={cx('label__label')}>{label}</div>
                    {tooltipNode && (
                        <Tooltip
                            className={cx('tooltip')}
                            position={TooltipPositionEnum.topCenter}
                            theme={TooltipThemeEnum.black}
                            tooltipNode={<div className={cs(cx('tooltip__inner'))}>{tooltipNode}</div>}
                            delay={SHOW_TOOLTIP_DELAY}
                        >
                            {(isShow) => <TooltipIconTrigger className={cx('tooltip__icon')} isShow={isShow} />}
                        </Tooltip>
                    )}
                    {duration}
                </div>
                {!!link && <div className={cx('link')}>{link}</div>}
            </div>
            <RangeSelector
                isLocked={isDisabled}
                valuesRange={range}
                availableValues={availableValues || range}
                ranges={ranges || []}
                values={values}
                valueStep={step}
                isLockedLeftRangeControl={isDisableLeftRangeControl}
                isLockedRightRangeControl={isDisableRightRangeControl}
                minValuesRangeWidth={minRangeWidth}
                maxValuesRangeWidth={maxRangeWidth}
                labelsConfig={labelsConfig}
                renderLabel={(value: number) => {
                    const relativeTime = getTimeRelativeStartDay(value);
                    if (value && relativeTime === '00:00') {
                        return '23:59';
                    }

                    return relativeTime;
                }}
                onSelect={handleSelect}
            />
        </div>
    );
};

const TimeWindowPicker = memo(BaseTimeWindowPicker) as typeof BaseTimeWindowPicker;

export default TimeWindowPicker;
