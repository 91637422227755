import { put, select, takeEvery } from 'redux-saga/effects';
import { FETCH_SPOT_CARRIER_PROFILE_REQUEST, FetchSpotCarrierProfileActionT } from './types';
import { fetchSpotCarrierProfileBegin, fetchSpotCarrierProfileError, fetchSpotCarrierProfileSuccess } from './actions';

import { logWarning } from 'common/utils/logger';
import checkNeedRequest from 'common/utils/check-need-request';
import spotCarrierTranziitApi from 'carrier/utils/api/spot-carrier-tranziit/api';
import { selectSpotCarrierProfile } from 'carrier/store/spot-carrier-profile/selectors';

function* fetchCarrierProfileSaga(action: FetchSpotCarrierProfileActionT): WrapGeneratorT<void> {
    const { carrierId, options } = action;

    const { profileRequestStatus }: ReReturnT<typeof selectSpotCarrierProfile> = yield select(
        selectSpotCarrierProfile(carrierId),
    );
    const isNeedRequest = checkNeedRequest(profileRequestStatus, options);
    if (!isNeedRequest) {
        return;
    }

    yield put(fetchSpotCarrierProfileBegin(carrierId));
    const [error, carrierProfile]: ReturnApiT<typeof spotCarrierTranziitApi.fetchCurrentCarrierProfile> =
        yield spotCarrierTranziitApi.fetchCurrentCarrierProfile();

    if (!carrierProfile) {
        logWarning('fetched empty company');
    }

    if (error) {
        yield put(fetchSpotCarrierProfileError(carrierId, error));
    } else {
        yield put(fetchSpotCarrierProfileSuccess(carrierId, carrierProfile));
    }
}

function* spotCarrierProfileSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_SPOT_CARRIER_PROFILE_REQUEST, fetchCarrierProfileSaga);
}

export default spotCarrierProfileSaga;
