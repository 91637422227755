import {
    FETCH_TRANSPORT_ORDER_DETAILS_REQUEST,
    FETCH_TRANSPORT_ORDER_DETAILS_REQUEST_BEGIN,
    FETCH_TRANSPORT_ORDER_DETAILS_REQUEST_ERROR,
    FETCH_TRANSPORT_ORDER_DETAILS_REQUEST_SUCCESS,
    FetchTransportOrderDetailsRequestActionT,
    FetchTransportOrderDetailsRequestBeginActionT,
    FetchTransportOrderDetailsRequestErrorActionT,
    FetchTransportOrderDetailsRequestSuccessActionT,
} from './types';
import { TransportOrderDetailsT } from './models';

export const fetchTransportOrderDetails = (
    companyId: CompanyIdT,
    id: TransportOrderIdT,
    options?: FetchOptionsT,
): FetchTransportOrderDetailsRequestActionT => ({
    type: FETCH_TRANSPORT_ORDER_DETAILS_REQUEST,
    companyId,
    id,
    options,
});

export const fetchTransportOrderDetailsBegin = (
    id: TransportOrderIdT,
): FetchTransportOrderDetailsRequestBeginActionT => ({
    type: FETCH_TRANSPORT_ORDER_DETAILS_REQUEST_BEGIN,
    id,
});

export const fetchTransportOrderDetailsSuccess = (
    id: TransportOrderIdT,
    transportOrderDetails: TransportOrderDetailsT | null,
): FetchTransportOrderDetailsRequestSuccessActionT => ({
    type: FETCH_TRANSPORT_ORDER_DETAILS_REQUEST_SUCCESS,
    transportOrderDetails,
    id,
});

export const fetchTransportOrderDetailsError = (
    id: TransportOrderIdT,
    error: Error,
): FetchTransportOrderDetailsRequestErrorActionT => ({
    type: FETCH_TRANSPORT_ORDER_DETAILS_REQUEST_ERROR,
    error,
    id,
});
