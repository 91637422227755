import * as React from 'react';
import Link, { LinkPropsT, LinkThemeEnum } from 'common/components/Link/Link';

type PropsT =
    | {
          assetId: AssetIdT | null | undefined;
          onOpenAssetDetails: (assetId: AssetIdT) => void;
      }
    | {
          to: LinkPropsT['to'];
      };

const AssetLinkFormatter: React.FC<PropsT> = React.memo((props) => {
    if ('to' in props && props.to) {
        return (
            <Link to={props.to} theme={LinkThemeEnum.boldBrandDark}>
                {props.children}
            </Link>
        );
    }

    if ('assetId' in props && props.assetId) {
        return (
            <Link
                onClick={() => {
                    if (!props.assetId) {
                        return;
                    }

                    props.onOpenAssetDetails(props.assetId);
                }}
                theme={LinkThemeEnum.boldBrandDark}
            >
                {props.children}
            </Link>
        );
    }

    return <span>{props.children}</span>;
});

export default AssetLinkFormatter;
