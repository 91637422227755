import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './CheckboxCell.scss';
import Checkbox from 'design-system/components/Checkbox/Checkbox';

type PropsT<TableRowT> = {
    row: TableRowT;
    selectedRowsSet: Set<TableRowT>;
    onChange: (selectedRows: Set<TableRowT>) => void;
    className?: string;
};

const cx = classNames.bind(styles);

const CheckboxCell = <TableRowT extends {}>(props: PropsT<TableRowT>) => {
    const { row, selectedRowsSet, onChange, className } = props;

    return (
        <span onClick={(event) => event.stopPropagation()} className={className}>
            <Checkbox
                testSelector="row"
                checked={selectedRowsSet?.has(row)}
                onChange={() => {
                    const newSelectedRowsSet = new Set(selectedRowsSet || []);

                    if (selectedRowsSet?.has(row)) {
                        newSelectedRowsSet.delete(row);
                    } else {
                        newSelectedRowsSet.add(row);
                    }

                    onChange(newSelectedRowsSet);
                }}
                label={null}
            />
        </span>
    );
};

export default React.memo(CheckboxCell) as typeof CheckboxCell;
