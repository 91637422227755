import * as React from 'react';

import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import DropdownMultipleInput from 'design-system/components/dropdowns/DropdownMultipleInput/DropdownMultipleInput';
import { useTranslation } from 'react-i18next';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import ArrowsIcon from 'common/icons/ArrowsIcon';

enum TypeEnum {
    spot = 'spot',
    dedicated = 'dedicated',
}

type OptionT = {
    id: TypeEnum;
    label: React.ReactNode;
};

export type PropsT = {
    className?: string;
    isSelectedSpot?: boolean;
    isSelectedDedicated?: boolean;
    onSelect: (value: { isSelectedSpot?: boolean; isSelectedDedicated?: boolean }) => void;
    testSelector?: string;
};

const TransportOrderTypeDropdown = (props: PropsT) => {
    const { className, isSelectedSpot, isSelectedDedicated, onSelect } = props;

    const { t } = useTranslation();

    const selectedOptionValues = React.useMemo((): Array<TypeEnum> => {
        const result: Array<TypeEnum> = [];

        if (isSelectedSpot) {
            result.push(TypeEnum.spot);
        }

        if (isSelectedDedicated) {
            result.push(TypeEnum.dedicated);
        }

        return result;
    }, [isSelectedSpot, isSelectedDedicated]);

    const options = React.useMemo((): Array<OptionT> => {
        return [
            {
                id: TypeEnum.spot,
                label: t('common:transport-orders-page.filters.quick-transport-order-type.spot'),
            },
            {
                id: TypeEnum.dedicated,
                label: t('common:transport-orders-page.filters.quick-transport-order-type.dedicated'),
            },
        ];
    }, []);

    const handleChange = React.useCallback(
        (values: Array<TypeEnum>) => {
            onSelect({
                isSelectedSpot: values.includes(TypeEnum.spot),
                isSelectedDedicated: values.includes(TypeEnum.dedicated),
            });
        },
        [onSelect],
    );

    return (
        <DropdownMultipleInput<OptionT, TypeEnum>
            className={className}
            selectedValues={selectedOptionValues}
            options={options}
            onSelect={handleChange}
            renderOption={(option) => {
                if (!option) {
                    return null;
                }

                return <DropdownControlOptionLabel withoutPaddings label={option.label} />;
            }}
            renderTrigger={(props, selectedOptions) => {
                let label;
                if (selectedOptions?.length === options?.length || !selectedOptions?.length) {
                    label = t('common:transport-orders-page.filters.quick-transport-order-type.all');
                } else if (selectedOptions?.find((option) => option.id === TypeEnum.spot)) {
                    label = t('common:transport-orders-page.filters.quick-transport-order-type.spot');
                } else if (selectedOptions?.find((option) => option.id === TypeEnum.dedicated)) {
                    label = t('common:transport-orders-page.filters.quick-transport-order-type.dedicated');
                }

                return (
                    <TransparentTrigger
                        testSelector="trigger"
                        onClick={props.onClick}
                        isPressed={props.isPressed}
                        label={label}
                        rightIcon={<ArrowsIcon />}
                        reflectionTheme={ReflectionThemeEnum.light}
                    />
                );
            }}
            getOptionValue={(option) => option?.id}
            overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
        />
    );
};

export default TransportOrderTypeDropdown;
