import { put, select, takeEvery } from 'redux-saga/effects';
import { FETCH_SPOT_LOAD_BOARD_REQUESTS_PAGE_REQUEST, FetchSpotLoadBoardRequestsPageActionT } from './types';
import {
    fetchSpotLoadBoardRequestsPageBegin,
    fetchSpotLoadBoardRequestsPageError,
    fetchSpotLoadBoardRequestsPageSuccess,
    resetSpotLoadBoardRequests,
} from './actions';
import { selectSpotLoadBoardRequestsPages, selectSpotLoadBoardRequestsQuery } from './selectors';
import checkNeedRequest from 'common/utils/check-need-request';
import { checkIsSameQuery, checkShouldEmitChangePage } from 'common/utils/pagination/utils';
import spotCarrierTranziitApi from 'carrier/utils/api/spot-carrier-tranziit/api';
import { spotLoadBoardRequestsPaginationChannel } from 'carrier/store/spot-load-board-requests/channels';

function* fetchSpotLoadBoardRequestsSaga(action: FetchSpotLoadBoardRequestsPageActionT): WrapGeneratorT<void> {
    const { pageNumber, query, options } = action;

    const prevQuery: ReturnType<typeof selectSpotLoadBoardRequestsQuery> = yield select(
        selectSpotLoadBoardRequestsQuery,
    );
    const pages: ReturnType<typeof selectSpotLoadBoardRequestsPages> = yield select(selectSpotLoadBoardRequestsPages);
    const isSameQuery = checkIsSameQuery(query, prevQuery);
    const isNeedRequest = checkNeedRequest(pages[pageNumber]?.requestStatus, options);
    if (isSameQuery && !isNeedRequest) {
        return;
    }
    if (!isSameQuery) {
        yield put(resetSpotLoadBoardRequests({ savingPageNumber: pageNumber }));
    }

    yield put(fetchSpotLoadBoardRequestsPageBegin(query, pageNumber));

    const [error, response]: ReturnApiT<typeof spotCarrierTranziitApi.fetchSpotLoadBoardRequests> =
        yield spotCarrierTranziitApi.fetchSpotLoadBoardRequests({
            ...query,
            page: pageNumber,
        });

    if (error) {
        yield put(fetchSpotLoadBoardRequestsPageError(query, pageNumber, error));
        return;
    }

    yield put(fetchSpotLoadBoardRequestsPageSuccess(query, pageNumber, response));

    checkShouldEmitChangePage(pageNumber, response, spotLoadBoardRequestsPaginationChannel);
}

function* spotLoadBoardRequestsSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_SPOT_LOAD_BOARD_REQUESTS_PAGE_REQUEST, fetchSpotLoadBoardRequestsSaga);
}

export default spotLoadBoardRequestsSaga;
