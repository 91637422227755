import * as React from 'react';
import Link, { LinkThemeEnum } from 'common/components/Link/Link';
import isNil from 'lodash/isNil';

type PropsT = {
    driverId: DriverIdT | null | undefined;
    onOpenDriverDetails?: (driverId: DriverIdT) => void;
};

const DriverLinkFormatter: React.FC<PropsT> = React.memo((props) => {
    const { children, driverId, onOpenDriverDetails } = props;

    if (isNil(driverId) || !onOpenDriverDetails) {
        return <span>{children}</span>;
    }

    return (
        <Link onClick={() => onOpenDriverDetails(driverId)} theme={LinkThemeEnum.boldBrandDark}>
            {children}
        </Link>
    );
});

export default DriverLinkFormatter;
