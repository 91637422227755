import { put, select, takeEvery } from 'redux-saga/effects';
import {
    deleteDriverBegin,
    deleteDriverError,
    deleteDriverSuccess,
    fetchDriverDetailsBegin,
    fetchDriverDetailsError,
    fetchDriverDetailsSuccess,
    fetchDriversPageBegin,
    fetchDriversPageError,
    fetchDriversPageSuccess,
    inviteDriverBegin,
    inviteDriverError,
    inviteDriverSuccess,
    resetDrivers,
    updateDriverBegin,
    updateDriverError,
    updateDriverSuccess,
} from './actions';

import {
    ACTIVATE_DRIVER,
    ActivateDriverActionT,
    ARCHIVE_DRIVER,
    ArchiveDriverActionT,
    CANCEL_DRIVER_INVITE,
    CancelDriverInviteActionT,
    DELETE_DRIVER_REQUEST,
    DeleteDriverActionT,
    FETCH_DRIVER_DETAILS_REQUEST,
    FETCH_DRIVERS_PAGE_REQUEST,
    FetchDriverDetailsActionT,
    FetchDriversPageActionT,
    INVITE_DRIVER_REQUEST,
    InviteDriverActionT,
    SET_DRIVER_STATUS,
    SetDriverStatusActionT,
    UPDATE_DRIVER_REQUEST,
    UpdateDriverActionT,
} from './types';
import checkNeedRequest from 'common/utils/check-need-request';
import { selectDriversPages, selectDriversQuery } from './selectors';
import { checkIsDefaultCompanyId } from 'common/utils';
import carrierTranziitApi from 'carrier/utils/api/carrier-tranziit/api';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import { checkIsSameQuery, checkShouldEmitChangePage } from 'common/utils/pagination/utils';
import commonTranziitApi from 'common/utils/api/tranziit/common-tranziit-api';
import {
    driverDetailsRefreshChannel,
    driversPaginationChannel,
    driversRefreshChannel,
} from 'common/store/drivers/channels';

function* fetchDriversSaga(action: FetchDriversPageActionT): WrapGeneratorT<void> {
    const { pageNumber, query, options, companyId } = action;

    const prevQuery: ReReturnT<typeof selectDriversQuery> = yield select(selectDriversQuery(companyId));
    const pages: ReReturnT<typeof selectDriversPages> = yield select(selectDriversPages(companyId));

    const isSameQuery = checkIsSameQuery(query, prevQuery);
    const isNeedRequest = checkNeedRequest(pages[pageNumber]?.requestStatus, options);
    if (isSameQuery && !isNeedRequest) {
        return;
    }

    if (!isSameQuery) {
        yield put(resetDrivers(companyId, { savingPageNumber: pageNumber }));
    }

    yield put(fetchDriversPageBegin(query, pageNumber, companyId));

    let data: ReturnApiT<typeof carrierTranziitApi.fetchDriversPage | typeof brokerTranziitApi.fetchDriversPage>;
    if (checkIsDefaultCompanyId(companyId)) {
        data = yield carrierTranziitApi.fetchDriversPage({
            ...query,
            page: pageNumber,
        });
    } else {
        data = yield brokerTranziitApi.fetchDriversPage(companyId, {
            ...query,
            page: pageNumber,
        });
    }
    const [error, response] = data;
    if (error) {
        yield put(fetchDriversPageError(query, pageNumber, error, companyId));
        return;
    }

    yield put(fetchDriversPageSuccess(query, pageNumber, response, companyId));

    checkShouldEmitChangePage(pageNumber, response, driversPaginationChannel);
}

function* inviteDriverSaga(action: InviteDriverActionT): WrapGeneratorT<void> {
    const { newDriver, companyId } = action;

    yield put(inviteDriverBegin(companyId));

    let response: ReturnApiT<typeof carrierTranziitApi.inviteDriver | typeof brokerTranziitApi.invitePartnerDriver>;
    if (checkIsDefaultCompanyId(companyId)) {
        response = yield carrierTranziitApi.inviteDriver(newDriver);
    } else {
        response = yield brokerTranziitApi.invitePartnerDriver(companyId, newDriver);
    }
    const [error] = response;

    if (error) {
        yield put(inviteDriverError(error, companyId));
    } else {
        yield put(inviteDriverSuccess(companyId));

        driversRefreshChannel.emit({});
    }
}

function* deleteDriverSaga(action: DeleteDriverActionT): WrapGeneratorT<void> {
    const { companyId, driverId } = action;

    yield put(deleteDriverBegin(companyId));

    let response: ReturnApiT<typeof carrierTranziitApi.deleteDriver | typeof brokerTranziitApi.deletePartnerDriver>;
    if (checkIsDefaultCompanyId(companyId)) {
        response = yield carrierTranziitApi.deleteDriver(driverId);
    } else {
        response = yield brokerTranziitApi.deletePartnerDriver(companyId, driverId);
    }
    const [error] = response;

    if (error) {
        yield put(deleteDriverError(error, companyId));
    } else {
        yield put(deleteDriverSuccess(companyId));

        driversRefreshChannel.emit({});
        driverDetailsRefreshChannel.emit({
            driverId,
            partnerId: companyId,
        });
    }
}

function* setDriverStatusSaga(action: SetDriverStatusActionT): WrapGeneratorT<void> {
    const { companyId, driverId, status } = action;

    yield put(updateDriverBegin(companyId));

    let response: ReturnApiT<
        typeof carrierTranziitApi.setDriverStatus | typeof brokerTranziitApi.setPartnerDriverStatus
    >;
    if (checkIsDefaultCompanyId(companyId)) {
        response = yield carrierTranziitApi.setDriverStatus(driverId, status);
    } else {
        response = yield brokerTranziitApi.setPartnerDriverStatus(driverId, status);
    }
    const [error] = response;

    if (error) {
        yield put(updateDriverError(error, companyId));
    } else {
        yield put(updateDriverSuccess(companyId));

        driversRefreshChannel.emit({});
        driverDetailsRefreshChannel.emit({
            driverId,
            partnerId: companyId,
        });
    }
}

function* updateDriverSaga(action: UpdateDriverActionT): WrapGeneratorT<void> {
    const { companyId, driverId, driverChanges } = action;

    yield put(updateDriverBegin(companyId));

    let response: ReturnApiT<typeof carrierTranziitApi.updateDriver | typeof brokerTranziitApi.updatePartnerDriver>;
    if (checkIsDefaultCompanyId(companyId)) {
        response = yield carrierTranziitApi.updateDriver(driverId, driverChanges);
    } else {
        response = yield brokerTranziitApi.updatePartnerDriver(driverId, driverChanges);
    }
    const [error] = response;

    if (error) {
        yield put(updateDriverError(error, companyId));
    } else {
        yield put(updateDriverSuccess(companyId));

        driversRefreshChannel.emit({});
        driverDetailsRefreshChannel.emit({
            driverId,
            partnerId: companyId,
        });
    }
}

function* fetchDriverDetailsSaga(action: FetchDriverDetailsActionT): WrapGeneratorT<void> {
    const { companyId, driverId } = action;

    yield put(fetchDriverDetailsBegin(companyId));

    let response: ReturnApiT<
        typeof carrierTranziitApi.fetchDriverDetails | typeof brokerTranziitApi.fetchPartnerDriverDetails
    >;
    if (checkIsDefaultCompanyId(companyId)) {
        response = yield carrierTranziitApi.fetchDriverDetails(driverId);
    } else {
        response = yield brokerTranziitApi.fetchPartnerDriverDetails(companyId, driverId);
    }
    const [error, details] = response;

    if (error) {
        yield put(fetchDriverDetailsError(error, companyId));
    } else {
        yield put(fetchDriverDetailsSuccess(driverId, details, companyId));
    }
}

function* activateDriverSaga(action: ActivateDriverActionT): WrapGeneratorT<void> {
    const { companyId, driverId } = action;

    yield put(updateDriverBegin(companyId));

    let response: ReturnApiT<typeof carrierTranziitApi.activateDriver | typeof brokerTranziitApi.activatePartnerDriver>;
    if (checkIsDefaultCompanyId(companyId)) {
        response = yield carrierTranziitApi.activateDriver(driverId);
    } else {
        response = yield brokerTranziitApi.activatePartnerDriver(companyId, driverId);
    }
    const [error] = response;

    if (error) {
        yield put(updateDriverError(error, companyId));
    } else {
        yield put(updateDriverSuccess(companyId));

        driversRefreshChannel.emit({});
        driverDetailsRefreshChannel.emit({
            driverId,
            partnerId: companyId,
        });
    }
}

function* cancelDriverInviteSaga(action: CancelDriverInviteActionT): WrapGeneratorT<void> {
    const { driverUserId, companyId } = action;

    yield put(updateDriverBegin(companyId));

    let response: ReturnApiT<typeof commonTranziitApi.cancelUserInvite | typeof brokerTranziitApi.cancelUserInvite>;
    if (checkIsDefaultCompanyId(companyId)) {
        response = yield commonTranziitApi.cancelUserInvite(driverUserId);
    } else {
        response = yield brokerTranziitApi.cancelUserInvite(driverUserId);
    }
    const [error] = response;

    if (error) {
        yield put(updateDriverError(error, companyId));
    } else {
        yield put(updateDriverSuccess(companyId));

        driversRefreshChannel.emit({});
    }
}

function* archiveDriverSaga(action: ArchiveDriverActionT): WrapGeneratorT<void> {
    const { companyId, driverId } = action;

    yield put(updateDriverBegin(companyId));

    let response: ReturnApiT<typeof carrierTranziitApi.archiveDriver | typeof brokerTranziitApi.archivePartnerDriver>;
    if (checkIsDefaultCompanyId(companyId)) {
        response = yield carrierTranziitApi.archiveDriver(driverId);
    } else {
        response = yield brokerTranziitApi.archivePartnerDriver(companyId, driverId);
    }
    const [error] = response;

    if (error) {
        yield put(updateDriverError(error, companyId));
    } else {
        yield put(updateDriverSuccess(companyId));

        driversRefreshChannel.emit({});
        driverDetailsRefreshChannel.emit({
            driverId,
            partnerId: companyId,
        });
    }
}

function* companyDriversSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_DRIVERS_PAGE_REQUEST, fetchDriversSaga);
    yield takeEvery(INVITE_DRIVER_REQUEST, inviteDriverSaga);
    yield takeEvery(DELETE_DRIVER_REQUEST, deleteDriverSaga);
    yield takeEvery(UPDATE_DRIVER_REQUEST, updateDriverSaga);
    yield takeEvery(SET_DRIVER_STATUS, setDriverStatusSaga);
    yield takeEvery(ACTIVATE_DRIVER, activateDriverSaga);
    yield takeEvery(CANCEL_DRIVER_INVITE, cancelDriverInviteSaga);
    yield takeEvery(ARCHIVE_DRIVER, archiveDriverSaga);
    yield takeEvery(FETCH_DRIVER_DETAILS_REQUEST, fetchDriverDetailsSaga);
}

export default companyDriversSaga;
