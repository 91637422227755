import React from 'react';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import Modal from 'common/components/Modal/Modal';
import { RequestStatusT } from 'common/utils/request-status';
import InviteDriverForm from 'common/layouts/DriversPageLayout/InviteDriverForm/InviteDriverForm';
import { ApiInviteDriverRequestT } from 'common/utils/api/models';

export type DriverInviteModalDataT = {
    // nothing
};

type PropsT = {
    data: DriverInviteModalDataT | null;
    onClose: () => void;
    onInviteUser: (newDriver: ApiInviteDriverRequestT) => void;
    requestStatus: RequestStatusT;
};

const DriverInviteModal: React.FC<PropsT> = React.memo((props) => {
    const { data, onClose, requestStatus, onInviteUser } = props;

    if (!data) {
        return null;
    }

    return (
        <Modal>
            <ModalContent testSelector="driver-invite" onClose={onClose}>
                <InviteDriverForm requestStatus={requestStatus} onInviteUser={onInviteUser} />
            </ModalContent>
        </Modal>
    );
});

export default DriverInviteModal;
