import { CompanyTransportOrdersStateT } from './types';
import { SharableStateT } from '../models';
import { initialCompanyTransportOrdersState } from './reducer';

const selectCompanyState = (state: SharableStateT, companyId: CompanyIdT): CompanyTransportOrdersStateT => {
    return state.transportOrders[companyId] || initialCompanyTransportOrdersState;
};

export const selectTransportOrdersPages = (companyId: CompanyIdT) => {
    return (state: SharableStateT): CompanyTransportOrdersStateT['pages'] => {
        return selectCompanyState(state, companyId).pages;
    };
};

export const selectTransportOrdersQuery = (companyId: CompanyIdT) => {
    return (state: SharableStateT): CompanyTransportOrdersStateT['query'] => selectCompanyState(state, companyId).query;
};

export const selectTransportOrdersTotal = (companyId: CompanyIdT) => {
    return (state: SharableStateT): CompanyTransportOrdersStateT['total'] => selectCompanyState(state, companyId).total;
};

export const selectTransportOrdersByIds = (companyId: CompanyIdT) => {
    return (state: SharableStateT): CompanyTransportOrdersStateT['byId'] => selectCompanyState(state, companyId).byId;
};
