import {
    CHANGE_SPOT_CARRIER_LANE_REQUEST_BEGIN,
    CHANGE_SPOT_CARRIER_LANE_REQUEST_ERROR,
    CHANGE_SPOT_CARRIER_LANE_REQUEST_SUCCESS,
    SpotCarrierLaneChangesActionT,
    SpotCarrierLaneChangesStateT,
} from './types';
import requestStatus from 'common/utils/request-status';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';
import { checkIsSameQuery } from 'common/utils/pagination/utils';

const initialState: SpotCarrierLaneChangesStateT = {
    changes: null,
    request: requestStatus.initial,
};

export default (
    state = initialState,
    action: SpotCarrierLaneChangesActionT | DestroySessionActionT,
): SpotCarrierLaneChangesStateT => {
    switch (action.type) {
        case CHANGE_SPOT_CARRIER_LANE_REQUEST_BEGIN: {
            const { changes } = action;

            return {
                ...state,
                changes,
                request: requestStatus.loading,
            };
        }

        case CHANGE_SPOT_CARRIER_LANE_REQUEST_ERROR: {
            const { changes, error } = action;

            if (!checkIsSameQuery(changes, state.changes)) {
                return state;
            }

            return {
                ...state,
                request: requestStatus.setError(error),
            };
        }

        case CHANGE_SPOT_CARRIER_LANE_REQUEST_SUCCESS: {
            const { changes } = action;

            if (!checkIsSameQuery(changes, state.changes)) {
                return state;
            }

            return {
                ...state,
                request: requestStatus.ok,
            };
        }

        case DESTROY_SESSION: {
            return initialState;
        }

        default:
            return state;
    }
};
