import { formatPageQuerySort } from 'common/utils/page-sort';
import { FetchTrucksPageQueryT } from 'common/store/trucks/models';
import { TabEnum } from './constants';
import { QueryFiltersKeysEnum, QueryFiltersT } from './query-models';
import { TrucksSortEnum, TruckStatusEnum } from 'common/utils/api/models';
import { LinkValuesEnum } from 'common/components/radio-groups/AssetLinkRadioGroup/constants';
import { GPSTrackedValuesEnum } from 'common/components/radio-groups/GPSTrackerRadioGroup/constants';
import { DedicationValuesEnum } from 'common/components/radio-groups/AssetDedicationRadioGroup/constants';
import { PageSortT } from 'common/utils/query';

const prepareFetchPageQuery = (
    queryFilters: QueryFiltersT,
    sort: PageSortT<TrucksSortEnum> | null,
    activeTab: TabEnum,
): FetchTrucksPageQueryT => {
    let linked;
    if (queryFilters[QueryFiltersKeysEnum.link]) {
        linked = queryFilters[QueryFiltersKeysEnum.link] === LinkValuesEnum.linked;
    }

    let gpsTracker;
    if (queryFilters[QueryFiltersKeysEnum.GPSTracker]) {
        gpsTracker = queryFilters[QueryFiltersKeysEnum.GPSTracker] === GPSTrackedValuesEnum.traked;
    }

    let dedicated;
    if (queryFilters[QueryFiltersKeysEnum.dedication]) {
        dedicated = queryFilters[QueryFiltersKeysEnum.dedication] === DedicationValuesEnum.dedicated;
    }

    let excludeStatuses: TruckStatusEnum[] = [];
    if (activeTab === TabEnum.archive) {
        excludeStatuses = [
            ...excludeStatuses,
            TruckStatusEnum.free,
            TruckStatusEnum.inTransit,
            TruckStatusEnum.unavailable,
        ];
    } else {
        excludeStatuses = [...excludeStatuses, TruckStatusEnum.archived];
    }

    const includeStatuses: TruckStatusEnum[] = [];
    const status = queryFilters?.[QueryFiltersKeysEnum.status] as TruckStatusEnum;
    if (status) {
        includeStatuses.push(status);
    }

    let emissionStandard;
    if (queryFilters?.[QueryFiltersKeysEnum.emissionClass]) {
        emissionStandard = queryFilters[QueryFiltersKeysEnum.emissionClass];
    }

    return {
        truckModel: queryFilters?.[QueryFiltersKeysEnum.model] || undefined,
        plateNumber: queryFilters?.[QueryFiltersKeysEnum.plateNumber] || undefined,
        emissionStandard,

        includeStatuses,
        excludeStatuses,

        linked,
        gpsTracked: gpsTracker,
        contracted: dedicated,

        addDateFrom: queryFilters?.[QueryFiltersKeysEnum.createdDateFrom] || undefined,
        addDateTo: queryFilters?.[QueryFiltersKeysEnum.createdDateTo] || undefined,

        lastModifiedFrom: queryFilters[QueryFiltersKeysEnum.lastModifyDateFrom] || undefined,
        lastModifiedTo: queryFilters[QueryFiltersKeysEnum.lastModifyDateTo] || undefined,

        sort: formatPageQuerySort(sort),
    };
};

export { prepareFetchPageQuery };
