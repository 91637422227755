import {
    FETCH_ORDERS_STATS_REQUEST_BEGIN,
    FETCH_ORDERS_STATS_REQUEST_ERROR,
    FETCH_ORDERS_STATS_REQUEST_SUCCESS,
    TransportOrdersStatsActionT,
    TransportOrdersStatsStateT,
} from './types';
import requestStatus from 'common/utils/request-status';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

const initialState: TransportOrdersStatsStateT = {
    stats: null,
    fetchRequest: requestStatus.initial,
};

export default (
    state = initialState,
    action: TransportOrdersStatsActionT | DestroySessionActionT,
): TransportOrdersStatsStateT => {
    switch (action.type) {
        case FETCH_ORDERS_STATS_REQUEST_BEGIN: {
            return {
                ...state,
                fetchRequest: requestStatus.loading,
            };
        }

        case FETCH_ORDERS_STATS_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                fetchRequest: requestStatus.setError(error),
            };
        }

        case FETCH_ORDERS_STATS_REQUEST_SUCCESS: {
            const { statistics } = action;
            return {
                ...state,
                stats: statistics,
                fetchRequest: requestStatus.ok,
            };
        }
        case DESTROY_SESSION: {
            return initialState;
        }

        default:
            return state;
    }
};
