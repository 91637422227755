import {
    FETCH_SPOT_CARRIER_PROFILE_REQUEST_BEGIN,
    FETCH_SPOT_CARRIER_PROFILE_REQUEST_ERROR,
    FETCH_SPOT_CARRIER_PROFILE_REQUEST_SUCCESS,
    SpotCarrierProfileActionT,
    SpotCarrierProfileStateT,
} from './types';
import requestStatus from 'common/utils/request-status';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

export const initialCompanyProfileState: SpotCarrierProfileStateT[CarrierIdT] = {
    profile: null,
    profileRequestStatus: requestStatus.initial,
};

const initialState: SpotCarrierProfileStateT = {};

export default (
    state = initialState,
    action: SpotCarrierProfileActionT | DestroySessionActionT,
): SpotCarrierProfileStateT => {
    switch (action.type) {
        case FETCH_SPOT_CARRIER_PROFILE_REQUEST_BEGIN: {
            const { carrierId } = action;

            const companyProfileState = state[carrierId] || initialCompanyProfileState;

            return {
                ...state,
                [carrierId]: {
                    ...companyProfileState,
                    profileRequestStatus: requestStatus.loading,
                },
            };
        }
        case FETCH_SPOT_CARRIER_PROFILE_REQUEST_SUCCESS: {
            const { carrierId, carrierProfile } = action;

            const companyProfileState = state[carrierId] || initialCompanyProfileState;

            return {
                ...state,
                [carrierId]: {
                    ...companyProfileState,
                    profile: carrierProfile,
                    profileRequestStatus: requestStatus.ok,
                },
            };
        }
        case FETCH_SPOT_CARRIER_PROFILE_REQUEST_ERROR: {
            const { carrierId, error } = action;

            const companyProfileState = state[carrierId] || initialCompanyProfileState;

            return {
                ...state,
                [carrierId]: {
                    ...companyProfileState,
                    profile: null,
                    profileRequestStatus: requestStatus.setError(error),
                },
            };
        }
        case DESTROY_SESSION: {
            return initialState;
        }
        default:
            return state;
    }
};
