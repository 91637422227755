export enum SpotRequestsQuickTabEnum {
    loadBoard = 'load-board',
    inbox = 'inbox',
}

export enum SpotRequestsInboxQuickFilterEnum {
    new = 'new',
    open = 'open',
    expired = 'expired',
    accepted = 'accepted',
    rejected = 'rejected',
    won = 'won',
    lost = 'lost',
    missed = 'missed',
    canceled = 'canceled',
}
