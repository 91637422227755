import { put, select, takeEvery } from 'redux-saga/effects';
import { FETCH_SPOT_INBOX_REQUESTS_PAGE_REQUEST, FetchSpotInboxRequestsPageActionT } from './types';
import {
    fetchSpotInboxRequestsPageBegin,
    fetchSpotInboxRequestsPageError,
    fetchSpotInboxRequestsPageSuccess,
    resetSpotInboxRequests,
} from './actions';
import { selectSpotInboxRequestsPages, selectSpotInboxRequestsQuery } from './selectors';
import checkNeedRequest from 'common/utils/check-need-request';
import { checkIsSameQuery, checkShouldEmitChangePage } from 'common/utils/pagination/utils';
import spotCarrierTranziitApi from 'carrier/utils/api/spot-carrier-tranziit/api';
import { spotInboxRequestsPaginationChannel } from 'carrier/store/spot-inbox-requests/channels';

function* fetchSpotInboxRequestsSaga(action: FetchSpotInboxRequestsPageActionT): WrapGeneratorT<void> {
    const { pageNumber, query, options } = action;

    const prevQuery: ReturnType<typeof selectSpotInboxRequestsQuery> = yield select(selectSpotInboxRequestsQuery);
    const pages: ReturnType<typeof selectSpotInboxRequestsPages> = yield select(selectSpotInboxRequestsPages);
    const isSameQuery = checkIsSameQuery(query, prevQuery);
    const isNeedRequest = checkNeedRequest(pages[pageNumber]?.requestStatus, options);
    if (isSameQuery && !isNeedRequest) {
        return;
    }
    if (!isSameQuery) {
        yield put(
            resetSpotInboxRequests({
                savingPageNumber: pageNumber,
            }),
        );
    }

    yield put(fetchSpotInboxRequestsPageBegin(query, pageNumber));

    const [error, response]: ReturnApiT<typeof spotCarrierTranziitApi.fetchSpotInboxRequests> =
        yield spotCarrierTranziitApi.fetchSpotInboxRequests({
            ...query,
            page: pageNumber,
        });

    if (error) {
        yield put(fetchSpotInboxRequestsPageError(query, pageNumber, error));
        return;
    }

    yield put(fetchSpotInboxRequestsPageSuccess(query, pageNumber, response));

    checkShouldEmitChangePage(pageNumber, response, spotInboxRequestsPaginationChannel);
}

function* spotInboxRequestsSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_SPOT_INBOX_REQUESTS_PAGE_REQUEST, fetchSpotInboxRequestsSaga);
}

export default spotInboxRequestsSaga;
