import * as React from 'react';
import styles from './ChangeTrailerVariantControl.scss';
import classNames from 'classnames/bind';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownControl from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import { TableActionOptionT } from 'common/components/Table/TableActions/TableActions';
import { SpotCarrierLanesTrailerVariantEnum } from 'carrier/store/spot-carrier-lane-changes/models';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import TrailerIcon from 'common/icons/TrailerIcon';
import { useTranslation } from 'react-i18next';
import ExpandIcon from 'common/icons/ExpandIcon';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    onChangeTrailerVariant: (trailerVariant: SpotCarrierLanesTrailerVariantEnum) => void;
};

const ALLOWED_TRAILER_VARIANTS: Array<SpotCarrierLanesTrailerVariantEnum> = [
    SpotCarrierLanesTrailerVariantEnum.all,
    SpotCarrierLanesTrailerVariantEnum.box,
    SpotCarrierLanesTrailerVariantEnum.tilt,
];

const TRAILER_VARIANT_LABEL_MAP: Record<SpotCarrierLanesTrailerVariantEnum, string> = {
    [SpotCarrierLanesTrailerVariantEnum.all]: 'spot-carrier-lanes.list.table.trailer-type.all',
    [SpotCarrierLanesTrailerVariantEnum.box]: 'spot-carrier-lanes.list.table.trailer-type.box',
    [SpotCarrierLanesTrailerVariantEnum.tilt]: 'spot-carrier-lanes.list.table.trailer-type.tilt',
};

const ChangeTrailerVariantControl: React.FC<PropsT> = React.memo((props) => {
    const { className, onChangeTrailerVariant } = props;

    const { t } = useTranslation();

    const options: Array<TableActionOptionT> = React.useMemo(() => {
        return ALLOWED_TRAILER_VARIANTS.map((trailerVariant): TableActionOptionT => {
            return {
                label: <DropdownControlOptionLabel label={t(TRAILER_VARIANT_LABEL_MAP[trailerVariant])} />,
                onSelect: () => {
                    onChangeTrailerVariant(trailerVariant);
                },
                testSelector: trailerVariant,
            };
        });
    }, [t, onChangeTrailerVariant]);

    return (
        <DropdownControl
            className={className}
            options={options}
            renderTrigger={(isActive, onClick) => (
                <TransparentTrigger
                    isPressed={isActive}
                    onClick={onClick}
                    leftIcon={
                        <TrailerIcon
                            size={DEFAULT_ICON_SIZE}
                            fillColor={StyleGuideColorsEnum.transparent}
                            strokeColor={StyleGuideColorsEnum.charcoal}
                        />
                    }
                    label={t('spot-carrier-lanes.list.actions.set-trailer-variant')}
                    rightIcon={<ExpandIcon fillColor={StyleGuideColorsEnum.charcoal} />}
                    testSelector="trigger-change-trailer-variant"
                    reflectionTheme={ReflectionThemeEnum.light}
                />
            )}
            overlayClassName={cx('actions-overlay')}
            overlayPosition={DropdownOverlayPositionEnum.bottomLeft}
        />
    );
});

export default ChangeTrailerVariantControl;
