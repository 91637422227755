import { SharableStateT } from 'common/store/models';
import { DriverAssigmentStateT } from './types';
import requestStatus from 'common/utils/request-status';

export const selectDriverAssignmentTourId = (state: SharableStateT): DriverAssigmentStateT['tourId'] =>
    state.driverAssignment.tourId;

export const selectAssignmentRequestStatus = (state: SharableStateT): DriverAssigmentStateT['assigmentStatus'] =>
    state?.driverAssignment?.assigmentStatus || requestStatus.initial;

export const selectReAssignmentRequestStatus = (state: SharableStateT): DriverAssigmentStateT['reAssigmentStatus'] =>
    state.driverAssignment.reAssigmentStatus;
