import moment from 'moment/moment';

export const getDateStringToTimeWindow = (timeWindow: TimeWindowT | null): string | null => {
    const endTimeWindow = timeWindow?.[1];
    if (!endTimeWindow) {
        return null;
    }

    return moment(endTimeWindow).format('YYYY-MM-DD');
};

export const mergeWithRelativeTimeWindow = (
    absoluteTimeWindow: TimeWindowT | null,
    relativeTimeWindow: TimeWindowT | null,
): TimeWindowT | null => {
    if (!absoluteTimeWindow || !relativeTimeWindow) {
        return absoluteTimeWindow;
    }

    const isMidnight = moment(absoluteTimeWindow[1]).format('HH:mm') === '00:00';

    return [
        absoluteTimeWindow[0],
        moment(absoluteTimeWindow[1] - (isMidnight ? 1 : 0))
            .startOf('day')
            .valueOf() + relativeTimeWindow[1],
    ];
};
