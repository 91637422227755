import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './TransportOrderListPageLayout.scss';
import { useDispatch, useSelector } from 'react-redux';
import { QueryFiltersKeysEnum, QueryFiltersT, QueryKeysEnum } from './query-models';
import TransportOrdersTable from './TransportOrdersTable/TransportOrdersTable';
import { useTranslation } from 'react-i18next';
import FiltersTrigger from 'common/components/Table/FiltersTrigger/FiltersTrigger';
import SortDropdown, {
    SortDropdownOptionT,
    SortDropdownOverlayPositionEnum,
} from 'common/components/Table/SortDropdown/SortDropdown';
import omit from 'lodash/omit';
import { TransportOrderQuickFilterEnum, TransportOrderSortEnum } from 'common/utils/api/models';
import Pagination from 'common/components/Table/Pagination/Pagination';
import { TransportOrderT } from 'common/store/transport-orders/models';
import FiltersSidebarContent from './FiltersSidebarContent/FiltersSidebarContent';
import SideBar from 'common/layouts/LeftMenuLayout/SideBar/SideBar';
import {
    createJsonParams,
    createPageNumberParam,
    createSortParams,
    PageSortT,
    SortDirectionEnum,
} from 'common/utils/query';
import ContentMargins from 'common/layouts/LeftMenuLayout/ContentMargins/ContentMargins';
import StickyFooter from 'common/layouts/LeftMenuLayout/StickyFooter/StickyFooter';
import { QuickFiltersOptionT } from 'common/components/Table/QuickFilters/QuickFilters';
import {
    selectTransportOrdersByIds,
    selectTransportOrdersPages,
    selectTransportOrdersTotal,
} from 'common/store/transport-orders/selectors';
import { fetchTransportOrdersPage } from 'common/store/transport-orders/actions';
import { isNonNil } from 'common/utils';
import { fetchTrailersDict } from 'common/store/trailers-dict/actions';
import { fetchTrucksDict } from 'common/store/trucks-dict/actions';
import SearchControl from 'common/components/Table/SearchControl/SearchControl';
import TableError from 'common/components/Table/TableError/TableError';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import { prepareFetchPageQuery } from './prepare-fetch-page-query';
import ListPageLayout from 'common/layouts/ListPage/ListPageLayout/ListPageLayout';
import ListPageHeaderLayout from 'common/layouts/ListPage/ListPageHeaderLayout/ListPageHeaderLayout';
import ListPageHeaderTabsLayout from 'common/layouts/ListPage/ListPageHeaderTabsLayout/ListPageHeaderTabsLayout';
import { fetchGroupedNotificationCounts } from 'common/store/grouped-notification-counts/actions';
import SelectedFilters from 'common/layouts/TransportOrderListPageLayout/SelectedFilters/SelectedFilters';
import VerificationAlert from 'common/components/VerificationAlert/VerificationAlert';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import {
    transportOrdersPaginationChannel,
    transportOrdersRefreshChannel,
} from 'common/store/transport-orders/channels';
import { InferChannelEventT } from 'common/utils/view-event-channel';
import { useQueryParams } from 'use-query-params';
import { checkNeedRenderEmptyState } from 'common/components/Table/utils/check-need-render-empty-state';
import TableMessage, { TableMessageIconsEnum } from 'common/components/Table/TableMessage/TableMessage';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import noop from 'lodash/noop';
import { checkFilters } from 'common/components/Table/utils/check-filters';
import QuickFiltersButtonGroups from 'common/components/Table/QuickFiltersButtonGroups/QuickFiltersButtonGroups';
import TransportOrderTypeDropdown from 'common/layouts/TransportOrderListPageLayout/TransportOrderTypeDropdown/TransportOrderTypeDropdown';
import WarningsFilter from 'common/layouts/TransportOrderListPageLayout/WarningsFilter/WarningsFilter';
import { TransportOrderStatsT } from 'common/store/transport-orders-stats/models';

const cx = classNames.bind(styles);

type PropsT = {
    stat: TransportOrderStatsT | null;
    onOpenTransportOrderDetails: (event: React.MouseEvent, transportOrderId: TransportOrderIdT | null) => void;
    orderQuickFilterOptions: Array<QuickFiltersOptionT<TransportOrderQuickFilterEnum>> | null;
    orderQuickFilterOptionSeparators: number[];
    hasProfileVerificationProblem?: boolean;
    goToProfile?: () => void;
};

const TransportOrderListPageLayout: React.FC<PropsT> = React.memo((props) => {
    const {
        stat,
        onOpenTransportOrderDetails,
        orderQuickFilterOptions,
        orderQuickFilterOptionSeparators,
        hasProfileVerificationProblem,
        goToProfile,
    } = props;

    const { partnerId } = usePartnerContext();

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const total = useSelector(selectTransportOrdersTotal(partnerId));
    const ordersPages = useSelector(selectTransportOrdersPages(partnerId));
    const orderById = useSelector(selectTransportOrdersByIds(partnerId));

    const [isShowFilters, triggerFilters] = React.useState<boolean>(false);
    const showFilters = (): void => {
        triggerFilters(true);
    };
    const hideFilters = (): void => {
        triggerFilters(false);
    };

    const [query, changeQuery] = useQueryParams({
        [QueryKeysEnum.transportOrdersFilters]: createJsonParams<QueryFiltersT>({}),
        [QueryKeysEnum.transportOrdersPage]: createPageNumberParam(),
        [QueryKeysEnum.transportOrdersSort]: createSortParams<TransportOrderSortEnum>({
            direction: SortDirectionEnum.DESC,
            value: TransportOrderSortEnum.receiveDate,
        }),
    });

    const pageNumber = query[QueryKeysEnum.transportOrdersPage];
    const selectedSort = query[QueryKeysEnum.transportOrdersSort];
    const queryFilters = query[QueryKeysEnum.transportOrdersFilters];

    const page = ordersPages[pageNumber];
    const { ids, requestStatus } = page || {};

    const documentVisibilityChangeHandler = React.useCallback(() => {
        const query = prepareFetchPageQuery(queryFilters, selectedSort);
        dispatch(fetchTransportOrdersPage(pageNumber, query, partnerId, { isForceUpdate: false }));

        dispatch(fetchGroupedNotificationCounts({ isForceUpdate: false }));
    }, [pageNumber, selectedSort, queryFilters, partnerId]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    React.useEffect(() => {
        const query = prepareFetchPageQuery(queryFilters, selectedSort);
        dispatch(fetchTransportOrdersPage(pageNumber, query, partnerId));

        dispatch(fetchGroupedNotificationCounts({ isForceUpdate: true }));

        return (): void => {
            // TODO reset
        };
    }, [pageNumber, selectedSort, queryFilters, partnerId]);

    const refreshPageHandler = React.useCallback(() => {
        const query = prepareFetchPageQuery(queryFilters, selectedSort);
        dispatch(fetchTransportOrdersPage(pageNumber, query, partnerId, { isForceUpdate: true }));

        dispatch(fetchGroupedNotificationCounts({ isForceUpdate: true }));
    }, [queryFilters, selectedSort, pageNumber, partnerId]);
    useChannelSubscribe(transportOrdersRefreshChannel, refreshPageHandler);

    React.useEffect(() => {
        dispatch(fetchTrailersDict());
        dispatch(fetchTrucksDict());
    }, []);

    const orders = React.useMemo(() => {
        return (ids || []).map((id): TransportOrderT => orderById[id as string]).filter(isNonNil);
    }, [ids, orderById]);

    const goToPage = React.useCallback(
        (pageNumber: PageNumberT) => {
            changeQuery({
                [QueryKeysEnum.transportOrdersPage]: pageNumber,
            });
        },
        [query],
    );

    const setPageHandler = React.useCallback(
        ({ pageNumber }: InferChannelEventT<typeof transportOrdersPaginationChannel>) => {
            goToPage(pageNumber);
        },
        [goToPage],
    );

    useChannelSubscribe(transportOrdersPaginationChannel, setPageHandler);

    const sortOptions: Array<SortDropdownOptionT<TransportOrderSortEnum>> = React.useMemo(
        () => [
            {
                label: t('common:transport-orders-page.table.sorts.triggers.receivedDate'),
                value: TransportOrderSortEnum.receiveDate,
            },
            {
                label: t('common:transport-orders-page.table.sorts.triggers.pickupDate'),
                value: TransportOrderSortEnum.pickupDate,
            },
            {
                label: t('common:transport-orders-page.table.sorts.triggers.dropOffDate'),
                value: TransportOrderSortEnum.dropOffDate,
            },
            {
                label: t('common:transport-orders-page.table.sorts.triggers.price'),
                value: TransportOrderSortEnum.price,
            },
        ],
        [t],
    );

    const handleSelectSort = (sort: PageSortT<TransportOrderSortEnum>) => {
        changeQuery({
            [QueryKeysEnum.transportOrdersPage]: 0,
            [QueryKeysEnum.transportOrdersSort]: sort,
        });
    };

    const handleSetStatusFilter = (quickFilterId: TransportOrderQuickFilterEnum | undefined) => {
        const prevQueryFilters = query[QueryKeysEnum.transportOrdersFilters] || {};

        const queryFilters: QueryFiltersT = {
            ...prevQueryFilters,
            [QueryFiltersKeysEnum.quickFilter]: quickFilterId,
        };

        changeQuery({
            [QueryKeysEnum.transportOrdersPage]: 0,
            [QueryKeysEnum.transportOrdersFilters]: queryFilters,
        });
    };

    const handleSetQueryFilters = (selectedQueryFilters: QueryFiltersT) => {
        const queryFilters = {
            ...selectedQueryFilters,
        };

        changeQuery({
            [QueryKeysEnum.transportOrdersPage]: 0,
            [QueryKeysEnum.transportOrdersFilters]: queryFilters,
        });

        hideFilters();
    };

    const updateQueryFilters = (queryFiltersChanges: QueryFiltersT) => {
        const prevQueryFilters = query[QueryKeysEnum.transportOrdersFilters] || {};

        const queryFilters = {
            ...prevQueryFilters,
            ...queryFiltersChanges,
        };

        changeQuery({
            [QueryKeysEnum.transportOrdersPage]: 0,
            [QueryKeysEnum.transportOrdersFilters]: queryFilters,
        });
    };

    const handleSearch = (searchText: string) => {
        updateQueryFilters({
            [QueryFiltersKeysEnum.searchText]: searchText || undefined,
        });
    };

    const renderTableMessage = () => {
        if (!checkNeedRenderEmptyState(orders, requestStatus)) {
            return null;
        }

        if (requestStatus?.error) {
            return <TableError />;
        }

        if (hasProfileVerificationProblem) {
            return (
                <TableMessage
                    testSelector="verification-problem"
                    iconType={TableMessageIconsEnum.warning}
                    title={t('common:transport-orders-page.table.messages.verification-problem.title')}
                    description={t('common:transport-orders-page.table.messages.verification-problem.description')}
                    isShowAction
                    actionTitle={t('common:transport-orders-page.table.messages.verification-problem.action')}
                    actionTheme={ButtonThemeEnum.secondary}
                    onActionClick={goToProfile || noop}
                />
            );
        }

        const { hasPrimaryQueryFilters, hasSecondaryQueryFilters } = checkFilters(
            [queryFilters[QueryFiltersKeysEnum.quickFilter]],
            [omit(queryFilters, [QueryFiltersKeysEnum.quickFilter])],
        );
        if (requestStatus?.ok && hasSecondaryQueryFilters) {
            return (
                <TableMessage
                    iconType={TableMessageIconsEnum.notFound}
                    title={t('common:transport-orders-page.table.messages.not-found.title')}
                    description={t('common:transport-orders-page.table.messages.not-found.description')}
                    isShowAction
                    actionTitle={t('common:transport-orders-page.table.messages.not-found.action')}
                    actionTheme={ButtonThemeEnum.secondary}
                    onActionClick={() => handleSetQueryFilters({})}
                    testSelector="empty-state-has-secondary-filters"
                />
            );
        }

        if (requestStatus?.ok && hasPrimaryQueryFilters) {
            return (
                <TableMessage
                    iconType={TableMessageIconsEnum.empty}
                    title={t('common:transport-orders-page.table.messages.not-found-only-primary-filters.title')}
                    description=""
                    testSelector="empty-state-has-primary-filters"
                    isShowAction={false}
                />
            );
        }

        return (
            <TableMessage
                iconType={TableMessageIconsEnum.empty}
                title={t('common:transport-orders-page.table.messages.empty.title')}
                testSelector="empty"
                isShowAction={false}
            />
        );
    };

    return (
        <>
            <ContentMargins>
                <ListPageLayout>
                    {orderQuickFilterOptions && (
                        <ListPageHeaderTabsLayout>
                            <QuickFiltersButtonGroups
                                options={orderQuickFilterOptions}
                                revertSeparatorIndexes={orderQuickFilterOptionSeparators}
                                selectedId={queryFilters[QueryFiltersKeysEnum.quickFilter]}
                                onSelect={handleSetStatusFilter}
                                moreStatusesLabel={t('common:transport-orders-page.status-filter.more-statuses')}
                            />
                        </ListPageHeaderTabsLayout>
                    )}
                    <ListPageHeaderLayout
                        withTopPadding
                        leftToolsNode={
                            <>
                                <FiltersTrigger
                                    className={cx('filters-trigger')}
                                    title={t('common:transport-orders-page.filters.trigger')}
                                    isActive={isShowFilters}
                                    onClick={showFilters}
                                />
                                <TransportOrderTypeDropdown
                                    isSelectedDedicated={!!queryFilters[QueryFiltersKeysEnum.dedicated]}
                                    isSelectedSpot={!!queryFilters[QueryFiltersKeysEnum.spot]}
                                    onSelect={({ isSelectedSpot, isSelectedDedicated }) => {
                                        updateQueryFilters({
                                            [QueryFiltersKeysEnum.spot]: isSelectedSpot || undefined,
                                            [QueryFiltersKeysEnum.dedicated]: isSelectedDedicated || undefined,
                                        });
                                    }}
                                />
                                <WarningsFilter
                                    className={cx('warnings-filter')}
                                    stat={stat}
                                    queryFilters={queryFilters}
                                    onQueryFiltersChange={updateQueryFilters}
                                />
                                <SortDropdown
                                    className={cx('sort-trigger')}
                                    overlayPosition={SortDropdownOverlayPositionEnum.left}
                                    selectedValue={selectedSort}
                                    options={sortOptions}
                                    onSelect={handleSelectSort}
                                />
                                <SearchControl
                                    placeholder={t('common:transport-orders-page.filters.placeholders.search')}
                                    searchText={queryFilters[QueryFiltersKeysEnum.searchText]}
                                    onChangeSearchText={handleSearch}
                                />
                            </>
                        }
                        filterTagsNode={
                            <SelectedFilters
                                isCompact
                                queryFilters={queryFilters}
                                setQueryFilters={handleSetQueryFilters}
                            />
                        }
                    />
                    {hasProfileVerificationProblem && (
                        <VerificationAlert
                            className={cx('verification-alert')}
                            actionText={t('inline-alerts.verification-company-problem.action')}
                            message={t('inline-alerts.verification-company-problem.message')}
                            onClickAction={goToProfile}
                        />
                    )}
                    {renderTableMessage()}
                    <TransportOrdersTable
                        orders={orders}
                        goToTransportOrderDetails={onOpenTransportOrderDetails}
                        isLoading={requestStatus?.loading}
                    />
                </ListPageLayout>
            </ContentMargins>
            <StickyFooter>
                <Pagination current={pageNumber} count={total?.pageCount} goToPage={goToPage} />
            </StickyFooter>
            <SideBar isShow={isShowFilters} onClose={hideFilters}>
                {(onClose) => (
                    <FiltersSidebarContent
                        onClose={onClose}
                        queryFilters={queryFilters}
                        setQueryFilters={handleSetQueryFilters}
                    />
                )}
            </SideBar>
        </>
    );
});

export default TransportOrderListPageLayout;
