import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './StatusCell.scss';
import { CompanyDriverT } from 'common/store/drivers/models';
import DriverStatusPill from 'common/components/status-pill/DriverStatusPill/DriverStatusPill';

type PropsT = {
    driver: CompanyDriverT;
    className?: string;
};

const cx = classNames.bind(styles);

const StatusCell: React.FC<PropsT> = React.memo((props) => {
    const { driver, className } = props;

    return (
        <div className={cx('status')}>
            <DriverStatusPill isSymmetrical className={className} status={driver?.status} />
        </div>
    );
});

export default StatusCell;
