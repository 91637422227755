import { put, select, takeEvery } from 'redux-saga/effects';
import { FETCH_TRANSPORT_ORDER_DETAILS_REQUEST, FetchTransportOrderDetailsRequestActionT } from './types';
import {
    fetchTransportOrderDetailsBegin,
    fetchTransportOrderDetailsError,
    fetchTransportOrderDetailsSuccess,
} from './actions';
import { selectTransportOrderDetailsRequestStatus } from './selectors';
import checkNeedRequest from 'common/utils/check-need-request';
import { CompanyTypeEnum } from 'common/constants';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import carrierTranziitApi from 'carrier/utils/api/carrier-tranziit/api';

const getFetchTransportOrderDetailsSaga = (companyType: CompanyTypeEnum) => {
    return function* fetchTransportOrderDetailsSaga(
        action: FetchTransportOrderDetailsRequestActionT,
    ): WrapGeneratorT<void> {
        const { id, companyId, options } = action;

        const requestStatus: ReReturnT<typeof selectTransportOrderDetailsRequestStatus> = yield select(
            selectTransportOrderDetailsRequestStatus(id),
        );
        if (!checkNeedRequest(requestStatus, options)) {
            return;
        }

        yield put(fetchTransportOrderDetailsBegin(id));

        let result: ReturnApiT<
            typeof brokerTranziitApi.getTransportOrderDetails | typeof carrierTranziitApi.getTransportOrderDetails
        >;
        if (companyType === CompanyTypeEnum.broker) {
            result = yield brokerTranziitApi.getTransportOrderDetails(companyId, id);
        } else {
            result = yield carrierTranziitApi.getTransportOrderDetails(id);
        }

        const [error, transportOrderDetails] = result;

        if (error) {
            yield put(fetchTransportOrderDetailsError(id, error));
        } else {
            yield put(fetchTransportOrderDetailsSuccess(id, transportOrderDetails));
        }
    };
};

function* transportOrderDetailsSaga(companyType: CompanyTypeEnum): WrapGeneratorT<void> {
    yield takeEvery(FETCH_TRANSPORT_ORDER_DETAILS_REQUEST, getFetchTransportOrderDetailsSaga(companyType));
}

export default transportOrderDetailsSaga;
