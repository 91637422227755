import * as React from 'react';
import { CountryCodeT } from 'common/store/countries-dict/models';
import CheckboxOptionsList, {
    CheckboxOptionsListPropsT,
} from 'common/components/CheckboxOptionsList/CheckboxOptionsList';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectCountriesAllCodes,
    selectCountriesByCode,
    selectCountriesDictRequest,
} from 'common/store/countries-dict/selectors';
import CountyFlagLabel from 'common/components/CountyFlagLabel/CountyFlagLabel';
import { fetchCountriesDict } from 'common/store/countries-dict/actions';

type BasePropsT = CheckboxOptionsListPropsT<CountryCodeT, CountryCodeT>;

type PropsT = {
    className?: BasePropsT['className'];
    isShowSelectAllTrigger?: boolean;
    selectedValues: BasePropsT['selectedValues'];
    onSelect: BasePropsT['onSelect'];
    hasWarning?: BasePropsT['hasWarning'];
    hasError?: BasePropsT['hasError'];
    testSelectorPrefix: string;
};

const CountriesCheckboxOptionsList: React.FC<PropsT> = React.memo((props) => {
    const { testSelectorPrefix } = props;

    const dispatch = useDispatch();

    const allCountryCodes = useSelector(selectCountriesAllCodes);
    const countryByCode = useSelector(selectCountriesByCode);
    const request = useSelector(selectCountriesDictRequest);

    const getFromOptionTestSelector = React.useCallback(
        (countryCode: CountryCodeT) => {
            return `${testSelectorPrefix}_${countryCode}`;
        },
        [testSelectorPrefix],
    );

    const renderCountryOption = React.useCallback(
        (countryCode: CountryCodeT) => {
            return <CountyFlagLabel isThin country={countryByCode[countryCode]} />;
        },
        [countryByCode],
    );

    React.useEffect(() => {
        dispatch(fetchCountriesDict());
    }, []);

    const getCountryOptionValue = React.useCallback((option: CountryCodeT) => option, []);

    return (
        <CheckboxOptionsList<CountryCodeT, CountryCodeT>
            className={props.className}
            isShowSelectAllTrigger={props.isShowSelectAllTrigger}
            selectedValues={props.selectedValues}
            hasWarning={props.hasWarning}
            hasError={props.hasError}
            isLoading={request.loading}
            options={allCountryCodes}
            onSelect={props.onSelect}
            getOptionTestSelector={getFromOptionTestSelector}
            renderOption={renderCountryOption}
            getOptionValue={getCountryOptionValue}
        />
    );
});

export default CountriesCheckboxOptionsList;
