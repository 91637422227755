import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './RightSidebarLayout.scss';

const cx = classNames.bind(styles);

type PropsT = {};

const RightSidebarLayout: React.FC<PropsT> = React.memo((props) => {
    const { children } = props;

    return <div className={cx('right-sidebar')}>{children}</div>;
});

export default RightSidebarLayout;
