import * as React from 'react';
import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './TimelineSidebar.scss';
import { StyleGuideColorsEnum } from 'common/constants';
import CloseIcon from 'common/icons/CloseIcon';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import LeftArrowIcon from 'common/icons/LeftArrowIcon';
import { MS_IN_DAY, MS_IN_HOUR } from 'common/utils/time';
import moment from 'moment';
import Timeline, { TimelineLevelT, TimeWindowT } from 'common/components/Timeline/Timeline';
import LoaderOverlay from 'common/layouts/LoaderOverlay/LoaderOverlay';
import { VehicleScheduleEventT } from 'common/store/vehicle-schedules/models';
import scrollIntoView from 'scroll-into-view';
import { useTranslation } from 'react-i18next';
import TimelineAutoSizer from '../TimelineAutoSizer/TimelineAutoSizer';
import { LEFT_PADDING } from 'common/components/Timeline/constants';
import isNumber from 'lodash/isNumber';

const cx = classNames.bind(styles);

type ZoomCallbackT = () => void;

type TimelineSidebarLevelT = TimelineLevelT & {
    icon?: React.ReactNode;
};

type PropsT = {
    className?: string;
    onClose: () => void;
    utcOffset?: number | null;
    selectedTimeWindowId: string | null;
    onTimeWindowClick?: (eventId: string) => void;
    onTimeWindowBlur?: (eventId: string) => void;
    onTimeWindowFocus?: (eventId: string) => void;
    isLoading?: boolean;
    levels: Array<TimelineSidebarLevelT>;
};

const STEPS_ON_SCREEN = 14;

const TimelineSidebar: React.FC<PropsT> = React.memo((props) => {
    const {
        className,
        onClose,
        selectedTimeWindowId,
        onTimeWindowClick,
        onTimeWindowBlur,
        onTimeWindowFocus,
        isLoading,
        levels,
        utcOffset,
    } = props;

    const { t } = useTranslation();

    const ref = React.useRef<Timeline>(null);

    const [isZoomed, setIsZoomed] = React.useState<boolean>(false);

    const zoom: ZoomCallbackT = () => {
        setIsZoomed(true);
    };

    const formatter = (value: number, utcOffset: number | null): string => {
        const momentObj = moment(value);

        if (isNumber(utcOffset)) {
            momentObj.utcOffset(utcOffset);
        }

        if (isZoomed) {
            const formattedDate = momentObj.format('HH:mm');
            const isMidnight = formattedDate === '00:00';
            return isMidnight ? momentObj.format('HH:mm, D MMM') : formattedDate;
        }

        return momentObj.format('ddd, D MMM');
    };

    const allTimeWindows = React.useMemo(() => {
        return levels.reduce<Array<TimeWindowT<TODO>>>((result, level) => {
            return [...result, ...level.timeWindows];
        }, []);
    }, [levels]);

    const start = React.useMemo(() => {
        if (!allTimeWindows.length) {
            return null;
        }

        return Math.min(...allTimeWindows.map((timeWindow) => timeWindow.start));
    }, [allTimeWindows]);

    const finish = React.useMemo(() => {
        if (!allTimeWindows.length) {
            return null;
        }

        return Math.max(...allTimeWindows.map((timeWindow) => timeWindow.end));
    }, [allTimeWindows]);

    const handleChangeZoom = () => {
        if (!ref.current || !selectedTimeWindowId) {
            return;
        }

        const node = ref.current.timeWindowRefById[selectedTimeWindowId];
        if (!node) {
            return;
        }

        scrollIntoView(node, {
            time: 0,
            align: {
                top: 0,
            },
        });
    };

    const handleTimeWindowClick = (timeWindow: TimeWindowT<VehicleScheduleEventT>) => {
        if (onTimeWindowClick) {
            onTimeWindowClick(timeWindow.id);
        }

        zoom();
    };

    const handleTimeWindowBlur = (timeWindow: TimeWindowT<VehicleScheduleEventT>) => {
        if (onTimeWindowBlur) {
            onTimeWindowBlur(timeWindow.id);
        }
    };

    const handleTimeWindowFocus = (timeWindow: TimeWindowT<VehicleScheduleEventT>) => {
        if (onTimeWindowFocus) {
            onTimeWindowFocus(timeWindow.id);
        }
    };

    const hasIcons = levels?.some((level) => !!level.icon);

    return (
        <div className={cs(cx('wrap'), className)}>
            {isLoading && <LoaderOverlay />}
            <div className={cx('header')}>
                {isZoomed ? (
                    <TransparentTrigger
                        onClick={() => {
                            setIsZoomed(false);
                        }}
                        leftIcon={<LeftArrowIcon fillColor={StyleGuideColorsEnum.white} />}
                        reflectionTheme={ReflectionThemeEnum.light}
                    />
                ) : (
                    <div />
                )}
                <TransparentTrigger
                    onClick={onClose}
                    leftIcon={<CloseIcon fillColor={StyleGuideColorsEnum.white} />}
                    reflectionTheme={ReflectionThemeEnum.light}
                />
            </div>
            {hasIcons && (
                <div
                    className={cx('icons')}
                    style={{
                        paddingLeft: `${LEFT_PADDING}px`,
                    }}
                >
                    <div className={cx('icons__inner')}>
                        {levels.map((level, levelIndex) => {
                            return (
                                <div key={levelIndex} className={cx('icons__item')}>
                                    {level.icon}
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
            <div className={cx('inner')}>
                <TimelineAutoSizer>
                    {(height) => (
                        <Timeline
                            ref={ref}
                            start={start}
                            finish={finish}
                            step={isZoomed ? MS_IN_HOUR : MS_IN_DAY}
                            minStepCount={STEPS_ON_SCREEN}
                            stepHeight={height / STEPS_ON_SCREEN}
                            utcOffset={utcOffset}
                            formatter={formatter}
                            levels={levels}
                            onTimeWindowClick={handleTimeWindowClick}
                            onTimeWindowBlur={handleTimeWindowBlur}
                            onTimeWindowFocus={handleTimeWindowFocus}
                            nowLabel={t('common:now')}
                            onChangeZoom={handleChangeZoom}
                        />
                    )}
                </TimelineAutoSizer>
            </div>
        </div>
    );
});

export default TimelineSidebar;
