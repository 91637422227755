import * as React from 'react';

import classNames from 'classnames/bind';

import styles from './WarningIndicatorCell.scss';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import WarningIcon from 'common/icons/WarningIcon';
import { StyleGuideColorsEnum } from 'common/constants';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';

type PropsT = {
    isShow: boolean;
    tooltipText?: React.ReactNode | null;
};

const cx = classNames.bind(styles);

const WarningIndicatorCell: React.FC<PropsT> = React.memo((props) => {
    const { tooltipText, isShow } = props;
    if (!tooltipText || !isShow) {
        return null;
    }

    return (
        <Tooltip
            className={cx('tooltip')}
            position={TooltipPositionEnum.topCenter}
            theme={TooltipThemeEnum.black}
            tooltipNode={
                <TooltipContent theme={TooltipContentThemeEnum.black} isNoWrap>
                    {tooltipText}
                </TooltipContent>
            }
        >
            {() => <WarningIcon fillColor={StyleGuideColorsEnum.tomatoRed} />}
        </Tooltip>
    );
});

export default WarningIndicatorCell;
