import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './TrailersPage.scss';
import { Route, Switch } from 'react-router-dom';
import { TrailersRoutesEnum } from 'carrier/constants';
import TrailerDetailsPage from 'common/layouts/TrailerDetailsPage/TrailerDetailsPage';
import history, { goBackIfHasRePath } from 'common/utils/history';
import TrailersListPage from './TrailersListPage/TrailersListPage';
import SideBars from 'carrier/layouts/SideBars/SideBars';

const cx = classNames.bind(styles);

type PropsT = {};

type QueryT = {};

const TrailersPage: React.FC<PropsT> = React.memo((props) => {
    return (
        <>
            <Switch>
                <Route path={TrailersRoutesEnum.list}>
                    <TrailersListPage />
                </Route>
                <Route path={TrailersRoutesEnum.details}>
                    <TrailerDetailsPage
                        onClose={() => {
                            goBackIfHasRePath(
                                () => {
                                    history.push({
                                        pathname: TrailersRoutesEnum.list,
                                    });
                                },
                                {
                                    shouldCloseSidebars: true,
                                },
                            );
                        }}
                        onCloseMap={() => {
                            goBackIfHasRePath(() => {
                                history.push({
                                    pathname: TrailersRoutesEnum.list,
                                });
                            });
                        }}
                    />
                </Route>
            </Switch>
            <SideBars />
        </>
    );
});

export default TrailersPage;
