import {
    FETCH_SPOT_INBOX_REQUESTS_PAGE_REQUEST,
    FETCH_SPOT_INBOX_REQUESTS_PAGE_REQUEST_BEGIN,
    FETCH_SPOT_INBOX_REQUESTS_PAGE_REQUEST_ERROR,
    FETCH_SPOT_INBOX_REQUESTS_PAGE_REQUEST_SUCCESS,
    FetchSpotInboxRequestsPageActionT,
    FetchSpotInboxRequestsPageBeginActionT,
    FetchSpotInboxRequestsPageErrorActionT,
    FetchSpotInboxRequestsPageSuccessActionT,
    RESET_SPOT_INBOX_REQUESTS,
    ResetSpotInboxRequestsActionT,
} from './types';
import { SpotInboxRequestT, FetchSpotInboxRequestsPageQueryT } from './models';
import { PageResponseT } from 'common/utils/pagination/models';

export const fetchSpotInboxRequestsPage = (
    pageNumber: PageNumberT,
    query: FetchSpotInboxRequestsPageQueryT,
    options?: FetchOptionsT,
): FetchSpotInboxRequestsPageActionT => ({
    type: FETCH_SPOT_INBOX_REQUESTS_PAGE_REQUEST,
    query,
    pageNumber,
    options,
});

export const fetchSpotInboxRequestsPageBegin = (
    query: FetchSpotInboxRequestsPageQueryT,
    pageNumber: PageNumberT,
): FetchSpotInboxRequestsPageBeginActionT => ({
    type: FETCH_SPOT_INBOX_REQUESTS_PAGE_REQUEST_BEGIN,
    query,
    pageNumber,
});

export const fetchSpotInboxRequestsPageSuccess = (
    query: FetchSpotInboxRequestsPageQueryT,
    pageNumber: PageNumberT,
    pageResponse: PageResponseT<SpotInboxRequestT> | null,
): FetchSpotInboxRequestsPageSuccessActionT => ({
    type: FETCH_SPOT_INBOX_REQUESTS_PAGE_REQUEST_SUCCESS,
    query,
    pageResponse,
    pageNumber,
});

export const fetchSpotInboxRequestsPageError = (
    query: FetchSpotInboxRequestsPageQueryT,
    pageNumber: PageNumberT,
    error: Error,
): FetchSpotInboxRequestsPageErrorActionT => ({
    type: FETCH_SPOT_INBOX_REQUESTS_PAGE_REQUEST_ERROR,
    query,
    error,
    pageNumber,
});

export const resetSpotInboxRequests = (mutationListOptions: MutationListOptionsT): ResetSpotInboxRequestsActionT => ({
    type: RESET_SPOT_INBOX_REQUESTS,
    mutationListOptions,
});
