import requestStatus from 'common/utils/request-status';
import {
    FETCH_DRIVERS_REQUEST_BEGIN,
    FETCH_DRIVERS_REQUEST_ERROR,
    FETCH_DRIVERS_REQUEST_SUCCESS,
    DriverSearchActionT,
    DriverSearchStateT,
} from './types';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';
import keyBy from 'lodash/keyBy';
import { checkIsSameQuery } from 'common/utils/pagination/utils';

const initialState: DriverSearchStateT = {
    request: requestStatus.initial,
    query: null,
    allIds: [],
    byId: {},
};

export default (state = initialState, action: DriverSearchActionT | DestroySessionActionT): DriverSearchStateT => {
    switch (action.type) {
        case FETCH_DRIVERS_REQUEST_BEGIN: {
            const { query } = action;
            return {
                ...state,
                query,
                request: requestStatus.loading,
            };
        }
        case FETCH_DRIVERS_REQUEST_SUCCESS: {
            const { drivers, query } = action;

            if (!checkIsSameQuery(state.query, query)) {
                return state;
            }

            const allIds = drivers.map((driver) => driver.id);
            const byId = keyBy(drivers, 'id');

            return {
                ...state,
                allIds,
                byId,
                request: requestStatus.ok,
            };
        }
        case FETCH_DRIVERS_REQUEST_ERROR: {
            const { error, query } = action;

            if (!checkIsSameQuery(state.query, query)) {
                return state;
            }

            return {
                ...state,
                request: requestStatus.setError(error),
            };
        }
        case DESTROY_SESSION: {
            return initialState;
        }
        default:
            return state;
    }
};
