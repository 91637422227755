import React from 'react';
import { AssetServiceLocationContextT } from '../contexts/asset-service-location-context';

const useAssetServiceLocationContextValue = (): AssetServiceLocationContextT => {
    const [location, setLocation] = React.useState<LocationT | null>(null);

    return {
        location,
        setLocation,
    };
};

export { useAssetServiceLocationContextValue };
