import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './SpotRequestStatusCard.scss';
import { Trans } from 'react-i18next';

const cx = classNames.bind(styles);

export type SpotRequestStatusCardPropsT = {
    type: 'new' | 'won' | 'cancelled' | 'expired';
    fromLocation: string | null | undefined;
    toLocation: string | null | undefined;
    spotRequestNumber: string | null | undefined;
};

const labelMap: Record<SpotRequestStatusCardPropsT['type'], string> = {
    new: 'common:notifications.spot-request-status.new',
    won: 'common:notifications.spot-request-status.won',
    cancelled: 'common:notifications.spot-request-status.cancelled',
    expired: 'common:notifications.spot-request-status.expired',
};

const Accent: React.FC<{ number: React.ReactNode }> = ({ number }) => {
    return <span className={cx('accent')}>{number}</span>;
};

const SpotRequestStatusCard: React.FC<SpotRequestStatusCardPropsT> = React.memo((props) => {
    const { type, fromLocation, toLocation, spotRequestNumber } = props;

    return (
        <div className={cx('wrap')}>
            <Trans
                i18nKey={labelMap[type]}
                components={{
                    fromLocation: <Accent number={fromLocation} />,
                    toLocation: <Accent number={toLocation} />,
                    spotRequestNumber: <Accent number={spotRequestNumber} />,
                }}
            />
        </div>
    );
});

export default SpotRequestStatusCard;
