import React, { MouseEvent } from 'react';
import classNames from 'classnames/bind';

import styles from './DropdownMultipleInput.scss';
import Checkbox from 'design-system/components/Checkbox/Checkbox';
import { isNonNil } from 'common/utils';
import { DropdownOverlayPositionEnum } from '../constants';
import DropdownBaseLayout from '../base/DropdownBaseLayout/DropdownBaseLayout';
import DropdownBaseButtonTrigger from '../base/DropdownBaseButtonTrigger/DropdownBaseButtonTrigger';
import cs from 'classnames';
import {
    checkIsSpecialOption,
    SpecialOptionEnum,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';

const cx = classNames.bind(styles);

type RenderTriggerPropsT = {
    isEmpty: boolean;
    isPressed: boolean;
    isDisabled: boolean;
    hasChanges: boolean;
    hasWarning: boolean;
    hasError: boolean;
    onClick: () => void;
    onReset?: (event: MouseEvent<HTMLElement>) => void;
    isShowClearControl: boolean;
};

export type PropsT<OptionT, ValueT> = {
    selectedValues: Array<ValueT>;
    placeholder?: string;
    isDisabled?: boolean;
    isInline?: boolean;
    hasWarning?: boolean;
    hasError?: boolean;
    options: Array<OptionT | SpecialOptionEnum>;
    onSelect: (values: Array<ValueT>) => void;
    onBlur?: () => void;
    onFocus?: () => void;
    overlayPosition: DropdownOverlayPositionEnum;
    renderTrigger?: (
        props: RenderTriggerPropsT,
        options: Array<OptionT>,
        placeholder: string | undefined,
    ) => React.ReactNode;
    renderTriggerContent?: (options: Array<OptionT>, placeholder: string | undefined) => React.ReactNode;
    renderOption: (option: OptionT) => React.ReactNode;
    getOptionValue: (option: OptionT) => ValueT;
    className?: string;
    hasChanges?: boolean;
    triggerClassName?: string;
    overlayClassName?: string;
    testSelector?: string;
    onReset?: (event: MouseEvent<HTMLElement>) => void;
};

const DropdownMultipleInput = <OptionT, ValueT>(props: PropsT<OptionT, ValueT>): React.ReactElement => {
    const {
        selectedValues,
        placeholder,
        onSelect,
        options,
        renderTrigger,
        renderTriggerContent,
        renderOption,
        getOptionValue,
        isDisabled,
        isInline,
        hasWarning,
        hasError,
        overlayPosition,
        className,
        hasChanges,
        triggerClassName,
        overlayClassName,
        onFocus,
        onBlur,
        testSelector,
        onReset,
    } = props;

    const [isOpen, toggleOpen] = React.useState(false);

    const handleOpen = (): void => {
        if (isDisabled) {
            return;
        }
        if (onFocus) {
            onFocus();
        }
        toggleOpen(true);
    };

    const handleClose = (): void => {
        if (onBlur) {
            onBlur();
        }
        toggleOpen(false);
    };

    const handleOuterEvent = (): void => {
        if (onBlur) {
            onBlur();
        }
        handleClose();
    };

    const optionByValue = new Map<ValueT, OptionT>();
    options.forEach((option) => {
        if (checkIsSpecialOption(option)) {
            return;
        }

        const value = getOptionValue(option);

        optionByValue.set(value, option);
    });

    const selectedValuesSet = new Set<ValueT>(selectedValues);
    const selectedOptions: Array<OptionT> = selectedValues.map((value) => optionByValue.get(value)).filter(isNonNil);

    const handleChange = (value: ValueT): void => {
        if (isDisabled) {
            return;
        }

        const newSelectedValuesSet = new Set(selectedValuesSet);
        const isAlreadySelected = newSelectedValuesSet.has(value);
        if (isAlreadySelected) {
            newSelectedValuesSet.delete(value);
        } else {
            newSelectedValuesSet.add(value);
        }
        onSelect([...newSelectedValuesSet]);
    };

    return (
        <DropdownBaseLayout
            isInline={isInline}
            isOpen={isOpen}
            className={className}
            onClose={handleOuterEvent}
            triggerNode={
                renderTrigger ? (
                    renderTrigger(
                        {
                            isEmpty: !selectedOptions.length,
                            isPressed: isOpen,
                            isDisabled: !!isDisabled,
                            hasChanges: !!hasChanges,
                            hasWarning: !!hasWarning,
                            hasError: !!hasError,
                            onClick: handleOpen,
                            isShowClearControl: !!onReset,
                            onReset,
                        },
                        selectedOptions,
                        placeholder,
                    )
                ) : (
                    <DropdownBaseButtonTrigger
                        isEmpty={!selectedOptions.length}
                        isPressed={isOpen}
                        isDisabled={isDisabled}
                        hasChanges={hasChanges}
                        hasWarning={hasWarning}
                        hasError={hasError}
                        className={triggerClassName}
                        testSelector={testSelector}
                        onClick={handleOpen}
                        isShowClearControl={!!onReset}
                        onReset={onReset}
                    >
                        {renderTriggerContent?.(selectedOptions, placeholder)}
                    </DropdownBaseButtonTrigger>
                )
            }
            overlayPosition={overlayPosition}
            overlayClassName={cs(cx('overlay'), overlayClassName)}
            overlayNode={
                <>
                    {options.map((option, index): React.ReactElement => {
                        if (checkIsSpecialOption(option)) {
                            return <div key={index} className={cx('separator')} />;
                        }

                        const value = getOptionValue(option);

                        const isSelected = selectedValuesSet.has(value);

                        return (
                            <div key={index} className={cx('option')}>
                                <Checkbox
                                    isFullWidth
                                    checked={isSelected}
                                    className={cx('option__checkbox')}
                                    testSelector={`${testSelector}_option_${index}`}
                                    label={
                                        <div
                                            className={cx('option__label')}
                                            onClick={() => {
                                                handleChange(value);
                                            }}
                                        >
                                            {renderOption(option)}
                                        </div>
                                    }
                                    onChange={() => {
                                        handleChange(value);
                                    }}
                                />
                            </div>
                        );
                    })}
                </>
            }
        />
    );
};

export default DropdownMultipleInput;
