import { FetchSpotInboxRequestsPageQueryT, SpotInboxRequestT } from './models';

import { PageResponseT, PaginationStateT } from 'common/utils/pagination/models';

export const FETCH_SPOT_INBOX_REQUESTS_PAGE_REQUEST = 'carrier/sport-requests/FETCH_SPOT_INBOX_REQUESTS_PAGE_REQUEST';
export const FETCH_SPOT_INBOX_REQUESTS_PAGE_REQUEST_BEGIN =
    'carrier/sport-requests/FETCH_SPOT_INBOX_REQUESTS_PAGE_REQUEST_BEGIN';
export const FETCH_SPOT_INBOX_REQUESTS_PAGE_REQUEST_ERROR =
    'carrier/sport-requests/FETCH_SPOT_INBOX_REQUESTS_PAGE_REQUEST_ERROR';
export const FETCH_SPOT_INBOX_REQUESTS_PAGE_REQUEST_SUCCESS =
    'carrier/sport-requests/FETCH_SPOT_INBOX_REQUESTS_PAGE_REQUEST_SUCCESS';

export const RESET_SPOT_INBOX_REQUESTS = 'carrier/sport-requests/RESET_SPOT_INBOX_REQUESTS';

export type SpotInboxRequestsStateT = PaginationStateT<SpotInboxRequestT, FetchSpotInboxRequestsPageQueryT>;

export type FetchSpotInboxRequestsPageActionT = {
    type: typeof FETCH_SPOT_INBOX_REQUESTS_PAGE_REQUEST;
    pageNumber: PageNumberT;
    query: FetchSpotInboxRequestsPageQueryT;
    options?: FetchOptionsT;
};

export type FetchSpotInboxRequestsPageBeginActionT = {
    type: typeof FETCH_SPOT_INBOX_REQUESTS_PAGE_REQUEST_BEGIN;
    pageNumber: PageNumberT;
    query: FetchSpotInboxRequestsPageQueryT;
};

export type FetchSpotInboxRequestsPageSuccessActionT = {
    type: typeof FETCH_SPOT_INBOX_REQUESTS_PAGE_REQUEST_SUCCESS;
    pageNumber: PageNumberT;
    pageResponse: PageResponseT<SpotInboxRequestT> | null;
    query: FetchSpotInboxRequestsPageQueryT;
};

export type FetchSpotInboxRequestsPageErrorActionT = {
    type: typeof FETCH_SPOT_INBOX_REQUESTS_PAGE_REQUEST_ERROR;
    query: FetchSpotInboxRequestsPageQueryT;
    pageNumber: PageNumberT;
    error: Error;
};

export type ResetSpotInboxRequestsActionT = {
    type: typeof RESET_SPOT_INBOX_REQUESTS;
    mutationListOptions: MutationListOptionsT;
};

export type SpotInboxRequestsActionT =
    | FetchSpotInboxRequestsPageActionT
    | FetchSpotInboxRequestsPageBeginActionT
    | FetchSpotInboxRequestsPageSuccessActionT
    | FetchSpotInboxRequestsPageErrorActionT
    | ResetSpotInboxRequestsActionT;
