import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    size: number;
    strokeColor: StyleGuideColorsEnum;
};

const TeamDrive2Icon: React.FC<PropsT> = (props) => {
    const { size, strokeColor } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20">
            <path
                fill={strokeColor}
                fillRule="evenodd"
                d="M11 2.062A8 8 0 1 1 2.062 11h2.022c.423 2.5 2.405 4.47 4.908 4.899-.03-2.046-.908-3.843-2.233-4.9L11 11l-.001-3.001L15.633 8a6.08 6.08 0 0 0-.984-1.758l-.184-.22A5.955 5.955 0 0 0 11 4.084V2.062zM16 10c-2.732 0-4.945 2.633-4.992 5.899C13.836 15.414 16 12.963 16 10zM6.75 2.75v2.5h2.5v1.5h-2.5v2.5h-1.5v-2.5h-2.5v-1.5h2.5v-2.5h1.5z"
            />
        </svg>
    );
};

TeamDrive2Icon.displayName = 'TeamDrive2Icon';

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default TeamDrive2Icon;
