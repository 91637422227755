import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import Modal from 'common/components/Modal/Modal';
import BigTrashIcon from 'common/icons/BigTrashIcon';
import { UpdateEntityStatusActionT } from 'common/store/state-machine/types';
import { RequestStatusT } from 'common/utils/request-status';

export type CancelLastShipmentConfirmationDataT = UpdateEntityStatusActionT['payload'];

type PropsT = {
    requestStatus: RequestStatusT | null;
    data: CancelLastShipmentConfirmationDataT | null;
    onCancel: () => void;
    onClose: () => void;
    onConfirm: (data: CancelLastShipmentConfirmationDataT) => void;
};

const TEST_SELECTOR = 'cancel-last-shipment-confirmation';

const CancelLastShipmentConfirmation: React.FC<PropsT> = React.memo((props) => {
    const { requestStatus, data, onCancel, onClose, onConfirm } = props;

    const { t } = useTranslation();

    if (!data) {
        return null;
    }

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                <ModalDialog
                    width={DEFAULT_MODAL_DIALOG_WIDTH}
                    testSelector={TEST_SELECTOR}
                    actions={[
                        {
                            children: t('common:error-modal.actions.keep'),
                            theme: ButtonThemeEnum.secondary,
                            testSelector: `${TEST_SELECTOR}_cancel`,
                            isDisabled: requestStatus?.loading,
                            onClick: onCancel,
                        },
                        {
                            children: t('common:error-modal.actions.cancel'),
                            theme: ButtonThemeEnum.danger,
                            testSelector: `${TEST_SELECTOR}_confirm`,
                            isLoading: requestStatus?.loading,
                            isDisabled: requestStatus?.loading,
                            onClick: () => {
                                onConfirm(data);
                            },
                        },
                    ]}
                    icon={<BigTrashIcon />}
                    title={t('common:dialogs.last-shipment-cancel-confirmation.title')}
                    message={t('common:dialogs.last-shipment-cancel-confirmation.description')}
                />
            </ModalContent>
        </Modal>
    );
});

export default CancelLastShipmentConfirmation;
