import React from 'react';

import { PropsT as InputPropsT } from 'common/components/Input/Input';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import SuggestInput from 'design-system/components/dropdowns/SuggestInput/SuggestInput';
import { useDispatch, useSelector } from 'react-redux';

import {
    selectSearchDriverById,
    selectSearchDriverIds,
    selectSearchDriverRequest,
} from 'common/store/driver-search/selectors';
import { fetchDrivers } from 'common/store/driver-search/actions';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import Avatar from 'common/components/Avatar/Avatar';

type ValueT = DriverIdT | null;

type OptionT = {
    id: ValueT;
    text: string;
    label: React.ReactNode;
};

export type PropsT = {
    partnerId: PartnerIdT;
    initialValue: ValueT;
    initialLabel: string;
    onSelect: (value: ValueT, name: string) => void;
    value: ValueT;
    overlayPosition: DropdownOverlayPositionEnum;
    hasError: InputPropsT['hasError'];
    hasWarning: InputPropsT['hasWarning'];
    placeholder: InputPropsT['placeholder'];
    onBlur: InputPropsT['onBlur'];
    onFocus: InputPropsT['onFocus'];
    onKeyUp?: InputPropsT['onKeyUp'];
    isDisabled?: InputPropsT['isDisabled'];
    isFocused?: InputPropsT['isFocused'];
    hasClearControl?: InputPropsT['hasClearControl'];
    hasChanges?: boolean;
};

const DriverSuggest: React.FC<PropsT> = (props) => {
    const {
        partnerId,
        initialValue,
        initialLabel,
        value,
        onSelect,
        hasWarning,
        hasError,
        overlayPosition,
        isFocused,
        isDisabled,
        placeholder,
        onBlur,
        onFocus,
        onKeyUp,
        hasChanges,
        hasClearControl,
    } = props;

    const [options, setOptions] = React.useState<OptionT[]>([]);

    const dispatch = useDispatch();

    const ids = useSelector(selectSearchDriverIds);
    const byId = useSelector(selectSearchDriverById);
    const requestStatus = useSelector(selectSearchDriverRequest);

    React.useEffect(() => {
        const options = ids
            .filter((id) => byId[id])
            .map((id): OptionT => {
                const driver = byId[id];

                return {
                    id: driver.id || null,
                    text: driver.name || '',
                    label: (
                        <DropdownControlOptionLabel
                            icon={<Avatar hash={driver.name || ''} />}
                            label={driver.name || ''}
                        />
                    ),
                };
            });

        setOptions(options);
    }, [ids, byId]);

    React.useEffect(() => {
        if (!initialValue) {
            return;
        }

        const initialOption: OptionT = {
            id: initialValue,
            text: initialLabel,
            label: <span>{initialLabel}</span>,
        };

        setOptions([initialOption]);
    }, []);

    const handleChangeQuery = React.useCallback(
        (query: string): void => {
            if (!query) {
                return;
            }

            dispatch(
                fetchDrivers({
                    name: query,
                    partnerId,
                }),
            );
        },
        [partnerId],
    );

    const hasValue = !!value;

    const handleSelect = (value: ValueT) => {
        const partner = byId[value as string];

        onSelect(value || null, partner?.name || '');
    };

    const selectedDriver = byId[value || ''] || null;

    return (
        <SuggestInput<OptionT, ValueT>
            selectedValue={value}
            initialInputValue={initialLabel}
            hasChanges={hasChanges}
            options={options}
            onSelect={handleSelect}
            renderOption={(option) => option.label}
            formatOption={(option) => option.text}
            getOptionValue={(option) => option.id}
            onChangeQuery={handleChangeQuery}
            hasWarning={hasWarning}
            hasError={hasError}
            renderLeftIcon={() => <Avatar hash={selectedDriver?.name || null} />}
            overlayPosition={overlayPosition}
            placeholder={placeholder}
            isLoading={requestStatus.loading}
            isFocused={isFocused}
            isDisabled={isDisabled}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyUp={onKeyUp}
            hasClearControl={hasClearControl}
        />
    );
};

export default DriverSuggest;
