import { put, select, takeEvery } from 'redux-saga/effects';

import { AssignActionT, ASSIGNMENT_REQUEST, RE_ASSIGNMENT_REQUEST, ReAssignActionT } from './types';
import { assignBegin, assignError, assignSuccess, reAssignBegin, reAssignError, reAssignSuccess } from './actions';
import { selectAssignmentRequestStatus, selectReAssignmentRequestStatus } from './selectors';
import { logWarning } from 'common/utils/logger';
import carrierTranziitApi from 'carrier/utils/api/carrier-tranziit/api';
import { addAlert } from 'common/store/alerts/actions';
import { CommonAlertTypeEnum, CommonAnyAlert } from 'common/components/toasts/AlertToastsManager/models';
import { transportOrdersRefreshChannel } from 'common/store/transport-orders/channels';
import { stateMachineRefreshChannel } from 'common/store/state-machine/channels';
import { transportOrderRefreshChannel } from 'common/store/transport-order-details/channels';

function* assignSaga(action: AssignActionT): WrapGeneratorT<void> {
    const { driverIds, transportationOrderId } = action;

    const requestStatus: ReturnType<typeof selectAssignmentRequestStatus> = yield select(selectAssignmentRequestStatus);
    if (requestStatus.loading) {
        return;
    }

    yield put(assignBegin());

    const [error]: ReturnApiT<typeof carrierTranziitApi.assignDrivers> = yield carrierTranziitApi.assignDrivers(
        transportationOrderId,
        {
            mainDriverId: driverIds[0],
            teamDriverId: driverIds[1] || undefined,
        },
    );

    if (error) {
        yield put(assignError(error));
    } else {
        yield put(assignSuccess());

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.driverAssigned,
                    data: {
                        driversCount: driverIds?.length || 0,
                    },
                }),
            ),
        );
    }

    transportOrderRefreshChannel.emit({});
    transportOrdersRefreshChannel.emit({});
    stateMachineRefreshChannel.emit({});
}

function* reAssignSaga(action: ReAssignActionT): WrapGeneratorT<void> {
    const { transportationOrderId, driverIds } = action;
    if (!transportationOrderId || !driverIds.length) {
        logWarning('empty tourId or driverId');
        return;
    }

    const requestStatus: ReturnType<typeof selectReAssignmentRequestStatus> = yield select(
        selectReAssignmentRequestStatus,
    );
    if (requestStatus.loading) {
        return;
    }

    yield put(reAssignBegin());

    const [error]: ReturnApiT<typeof carrierTranziitApi.reAssignDrivers> = yield carrierTranziitApi.reAssignDrivers(
        transportationOrderId,
    );
    if (error) {
        yield put(reAssignError(error));
    } else {
        yield put(reAssignSuccess());

        yield put(
            addAlert(
                new CommonAnyAlert({
                    type: CommonAlertTypeEnum.driverUnassigned,
                    data: {
                        driversCount: driverIds?.length || 0,
                    },
                }),
            ),
        );
    }

    transportOrderRefreshChannel.emit({});
    transportOrdersRefreshChannel.emit({});
    stateMachineRefreshChannel.emit({});
}

function* dispatchAssigmentSaga(): WrapGeneratorT<void> {
    yield takeEvery(ASSIGNMENT_REQUEST, assignSaga);
    yield takeEvery(RE_ASSIGNMENT_REQUEST, reAssignSaga);
}

export default dispatchAssigmentSaga;
