import * as React from 'react';
import { isNonNil } from 'common/utils';

type PropsT = {
    driver?: {
        name?: string;
        surname?: string;
    };
};

const SimpleDriverFormatter: React.FC<PropsT> = React.memo((props) => {
    const { driver } = props;

    if (!driver) {
        return null;
    }

    const fullName = [driver.surname, driver.name].filter(isNonNil).join(' ');

    return <span>{fullName}</span>;
});

export default SimpleDriverFormatter;
