import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './EmissionClassCell.scss';

import { TruckT } from 'common/store/trucks/models';
import { EMISSION_STANDART_MAP } from 'common/components/EmissionStandartLabel/EmissionStandartLabel';

type PropsT = {
    truck: TruckT;
};

const cx = classNames.bind(styles);

const EmissionClassCell: React.FC<PropsT> = React.memo((props) => {
    const { truck } = props;

    const emission = truck.emissionClass ? EMISSION_STANDART_MAP[truck.emissionClass] : null;

    return <div className={cx('emission-class')}>{emission}</div>;
});

export default EmissionClassCell;
