import { put, select, takeEvery } from 'redux-saga/effects';
import { FETCH_ORDERS_PAGE_REQUEST, FetchTransportOrdersPageActionT } from './types';
import {
    fetchTransportOrdersPageBegin,
    fetchTransportOrdersPageError,
    fetchTransportOrdersPageSuccess,
    resetTransportOrders,
} from './actions';
import { selectTransportOrdersPages, selectTransportOrdersQuery } from './selectors';
import checkNeedRequest from 'common/utils/check-need-request';
import carrierTranziitApi from 'carrier/utils/api/carrier-tranziit/api';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import { checkIsSameQuery, checkShouldEmitChangePage } from 'common/utils/pagination/utils';
import { checkIsDefaultCompanyId } from 'common/utils';
import { transportOrdersPaginationChannel } from 'common/store/transport-orders/channels';

function* fetchTransportOrdersSaga(action: FetchTransportOrdersPageActionT): WrapGeneratorT<void> {
    const { pageNumber, query: rawQuery, companyId } = action;

    const query: typeof rawQuery = {
        ...rawQuery,
    };

    const prevQuery: ReReturnT<typeof selectTransportOrdersQuery> = yield select(selectTransportOrdersQuery(companyId));
    const pages: ReReturnT<typeof selectTransportOrdersPages> = yield select(selectTransportOrdersPages(companyId));
    const isSameQuery = checkIsSameQuery(query, prevQuery);
    const isNeedRequest = checkNeedRequest(pages[pageNumber]?.requestStatus);
    if (isSameQuery && !isNeedRequest) {
        return;
    }
    if (!isSameQuery) {
        yield put(resetTransportOrders(companyId, { savingPageNumber: pageNumber }));
    }

    yield put(fetchTransportOrdersPageBegin(query, pageNumber, companyId));

    let result: ReturnApiT<
        typeof carrierTranziitApi.fetchTransportOrdersPage | typeof brokerTranziitApi.fetchTransportOrdersPage
    >;
    if (checkIsDefaultCompanyId(companyId)) {
        result = yield carrierTranziitApi.fetchTransportOrdersPage({
            ...query,
            page: pageNumber,
        });
    } else {
        result = yield brokerTranziitApi.fetchTransportOrdersPage(companyId, {
            ...query,
            page: pageNumber,
        });
    }

    const [error, response] = result;

    if (error) {
        yield put(fetchTransportOrdersPageError(query, pageNumber, error, companyId));
        return;
    }

    yield put(fetchTransportOrdersPageSuccess(query, pageNumber, response, companyId));

    checkShouldEmitChangePage(pageNumber, response, transportOrdersPaginationChannel);
}

function* transportOrdersSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_ORDERS_PAGE_REQUEST, fetchTransportOrdersSaga);
}

export default transportOrdersSaga;
