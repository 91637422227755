import { put, takeEvery } from 'redux-saga/effects';
import { ACCEPT_BID, AcceptBidActionT, PLACE_BID, PlaceBidActionT, REJECT_BID, RejectBidActionT } from './types';
import { updateBidBegin, updateBidError, updateBidSuccess } from './actions';
import spotCarrierTranziitApi from 'carrier/utils/api/spot-carrier-tranziit/api';
import { spotDetailsRefreshChannel } from 'carrier/store/spot-request-details/channels';
import { spotInboxRequestsRefreshChannel } from 'carrier/store/spot-inbox-requests/channels';
import { spotLoadBoardRequestsRefreshChannel } from 'carrier/store/spot-load-board-requests/channels';
import { spotInboxRequestsStatsRefreshChannel } from 'carrier/store/spot-inbox-requests-stats/channels';

function* acceptBidSaga(action: AcceptBidActionT): WrapGeneratorT<void> {
    const { bidId, spotRequestId, details } = action;

    yield put(updateBidBegin());

    const [error]: ReturnApiT<typeof spotCarrierTranziitApi.acceptBid> = yield spotCarrierTranziitApi.acceptBid(bidId, {
        bidPrice: details.price,
        comment: details.comment,
        lifeTime: details.deadline,
    });

    if (error) {
        yield put(updateBidError(error));
        return;
    }

    yield put(updateBidSuccess());

    spotDetailsRefreshChannel.emit({
        spotRequestId,
    });
    spotInboxRequestsRefreshChannel.emit({});
    spotLoadBoardRequestsRefreshChannel.emit({});
    spotInboxRequestsStatsRefreshChannel.emit({});
}

function* placeBidSaga(action: PlaceBidActionT): WrapGeneratorT<void> {
    const { spotRequestId, details } = action;

    yield put(updateBidBegin());

    const [error]: ReturnApiT<typeof spotCarrierTranziitApi.placeBid> = yield spotCarrierTranziitApi.placeBid(
        spotRequestId,
        {
            bidPrice: details.price,
            comment: details.comment,
            lifeTime: details.deadline,
        },
    );

    if (error) {
        yield put(updateBidError(error));
        return;
    }

    yield put(updateBidSuccess());

    spotDetailsRefreshChannel.emit({
        spotRequestId,
    });
    spotInboxRequestsRefreshChannel.emit({});
    spotLoadBoardRequestsRefreshChannel.emit({});
    spotInboxRequestsStatsRefreshChannel.emit({});
}

function* rejectBidSaga(action: RejectBidActionT): WrapGeneratorT<void> {
    const { bidId, spotRequestId } = action;

    yield put(updateBidBegin());

    const [error]: ReturnApiT<typeof spotCarrierTranziitApi.rejectBid> = yield spotCarrierTranziitApi.rejectBid(bidId);

    if (error) {
        yield put(updateBidError(error));
        return;
    }

    yield put(updateBidSuccess());

    spotDetailsRefreshChannel.emit({
        spotRequestId,
    });
    spotInboxRequestsRefreshChannel.emit({});
    spotLoadBoardRequestsRefreshChannel.emit({});
    spotInboxRequestsStatsRefreshChannel.emit({});
}

function* bidsSaga(): WrapGeneratorT<void> {
    yield takeEvery(PLACE_BID, placeBidSaga);
    yield takeEvery(ACCEPT_BID, acceptBidSaga);
    yield takeEvery(REJECT_BID, rejectBidSaga);
}

export default bidsSaga;
