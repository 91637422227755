import * as React from 'react';
import { useMemo } from 'react';

import classNames from 'classnames/bind';
import styles from './TransportOrderRouteMap.scss';

import GoogleMapReact from 'google-map-react';
import { BOOTSTRAP_URL_KEYS, DEFAULT_CENTER, DEFAULT_ZOOM, MAP_OPTIONS } from 'common/store/constants';
import GoogleMapContext from 'common/contexts/google-map-context';
import last from 'lodash/last';
import { AssetTypeEnum, StyleGuideColorsEnum } from 'common/constants';
import LastAssetTrackPointPin from 'common/components/maps/pins/LastAssetTrackPointPin/LastAssetTrackPointPin';
import MapRoute, { MapRouteThemeEnum } from 'common/components/maps/MapRoute/MapRoute';
import MapLoader from 'common/components/maps/MapLoader/MapLoader';
import { TransportOrderDetailsT } from 'common/store/transport-order-details/models';
import { useDispatch, useSelector } from 'react-redux';
import { selectRoutingGeometryState } from 'common/store/routing-geometry/selectors';
import { fetchTracksByTransportOrder } from 'common/store/asset-track/actions';
import { getAssetTrackHashByTransportOrderId } from 'common/store/asset-track/utils';
import { selectAssetTrackPoints, selectAssetTrackRequest } from 'common/store/asset-track/selectors';
import { isNonNil } from 'common/utils';
import { useRouteGeometry } from 'common/utils/hooks/useRouteGeometry';
import { StopEnum } from 'common/utils/api/models';
import NumberPinIcon from 'common/icons/NumberPinIcon';
import AssetDropOffLocationPin from 'common/components/maps/pins/AssetDropOffLocationPin/AssetDropOffLocationPin';
import AssetPickUpLocationPin from 'common/components/maps/pins/AssetPickUpLocationPin/AssetPickUpLocationPin';

const cx = classNames.bind(styles);

type PropsT = {
    isLoading?: boolean;
    transportOrderId: TransportOrderIdT;
    transportOrderDetails: TransportOrderDetailsT | null | undefined;
};

const TransportOrderRouteMap: React.FC<PropsT> = React.memo((props) => {
    const { transportOrderId, transportOrderDetails } = props;

    const dispatch = useDispatch();

    const polylineIds = useMemo(() => {
        return [
            transportOrderDetails?.dropTrailerPolylineId,
            transportOrderDetails?.dropTruckPolylineId,
            transportOrderDetails?.polylineId,
            transportOrderDetails?.trailerToPickUpPolylineId,
            transportOrderDetails?.truckToTrailerPolylineId,
        ].filter(isNonNil);
    }, [
        transportOrderDetails?.dropTrailerPolylineId,
        transportOrderDetails?.dropTruckPolylineId,
        transportOrderDetails?.polylineId,
        transportOrderDetails?.trailerToPickUpPolylineId,
        transportOrderDetails?.truckToTrailerPolylineId,
    ]);

    useRouteGeometry(polylineIds);

    const dropTrailerGeometryState = useSelector(
        selectRoutingGeometryState(transportOrderDetails?.dropTrailerPolylineId),
    );
    const dropTruckGeometryState = useSelector(selectRoutingGeometryState(transportOrderDetails?.dropTruckPolylineId));
    const payloadGeometryState = useSelector(selectRoutingGeometryState(transportOrderDetails?.polylineId));
    const trailerToPickUpGeometryState = useSelector(
        selectRoutingGeometryState(transportOrderDetails?.trailerToPickUpPolylineId),
    );
    const truckToTrailerGeometryState = useSelector(
        selectRoutingGeometryState(transportOrderDetails?.truckToTrailerPolylineId),
    );

    const isLoadingGeometry =
        dropTrailerGeometryState?.requestStatus?.loading ||
        dropTruckGeometryState?.requestStatus?.loading ||
        payloadGeometryState?.requestStatus?.loading ||
        trailerToPickUpGeometryState?.requestStatus?.loading ||
        truckToTrailerGeometryState?.requestStatus?.loading;

    React.useEffect(() => {
        if (transportOrderId) {
            dispatch(fetchTracksByTransportOrder(transportOrderId));
        }
    }, [transportOrderId]);

    const firstTourId = transportOrderDetails?.tourInfo?.id;
    const firstTourTrailerId = transportOrderDetails?.trailer?.id;
    const firstTourTruckId = transportOrderDetails?.truck?.id;

    const trailerHash = getAssetTrackHashByTransportOrderId(firstTourId, firstTourTrailerId);
    const trailerTrack = useSelector(selectAssetTrackPoints(trailerHash));
    const trailerTrackRequest = useSelector(selectAssetTrackRequest(trailerHash));

    const truckHash = getAssetTrackHashByTransportOrderId(firstTourId, firstTourTruckId);
    const truckTrack = useSelector(selectAssetTrackPoints(truckHash));
    const truckTrackRequest = useSelector(selectAssetTrackRequest(truckHash));

    const googleMapContext = React.useContext(GoogleMapContext);

    const apiIsLoaded: OnGoogleApiLoadedT = (api) => {
        const { map, maps } = api;

        googleMapContext.googleMaps?.set(maps, map, ['geometry']);
    };

    const lastTruckTrackPoint = last(truckTrack);
    const lastTrailerTrackPoint = last(trailerTrack);

    const renderWaypoint = (waypoint: TransportOrderDetailsT['waypoints'][number]) => {
        const isVisitedWaypoint = !!waypoint.driverLeftTimeStamp;

        const commonProps = {
            key: `waypoint-${waypoint.index}`,
            className: cx('location'),
            lng: waypoint?.address?.longitude,
            lat: waypoint?.address?.latitude,
        } as const;

        switch (waypoint.type) {
            case StopEnum.pickupTrailer: {
                return <AssetPickUpLocationPin assetType={AssetTypeEnum.trailer} {...commonProps} />;
            }
            case StopEnum.pickupTruck: {
                return <AssetPickUpLocationPin assetType={AssetTypeEnum.truck} {...commonProps} />;
            }
            case StopEnum.pickupRoadTrain: {
                return <AssetPickUpLocationPin assetType={null} {...commonProps} />;
            }
            case StopEnum.dropTrailer: {
                return <AssetDropOffLocationPin assetType={AssetTypeEnum.trailer} {...commonProps} />;
            }
            case StopEnum.dropTruck: {
                return <AssetDropOffLocationPin assetType={AssetTypeEnum.truck} {...commonProps} />;
            }
            case StopEnum.dropRoadTrain: {
                return <AssetDropOffLocationPin assetType={null} {...commonProps} />;
            }
            case StopEnum.driveThrough: {
                return (
                    <NumberPinIcon
                        number={(waypoint.index || 0) + 1}
                        fillColor={isVisitedWaypoint ? StyleGuideColorsEnum.brandDark : StyleGuideColorsEnum.gray}
                        {...commonProps}
                    />
                );
            }
            case StopEnum.dropAndHook:
            case StopEnum.pickupDeliveryShipment:
            default: {
                return (
                    <NumberPinIcon
                        number={(waypoint.index || 0) + 1}
                        fillColor={isVisitedWaypoint ? StyleGuideColorsEnum.brandDark : StyleGuideColorsEnum.charcoal}
                        {...commonProps}
                    />
                );
            }
        }
    };

    const isLoading =
        props.isLoading || isLoadingGeometry || truckTrackRequest?.loading || trailerTrackRequest?.loading;

    return (
        <>
            {isLoading && <MapLoader />}
            <GoogleMapReact
                defaultCenter={DEFAULT_CENTER}
                defaultZoom={DEFAULT_ZOOM}
                bootstrapURLKeys={BOOTSTRAP_URL_KEYS}
                options={MAP_OPTIONS}
                onGoogleApiLoaded={apiIsLoaded}
            >
                {transportOrderDetails?.waypoints?.map((waypoint) => {
                    return renderWaypoint(waypoint);
                })}
                {lastTruckTrackPoint && (
                    <LastAssetTrackPointPin
                        key="truck-last-point"
                        iconType={AssetTypeEnum.truck}
                        lat={lastTruckTrackPoint?.lat}
                        lng={lastTruckTrackPoint?.lng}
                        timestamp={lastTruckTrackPoint?.timestamp}
                    />
                )}
                {lastTrailerTrackPoint && (
                    <LastAssetTrackPointPin
                        key="trailer-last-point"
                        iconType={AssetTypeEnum.trailer}
                        lat={lastTrailerTrackPoint?.lat}
                        lng={lastTrailerTrackPoint?.lng}
                        timestamp={lastTrailerTrackPoint?.timestamp}
                    />
                )}
            </GoogleMapReact>
            {dropTrailerGeometryState && (
                <MapRoute
                    key="dropTrailerGeometryState"
                    map={googleMapContext.googleMaps?.map}
                    maps={googleMapContext.googleMaps?.maps}
                    geometryLibrary={googleMapContext?.googleMaps?.libraries?.geometry}
                    polylines={dropTrailerGeometryState.data}
                    theme={MapRouteThemeEnum.trackFuture}
                />
            )}
            {dropTruckGeometryState && (
                <MapRoute
                    key="dropTruckGeometryState"
                    map={googleMapContext.googleMaps?.map}
                    maps={googleMapContext.googleMaps?.maps}
                    geometryLibrary={googleMapContext?.googleMaps?.libraries?.geometry}
                    polylines={dropTruckGeometryState.data}
                    theme={MapRouteThemeEnum.trackFuture}
                />
            )}
            {payloadGeometryState && (
                <MapRoute
                    key="payloadGeometryState"
                    map={googleMapContext.googleMaps?.map}
                    maps={googleMapContext.googleMaps?.maps}
                    geometryLibrary={googleMapContext?.googleMaps?.libraries?.geometry}
                    polylines={payloadGeometryState.data}
                    theme={MapRouteThemeEnum.trackFuture}
                />
            )}
            {trailerToPickUpGeometryState && (
                <MapRoute
                    key="trailerToPickUpGeometryState"
                    map={googleMapContext.googleMaps?.map}
                    maps={googleMapContext.googleMaps?.maps}
                    geometryLibrary={googleMapContext?.googleMaps?.libraries?.geometry}
                    polylines={trailerToPickUpGeometryState.data}
                    theme={MapRouteThemeEnum.trackFuture}
                />
            )}
            {truckToTrailerGeometryState && (
                <MapRoute
                    key="truckToTrailerGeometryState"
                    map={googleMapContext.googleMaps?.map}
                    maps={googleMapContext.googleMaps?.maps}
                    geometryLibrary={googleMapContext?.googleMaps?.libraries?.geometry}
                    polylines={truckToTrailerGeometryState.data}
                    theme={MapRouteThemeEnum.trackFuture}
                />
            )}
            <MapRoute
                map={googleMapContext.googleMaps?.map}
                maps={googleMapContext.googleMaps?.maps}
                route={trailerTrack}
                theme={MapRouteThemeEnum.trackPast}
            />
            <MapRoute
                map={googleMapContext.googleMaps?.map}
                maps={googleMapContext.googleMaps?.maps}
                route={truckTrack}
                theme={MapRouteThemeEnum.trackPast}
            />
        </>
    );
});

export default TransportOrderRouteMap;
