import * as React from 'react';
import TopBar from 'common/layouts/LeftMenuLayout/TopBar/TopBar';
import classNames from 'classnames/bind';
import styles from './TransportOrderListPage.scss';
import { useTranslation } from 'react-i18next';
import history from 'common/utils/history';
import ScrollableContent from 'common/layouts/LeftMenuLayout/ScrollableContent/ScrollableContent';
import NotificationsBarTrigger from 'common/components/notifications/NotificationsBarTrigger/NotificationsBarTrigger';
import { urlFactory } from 'carrier/utils/urls';

import TransportOrderListPageLayout from 'common/layouts/TransportOrderListPageLayout/TransportOrderListPageLayout';
import { QuickFiltersOptionT } from 'common/components/Table/QuickFilters/QuickFilters';
import { TransportOrderQuickFilterEnum } from 'common/utils/api/models';
import { colorCodingNotificationLabelTheme } from 'common/components/notifications/NotificationLabel/NotificationLabel';
import { useSelector } from 'react-redux';
import { selectTransportOrdersStats } from 'common/store/transport-orders-stats/selectors';
import TopBarContent from 'common/layouts/LeftMenuLayout/TopBarContent/TopBarContent';
import ContentMargins from 'common/layouts/LeftMenuLayout/ContentMargins/ContentMargins';
import { selectGroupedNotificationCounts } from 'common/store/grouped-notification-counts/selectors';
import PageTitle from 'common/components/PageTitle/PageTitle';
import { selectCompanyVerificationProblems } from 'common/store/company/selectors';
import { useHistoryPush } from 'common/utils/hooks/useHistoryPush';

const cx = classNames.bind(styles);

type PropsT = {};

const quickFilterOptionSeparators: Array<number> = [];

const TransportOrderListPage: React.FC<PropsT> = React.memo(() => {
    const verificationProblems = useSelector(selectCompanyVerificationProblems);

    const { historyPush } = useHistoryPush();

    const { t } = useTranslation();

    const handleOpenTransportOrderDetails = (event: React.MouseEvent, transportOrderId: TransportOrderIdT | null) => {
        if (!transportOrderId) {
            return;
        }

        const url = urlFactory.transportOrdersDetails(transportOrderId);
        historyPush(event, url);
    };

    const stat = useSelector(selectTransportOrdersStats);
    const unReadNotificationsGroupedCounts = useSelector(selectGroupedNotificationCounts);

    const quickFilterOptions: Array<QuickFiltersOptionT<TransportOrderQuickFilterEnum>> = React.useMemo(
        () => [
            {
                id: undefined,
                showPriority: 0,
                hidePriority: 0,
                label: t('common:transport-orders-page.status-filter.all'),
                testSelector: 'all',
            },
            {
                id: TransportOrderQuickFilterEnum.new,
                showPriority: 1,
                hidePriority: 1,
                label: t('common:transport-orders-page.status-filter.new'),
                testSelector: TransportOrderQuickFilterEnum.new,
                notificationProps: {
                    count: stat?.new,
                    theme: colorCodingNotificationLabelTheme.attention,
                },
            },
            {
                id: TransportOrderQuickFilterEnum.noAssets,
                showPriority: 2,
                hidePriority: 2,
                label: t('common:transport-orders-page.status-filter.no-assets'),
                testSelector: TransportOrderQuickFilterEnum.noAssets,
                notificationProps: {
                    count: stat?.assetsUnassigned,
                    theme: colorCodingNotificationLabelTheme.attention,
                },
            },
            {
                id: TransportOrderQuickFilterEnum.noDriver,
                showPriority: 3,
                hidePriority: 3,
                label: t('common:transport-orders-page.status-filter.no-driver'),
                testSelector: TransportOrderQuickFilterEnum.noDriver,
                notificationProps: {
                    count: stat?.driverUnassigned,
                    theme: colorCodingNotificationLabelTheme.attention,
                },
            },
            {
                id: TransportOrderQuickFilterEnum.assigned,
                showPriority: 4,
                hidePriority: 4,
                label: t('common:transport-orders-page.status-filter.assigned'),
                testSelector: TransportOrderQuickFilterEnum.assigned,
                notificationProps: {
                    count: stat?.assigned,
                    theme: colorCodingNotificationLabelTheme.normal,
                },
            },
            {
                id: TransportOrderQuickFilterEnum.inTransit,
                showPriority: 5,
                hidePriority: 5,
                label: t('common:transport-orders-page.status-filter.in-transit'),
                testSelector: TransportOrderQuickFilterEnum.inTransit,
                notificationProps: {
                    count: stat?.inTransit,
                    theme: colorCodingNotificationLabelTheme.normal,
                },
            },
            {
                id: TransportOrderQuickFilterEnum.declined,
                showPriority: 6,
                hidePriority: 6,
                label: t('common:transport-orders-page.status-filter.declined'),
                testSelector: TransportOrderQuickFilterEnum.declined,
                notificationProps: {
                    isShowPlusSign: true,
                    count: unReadNotificationsGroupedCounts?.TRANSPORTATION_ORDER_STATUS_CHANGED?.DECLINED,
                    theme: colorCodingNotificationLabelTheme.normal,
                },
            },
            {
                id: TransportOrderQuickFilterEnum.canceled,
                showPriority: 7,
                hidePriority: 7,
                label: t('common:transport-orders-page.status-filter.canceled'),
                testSelector: TransportOrderQuickFilterEnum.canceled,
                notificationProps: {
                    isShowPlusSign: true,
                    count: unReadNotificationsGroupedCounts?.TRANSPORTATION_ORDER_STATUS_CHANGED?.CANCELED,
                    theme: colorCodingNotificationLabelTheme.attention,
                },
            },
            {
                id: TransportOrderQuickFilterEnum.done,
                showPriority: 8,
                hidePriority: 8,
                label: t('common:transport-orders-page.status-filter.done'),
                testSelector: TransportOrderQuickFilterEnum.done,
                notificationProps: {
                    isShowPlusSign: true,
                    count: unReadNotificationsGroupedCounts?.TRANSPORTATION_ORDER_STATUS_CHANGED?.DONE,
                    theme: colorCodingNotificationLabelTheme.success,
                },
            },
        ],
        [unReadNotificationsGroupedCounts, stat, t],
    );

    const goToProfile = () => {
        let link;

        if (verificationProblems.anyBasicInformation) {
            link = urlFactory.profileBasic();
        } else if (verificationProblems.anyDocument) {
            link = urlFactory.profileDocuments();
        }

        if (link) {
            history.push(link);
        }
    };

    return (
        <ScrollableContent>
            <PageTitle title={t('page-titles.transport-orders')} />
            <ContentMargins>
                <TopBar>
                    <TopBarContent
                        title={t('common:transport-orders-page.title')}
                        rightNode={<NotificationsBarTrigger />}
                    />
                </TopBar>
            </ContentMargins>
            <TransportOrderListPageLayout
                stat={stat}
                orderQuickFilterOptions={quickFilterOptions}
                orderQuickFilterOptionSeparators={quickFilterOptionSeparators}
                onOpenTransportOrderDetails={handleOpenTransportOrderDetails}
                hasProfileVerificationProblem={verificationProblems.any}
                goToProfile={goToProfile}
            />
        </ScrollableContent>
    );
});

export default TransportOrderListPage;
