import { QueryFiltersT } from '../query-models';
import { FieldsEnum, FormValuesT } from './constants';

const getInitialValues = (queryFilters: QueryFiltersT): FormValuesT => {
    const values: FormValuesT = {
        [FieldsEnum.orderType]: null,
        [FieldsEnum.receiveDates]: null,

        [FieldsEnum.dictTrailerId]: null,
        [FieldsEnum.plateNumber]: null,

        [FieldsEnum.originAddress]: null,
        [FieldsEnum.destinationAddress]: null,

        [FieldsEnum.driverId]: null,
        [FieldsEnum.driverName]: null,
        [FieldsEnum.minPrice]: '',
        [FieldsEnum.maxPrice]: '',

        [FieldsEnum.pickupDates]: null,
        [FieldsEnum.dropoffDates]: null,
    };

    return values;
};

export default getInitialValues;
