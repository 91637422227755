import { TransportOrderQuickFilterEnum, TransportOrderTypeEnum } from 'common/utils/api/models';

export enum QueryFiltersKeysEnum {
    dictTrailerId = 'dictTrailerId',
    maxPrice = 'maxPrice',
    minPrice = 'minPrice',
    orderType = 'orderType',
    plateNumber = 'plateNumber',
    destinationAddress = 'destinationAddress',
    originAddress = 'originAddress',
    driverId = 'driverId',
    driverName = 'driverName',
    receiveDatesFrom = 'receiveDatesFrom',
    receiveDatesTo = 'receiveDatesTo',
    pickupDateFrom = 'pickupDateFrom',
    pickupDateTo = 'pickupDateTo',
    dropOffDateFrom = 'dropOffDateFrom',
    dropOffDateTo = 'dropOffDateTo',
    searchText = 'searchText',
    quickFilter = 'quickFilter',

    spot = 'spot',
    dedicated = 'dedicated',

    runningOutOfTimeToAssignDriver = 'running-out-of-time-to-assign-driver',
    ranOutOfTimeToAssignDriver = 'ran-out-of-time-to-assign-driver',
    runningOutOfTimeToAssignAssets = 'running-out-of-time-to-assign-assets',
    ranOutOfTimeToAssignAssets = 'ran-out-of-time-to-assign-assets',
    expired = 'expired',
    missed = 'missed',
    delaying = 'delaying',
}

export type QueryFiltersT = Partial<{
    [QueryFiltersKeysEnum.dictTrailerId]: QueryParam.StringT;
    [QueryFiltersKeysEnum.maxPrice]: QueryParam.StringT;
    [QueryFiltersKeysEnum.minPrice]: QueryParam.StringT;
    [QueryFiltersKeysEnum.orderType]: TransportOrderTypeEnum;
    [QueryFiltersKeysEnum.plateNumber]: QueryParam.StringT;
    [QueryFiltersKeysEnum.destinationAddress]: QueryParam.StringT;
    [QueryFiltersKeysEnum.originAddress]: QueryParam.StringT;
    [QueryFiltersKeysEnum.driverId]: QueryParam.StringT;
    [QueryFiltersKeysEnum.driverName]: QueryParam.StringT;
    [QueryFiltersKeysEnum.receiveDatesFrom]: QueryParam.StringT;
    [QueryFiltersKeysEnum.receiveDatesTo]: QueryParam.StringT;
    [QueryFiltersKeysEnum.pickupDateFrom]: QueryParam.StringT;
    [QueryFiltersKeysEnum.pickupDateTo]: QueryParam.StringT;
    [QueryFiltersKeysEnum.dropOffDateFrom]: QueryParam.StringT;
    [QueryFiltersKeysEnum.dropOffDateTo]: QueryParam.StringT;
    [QueryFiltersKeysEnum.searchText]: QueryParam.StringT;
    [QueryFiltersKeysEnum.quickFilter]: TransportOrderQuickFilterEnum;
    [QueryFiltersKeysEnum.searchText]: QueryParam.StringT;
    [QueryFiltersKeysEnum.spot]: QueryParam.BooleanT;
    [QueryFiltersKeysEnum.dedicated]: QueryParam.BooleanT;
    [QueryFiltersKeysEnum.runningOutOfTimeToAssignAssets]: QueryParam.BooleanT;
    [QueryFiltersKeysEnum.ranOutOfTimeToAssignAssets]: QueryParam.BooleanT;
    [QueryFiltersKeysEnum.runningOutOfTimeToAssignDriver]: QueryParam.BooleanT;
    [QueryFiltersKeysEnum.ranOutOfTimeToAssignDriver]: QueryParam.BooleanT;
    [QueryFiltersKeysEnum.delaying]: QueryParam.BooleanT;
    [QueryFiltersKeysEnum.expired]: QueryParam.BooleanT;
    [QueryFiltersKeysEnum.missed]: QueryParam.BooleanT;
}>;

export enum QueryKeysEnum {
    transportOrdersPage = 'transport-orders-page',
    transportOrdersSort = 'transport-orders-sort',
    transportOrdersFilters = 'transport-orders-filters',
}
