import { QueryFiltersKeysEnum, QueryFiltersT } from './query-models';
import { formatPageQuerySort } from 'common/utils/page-sort';
import { FetchCompanyDriversPageQueryT } from 'common/store/drivers/models';
import { TabEnum } from './constants';
import { DriversSortEnum, DriverStatusEnum } from 'common/utils/api/models';
import difference from 'lodash/difference';
import { PageSortT } from 'common/utils/query';

const allDriverStatuses = Object.values(DriverStatusEnum);

const prepareFetchPageQuery = (
    queryFilters: QueryFiltersT,
    activeTab: TabEnum | null | undefined,
    sort: PageSortT<DriversSortEnum> | null,
): FetchCompanyDriversPageQueryT => {
    let statuses: DriverStatusEnum[] = [];

    if (activeTab === TabEnum.active) {
        statuses = difference(allDriverStatuses, [DriverStatusEnum.archived]);
    } else {
        statuses = [DriverStatusEnum.archived];
    }

    const excludedDriversStatus = queryFilters[QueryFiltersKeysEnum.excludedStatus] || [];

    return {
        name: queryFilters[QueryFiltersKeysEnum.searchText] || queryFilters[QueryFiltersKeysEnum.name] || undefined,
        statuses: difference(statuses, excludedDriversStatus),
        sort: formatPageQuerySort(sort),
    };
};

export { prepareFetchPageQuery };
