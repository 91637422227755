import * as React from 'react';
import EmissionClassLabel from 'common/components/EmissionClassLabel/EmissionClassLabel';
import { ApiEmissionClassT } from 'common/constants';

type PropsT = {
    emissionClasses: Array<ApiEmissionClassT | null | undefined> | null | undefined;
};

const DELIMETER = ', ';

const EmissionClassesFormatter: React.FC<PropsT> = React.memo((props) => {
    const { emissionClasses } = props;

    if (!emissionClasses) {
        return null;
    }

    return (
        <>
            {emissionClasses.map((emissionClass, index) => {
                return (
                    <>
                        <EmissionClassLabel emissionClass={emissionClass} />
                        {index === emissionClasses.length - 1 && <span>{DELIMETER}</span>}
                    </>
                );
            })}
        </>
    );
});

export default EmissionClassesFormatter;
