import { ApiBrokerTransportOrderDetailsT, ApiCarrierTransportOrderDetailsT } from 'common/utils/api/models';
import { TransportOrderDetailsT } from 'common/store/transport-order-details/models';

export const checkIsCarrierTransportOrderDetails = (
    details: TransportOrderDetailsT | null,
): details is ApiCarrierTransportOrderDetailsT => {
    if (!details) {
        return false;
    }

    return (
        'availableToAssignAssets' in details || 'availableToAssignDriver' in details || 'availableToDecline' in details
    );
};

export const checkIsBrokerTransportOrderDetails = (
    details: TransportOrderDetailsT | null,
): details is ApiBrokerTransportOrderDetailsT => {
    if (!details) {
        return false;
    }

    return 'availableToCancel' in details;
};
