import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './CheckboxHeader.scss';
import Checkbox from 'design-system/components/Checkbox/Checkbox';

type PropsT<TableRowT> = {
    rows: Array<TableRowT>;
    selectedRowsSet: Set<TableRowT>;
    onChange: (selectedRowsSet: Set<TableRowT>) => void;
    className?: string;
};

const cx = classNames.bind(styles);

const CheckboxHeader = <TableRowT extends {}>(props: PropsT<TableRowT>) => {
    const { rows, selectedRowsSet, onChange, className } = props;

    const [isShowSelectedAll, setShowSelectedAll] = React.useState<boolean>(false);

    React.useEffect(() => {
        setShowSelectedAll(false);
    }, [rows]);

    return (
        <Checkbox
            checked={isShowSelectedAll}
            onChange={() => {
                const isSelectedAll = rows.length === selectedRowsSet.size;
                if (isSelectedAll) {
                    setShowSelectedAll(false);
                    onChange(new Set());
                } else {
                    setShowSelectedAll(true);
                    onChange(new Set(rows));
                }
            }}
            className={className}
            label={null}
        />
    );
};

export default React.memo(CheckboxHeader) as typeof CheckboxHeader;
