import * as React from 'react';
import styles from './WarningFilterOptionsDropdown.scss';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import NotificationLabel, {
    NotificationLabelThemeEnum,
    NotificationPropsT,
} from 'common/components/notifications/NotificationLabel/NotificationLabel';
import DropdownMultipleInput from 'design-system/components/dropdowns/DropdownMultipleInput/DropdownMultipleInput';
import { useTranslation } from 'react-i18next';
import { SpecialOptionEnum } from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import classNames from 'classnames/bind';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import ArrowsIcon from 'common/icons/ArrowsIcon';

const cx = classNames.bind(styles);

export enum SpecialValueEnum {
    all = 'all',
}

export type WarningFilterOptionT<Value> = {
    id: Value;
    label: React.ReactNode;
    notificationProps?: NotificationPropsT | null;
    testSelector?: string;
};

export type PropsT<ValueT> = {
    className?: string;
    value: Array<ValueT | SpecialValueEnum> | null | undefined;
    onSelect: (selectedIds: Array<ValueT>) => void;
    options: Array<WarningFilterOptionT<ValueT> | SpecialOptionEnum>;
    testSelector?: string;
    overlayPosition?: DropdownOverlayPositionEnum;
};

const WarningFilterOptionsDropdown = <Value extends string>(props: PropsT<Value>) => {
    const { className, value, onSelect, options, testSelector, overlayPosition } = props;

    const { t } = useTranslation();

    const isAllOptionsSelected = React.useMemo(() => {
        const valueSet = new Set(value || []);

        return (
            !!options?.length &&
            options?.every((option) => {
                if (option === SpecialOptionEnum.separator) {
                    return true;
                }

                return option?.id && valueSet.has(option.id);
            })
        );
    }, [value]);

    const selectedOptionValues = React.useMemo((): Array<Value | SpecialValueEnum> => {
        const result = [...(value || [])];

        if (isAllOptionsSelected) {
            result.unshift(SpecialValueEnum.all);
        }

        return result;
    }, [value, isAllOptionsSelected]);

    const allOption = React.useMemo((): WarningFilterOptionT<SpecialValueEnum> => {
        const summaryCount = options.reduce<number>((acc, option) => {
            if (option === SpecialOptionEnum.separator) {
                return acc;
            }

            return acc + (option.notificationProps?.count || 0);
        }, 0);

        return {
            id: SpecialValueEnum.all,
            label: t('common:table.warning-filter.all'),
            notificationProps: summaryCount
                ? {
                      count: summaryCount,
                      theme: NotificationLabelThemeEnum.red,
                  }
                : null,
            testSelector: 'all',
        };
    }, [options]);

    const allOptions = React.useMemo((): Array<WarningFilterOptionT<Value | SpecialValueEnum> | SpecialOptionEnum> => {
        return [allOption, ...options];
    }, [allOption, options]);

    const handleChange = React.useCallback(
        (values: Array<Value | SpecialValueEnum>) => {
            const isSelectedAll = values?.includes(SpecialValueEnum.all);
            if (isSelectedAll && !isAllOptionsSelected) {
                const newAllValues = options.reduce<Array<Value>>((acc, option) => {
                    if (option === SpecialOptionEnum.separator) {
                        return acc;
                    }

                    acc.push(option.id);

                    return acc;
                }, []);

                onSelect(newAllValues);
                return;
            }

            if (!isSelectedAll && isAllOptionsSelected) {
                onSelect([]);
                return;
            }

            const newValues = values.reduce<Array<Value>>((acc, value) => {
                if (value === SpecialValueEnum.all) {
                    return acc;
                }

                acc.push(value);

                return acc;
            }, []);
            onSelect(newValues);
        },
        [onSelect, options, isAllOptionsSelected],
    );

    const summarySelectedNotificationProps = React.useMemo((): NotificationPropsT | null => {
        const summaryCount = options.reduce<number>((acc, option) => {
            if (option === SpecialOptionEnum.separator) {
                return acc;
            }

            if (!option?.id) {
                return acc;
            }

            return acc + (option.notificationProps?.count || 0);
        }, 0);

        if (!summaryCount) {
            return null;
        }

        return {
            count: summaryCount,
            theme: NotificationLabelThemeEnum.red,
        };
    }, [options]);

    return (
        <DropdownMultipleInput<WarningFilterOptionT<Value | SpecialValueEnum>, Value | SpecialValueEnum>
            className={className}
            selectedValues={selectedOptionValues}
            options={allOptions}
            onSelect={handleChange}
            renderOption={(option) => {
                if (!option) {
                    return null;
                }

                return (
                    <DropdownControlOptionLabel
                        withoutPaddings
                        icon={
                            option.notificationProps?.count ? <NotificationLabel {...option.notificationProps} /> : null
                        }
                        label={option.label}
                    />
                );
            }}
            renderTrigger={(props) => {
                let icon;

                if (summarySelectedNotificationProps) {
                    icon = <NotificationLabel {...summarySelectedNotificationProps} />;
                }

                return (
                    <TransparentTrigger
                        testSelector={`${testSelector}_filters`}
                        onClick={props.onClick}
                        isPressed={props.isPressed}
                        label={t('common:table.warning-filter.trigger')}
                        leftIcon={icon}
                        rightIcon={<ArrowsIcon />}
                        reflectionTheme={ReflectionThemeEnum.light}
                    />
                );
            }}
            overlayClassName={cx('overlay')}
            getOptionValue={(option) => option?.id}
            overlayPosition={overlayPosition || DropdownOverlayPositionEnum.bottomLeft}
        />
    );
};

export default WarningFilterOptionsDropdown;
