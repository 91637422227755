import * as React from 'react';
import { useCallback } from 'react';

import classNames from 'classnames/bind';
import styles from './SpotRequestListPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import SpotRequestsTable, {
    SpotRequestItemsT,
} from 'carrier/layouts/SpotRequestsPage/SpotRequestListPage/SpotRequestsTable/SpotRequestsTable';
import { useTranslation } from 'react-i18next';
import TopBar from 'common/layouts/LeftMenuLayout/TopBar/TopBar';
import FiltersTrigger from 'common/components/Table/FiltersTrigger/FiltersTrigger';
import SortDropdown, {
    SortDropdownOptionT,
    SortDropdownOverlayPositionEnum,
} from 'common/components/Table/SortDropdown/SortDropdown';
import Pagination from 'common/components/Table/Pagination/Pagination';
import TableMessage, { TableMessageIconsEnum } from 'common/components/Table/TableMessage/TableMessage';
import {
    createJsonParams,
    createPageNumberParam,
    createSortParams,
    PageSortT,
    SortDirectionEnum,
} from 'common/utils/query';
import ScrollableContent from 'common/layouts/LeftMenuLayout/ScrollableContent/ScrollableContent';
import ContentMargins from 'common/layouts/LeftMenuLayout/ContentMargins/ContentMargins';
import StickyFooter from 'common/layouts/LeftMenuLayout/StickyFooter/StickyFooter';
import { QuickFiltersOptionT } from 'common/components/Table/QuickFilters/QuickFilters';
import SearchControl from 'common/components/Table/SearchControl/SearchControl';
import { fetchTrailersDict } from 'common/store/trailers-dict/actions';
import NotificationsBarTrigger from 'common/components/notifications/NotificationsBarTrigger/NotificationsBarTrigger';
import TableError from 'common/components/Table/TableError/TableError';
import useDocumentVisibilityChange from 'common/utils/hooks/useDocumentVisibilityChange';
import TopBarContent from 'common/layouts/LeftMenuLayout/TopBarContent/TopBarContent';
import ListPageHeaderLayout from 'common/layouts/ListPage/ListPageHeaderLayout/ListPageHeaderLayout';
import ListPageHeaderTabsLayout from 'common/layouts/ListPage/ListPageHeaderTabsLayout/ListPageHeaderTabsLayout';
import ListPageLayout from 'common/layouts/ListPage/ListPageLayout/ListPageLayout';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import PageTitle from 'common/components/PageTitle/PageTitle';
import { SpotRequestsInboxQuickFilterEnum, SpotRequestsQuickTabEnum } from './constants';
import { QueryFiltersKeysEnum, QueryFiltersT, QueryKeysEnum } from './query-models';
import {
    selectSpotInboxRequestsByIds,
    selectSpotInboxRequestsPages,
    selectSpotInboxRequestsTotal,
} from 'carrier/store/spot-inbox-requests/selectors';
import { selectSpotInboxRequestsStats } from 'carrier/store/spot-inbox-requests-stats/selectors';
import { fetchSpotInboxRequestsPage } from 'carrier/store/spot-inbox-requests/actions';
import { BidStatusEnum, SpotRequestsSortEnum } from 'carrier/utils/api/spot-carrier-tranziit/models';
import { prepareFetchInboxPageQuery, prepareFetchLoadBoardPageQuery } from './prepare-fetch-inbox-page-query';
import SideBars from 'carrier/layouts/SideBars/SideBars';
import { useOpenLeftSidebar } from 'carrier/layouts/SideBars/hooks';
import { CarrierSidebarsTypeEnum } from 'carrier/layouts/SideBars/constants';
import SelectedFilters from './SelectedFilters/SelectedFilters';
import { useCheckOpenedSidebar } from 'shipper/layouts/SideBars/hooks';
import { StringParam, useQueryParams } from 'use-query-params';
import SourceButtonGroup from 'carrier/layouts/SpotRequestsPage/SpotRequestListPage/SourceButtonGroup/SourceButtonGroup';
import { fetchSpotLoadBoardRequestsPage } from 'carrier/store/spot-load-board-requests/actions';
import {
    selectSpotLoadBoardRequestsByIds,
    selectSpotLoadBoardRequestsPages,
    selectSpotLoadBoardRequestsTotal,
} from 'carrier/store/spot-load-board-requests/selectors';
import { fetchSpotInboxRequestsStats } from 'carrier/store/spot-inbox-requests-stats/actions';
import { colorCodingNotificationLabelTheme } from 'common/components/notifications/NotificationLabel/NotificationLabel';
import { urlFactory } from 'carrier/utils/urls';
import history from 'common/utils/history';
import { fetchSpotCarrierProfile } from 'carrier/store/spot-carrier-profile/actions';
import { selectSpotCarrierProfile } from 'carrier/store/spot-carrier-profile/selectors';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import Switcher from 'common/components/Switcher/Switcher';
import { useChannelSubscribe } from 'common/utils/hooks/useChannelSubscribe';
import {
    spotInboxRequestsPaginationChannel,
    spotInboxRequestsRefreshChannel,
} from 'carrier/store/spot-inbox-requests/channels';
import { spotInboxRequestsStatsRefreshChannel } from 'carrier/store/spot-inbox-requests-stats/channels';
import {
    spotLoadBoardRequestsPaginationChannel,
    spotLoadBoardRequestsRefreshChannel,
} from 'carrier/store/spot-load-board-requests/channels';
import { InferChannelEventT } from 'common/utils/view-event-channel';
import { checkNeedRenderEmptyState } from 'common/components/Table/utils/check-need-render-empty-state';
import { checkFilters } from 'common/components/Table/utils/check-filters';
import omit from 'lodash/omit';
import QuickFiltersButtonGroups from 'common/components/Table/QuickFiltersButtonGroups/QuickFiltersButtonGroups';

const cx = classNames.bind(styles);

type PropsT = {};

const SpotRequestListPage: React.FC<PropsT> = React.memo((props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { partnerId } = usePartnerContext();

    const inboxRequestsTotal = useSelector(selectSpotInboxRequestsTotal);
    const spotInboxRequestsPages = useSelector(selectSpotInboxRequestsPages);
    const spotInboxRequestsById = useSelector(selectSpotInboxRequestsByIds);

    const loadBoardRequestsTotal = useSelector(selectSpotLoadBoardRequestsTotal);
    const spotLoadBoardRequestsPages = useSelector(selectSpotLoadBoardRequestsPages);
    const spotLoadBoardRequestsById = useSelector(selectSpotLoadBoardRequestsByIds);

    const spotInboxRequestsStats = useSelector(selectSpotInboxRequestsStats);

    React.useEffect(() => {
        dispatch(fetchTrailersDict());
    }, []);

    React.useEffect(() => {
        dispatch(fetchSpotCarrierProfile(partnerId));
    }, [partnerId]);

    const { profile, profileRequestStatus } = useSelector(selectSpotCarrierProfile(partnerId));

    const isOpenedFilters = useCheckOpenedSidebar(CarrierSidebarsTypeEnum.spotRequestsFilter);
    const openLeftSidebar = useOpenLeftSidebar();
    const openRequestsFilter = React.useCallback(() => {
        openLeftSidebar({
            type: CarrierSidebarsTypeEnum.spotRequestsFilter,
        });
    }, [openLeftSidebar]);

    const [query, changeQuery] = useQueryParams({
        [QueryKeysEnum.spotRequestsSourceTab]: StringParam,
        [QueryKeysEnum.spotRequestsFilters]: createJsonParams<QueryFiltersT>({}),
        [QueryKeysEnum.spotRequestsPage]: createPageNumberParam(),
        [QueryKeysEnum.spotRequestsSort]: createSortParams<SpotRequestsSortEnum>({
            value: SpotRequestsSortEnum.expirationTime,
            direction: SortDirectionEnum.DESC,
        }),
    });

    const selectedSourceTab = query[QueryKeysEnum.spotRequestsSourceTab] || SpotRequestsQuickTabEnum.inbox;
    const pageNumber = query[QueryKeysEnum.spotRequestsPage];
    const queryFilters = query[QueryKeysEnum.spotRequestsFilters];
    const selectedSort = query[QueryKeysEnum.spotRequestsSort];

    const isInboxTab = selectedSourceTab === SpotRequestsQuickTabEnum.inbox;
    const total = isInboxTab ? inboxRequestsTotal : loadBoardRequestsTotal;
    const spotRequestsPages = isInboxTab ? spotInboxRequestsPages : spotLoadBoardRequestsPages;
    const spotRequestsById = isInboxTab ? spotInboxRequestsById : spotLoadBoardRequestsById;

    const page = spotRequestsPages[pageNumber];
    const { ids, requestStatus } = page || {};

    const documentVisibilityChangeHandler = React.useCallback(() => {
        if (selectedSourceTab === SpotRequestsQuickTabEnum.inbox) {
            const query = prepareFetchInboxPageQuery(queryFilters, selectedSort);
            dispatch(fetchSpotInboxRequestsPage(pageNumber, query, { isForceUpdate: false }));

            dispatch(fetchSpotInboxRequestsStats());
        } else {
            const query = prepareFetchLoadBoardPageQuery(queryFilters, selectedSort);
            dispatch(fetchSpotLoadBoardRequestsPage(pageNumber, query, { isForceUpdate: false }));
        }

        dispatch(fetchSpotCarrierProfile(partnerId));
    }, [pageNumber, selectedSourceTab, selectedSort, queryFilters, partnerId]);
    useDocumentVisibilityChange(documentVisibilityChangeHandler);

    const refreshSpotLoadBoardRequestsPageHandler = useCallback(() => {
        if (selectedSourceTab !== SpotRequestsQuickTabEnum.loadBoard) {
            return;
        }

        const query = prepareFetchLoadBoardPageQuery(queryFilters, selectedSort);
        dispatch(fetchSpotLoadBoardRequestsPage(pageNumber, query, { isForceUpdate: true }));
    }, [queryFilters, selectedSort, pageNumber, selectedSourceTab]);
    useChannelSubscribe(spotLoadBoardRequestsRefreshChannel, refreshSpotLoadBoardRequestsPageHandler);

    const refreshSpotInboxRequestsPageHandler = useCallback(() => {
        if (selectedSourceTab !== SpotRequestsQuickTabEnum.inbox) {
            return;
        }

        const query = prepareFetchInboxPageQuery(queryFilters, selectedSort);
        dispatch(fetchSpotInboxRequestsPage(pageNumber, query, { isForceUpdate: true }));
    }, [queryFilters, selectedSort, pageNumber, selectedSourceTab]);
    useChannelSubscribe(spotInboxRequestsRefreshChannel, refreshSpotInboxRequestsPageHandler);

    const refreshSpotInboxRequestsStatsPageHandler = useCallback(() => {
        if (selectedSourceTab !== SpotRequestsQuickTabEnum.inbox) {
            return;
        }

        dispatch(fetchSpotInboxRequestsStats({ isForceUpdate: true }));
    }, [selectedSourceTab]);
    useChannelSubscribe(spotInboxRequestsStatsRefreshChannel, refreshSpotInboxRequestsStatsPageHandler);

    React.useEffect(() => {
        if (selectedSourceTab === SpotRequestsQuickTabEnum.inbox) {
            const query = prepareFetchInboxPageQuery(queryFilters, selectedSort);
            dispatch(fetchSpotInboxRequestsPage(pageNumber, query, { isForceUpdate: false }));

            dispatch(fetchSpotInboxRequestsStats());
        } else {
            const query = prepareFetchLoadBoardPageQuery(queryFilters, selectedSort);
            dispatch(fetchSpotLoadBoardRequestsPage(pageNumber, query, { isForceUpdate: false }));
        }

        return (): void => {
            // TODO reset
        };
    }, [pageNumber, selectedSourceTab, selectedSort, queryFilters]);

    const spotRequests = React.useMemo(() => {
        return (ids || []).map((id): SpotRequestItemsT => spotRequestsById[id]);
    }, [ids, spotRequestsById]);

    const goToPage = React.useCallback(
        (pageNumber: PageNumberT) => {
            changeQuery({
                [QueryKeysEnum.spotRequestsPage]: pageNumber,
            });
        },
        [changeQuery],
    );

    const setPageHandler = React.useCallback(
        ({
            pageNumber,
        }:
            | InferChannelEventT<typeof spotInboxRequestsPaginationChannel>
            | InferChannelEventT<typeof spotLoadBoardRequestsPaginationChannel>) => {
            goToPage(pageNumber);
        },
        [goToPage],
    );
    useChannelSubscribe(spotInboxRequestsPaginationChannel, setPageHandler);
    useChannelSubscribe(spotLoadBoardRequestsPaginationChannel, setPageHandler);

    const goToSpotRequestDetails = (spotRequestId: SpotRequestIdT) => {
        openLeftSidebar({
            type: CarrierSidebarsTypeEnum.spotRequestDetails,
            id: spotRequestId,
        });
    };

    const spotRequestsQuickFilterOptions = React.useMemo(
        (): Array<QuickFiltersOptionT<SpotRequestsInboxQuickFilterEnum>> => [
            {
                id: undefined,
                showPriority: 0,
                hidePriority: 0,
                label: t('spot-requests.list.status-filter.all'),
                testSelector: 'all',
            },
            {
                id: SpotRequestsInboxQuickFilterEnum.new,
                showPriority: 1,
                hidePriority: 1,
                label: t('spot-requests.list.status-filter.new'),
                testSelector: SpotRequestsInboxQuickFilterEnum.new,
                notificationProps: {
                    count: spotInboxRequestsStats?.[BidStatusEnum.new],
                    theme: colorCodingNotificationLabelTheme.attention,
                },
            },
            {
                id: SpotRequestsInboxQuickFilterEnum.open,
                showPriority: 2,
                hidePriority: 2,
                label: t('spot-requests.list.status-filter.open'),
                testSelector: SpotRequestsInboxQuickFilterEnum.open,
                notificationProps: {
                    count: spotInboxRequestsStats?.[BidStatusEnum.open],
                    theme: colorCodingNotificationLabelTheme.attention,
                },
            },
            {
                id: SpotRequestsInboxQuickFilterEnum.expired,
                showPriority: 3,
                hidePriority: 3,
                label: t('spot-requests.list.status-filter.expired'),
                testSelector: SpotRequestsInboxQuickFilterEnum.expired,
                notificationProps: {
                    count: spotInboxRequestsStats?.[BidStatusEnum.expired],
                    theme: colorCodingNotificationLabelTheme.attention,
                    isShowPlusSign: true,
                },
            },
            {
                id: SpotRequestsInboxQuickFilterEnum.accepted,
                showPriority: 4,
                hidePriority: 4,
                label: t('spot-requests.list.status-filter.accepted'),
                testSelector: SpotRequestsInboxQuickFilterEnum.accepted,
            },
            {
                id: SpotRequestsInboxQuickFilterEnum.rejected,
                showPriority: 5,
                hidePriority: 5,
                label: t('spot-requests.list.status-filter.rejected'),
                testSelector: SpotRequestsInboxQuickFilterEnum.rejected,
            },
            {
                id: SpotRequestsInboxQuickFilterEnum.won,
                showPriority: 6,
                hidePriority: 6,
                label: t('spot-requests.list.status-filter.won'),
                testSelector: SpotRequestsInboxQuickFilterEnum.won,
                notificationProps: {
                    count: spotInboxRequestsStats?.[BidStatusEnum.won],
                    theme: colorCodingNotificationLabelTheme.success,
                    isShowPlusSign: true,
                },
            },
            {
                id: SpotRequestsInboxQuickFilterEnum.lost,
                showPriority: 7,
                hidePriority: 7,
                label: t('spot-requests.list.status-filter.lost'),
                testSelector: SpotRequestsInboxQuickFilterEnum.lost,
                notificationProps: {
                    count: spotInboxRequestsStats?.[BidStatusEnum.lost],
                    theme: colorCodingNotificationLabelTheme.warning,
                    isShowPlusSign: true,
                },
            },
            {
                id: SpotRequestsInboxQuickFilterEnum.missed,
                showPriority: 8,
                hidePriority: 8,
                label: t('spot-requests.list.status-filter.missed'),
                testSelector: SpotRequestsInboxQuickFilterEnum.missed,
                notificationProps: {
                    count: spotInboxRequestsStats?.[BidStatusEnum.missed],
                    theme: colorCodingNotificationLabelTheme.warning,
                    isShowPlusSign: true,
                },
            },
            {
                id: SpotRequestsInboxQuickFilterEnum.canceled,
                showPriority: 9,
                hidePriority: 9,
                label: t('spot-requests.list.status-filter.canceled'),
                testSelector: SpotRequestsInboxQuickFilterEnum.canceled,
                notificationProps: {
                    count: spotInboxRequestsStats?.[BidStatusEnum.canceled],
                    theme: colorCodingNotificationLabelTheme.normal,
                    isShowPlusSign: true,
                },
            },
        ],
        [t, spotInboxRequestsStats],
    );

    const sortOptions: Array<SortDropdownOptionT<SpotRequestsSortEnum>> = React.useMemo(
        () => [
            {
                label: t('spot-requests.list.table.sorts.triggers.expiration-time'),
                value: SpotRequestsSortEnum.expirationTime,
            },
            {
                label: t('spot-requests.list.table.sorts.triggers.placed-time'),
                value: SpotRequestsSortEnum.placedTime,
            },
            {
                label: t('spot-requests.list.table.sorts.triggers.origin-time'),
                value: SpotRequestsSortEnum.originTime,
            },
            {
                label: t('spot-requests.list.table.sorts.triggers.destination-time'),
                value: SpotRequestsSortEnum.destinationTime,
            },
        ],
        [t],
    );

    const handleSelectSort = (sort: PageSortT<SpotRequestsSortEnum>) => {
        changeQuery({
            [QueryKeysEnum.spotRequestsPage]: 0,
            [QueryKeysEnum.spotRequestsSort]: sort,
        });
    };

    const handleSetStatusFilter = (quickFilterId: SpotRequestsInboxQuickFilterEnum | undefined) => {
        const newQueryFilters = {
            ...queryFilters,
            [QueryFiltersKeysEnum.inboxQuickFilter]: quickFilterId,
        };

        changeQuery({
            [QueryKeysEnum.spotRequestsPage]: 0,
            [QueryKeysEnum.spotRequestsFilters]: newQueryFilters,
        });
    };

    const updateQueryFilters = (queryFiltersChanges: QueryFiltersT) => {
        const newQueryFilters = {
            ...queryFilters,
            ...queryFiltersChanges,
        };

        changeQuery({
            [QueryKeysEnum.spotRequestsPage]: 0,
            [QueryKeysEnum.spotRequestsFilters]: newQueryFilters,
        });
    };

    const handleSetQueryFilters = (selectedQueryFilters: QueryFiltersT) => {
        const newQueryFilters = {
            [QueryFiltersKeysEnum.inboxQuickFilter]: queryFilters[QueryFiltersKeysEnum.inboxQuickFilter],
            ...selectedQueryFilters,
        };

        changeQuery({
            [QueryKeysEnum.spotRequestsPage]: 0,
            [QueryKeysEnum.spotRequestsFilters]: newQueryFilters,
        });
    };

    const handleSearch = (searchText: string) => {
        updateQueryFilters({
            [QueryFiltersKeysEnum.searchText]: searchText || undefined,
        });
    };

    const handleToggleOnlyAvailableToAccept = () => {
        updateQueryFilters({
            [QueryFiltersKeysEnum.loadBoardOnlyAvailableToAccept]:
                !queryFilters[QueryFiltersKeysEnum.loadBoardOnlyAvailableToAccept],
        });
    };

    const handleSelectSourceTab = (tab: string | null) => {
        changeQuery({
            [QueryKeysEnum.spotRequestsFilters]: {
                ...queryFilters,
                [QueryFiltersKeysEnum.loadBoardOnlyAvailableToAccept]: undefined,
                [QueryFiltersKeysEnum.inboxQuickFilter]: undefined,
            },
            [QueryKeysEnum.spotRequestsPage]: 0,
            [QueryKeysEnum.spotRequestsSourceTab]: tab || undefined,
        });
    };

    const renderEmptyState = () => {
        if (!checkNeedRenderEmptyState(spotRequests, requestStatus)) {
            return null;
        }

        if (profile && !profile?.verified) {
            return (
                <TableMessage
                    iconType={TableMessageIconsEnum.warning}
                    title={t('spot-requests.list.table.messages.no-access.title')}
                    description={t('spot-requests.list.table.messages.no-access.description')}
                    isShowAction
                    actionTitle={t('spot-requests.list.table.messages.no-access.action')}
                    actionTheme={ButtonThemeEnum.secondary}
                    onActionClick={() => {
                        history.push(urlFactory.optionalDocuments());
                    }}
                    testSelector="no-access"
                />
            );
        }

        if (requestStatus?.error) {
            return <TableError />;
        }

        const { hasSecondaryQueryFilters, hasPrimaryQueryFilters } = checkFilters(
            [
                query[QueryKeysEnum.spotRequestsSourceTab],
                query[QueryKeysEnum.spotRequestsFilters]?.[QueryFiltersKeysEnum.inboxQuickFilter],
            ],
            [omit(query[QueryKeysEnum.spotRequestsFilters], [QueryFiltersKeysEnum.inboxQuickFilter])],
        );

        if (hasSecondaryQueryFilters) {
            return (
                <TableMessage
                    iconType={TableMessageIconsEnum.notFound}
                    title={t('spot-requests.list.table.messages.not-found.title')}
                    description={t('spot-requests.list.table.messages.not-found.description')}
                    isShowAction
                    actionTitle={t('spot-requests.list.table.messages.not-found.action')}
                    actionTheme={ButtonThemeEnum.secondary}
                    onActionClick={() => handleSetQueryFilters({})}
                    testSelector="not-found"
                />
            );
        }

        if (
            selectedSourceTab === SpotRequestsQuickTabEnum.loadBoard ||
            (selectedSourceTab === SpotRequestsQuickTabEnum.inbox && !hasPrimaryQueryFilters)
        ) {
            return (
                <TableMessage
                    iconType={TableMessageIconsEnum.empty}
                    title={t('spot-requests.list.table.messages.empty.title')}
                    testSelector="empty"
                    isShowAction={false}
                />
            );
        }

        if (selectedSourceTab === SpotRequestsQuickTabEnum.inbox && hasPrimaryQueryFilters) {
            return (
                <TableMessage
                    iconType={TableMessageIconsEnum.empty}
                    title={t('spot-requests.list.table.messages.empty-state.title')}
                    testSelector="empty-state"
                    isShowAction={false}
                />
            );
        }

        return null;
    };

    return (
        <ScrollableContent>
            <PageTitle title={t('page-titles.spot')} />
            <ContentMargins>
                <TopBar>
                    <TopBarContent title={t('spot-requests.list.title')} rightNode={<NotificationsBarTrigger />} />
                </TopBar>
                <ListPageLayout>
                    <ListPageHeaderTabsLayout>
                        <SourceButtonGroup
                            className={cx('source-tabs')}
                            selectedTab={selectedSourceTab}
                            onSelectTab={handleSelectSourceTab}
                        />
                        {selectedSourceTab === SpotRequestsQuickTabEnum.inbox && (
                            <QuickFiltersButtonGroups
                                options={spotRequestsQuickFilterOptions}
                                selectedId={queryFilters[QueryFiltersKeysEnum.inboxQuickFilter]}
                                onSelect={handleSetStatusFilter}
                                moreStatusesLabel={t('spot-requests.list.status-filter.more-statuses')}
                            />
                        )}
                    </ListPageHeaderTabsLayout>
                    <ListPageHeaderLayout
                        withTopPadding
                        leftToolsNode={
                            <>
                                <FiltersTrigger
                                    className={cx('filters-trigger')}
                                    title={t('spot-requests.list.filters.trigger')}
                                    isActive={isOpenedFilters}
                                    onClick={openRequestsFilter}
                                />
                                <SortDropdown
                                    className={cx('sort-trigger')}
                                    overlayPosition={SortDropdownOverlayPositionEnum.left}
                                    selectedValue={selectedSort}
                                    options={sortOptions}
                                    onSelect={handleSelectSort}
                                />
                                <SearchControl
                                    placeholder={t('spot-requests.list.search-placeholder')}
                                    searchText={queryFilters[QueryFiltersKeysEnum.searchText]}
                                    onChangeSearchText={handleSearch}
                                />
                            </>
                        }
                        filterTagsNode={
                            <SelectedFilters
                                isCompact
                                queryFilters={queryFilters}
                                setQueryFilters={handleSetQueryFilters}
                            />
                        }
                        rightToolsNode={
                            <>
                                {selectedSourceTab === SpotRequestsQuickTabEnum.loadBoard && (
                                    <Switcher
                                        checked={!!queryFilters[QueryFiltersKeysEnum.loadBoardOnlyAvailableToAccept]}
                                        onToggle={handleToggleOnlyAvailableToAccept}
                                        label={t('spot-requests.list.filters.only-available-to-accept')}
                                    />
                                )}
                            </>
                        }
                    />
                    {renderEmptyState()}
                    <SpotRequestsTable
                        isLoadBoard={selectedSourceTab === SpotRequestsQuickTabEnum.loadBoard}
                        spotRequests={spotRequests}
                        className={cx('table')}
                        goToSpotRequestDetails={goToSpotRequestDetails}
                        isLoading={requestStatus?.loading || profileRequestStatus?.loading}
                    />
                </ListPageLayout>
                <StickyFooter>
                    <Pagination current={pageNumber} count={total?.pageCount} goToPage={goToPage} />
                </StickyFooter>
                <SideBars />
            </ContentMargins>
        </ScrollableContent>
    );
});

export default SpotRequestListPage;
