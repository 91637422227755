import * as React from 'react';
import Link, { LinkSizeEnum, LinkThemeEnum } from 'common/components/Link/Link';
import ClickInterceptorLabel from 'common/components/ClickInterceptorLabel/ClickInterceptorLabel';

type PropsT = {
    onOpenUserDetails: (userId: UserIdT | null) => void;
    userFullName: string | null | undefined;
    userId: UserIdT | null | undefined;
};

const EventAuthorCell: React.FC<PropsT> = React.memo((props) => {
    const { userId, userFullName, onOpenUserDetails } = props;

    return (
        <ClickInterceptorLabel>
            <Link
                theme={LinkThemeEnum.boldBrandDark}
                size={LinkSizeEnum.medium}
                onClick={() => {
                    if (!userId) {
                        return;
                    }

                    onOpenUserDetails(userId);
                }}
            >
                {userFullName}
            </Link>
        </ClickInterceptorLabel>
    );
});

export default EventAuthorCell;
