import * as React from 'react';
import { VehicleScheduleEventT, VehicleScheduleT } from 'common/store/vehicle-schedules/models';
import { TimeWindowT, TimeWindowThemeEnum } from 'common/components/Timeline/Timeline';
import { parseDateString } from 'common/utils/time';
import { VehicleScheduleEventTypeEnum } from 'common/utils/api/models';
import CargoUnitIcon, { getCargoUnitIconProps } from 'common/icons/CargoUnitIcon';
import { StyleGuideColorsEnum } from 'common/constants';
import TimelineSidebar from 'common/components/Timeline/TimelineSidebar/TimelineSidebar';
import { useTranslation } from 'react-i18next';
import LockIcon from 'common/icons/LockIcon';

type PropsT = {
    className?: string;
    isLoading?: boolean;
    onClose: () => void;
    onEventClick: (eventId: string) => void;
    selectedOrderId?: string;
    schedule: VehicleScheduleT | null;
    type: 'trailer' | 'truck';
};

const TIMELINE_ICON_SIZE = 16;

const AssetScheduleSidebar: React.FC<PropsT> = React.memo((props) => {
    const { className, isLoading, onClose, onEventClick, selectedOrderId, schedule, type } = props;

    const { t } = useTranslation();

    const timeWindows = React.useMemo(() => {
        const now = Date.now();

        return (schedule || []).reduce<Array<TimeWindowT<VehicleScheduleEventT>>>(
            (result, scheduleEvent: VehicleScheduleEventT) => {
                const dateFrom = type === 'trailer' ? scheduleEvent.trailerDateFrom : scheduleEvent.truckDateFrom;
                const dateTo = type === 'trailer' ? scheduleEvent.trailerDateTo : scheduleEvent.truckDateTo;

                const start = parseDateString(dateFrom);
                const end = parseDateString(dateTo);

                if (start && end) {
                    let label = '';
                    let theme = TimeWindowThemeEnum.accent;
                    let icon: React.ReactNode = null;

                    if (scheduleEvent.eventType === VehicleScheduleEventTypeEnum.serviceMaintenance) {
                        label = t('common:vehicle-schedule.event-type.unavailable');
                        theme = TimeWindowThemeEnum.unavailable;
                        icon = <LockIcon size={TIMELINE_ICON_SIZE} fillColor={StyleGuideColorsEnum.white} />;
                    }

                    if (scheduleEvent.eventType === VehicleScheduleEventTypeEnum.shipment) {
                        label = t('common:vehicle-schedule.event-type.transportation-order', {
                            number: scheduleEvent?.transportationOrderNumber || '',
                        });

                        if (start <= now && end <= now) {
                            theme = TimeWindowThemeEnum.past;
                            icon = (
                                <CargoUnitIcon
                                    size={TIMELINE_ICON_SIZE}
                                    {...getCargoUnitIconProps('schedule-event-past')}
                                />
                            );
                        } else if (now <= start && now <= end) {
                            theme = TimeWindowThemeEnum.future;
                            icon = (
                                <CargoUnitIcon
                                    size={TIMELINE_ICON_SIZE}
                                    {...getCargoUnitIconProps('schedule-event-future')}
                                />
                            );
                        } else {
                            theme = TimeWindowThemeEnum.inProgress;
                            icon = (
                                <CargoUnitIcon
                                    size={TIMELINE_ICON_SIZE}
                                    {...getCargoUnitIconProps('schedule-event-regular')}
                                />
                            );
                        }
                    }

                    result.push({
                        id: scheduleEvent.id || '',
                        data: scheduleEvent,
                        start,
                        end,
                        label,
                        icon,
                        theme,
                        isTransparent: false,
                    });
                }

                return result;
            },
            [],
        );
    }, [schedule, type]);

    const levels = React.useMemo(() => {
        return [
            {
                timeWindows,
            },
        ];
    }, [timeWindows]);

    return (
        <TimelineSidebar
            className={className}
            isLoading={isLoading}
            onClose={onClose}
            onTimeWindowClick={onEventClick}
            levels={levels}
            selectedTimeWindowId={selectedOrderId || null}
        />
    );
});

export default AssetScheduleSidebar;
