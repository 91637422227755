import isNumber from 'lodash/isNumber';

export type AssignmentVehiclesSuggestItemT = Api2.Definitions.CarrierAssetForAssignment;

export type LinksT = {
    byTruckId: Record<TruckIdT, TrailerIdT>;
    byTrailerId: Record<TrailerIdT, TruckIdT>;
};

export type TrailerAssignmentInfoT = Omit<
    NonNullable<Api2.Definitions.CarrierAssetForAssignment['trailer']>,
    'latitude' | 'longitude'
> & {
    arrivalDate?: string;
    currentPoint: {
        latitude: number;
        longitude: number;
    } | null;
    futurePoint: {
        latitude: number;
        longitude: number;
    } | null;
};

export type TruckAssignmentInfoT = Omit<
    NonNullable<Api2.Definitions.CarrierAssetForAssignment['truck']>,
    'latitude' | 'longitude'
> & {
    arrivalDate?: string;
    currentPoint: {
        latitude: number;
        longitude: number;
    } | null;
    futurePoint: {
        latitude: number;
        longitude: number;
    } | null;
};

export const getAssignmentVehicleLinks = (vehicles: AssignmentVehiclesSuggestItemT[]): LinksT => {
    return vehicles.reduce<LinksT>(
        (acc, vehicle) => {
            const truckId = vehicle?.truck?.id;
            const trailerId = vehicle?.trailer?.id;
            if (!truckId || !trailerId) {
                return acc;
            }

            return {
                byTruckId: {
                    ...acc.byTruckId,
                    [truckId]: trailerId,
                },
                byTrailerId: {
                    ...acc.byTrailerId,
                    [trailerId]: truckId,
                },
            };
        },
        {
            byTruckId: {},
            byTrailerId: {},
        },
    );
};

export const getTrailerById = (
    vehicles: AssignmentVehiclesSuggestItemT[],
): Record<TrailerIdT, TrailerAssignmentInfoT> => {
    return vehicles.reduce<Record<TrailerIdT, TrailerAssignmentInfoT>>((acc, vehicle) => {
        const { trailer, expectedLatitude, expectedLongitude, expectedArrivalDate } = vehicle;

        if (!trailer?.id) {
            return acc;
        }

        const { currentLatitude, currentLongitude, ...restTrailer } = trailer;

        const futurePoint =
            isNumber(expectedLatitude) && isNumber(expectedLongitude)
                ? {
                      latitude: expectedLatitude,
                      longitude: expectedLongitude,
                  }
                : null;

        const currentPoint =
            isNumber(currentLatitude) && isNumber(currentLongitude)
                ? {
                      latitude: currentLatitude,
                      longitude: currentLongitude,
                  }
                : null;

        return {
            ...acc,
            [trailer.id]: {
                ...restTrailer,
                arrivalDate: expectedArrivalDate,
                currentPoint,
                futurePoint,
            },
        };
    }, {});
};

export const getTruckById = (vehicles: AssignmentVehiclesSuggestItemT[]): Record<TruckIdT, TruckAssignmentInfoT> => {
    return vehicles.reduce<Record<TruckIdT, TruckAssignmentInfoT>>((acc, vehicle) => {
        const { truck, expectedLatitude, expectedLongitude, expectedArrivalDate } = vehicle;

        if (!truck?.id) {
            return acc;
        }

        const { currentLatitude, currentLongitude, ...restTruck } = truck;

        const futurePoint =
            isNumber(expectedLatitude) && isNumber(expectedLongitude)
                ? {
                      latitude: expectedLatitude,
                      longitude: expectedLongitude,
                  }
                : null;

        const currentPoint =
            isNumber(currentLatitude) && isNumber(currentLongitude)
                ? {
                      latitude: currentLatitude,
                      longitude: currentLongitude,
                  }
                : null;

        return {
            ...acc,
            [truck.id]: {
                ...restTruck,
                arrivalDate: expectedArrivalDate,
                currentPoint,
                futurePoint,
            },
        };
    }, {});
};
