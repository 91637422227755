import {
    TransportOrderDetailsActionT,
    TransportOrderDetailsByIdStateT,
    TransportOrderDetailsStateT,
    FETCH_TRANSPORT_ORDER_DETAILS_REQUEST_BEGIN,
    FETCH_TRANSPORT_ORDER_DETAILS_REQUEST_ERROR,
    FETCH_TRANSPORT_ORDER_DETAILS_REQUEST_SUCCESS,
} from './types';
import requestStatus from 'common/utils/request-status';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

const initialState: TransportOrderDetailsByIdStateT = {};

export const initialTransportOrderDetailsState: TransportOrderDetailsStateT = {
    details: null,
    requestStatus: requestStatus.initial,
};

export default (
    state = initialState,
    action: TransportOrderDetailsActionT | DestroySessionActionT,
): TransportOrderDetailsByIdStateT => {
    switch (action.type) {
        case FETCH_TRANSPORT_ORDER_DETAILS_REQUEST_BEGIN: {
            const { id } = action;

            const transportOrderDetailsState = state[id] || initialTransportOrderDetailsState;

            return {
                ...state,
                [id]: {
                    ...transportOrderDetailsState,
                    requestStatus: requestStatus.loading,
                },
            };
        }

        case FETCH_TRANSPORT_ORDER_DETAILS_REQUEST_ERROR: {
            const { error, id } = action;

            const transportOrderDetailsState = state[id] || initialTransportOrderDetailsState;

            return {
                ...state,
                [id]: {
                    ...transportOrderDetailsState,
                    requestStatus: requestStatus.setError(error),
                },
            };
        }

        case FETCH_TRANSPORT_ORDER_DETAILS_REQUEST_SUCCESS: {
            const { transportOrderDetails, id } = action;

            const transportOrderDetailsState = state[id] || initialTransportOrderDetailsState;

            return {
                ...state,
                [id]: {
                    ...transportOrderDetailsState,
                    requestStatus: requestStatus.ok,
                    details: transportOrderDetails,
                },
            };
        }

        case DESTROY_SESSION: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};
