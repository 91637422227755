import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './AssetCell.scss';
import { SpotInboxRequestT } from 'carrier/store/spot-inbox-requests/models';
import { SpotLoadBoardRequestT } from 'carrier/store/spot-load-board-requests/models';

type PropsT = {
    spotRequest: SpotInboxRequestT | SpotLoadBoardRequestT;
};

const cx = classNames.bind(styles);

const AssetCell: React.FC<PropsT> = React.memo((props) => {
    const { spotRequest } = props;

    const trailerTypeModel = spotRequest?.trailerTypeModel || null;
    if (!trailerTypeModel) {
        return null;
    }

    return <div className={cx('model')}>{trailerTypeModel}</div>;
});

export default AssetCell;
