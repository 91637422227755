import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './ControlsCell.scss';
import DropdownControl, {
    DropdownControlOptionT,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import MoreIcon from 'common/icons/MoreIcon';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import { useTranslation } from 'react-i18next';
import { SpotCarrierLaneT } from 'carrier/store/spot-carrier-lanes/models';
import { useSelector } from 'react-redux';
import { selectPermissions } from 'common/store/auth/selectors';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import DeleteIcon from 'common/icons/DeleteIcon';
import { StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    className?: string;
    spotLane: SpotCarrierLaneT;
    onDeleteSpotLane: (spotLane: SpotCarrierLaneT) => void;
};

const cx = classNames.bind(styles);

const ControlsCell: React.FC<PropsT> = React.memo((props) => {
    const { className, spotLane, onDeleteSpotLane } = props;

    const { t } = useTranslation();

    const permissions = useSelector(selectPermissions);

    const options: Array<DropdownControlOptionT | null | undefined> = [
        permissions.canEditSpotLanes
            ? {
                  label: (
                      <DropdownControlOptionLabel
                          icon={<DeleteIcon fillColor={StyleGuideColorsEnum.tomatoRed} />}
                          label={t('spot-carrier-lanes.list.actions.delete-lanes')}
                      />
                  ),
                  onSelect: () => {
                      onDeleteSpotLane(spotLane);
                  },
              }
            : null,
    ];

    return (
        <DropdownControl
            className={className}
            options={options}
            renderTrigger={(isActive, onClick) => (
                <TransparentTrigger
                    isPressed={isActive}
                    onClick={onClick}
                    leftIcon={<MoreIcon />}
                    reflectionTheme={ReflectionThemeEnum.light}
                />
            )}
            overlayPosition={DropdownOverlayPositionEnum.bottomRight}
        />
    );
});

export default ControlsCell;
