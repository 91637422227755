import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './ImageGalleryModal.scss';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import Modal from 'common/components/Modal/Modal';
import ImageLoader from '../ImageLoader/ImageLoader';
import CloseIcon from 'common/icons/CloseIcon';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';

const cx = classNames.bind(styles);

type PropsT = {
    alt: string | null;
    src: string | null;
    onClose: () => void;
};

const ImageGalleryModal: React.FC<PropsT> = React.memo((props) => {
    const { src, alt, onClose } = props;

    if (!src) {
        return null;
    }

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                <div className={cx('inner')}>
                    <ImageLoader className={cx('image')} alt={alt} src={src} />
                    <div className={cx('action')}>
                        <TransparentTrigger
                            onClick={onClose}
                            leftIcon={<CloseIcon />}
                            reflectionTheme={ReflectionThemeEnum.light}
                        />
                    </div>
                </div>
            </ModalContent>
        </Modal>
    );
});

export default ImageGalleryModal;
