import { QueryFiltersT, QueryFiltersKeysEnum } from './query-models';
import { TabEnum } from './constants';
import isNumber from 'lodash/isNumber';
import { formatPageQuerySort } from 'common/utils/page-sort';
import { FetchTrailersPageQueryT } from 'common/store/trailers/models';
import { TrailersSortEnum, TrailerStatusEnum } from 'common/utils/api/models';
import { LinkValuesEnum } from 'common/components/radio-groups/AssetLinkRadioGroup/constants';
import { GPSTrackedValuesEnum } from 'common/components/radio-groups/GPSTrackerRadioGroup/constants';
import { DedicationValuesEnum } from 'common/components/radio-groups/AssetDedicationRadioGroup/constants';
import { PageSortT } from 'common/utils/query';

const prepareFetchPageQuery = (
    queryFilters: QueryFiltersT,
    sort: PageSortT<TrailersSortEnum> | null,
    activeTab: TabEnum,
): FetchTrailersPageQueryT => {
    let linked;
    if (queryFilters[QueryFiltersKeysEnum.link]) {
        linked = queryFilters[QueryFiltersKeysEnum.link] === LinkValuesEnum.linked;
    }

    let gpsTracker;
    if (queryFilters[QueryFiltersKeysEnum.GPSTracker]) {
        gpsTracker = queryFilters[QueryFiltersKeysEnum.GPSTracker] === GPSTrackedValuesEnum.traked;
    }

    let dedicated;
    if (queryFilters[QueryFiltersKeysEnum.dedication]) {
        dedicated = queryFilters[QueryFiltersKeysEnum.dedication] === DedicationValuesEnum.dedicated;
    }

    const includeStatuses: TrailerStatusEnum[] = [];
    const status = queryFilters[QueryFiltersKeysEnum.status] as TrailerStatusEnum;
    if (status) {
        includeStatuses.push(status);
    }

    let excludeStatuses: TrailerStatusEnum[] = [];
    if (activeTab === TabEnum.archive) {
        excludeStatuses = [
            ...excludeStatuses,
            TrailerStatusEnum.free,
            TrailerStatusEnum.inTransit,
            TrailerStatusEnum.unavailable,
        ];
    } else {
        excludeStatuses = [...excludeStatuses, TrailerStatusEnum.archived];
    }

    const dictTrailerTypeId = queryFilters[QueryFiltersKeysEnum.dictTrailerTypeId];
    const parsedDictTrailerTypeId = dictTrailerTypeId ? parseInt(dictTrailerTypeId, 10) : undefined;

    return {
        includeStatuses,
        excludeStatuses,

        dictTrailerId:
            isNumber(parsedDictTrailerTypeId) && !Number.isNaN(parsedDictTrailerTypeId)
                ? parsedDictTrailerTypeId
                : undefined,

        plateNumber: queryFilters[QueryFiltersKeysEnum.plateNumber] || undefined,

        linked,
        gpsTracked: gpsTracker,
        contracted: dedicated,

        addedByUser: queryFilters[QueryFiltersKeysEnum.createdByUserId] || undefined,
        addDateFrom: queryFilters[QueryFiltersKeysEnum.createdDateFrom] || undefined,
        addDateTo: queryFilters[QueryFiltersKeysEnum.createdDateTo] || undefined,

        lastModifiedByUser: queryFilters[QueryFiltersKeysEnum.lastModifyByUserId] || undefined,
        lastModifiedFrom: queryFilters[QueryFiltersKeysEnum.lastModifyDateFrom] || undefined,
        lastModifiedTo: queryFilters[QueryFiltersKeysEnum.lastModifyDateTo] || undefined,

        sort: formatPageQuerySort(sort),
    };
};

export { prepareFetchPageQuery };
