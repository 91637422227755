import { RequestStatusT } from 'common/utils/request-status';
import { SpotCarrierProfileT } from './models';

export const FETCH_SPOT_CARRIER_PROFILE_REQUEST = 'common/company/FETCH_SPOT_CARRIER_PROFILE_REQUEST';
export const FETCH_SPOT_CARRIER_PROFILE_REQUEST_BEGIN = 'common/company/FETCH_SPOT_CARRIER_PROFILE_REQUEST_BEGIN';
export const FETCH_SPOT_CARRIER_PROFILE_REQUEST_ERROR = 'common/company/FETCH_SPOT_CARRIER_PROFILE_REQUEST_ERROR';
export const FETCH_SPOT_CARRIER_PROFILE_REQUEST_SUCCESS = 'common/company/FETCH_SPOT_CARRIER_PROFILE_REQUEST_SUCCESS';

export type SpotCarrierProfileStateT = Record<
    CarrierIdT,
    {
        profile: SpotCarrierProfileT | null;
        profileRequestStatus: RequestStatusT;
    }
>;

export type FetchSpotCarrierProfileActionT = {
    type: typeof FETCH_SPOT_CARRIER_PROFILE_REQUEST;
    carrierId: CarrierIdT;
    options?: FetchOptionsT;
};

export type FetchSpotCarrierProfileBeginActionT = {
    type: typeof FETCH_SPOT_CARRIER_PROFILE_REQUEST_BEGIN;
    carrierId: CarrierIdT;
};

export type FetchSpotCarrierProfileSuccessActionT = {
    type: typeof FETCH_SPOT_CARRIER_PROFILE_REQUEST_SUCCESS;
    carrierProfile: SpotCarrierProfileT | null;
    carrierId: CarrierIdT;
};

export type FetchSpotCarrierProfileErrorActionT = {
    type: typeof FETCH_SPOT_CARRIER_PROFILE_REQUEST_ERROR;
    error: Error;
    carrierId: CarrierIdT;
};

export type SpotCarrierProfileActionT =
    | FetchSpotCarrierProfileActionT
    | FetchSpotCarrierProfileBeginActionT
    | FetchSpotCarrierProfileSuccessActionT
    | FetchSpotCarrierProfileErrorActionT;
