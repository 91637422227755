import React from 'react';

import { useTranslation } from 'react-i18next';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import Modal from 'common/components/Modal/Modal';
import { SpotCarrierLanesChangesT } from 'carrier/store/spot-carrier-lane-changes/models';
import { useSelector } from 'react-redux';
import { selectSpotCarrierLaneChangesRequest } from 'carrier/store/spot-carrier-lane-changes/selectors';
import BigTrashIcon from 'common/icons/BigTrashIcon';

export type DeleteLanesConfirmationDataT = {
    changes: SpotCarrierLanesChangesT;
};

type PropsT = {
    data: DeleteLanesConfirmationDataT | null;
    onCancel: () => void;
    onClose: () => void;
    onConfirm: (data: DeleteLanesConfirmationDataT) => void;
};

const TEST_SELECTOR = 'delete-lanes-confirmation';

const DeleteLanesConfirmation: React.FC<PropsT> = React.memo((props) => {
    const { data, onCancel, onClose, onConfirm } = props;

    const { t } = useTranslation();

    const requestStatus = useSelector(selectSpotCarrierLaneChangesRequest);

    if (!data) {
        return null;
    }

    const countLanes = data.changes?.length || 0;

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                <ModalDialog
                    width={DEFAULT_MODAL_DIALOG_WIDTH}
                    testSelector={TEST_SELECTOR}
                    actions={[
                        {
                            children: t('common:error-modal.actions.cancel'),
                            theme: ButtonThemeEnum.secondary,
                            isDisabled: requestStatus.loading,
                            testSelector: `${TEST_SELECTOR}_cancel`,
                            onClick: () => {
                                onCancel();
                            },
                        },
                        {
                            children: t('common:error-modal.actions.delete'),
                            theme: ButtonThemeEnum.danger,
                            isDisabled: requestStatus.loading,
                            isLoading: requestStatus.loading,
                            testSelector: `${TEST_SELECTOR}_ok`,
                            onClick: () => {
                                onConfirm(data);
                            },
                        },
                    ]}
                    icon={<BigTrashIcon />}
                    title={t('spot-carrier-lanes.dialogs.delete-lanes.title', {
                        postProcess: 'interval',
                        count: countLanes,
                    })}
                />
            </ModalContent>
        </Modal>
    );
});

export default DeleteLanesConfirmation;
