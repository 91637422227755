import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { TrucksRoutesEnum } from 'carrier/constants';
import TrucksListPage from './TrucksListPage/TrucksListPage';
import TruckDetailsPage from 'common/layouts/TruckDetailsPage/TruckDetailsPage';
import history, { goBackIfHasRePath } from 'common/utils/history';
import { useOpenLeftSidebar } from 'broker-admin/layouts/SideBars/hooks';
import usePartnerContext from 'common/utils/hooks/usePartnerContext';
import SideBars from 'carrier/layouts/SideBars/SideBars';

type PropsT = {};

type QueryT = {};

const TrucksPage: React.FC<PropsT> = React.memo(() => {
    const { partnerId } = usePartnerContext();

    const openLeftSidebar = useOpenLeftSidebar();

    return (
        <>
            <Switch>
                <Route path={TrucksRoutesEnum.list}>
                    <TrucksListPage />
                </Route>
                <Route path={TrucksRoutesEnum.details}>
                    <TruckDetailsPage
                        onClose={() => {
                            goBackIfHasRePath(
                                () => {
                                    history.push({
                                        pathname: TrucksRoutesEnum.list,
                                    });
                                },
                                {
                                    shouldCloseSidebars: true,
                                },
                            );
                        }}
                        onCloseMap={() => {
                            goBackIfHasRePath(() => {
                                history.push({
                                    pathname: TrucksRoutesEnum.list,
                                });
                            });
                        }}
                    />
                </Route>
            </Switch>
            <SideBars />
        </>
    );
});

export default TrucksPage;
