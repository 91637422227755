import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import Modal from 'common/components/Modal/Modal';
import BigQuestionIcon from 'common/icons/BigQuestionIcon';
import { StyleGuideColorsEnum } from 'common/constants';
import { useSelector } from 'react-redux';
import { selectAssignmentRequestStatus } from 'common/store/spot-assets-assigment/selectors';

export type SpotAssetsUnassignmentConfirmationDataT = {
    transportOrderId: TransportOrderIdT;
};

type PropsT = {
    data: SpotAssetsUnassignmentConfirmationDataT | null;
    onConfirmation: (data: SpotAssetsUnassignmentConfirmationDataT) => void;
    onCancel: () => void;
    onClose: () => void;
};

const TEST_SELECTOR = 'spot-assets-unassignment-confirmation';

const SpotAssetsUnassignmentConfirmation: React.FC<PropsT> = React.memo((props) => {
    const { data, onConfirmation, onCancel, onClose } = props;

    const assignmentRequestStatus = useSelector(selectAssignmentRequestStatus);

    const { t } = useTranslation();

    if (!data) {
        return null;
    }

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                <ModalDialog
                    width={DEFAULT_MODAL_DIALOG_WIDTH}
                    testSelector={TEST_SELECTOR}
                    actions={[
                        {
                            children: t('common:error-modal.actions.cancel'),
                            theme: ButtonThemeEnum.secondary,
                            testSelector: `${TEST_SELECTOR}_cancel`,
                            isDisabled: assignmentRequestStatus.loading,
                            onClick: () => {
                                onCancel();
                            },
                        },
                        {
                            children: t('common:error-modal.actions.clear'),
                            theme: ButtonThemeEnum.danger,
                            testSelector: `${TEST_SELECTOR}_ok`,
                            isLoading: assignmentRequestStatus.loading,
                            isDisabled: assignmentRequestStatus.loading,
                            onClick: () => {
                                onConfirmation(data);
                            },
                        },
                    ]}
                    icon={<BigQuestionIcon baseColor={StyleGuideColorsEnum.tomatoRed} />}
                    title={t('common:transport-order-details.spot-assets-unassignment-confirmation.title')}
                    message={t('common:transport-order-details.spot-assets-unassignment-confirmation.description')}
                />
            </ModalContent>
        </Modal>
    );
});

export default SpotAssetsUnassignmentConfirmation;
