import { RequestStatusT } from 'common/utils/request-status';

export const ASSIGNMENT_REQUEST = 'common/driver-assigment/ASSIGNMENT_REQUEST';
export const ASSIGNMENT_REQUEST_BEGIN = 'common/driver-assigment/ASSIGNMENT_REQUEST_BEGIN';
export const ASSIGNMENT_REQUEST_ERROR = 'common/driver-assigment/ASSIGNMENT_REQUEST_ERROR';
export const ASSIGNMENT_REQUEST_SUCCESS = 'common/driver-assigment/ASSIGNMENT_REQUEST_SUCCESS';

export const RE_ASSIGNMENT_REQUEST = 'common/driver-assigment/RE_ASSIGNMENT_REQUEST';
export const RE_ASSIGNMENT_REQUEST_BEGIN = 'common/driver-assigment/RE_ASSIGNMENT_REQUEST_BEGIN';
export const RE_ASSIGNMENT_REQUEST_ERROR = 'common/driver-assigment/RE_ASSIGNMENT_REQUEST_ERROR';
export const RE_ASSIGNMENT_REQUEST_SUCCESS = 'common/driver-assigment/RE_ASSIGNMENT_REQUEST_SUCCESS';

export const RESET = 'common/driver-assigment/RESET';

export type DriverAssigmentStateT = {
    tourId: TourIdT | null;
    assigmentStatus: RequestStatusT;
    reAssigmentStatus: RequestStatusT;
};

export type AssignActionT = {
    type: typeof ASSIGNMENT_REQUEST;
    driverIds: DriverIdT[];
    transportationOrderId: TransportOrderIdT;
};

export type AssignBeginActionT = {
    type: typeof ASSIGNMENT_REQUEST_BEGIN;
};

export type AssignSuccessActionT = {
    type: typeof ASSIGNMENT_REQUEST_SUCCESS;
};

export type AssignErrorActionT = {
    type: typeof ASSIGNMENT_REQUEST_ERROR;
    error: Error;
};

export type ReAssignActionT = {
    type: typeof RE_ASSIGNMENT_REQUEST;
    driverIds: DriverIdT[];
    transportationOrderId: TransportOrderIdT | undefined;
};

export type ReAssignBeginActionT = {
    type: typeof RE_ASSIGNMENT_REQUEST_BEGIN;
};

export type ReAssignSuccessActionT = {
    type: typeof RE_ASSIGNMENT_REQUEST_SUCCESS;
};

export type ReAssignErrorActionT = {
    type: typeof RE_ASSIGNMENT_REQUEST_ERROR;
    error: Error;
};

export type ResetActionT = {
    type: typeof RESET;
};

export type DriverAssigmentActionT =
    | AssignActionT
    | AssignBeginActionT
    | AssignSuccessActionT
    | AssignErrorActionT
    | ReAssignActionT
    | ReAssignBeginActionT
    | ReAssignSuccessActionT
    | ReAssignErrorActionT
    | ResetActionT;
