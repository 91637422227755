import {
    CompanyTransportOrdersStateT,
    FETCH_ORDERS_PAGE_REQUEST_BEGIN,
    FETCH_ORDERS_PAGE_REQUEST_ERROR,
    FETCH_ORDERS_PAGE_REQUEST_SUCCESS,
    RESET_ORDERS,
    TransportOrdersActionT,
    TransportOrdersStateT,
} from './types';
import requestStatus from 'common/utils/request-status';
import { pageBeginReducer, pageSuccessReducer, resetPagesCacheReducer } from 'common/utils/pagination/reducers';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

export const initialCompanyTransportOrdersState: CompanyTransportOrdersStateT = {
    pages: [],
    query: null,
    total: null,
    byId: {},
};

const initialState: TransportOrdersStateT = {};

export default (
    state = initialState,
    action: TransportOrdersActionT | DestroySessionActionT,
): TransportOrdersStateT => {
    switch (action.type) {
        case FETCH_ORDERS_PAGE_REQUEST_BEGIN: {
            const { pageNumber, query, companyId } = action;

            const companyState = state[companyId] || initialCompanyTransportOrdersState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    ...pageBeginReducer(companyState, pageNumber, query),
                },
            };
        }
        case FETCH_ORDERS_PAGE_REQUEST_SUCCESS: {
            const { query, pageNumber, pageResponse, companyId } = action;

            const companyState = state[companyId] || initialCompanyTransportOrdersState;

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    ...pageSuccessReducer(companyState, pageNumber, query, pageResponse),
                },
            };
        }
        case FETCH_ORDERS_PAGE_REQUEST_ERROR: {
            const { error, pageNumber, companyId } = action;

            const companyState = state[companyId] || initialCompanyTransportOrdersState;

            const newPages = [...companyState.pages];
            newPages.splice(pageNumber, 1, {
                ...companyState.pages[pageNumber],
                requestStatus: requestStatus.setError(error),
            });

            return {
                ...state,
                [companyId]: {
                    ...companyState,
                    pages: newPages,
                },
            };
        }
        case RESET_ORDERS: {
            const { companyId, mutationListOptions } = action;

            const companyTransportOrdersState = state[companyId] || initialCompanyTransportOrdersState;

            return {
                ...initialState,
                [companyId]: {
                    ...initialCompanyTransportOrdersState,
                    ...resetPagesCacheReducer(
                        initialCompanyTransportOrdersState,
                        companyTransportOrdersState,
                        mutationListOptions,
                    ),
                },
            };
        }

        case DESTROY_SESSION: {
            return {
                ...initialState,
            };
        }
        default:
            return state;
    }
};
