import * as React from 'react';
import { useMemo } from 'react';

import classNames from 'classnames/bind';

import styles from './BidDetails.scss';
import { useTranslation } from 'react-i18next';
import Money from 'common/components/Money/Money';
import { CurrencyEnum, DEFAULT_ICON_SIZE, StyleGuideColorsEnum, UNIT } from 'common/constants';
import CloseIcon from 'common/icons/CloseIcon';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import { SpotBidT } from 'carrier/utils/api/carrier-tranziit/models';
import { BidStatusEnum } from 'carrier/utils/api/spot-carrier-tranziit/models';
import SpotBidStatusPill from 'common/components/status-pill/SpotBidStatusPill/SpotBidStatusPill';
import ExpandIcon from 'common/icons/ExpandIcon';
import cs from 'classnames';
import InfoTable, { InfoTableRowT } from 'design-system/components/InfoTable/InfoTable';
import UserLinkFormatter from 'design-system/components/InfoTable/formatters/UserLinkFormatter/UserLinkFormatter';
import CommentFormatter from 'design-system/components/InfoTable/formatters/CommentFormatter/CommentFormatter';
import DateIntervalFormatter from 'design-system/components/InfoTable/formatters/DateIntervalFormatter/DateIntervalFormatter';
import AccountIcon from 'common/icons/AccountIcon';
import CommentIcon from 'common/icons/CommentIcon';
import TimeWindowIcon from 'common/icons/TimeWindowIcon';
import SpotBidStatusDescriptionTooltip from 'carrier/components/status-description-tooltip/SpotBidStatusDescriptionTooltip/SpotBidStatusDescriptionTooltip';
import ClickInterceptorLabel from 'common/components/ClickInterceptorLabel/ClickInterceptorLabel';
import { availableRejectBidStatusesSet } from 'carrier/store/spot-request-details/constants';
import { logWarning } from 'common/utils/logger';

type PropsT = {
    isActiveBid: boolean;
    bid: SpotBidT | null | undefined;
    onRejectBid: (bidId: BidIdT) => void;
    onOpenUserDetails: (userId: UserIdT | null) => void;
    className?: string;
};

const cx = classNames.bind(styles);

const BidDetails: React.FC<PropsT> = React.memo((props) => {
    const { isActiveBid, bid, onRejectBid, onOpenUserDetails, className } = props;

    const [isOpenedDetails, setIsOpenedDetails] = React.useState<boolean>(false);

    const handleTriggerClick = () => {
        setIsOpenedDetails(!isOpenedDetails);
    };

    const { t } = useTranslation();

    const canRejectBid = availableRejectBidStatusesSet.has(bid?.status as BidStatusEnum);

    const bidDetails: Array<InfoTableRowT | null> = useMemo(
        () => [
            {
                icon: (
                    <AccountIcon
                        strokeColor={StyleGuideColorsEnum.gray}
                        fillColor={StyleGuideColorsEnum.light}
                        size={DEFAULT_ICON_SIZE}
                    />
                ),
                name: t('bids.details.placed-by'),
                value: (
                    <UserLinkFormatter
                        fullName={bid?.acceptedByName}
                        userId={bid?.acceptedById}
                        onOpenUserDetails={onOpenUserDetails}
                    />
                ),
                isBoldValue: true,
                emptyValue: t('common:info-table.placeholders.empty'),
                testSelector: 'upload-user',
            },
            {
                icon: <TimeWindowIcon strokeColor={StyleGuideColorsEnum.gray} fillColor={StyleGuideColorsEnum.light} />,
                name: t('bids.details.valid'),
                value: (
                    <DateIntervalFormatter
                        startDate={bid?.bidDate}
                        endDate={bid?.lifeTime}
                        format="D MMM YYYY, HH:mm"
                    />
                ),
                isBoldValue: true,
                emptyValue: t('common:info-table.placeholders.empty'),
                testSelector: 'upload-date',
            },
            bid?.comment
                ? {
                      icon: (
                          <CommentIcon strokeColor={StyleGuideColorsEnum.gray} fillColor={StyleGuideColorsEnum.light} />
                      ),
                      name: t('bids.details.comment'),
                      value: <CommentFormatter comment={bid.comment} />,
                      isBoldValue: true,
                      emptyValue: t('common:info-table.placeholders.empty'),
                      testSelector: 'upload-date',
                  }
                : null,
        ],
        [bid, onOpenUserDetails],
    );

    if (!bid) {
        return null;
    }

    return (
        <div className={cs(cx('inner'), className)}>
            <div
                className={cx('row', 'head', {
                    'head--isNarrow': !isActiveBid,
                    'head--isOpened': isOpenedDetails,
                })}
                onClick={handleTriggerClick}
            >
                <div className={cx('main-details')}>
                    <div
                        className={cx('price', {
                            'price--unactive': !isActiveBid,
                        })}
                    >
                        <Money amount={bid.bidPrice} currency={CurrencyEnum.EUR} />
                    </div>
                    <SpotBidStatusDescriptionTooltip status={bid.status}>
                        <SpotBidStatusPill isSymmetrical status={bid.status} className={cx('status')} />
                    </SpotBidStatusDescriptionTooltip>
                    <div onClick={handleTriggerClick}>
                        <ExpandIcon fillColor={StyleGuideColorsEnum.brandAccent} isInvert={isOpenedDetails} />
                    </div>
                </div>
                {canRejectBid && (
                    <ClickInterceptorLabel>
                        <Tooltip
                            className={cx('reject')}
                            position={TooltipPositionEnum.topCenter}
                            theme={TooltipThemeEnum.black}
                            tooltipNode={
                                <TooltipContent
                                    width={16 * UNIT}
                                    isCenter
                                    theme={TooltipContentThemeEnum.black}
                                    isNoWrap
                                >
                                    {t('bids.actions.reject')}
                                </TooltipContent>
                            }
                        >
                            {() => (
                                <TransparentTrigger
                                    onClick={() => {
                                        if (!bid?.id) {
                                            logWarning(`Failed to reject bid, empty bid.id`);
                                            return;
                                        }

                                        onRejectBid(bid.id);
                                    }}
                                    leftIcon={<CloseIcon />}
                                    spaces="xs"
                                    reflectionTheme={ReflectionThemeEnum.light}
                                />
                            )}
                        </Tooltip>
                    </ClickInterceptorLabel>
                )}
            </div>
            {isOpenedDetails && (
                <div className={cx('body')}>
                    <InfoTable shouldRenderIcons rows={bidDetails} testSelector="bid-details" />
                </div>
            )}
        </div>
    );
});

export default BidDetails;
