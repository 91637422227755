import * as React from 'react';

import classNames from 'classnames/bind';
import styles from './TrailerVariantCell.scss';
import DropdownControl, {
    DropdownControlOptionT,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import { DropdownOverlayPositionEnum } from 'design-system/components/dropdowns/constants';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import { useTranslation } from 'react-i18next';
import { SpotCarrierLaneT } from 'carrier/store/spot-carrier-lanes/models';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import { SpotCarrierLanesTrailerVariantEnum } from 'carrier/store/spot-carrier-lane-changes/models';
import ArrowsIcon from 'common/icons/ArrowsIcon';
import DoneIcon from 'common/icons/DoneIcon';

type PropsT = {
    spotLane: SpotCarrierLaneT;
    onChangeTrailerVariant: (spotLane: SpotCarrierLaneT, trailerVariant: SpotCarrierLanesTrailerVariantEnum) => void;
};

const ALLOWED_TRAILER_VARIANTS: Array<SpotCarrierLanesTrailerVariantEnum> = [
    SpotCarrierLanesTrailerVariantEnum.all,
    SpotCarrierLanesTrailerVariantEnum.box,
    SpotCarrierLanesTrailerVariantEnum.tilt,
];

const TRAILER_VARIANT_LABEL_MAP: Record<SpotCarrierLanesTrailerVariantEnum, string> = {
    [SpotCarrierLanesTrailerVariantEnum.all]: 'spot-carrier-lanes.list.table.trailer-type.all',
    [SpotCarrierLanesTrailerVariantEnum.box]: 'spot-carrier-lanes.list.table.trailer-type.box',
    [SpotCarrierLanesTrailerVariantEnum.tilt]: 'spot-carrier-lanes.list.table.trailer-type.tilt',
};

const cx = classNames.bind(styles);

const TrailerVariantCell: React.FC<PropsT> = React.memo((props) => {
    const { spotLane, onChangeTrailerVariant } = props;

    const { t } = useTranslation();

    const options: Array<DropdownControlOptionT | null | undefined> = ALLOWED_TRAILER_VARIANTS.map((trailerVariant) => {
        const isSelected = trailerVariant === spotLane.trailerType;

        return {
            label: (
                <DropdownControlOptionLabel
                    label={t(TRAILER_VARIANT_LABEL_MAP[trailerVariant])}
                    description={
                        isSelected ? (
                            <DoneIcon
                                fillColor={StyleGuideColorsEnum.charcoal}
                                size={DEFAULT_ICON_SIZE}
                                className={cx('icon')}
                            />
                        ) : null
                    }
                />
            ),
            onSelect: () => {
                if (isSelected) {
                    return;
                }

                onChangeTrailerVariant(spotLane, trailerVariant);
            },
        };
    });

    return (
        <DropdownControl
            options={options}
            renderTrigger={(isActive, onClick) => (
                <TransparentTrigger
                    className={cx('trigger')}
                    isPressed={isActive}
                    onClick={onClick}
                    rightIcon={<ArrowsIcon />}
                    label={t(TRAILER_VARIANT_LABEL_MAP[spotLane.trailerType])}
                    reflectionTheme={ReflectionThemeEnum.light}
                />
            )}
            overlayClassName={cx('overlay')}
            overlayPosition={DropdownOverlayPositionEnum.bottomRight}
        />
    );
});

export default TrailerVariantCell;
