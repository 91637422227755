import { put, select, takeEvery } from 'redux-saga/effects';
import {
    FETCH_SPOT_REQUEST_DETAILS_REQUEST,
    FETCH_SPOT_TOUR_DETAILS_REQUEST,
    FetchSpotRequestDetailsRequestActionT,
    FetchSpotTourDetailsRequestActionT,
} from './types';
import {
    fetchSpotRequestDetailsBegin,
    fetchSpotRequestDetailsError,
    fetchSpotRequestDetailsSuccess,
    fetchSpotTourDetails,
    fetchSpotTourDetailsBegin,
    fetchSpotTourDetailsError,
    fetchSpotTourDetailsSuccess,
} from './actions';
import { selectSpotRequestDetails } from './selectors';
import checkNeedRequest from 'common/utils/check-need-request';
import spotCarrierTranziitApi from 'carrier/utils/api/spot-carrier-tranziit/api';
import carrierTranziitApi from 'carrier/utils/api/carrier-tranziit/api';
import { spotInboxRequestsStatsRefreshChannel } from 'carrier/store/spot-inbox-requests-stats/channels';

function* fetchSpotRequestDetailsSaga(action: FetchSpotRequestDetailsRequestActionT): WrapGeneratorT<void> {
    const { id, options } = action;

    const { fetchDetailsRequestStatus }: ReReturnT<typeof selectSpotRequestDetails> = yield select(
        selectSpotRequestDetails(id),
    );
    if (!checkNeedRequest(fetchDetailsRequestStatus, options)) {
        return;
    }

    yield put(fetchSpotRequestDetailsBegin(id));

    const [error, details]: ReturnApiT<typeof spotCarrierTranziitApi.fetchSpotRequestDetails> =
        yield spotCarrierTranziitApi.fetchSpotRequestDetails(id);
    if (error) {
        yield put(fetchSpotRequestDetailsError(id, error));
    } else {
        yield put(fetchSpotRequestDetailsSuccess(id, details));
    }

    const tourId = details?.tourId;
    if (tourId) {
        yield put(fetchSpotTourDetails(id, tourId, options));
    }

    spotInboxRequestsStatsRefreshChannel.emit({});
}

function* fetchSpotTourDetailsSaga(action: FetchSpotTourDetailsRequestActionT): WrapGeneratorT<void> {
    const { spotRequestId, tourId, options } = action;

    const { fetchSpotTourDetailsRequestStatus }: ReReturnT<typeof selectSpotRequestDetails> = yield select(
        selectSpotRequestDetails(spotRequestId),
    );
    if (!checkNeedRequest(fetchSpotTourDetailsRequestStatus, options)) {
        return;
    }

    yield put(fetchSpotTourDetailsBegin(spotRequestId));

    const [error, details]: ReturnApiT<typeof carrierTranziitApi.fetchSpotTourDetails> =
        yield carrierTranziitApi.fetchSpotTourDetails(tourId);
    if (error) {
        yield put(fetchSpotTourDetailsError(spotRequestId, error));
    } else {
        yield put(fetchSpotTourDetailsSuccess(spotRequestId, details));
    }
}

function* spotRequestDetailsSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_SPOT_REQUEST_DETAILS_REQUEST, fetchSpotRequestDetailsSaga);
    yield takeEvery(FETCH_SPOT_TOUR_DETAILS_REQUEST, fetchSpotTourDetailsSaga);
}

export default spotRequestDetailsSaga;
