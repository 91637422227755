import { AssignmentVehiclesSuggestItemT, LinksT, TrailerAssignmentInfoT, TruckAssignmentInfoT } from './assignment';
import { RequestStatusT } from 'common/utils/request-status';

import { FindTrailerQueryT, FindTruckQueryT, UserSelectionT } from './models';
import { CountryCodeT } from 'common/utils/api/models';

export const CHANGE_USER_SELECTION = 'common/spot-assets-assigment/CHANGE_USER_SELECTION';

export const FIND_TRAILER_REQUEST = 'common/spot-assets-assigment/FIND_TRAILER_REQUEST';
export const FIND_TRAILER_REQUEST_BEGIN = 'common/spot-assets-assigment/FIND_TRAILER_REQUEST_BEGIN';
export const FIND_TRAILER_REQUEST_ERROR = 'common/spot-assets-assigment/FIND_TRAILER_REQUEST_ERROR';
export const FIND_TRAILER_REQUEST_SUCCESS = 'common/spot-assets-assigment/FIND_TRAILER_REQUEST_SUCCESS';

export const FIND_TRUCK_REQUEST = 'common/spot-assets-assigment/FIND_TRUCK_REQUEST';
export const FIND_TRUCK_REQUEST_BEGIN = 'common/spot-assets-assigment/FIND_TRUCK_REQUEST_BEGIN';
export const FIND_TRUCK_REQUEST_ERROR = 'common/spot-assets-assigment/FIND_TRUCK_REQUEST_ERROR';
export const FIND_TRUCK_REQUEST_SUCCESS = 'common/spot-assets-assigment/FIND_TRUCK_REQUEST_SUCCESS';

export const ASSIGN = 'common/spot-assets-assigment/ASSIGN';
export const UNASSIGN = 'common/spot-assets-assigment/UNASSIGN';
export const ASSIGNMENT_REQUEST_BEGIN = 'common/spot-assets-assigment/ASSIGNMENT_REQUEST_BEGIN';
export const ASSIGNMENT_REQUEST_ERROR = 'common/spot-assets-assigment/ASSIGNMENT_REQUEST_ERROR';
export const ASSIGNMENT_REQUEST_SUCCESS = 'common/spot-assets-assigment/ASSIGNMENT_REQUEST_SUCCESS';

export type SpotAssetsAssignmentStateT = {
    userSelection: UserSelectionT;
    truckById: Record<TruckIdT, TruckAssignmentInfoT>;
    truckSuggest: {
        query: FindTruckQueryT | null;
        requestStatus: RequestStatusT;
        ids: Array<TruckIdT>;
    };
    trailerById: Record<TrailerIdT, TrailerAssignmentInfoT>;
    trailerSuggest: {
        query: FindTrailerQueryT | null;
        requestStatus: RequestStatusT;
        ids: Array<TrailerIdT>;
    };
    links: LinksT;
    assigmentStatus: RequestStatusT;
};

export type ChangeUserSelectionActionT = {
    type: typeof CHANGE_USER_SELECTION;
    changes: Partial<UserSelectionT>;
    transportOrderId: TransportOrderIdT | null;
};

export type FindTrailerActionT = {
    type: typeof FIND_TRAILER_REQUEST;
    query: FindTrailerQueryT;
};

export type FindTrailerBeginActionT = {
    type: typeof FIND_TRAILER_REQUEST_BEGIN;
    query: FindTrailerQueryT;
};

export type FindTrailerSuccessActionT = {
    type: typeof FIND_TRAILER_REQUEST_SUCCESS;
    trailersSuggestItems: AssignmentVehiclesSuggestItemT[];
    query: FindTrailerQueryT;
};

export type FindTrailerErrorActionT = {
    type: typeof FIND_TRAILER_REQUEST_ERROR;
    error: Error;
    query: FindTrailerQueryT;
};

export type FindTruckActionT = {
    type: typeof FIND_TRUCK_REQUEST;
    query: FindTruckQueryT;
};

export type FindTruckBeginActionT = {
    type: typeof FIND_TRUCK_REQUEST_BEGIN;
    query: FindTruckQueryT;
};

export type FindTruckSuccessActionT = {
    type: typeof FIND_TRUCK_REQUEST_SUCCESS;
    trucksSuggestItems: AssignmentVehiclesSuggestItemT[];
    query: FindTruckQueryT;
};

export type FindTruckErrorActionT = {
    type: typeof FIND_TRUCK_REQUEST_ERROR;
    error: Error;
    query: FindTruckQueryT;
};

export type AssignActionT = {
    type: typeof ASSIGN;
    trailerId: TrailerIdT;
    truckId: TruckIdT;
    prohibitedCountries?: Array<CountryCodeT>;
    transportOrderId: TransportOrderIdT;
};

export type UnAssignActionT = {
    type: typeof UNASSIGN;
    transportOrderId: TransportOrderIdT;
};

export type AssignBeginActionT = {
    type: typeof ASSIGNMENT_REQUEST_BEGIN;
};

export type AssignSuccessActionT = {
    type: typeof ASSIGNMENT_REQUEST_SUCCESS;
};

export type AssignErrorActionT = {
    type: typeof ASSIGNMENT_REQUEST_ERROR;
    error: Error;
};

export type SpotAssetsAssignmentActionT =
    | ChangeUserSelectionActionT
    | FindTrailerActionT
    | FindTrailerBeginActionT
    | FindTrailerSuccessActionT
    | FindTrailerErrorActionT
    | FindTruckActionT
    | FindTruckBeginActionT
    | FindTruckSuccessActionT
    | FindTruckErrorActionT
    | AssignActionT
    | UnAssignActionT
    | AssignBeginActionT
    | AssignSuccessActionT
    | AssignErrorActionT;
