import { SharableStateT } from 'common/store/models';

import { HashPapersStateT } from './types';
import { initialHashPapersState } from './reducer';
import { getPapersHash } from 'common/store/papers/utils';

const selectHashPapersState = (tourId: TourIdT | null, state: SharableStateT): HashPapersStateT => {
    if (!tourId) {
        return initialHashPapersState;
    }

    const hash = getPapersHash(tourId);
    return state.papers[hash] || initialHashPapersState;
};

export const selectFetchPapersRequest =
    (tourId: TourIdT | null) =>
    (state: SharableStateT): HashPapersStateT['fetchRequest'] =>
        selectHashPapersState(tourId, state).fetchRequest;

export const selectParersList =
    (tourId: TourIdT | null) =>
    (state: SharableStateT): HashPapersStateT['list'] =>
        selectHashPapersState(tourId, state).list;

export const selectDeletePaperRequest =
    (tourId: TourIdT | null) =>
    (state: SharableStateT): HashPapersStateT['deleteRequest'] =>
        selectHashPapersState(tourId, state).deleteRequest;

export const selectDeletePaperQuery =
    (tourId: TourIdT | null) =>
    (state: SharableStateT): HashPapersStateT['deleteQuery'] =>
        selectHashPapersState(tourId, state).deleteQuery;

export const selectUploadPaperRequest =
    (tourId: TourIdT | null) =>
    (state: SharableStateT): HashPapersStateT['uploadRequest'] =>
        selectHashPapersState(tourId, state).uploadRequest;
