import * as React from 'react';
import HorizontalColumnsLayout from '../HorizontalColumnsLayout/HorizontalColumnsLayout';
import useGoogleMaps from 'common/utils/hooks/useGoogleMaps';
import GoogleMapContext from 'common/contexts/google-map-context';
import classNames from 'classnames/bind';
import styles from './DetailsLayout.scss';
import CalendarIcon from 'common/icons/CalendarIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import ArrowsIcon from 'common/icons/ArrowsIcon';
import TransparentTrigger, { ReflectionThemeEnum } from 'common/components/TransparentTrigger/TransparentTrigger';
import TabsLabel from 'common/components/TabsLabel/TabsLabel';
import TabLabel from 'common/components/TabsLabel/TabLabel/TabLabel';
import { TabsThemesEnum } from 'common/components/TabsLabel/constants';
import SideBarLayoutContextWrap from '../LeftMenuLayout/SideBarLayout/SideBarLayoutContextWrap/SideBarLayoutContextWrap';
import RightSidebarLayout from 'common/layouts/RightSidebarLayout/RightSidebarLayout';

const cx = classNames.bind(styles);

type CloseCallbackT = () => void;
type ZoomCallbackT = () => void;

type PropsT = {
    className?: string;
    isNarrow?: boolean;
    sideBarTitle: string;
    sideBarNode: React.ReactNode;
    mapTitle: string;
    mapNode: React.ReactNode;
    historyTitle: React.ReactNode;
    historyNode: React.ReactNode;
    timelineTitle: string;
    timelineNode: (onClose: CloseCallbackT) => React.ReactNode;
    overlayNode?: React.ReactNode;
};

type TabConfigT = {
    label: React.ReactNode;
    node: React.ReactNode;
};

const DetailsLayout: React.FC<PropsT> = React.memo((props) => {
    const {
        isNarrow,
        sideBarTitle,
        sideBarNode,
        mapTitle,
        mapNode,
        historyTitle,
        historyNode,
        timelineTitle,
        timelineNode,
        overlayNode,
        className,
    } = props;

    const [isShowTimeline, setShowTimeline] = React.useState<boolean>(false);

    const googleMaps = useGoogleMaps();
    const googleMapsContextValue = React.useMemo(() => {
        return {
            googleMaps,
        };
    }, [googleMaps]);

    const closeTimeline: CloseCallbackT = () => {
        setShowTimeline(false);
    };

    const [activeIndexTab, setActiveIndexTab] = React.useState<number>(0);
    const tabs: TabConfigT[] = [
        {
            label: mapTitle,
            node: <GoogleMapContext.Provider value={googleMapsContextValue}>{mapNode}</GoogleMapContext.Provider>,
        },
    ];

    if (historyNode && historyTitle) {
        tabs.push({
            label: historyTitle,
            node: historyNode,
        });
    }

    return (
        <HorizontalColumnsLayout
            className={className}
            isNarrow={isNarrow}
            leftColumnTitle={sideBarTitle}
            leftColumnNode={<SideBarLayoutContextWrap>{sideBarNode}</SideBarLayoutContextWrap>}
            rightColumnTitle={mapTitle}
            rightColumnNode={
                <div className={cx('right-column')}>
                    <div className={cx('tabs-wrap')}>
                        <TabsLabel>
                            {tabs.map((tab, tabIndex) => (
                                <TabLabel
                                    key={tabIndex}
                                    theme={TabsThemesEnum.light}
                                    onClick={() => {
                                        setActiveIndexTab(tabIndex);
                                    }}
                                    isActive={tabIndex === activeIndexTab}
                                >
                                    {tab.label}
                                </TabLabel>
                            ))}
                        </TabsLabel>
                        <div className={cx('tab')}>{tabs[activeIndexTab].node}</div>
                    </div>
                    <div className={cx('timeline-trigger')}>
                        <TransparentTrigger
                            onClick={() => setShowTimeline(!isShowTimeline)}
                            isPressed={isShowTimeline}
                            label={timelineTitle}
                            className={className}
                            leftIcon={
                                <CalendarIcon
                                    size={DEFAULT_ICON_SIZE}
                                    fillColor={StyleGuideColorsEnum.brandAccent}
                                    strokeColor={StyleGuideColorsEnum.brandDark}
                                />
                            }
                            reflectionTheme={ReflectionThemeEnum.light}
                            rightIcon={<ArrowsIcon />}
                        />
                    </div>
                    {isShowTimeline && <RightSidebarLayout>{timelineNode(closeTimeline)}</RightSidebarLayout>}
                </div>
            }
            overlayNode={overlayNode}
        />
    );
});

export default DetailsLayout;
