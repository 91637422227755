import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './TransportationOrderId.scss';

const cx = classNames.bind(styles);

type PropsT = {
    number: string;
};

const TransportationOrderId: React.FC<PropsT> = React.memo((props) => {
    const { number } = props;

    return <span className={cx('id')}>{number}</span>;
});

export default TransportationOrderId;
