import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './TrucksTable.scss';
import { useTranslation } from 'react-i18next';
import Table, { TableColumnT, TableRowMetaT, TableRowModsEnum } from 'common/components/Table/Table';
import { TruckT } from 'common/store/trucks/models';
import StatusCell from './cell-renderers/StatusCell/StatusCell';
import ModelCell from './cell-renderers/ModelCell/ModelCell';
import CheckboxCell from 'common/components/Table/cell-renderers/CheckboxCell/CheckboxCell';
import CheckboxHeader from 'common/components/Table/header-renderers/CheckboxHeader/CheckboxHeader';
import MemoizedTable from 'common/components/Table/MemoizedTable/MemoizedTable';
import EventCell from 'common/components/Table/cell-renderers/EventCell/EventCell';
import PlateNumberCell from 'common/components/Table/cell-renderers/PlateNumberCell/PlateNumberCell';
import FlagCell from 'common/components/Table/cell-renderers/FlagCell/FlagCell';
import EmissionClassCell from 'common/layouts/TrucksPageLayout/TrucksTable/cell-renderers/EmissionClassCell/EmissionClassCell';
import { TabEnum } from 'common/layouts/TrucksPageLayout/constants';

const cx = classNames.bind(styles);

type PropsT = {
    trucks: Array<TruckT>;
    className?: string;
    selectedTrucksSet: Set<TruckT>;
    onSelectTrucks: (selectedTrucksSet: Set<TruckT>) => void;
    onSelectRow: (event: React.MouseEvent, truck: TruckT) => void;
    onOpenUserDetails: (userId: UserIdT | null) => void;
    isLoading: boolean;
    canEditTrucks: boolean;
    activeTab: TabEnum;
};

const TrucksTable: React.FC<PropsT> = React.memo((props) => {
    const {
        trucks,
        canEditTrucks,
        className,
        selectedTrucksSet,
        onSelectRow,
        onSelectTrucks,
        isLoading,
        onOpenUserDetails,
        activeTab,
    } = props;

    const { t } = useTranslation();

    const columns: Array<TableColumnT<TruckT, void> | null> = [
        canEditTrucks
            ? {
                  renderHeader: () => (
                      <CheckboxHeader<TruckT>
                          selectedRowsSet={selectedTrucksSet}
                          rows={trucks}
                          onChange={onSelectTrucks}
                      />
                  ),
                  headerClassName: cx('table__header', 'table__header--checkbox'),
                  render: (truck: TruckT) => (
                      <CheckboxCell<TruckT> row={truck} selectedRowsSet={selectedTrucksSet} onChange={onSelectTrucks} />
                  ),
                  className: cx('table__column--checkbox'),
              }
            : null,
        {
            renderHeader: () => t('common:trucks-page.table.columns.plate-number'),
            headerClassName: cx('table__header'),
            render: (truck: TruckT) => <PlateNumberCell plateNumber={truck?.plateNumber} />,
            className: cx('table__column--plate-number'),
        },
        {
            renderHeader: () => t('common:trucks-page.table.columns.model'),
            headerClassName: cx('table__header'),
            render: (truck: TruckT) => <ModelCell truck={truck} />,
            className: cx('table__column--any'),
        },
        {
            renderHeader: () => t('common:trucks-page.table.columns.added-date'),
            headerClassName: cx('table__header'),
            render: (truck: TruckT) => (
                <EventCell
                    userFullName={truck.addedByUserName}
                    date={truck.added}
                    userId={truck.addedBy}
                    byBroker={truck.addedByBroker}
                    openUserDetails={onOpenUserDetails}
                />
            ),
            className: cx('table__column--any'),
        },
        {
            renderHeader: () => t('common:trucks-page.table.columns.emission-class'),
            headerClassName: cx('table__header', 'table__header--emission-class'),
            render: (truck: TruckT) => <EmissionClassCell truck={truck} />,
            className: cx('table__column--model'),
        },
    ];

    if (activeTab === TabEnum.active) {
        // columns.push({
        //     renderHeader: () => t('common:trailers-page.table.columns.linked'),
        //     headerClassName: cx('table__header', 'table__header--linked'),
        //     render: (truck: TruckT) => <FlagCell isShowFlag={!!truck.linked} />,
        //     className: cx('table__column--any'),
        // });

        columns.push({
            renderHeader: () => t('common:trailers-page.table.columns.dedicated'),
            headerClassName: cx('table__header', 'table__header--dedicated'),
            render: (truck: TruckT) => <FlagCell isShowFlag={!!truck.dedicated} />,
            className: cx('table__column--any'),
        });

        columns.push({
            renderHeader: () => t('common:trailers-page.table.columns.GPS'),
            headerClassName: cx('table__header', 'table__header--gps'),
            render: (truck: TruckT) => <FlagCell isShowFlag={!!truck.gpsTrucked} />,
            className: cx('table__column--any'),
        });

        columns.push({
            renderHeader: () => t('common:trucks-page.table.columns.status'),
            headerClassName: cx('table__header'),
            render: (truck: TruckT) => <StatusCell truck={truck} />,
            className: cx('table__column--status'),
        });
    }

    if (activeTab === TabEnum.archive) {
        columns.push({
            renderHeader: () => t('common:trucks-page.table.columns.archived'),
            headerClassName: cx('table__header'),
            render: (truck: TruckT) => (
                <EventCell
                    userFullName={truck.archivedByUserName}
                    date={truck.dateArchived}
                    userId={truck.archivedByUser}
                    openUserDetails={onOpenUserDetails}
                />
            ),
            className: cx('table__column--any'),
        });
    }

    const getRowMods = (meta: TableRowMetaT) => {
        return {
            [TableRowModsEnum.isSelected]: meta.isSelected,
        };
    };

    return (
        <div className={cx('table')}>
            <MemoizedTable<TruckT> tableName="trucks" isLoading={isLoading} rows={trucks}>
                {(rows, isUsedPrevRows) => (
                    <Table<TruckT, void>
                        className={className}
                        selectedRowsSet={selectedTrucksSet}
                        columns={columns}
                        rows={rows}
                        getRowMods={getRowMods}
                        isLoading={isLoading}
                        onSelectRow={onSelectRow}
                        isUsedPrevRows={isUsedPrevRows}
                    />
                )}
            </MemoizedTable>
        </div>
    );
});

export default TrucksTable;
