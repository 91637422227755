import React from 'react';
import cs from 'classnames';
import classNames from 'classnames/bind';

import styles from './SuggestInput.scss';
import Input, { PropsT as InputPropsT } from 'common/components/Input/Input';
import { DropdownOverlayPositionEnum } from '../constants';
import DropdownBaseLayout from '../base/DropdownBaseLayout/DropdownBaseLayout';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';

const cx = classNames.bind(styles);

export type PropsT<OptionT, OptionValueT> = {
    selectedValue: OptionValueT | null;
    initialInputValue?: string;
    options: Array<OptionT>;
    onSelect: (value: OptionValueT | null) => void;
    overlayPosition: DropdownOverlayPositionEnum;
    renderOption: (option: OptionT) => React.ReactNode;
    formatOption: (option: OptionT) => string;
    getOptionValue: (option: OptionT) => OptionValueT | null;
    onChangeQuery: (query: string) => void;
    renderLeftIcon: InputPropsT['renderLeftIcon'];
    rightInputText?: (option: OptionT | null) => React.ReactNode;
    hasError: InputPropsT['hasError'];
    hasWarning: InputPropsT['hasWarning'];
    hasSuccess?: InputPropsT['hasSuccess'];
    placeholder?: InputPropsT['placeholder'];
    onBlur?: InputPropsT['onBlur'];
    onFocus?: InputPropsT['onFocus'];
    onKeyUp?: InputPropsT['onKeyUp'];
    isDisabled?: InputPropsT['isDisabled'];
    isFocused?: InputPropsT['isFocused'];
    hasClearControl?: InputPropsT['hasClearControl'];
    hasOptionsSeparator?: boolean;
    isLoading?: boolean;
    hasChanges?: boolean;
    overlayClassName?: string;
    className?: string;
    isInline?: boolean;
};

const COLD_TIME = 300;

const SuggestInput = <OptionT, OptionValueT>(props: PropsT<OptionT, OptionValueT>) => {
    const {
        renderLeftIcon,
        rightInputText,
        selectedValue,
        isInline,
        initialInputValue,
        className,
        overlayClassName,
        placeholder,
        onSelect,
        options,
        renderOption,
        getOptionValue,
        hasWarning,
        hasError,
        hasSuccess,
        hasOptionsSeparator,
        hasClearControl,
        onChangeQuery,
        overlayPosition,
        formatOption,
        onBlur,
        onFocus,
        onKeyUp,
        isFocused,
        isDisabled,
        isLoading,
        hasChanges,
    } = props;

    const [selectedOption, setSelectedOption] = React.useState<OptionT | null>(null);
    const [inputValue, setInputValue] = React.useState<string>(initialInputValue || '');
    const [isOpen, toggleOpen] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (isDisabled && isOpen) {
            toggleOpen(false);
        }
    }, [isDisabled]);

    const handleClose = (): void => {
        toggleOpen(false);
    };

    const handleChangeQuery = React.useMemo(() => {
        return debounce((inputValue: string): void => {
            onChangeQuery(inputValue);
        }, COLD_TIME);
    }, [onChangeQuery, COLD_TIME]);

    const handleInputChange = (value: string) => {
        setInputValue(value);
        handleChangeQuery(value);

        if (!value) {
            onSelect(null);
        }
    };

    const handleFocus: InputPropsT['onFocus'] = () => {
        toggleOpen(true);

        if (onFocus) {
            onFocus();
        }
    };

    React.useEffect(() => {
        const optionsValue = options.map((option) => getOptionValue(option));
        const selectedOptionIndex = optionsValue.indexOf(selectedValue);
        const nextSelectedOption = selectedOptionIndex !== -1 ? options[selectedOptionIndex] : null;
        const isSameSelectedOption = isEqual(selectedOption, nextSelectedOption);
        if (!isSameSelectedOption) {
            setSelectedOption(nextSelectedOption);
        }
    }, [selectedValue]);

    React.useEffect(() => {
        const optionInputValue = selectedOption ? formatOption(selectedOption) : '';
        setInputValue(optionInputValue);
    }, [selectedOption]);

    const handleBlur = () => {
        if (!inputValue) {
            onSelect(null);
        }

        if (!options.length) {
            onSelect(null);
        }

        if (inputValue && selectedOption) {
            const optionInputValue = formatOption(selectedOption);
            setInputValue(optionInputValue);
        }

        if (onBlur) {
            onBlur();
        }
    };

    const handleOuterEvent = (): void => {
        if (isOpen) {
            handleClose();
            handleBlur();
        }
    };

    const inputRightTextNode = rightInputText ? rightInputText(selectedOption) : null;

    return (
        <DropdownBaseLayout
            isInline={isInline}
            isOpen={isOpen}
            className={className}
            onClose={handleOuterEvent}
            triggerNode={
                <Input
                    renderLeftIcon={
                        renderLeftIcon
                            ? (iconMeta) =>
                                  renderLeftIcon({
                                      ...iconMeta,
                                      hasValue: !!selectedOption,
                                  })
                            : undefined
                    }
                    name="suggest-input"
                    value={inputValue}
                    hasChanges={hasChanges}
                    placeholder={placeholder}
                    onChange={handleInputChange}
                    rightNode={inputRightTextNode}
                    hasWarning={hasWarning}
                    hasError={hasError}
                    hasSuccess={hasSuccess}
                    onFocus={handleFocus}
                    isLoading={isLoading}
                    onKeyUp={onKeyUp}
                    isFocused={isFocused}
                    isDisabled={isDisabled}
                    hasClearControl={hasClearControl}
                />
            }
            overlayPosition={overlayPosition}
            overlayClassName={cs(cx('overlay'), overlayClassName)}
            overlayNode={
                <div className={cx('options')}>
                    {options.map((option, index): React.ReactElement => {
                        const value = getOptionValue(option);

                        const isSelected = selectedValue === value;

                        return (
                            <div
                                key={index}
                                className={cx('option', {
                                    'option--isSelected': isSelected,
                                    'option--hasSeparator': hasOptionsSeparator,
                                })}
                                onClick={(): void => {
                                    onSelect(value);
                                    toggleOpen(false);
                                    handleClose();
                                    if (onBlur) {
                                        onBlur();
                                    }
                                }}
                            >
                                {renderOption(option)}
                            </div>
                        );
                    })}
                </div>
            }
        />
    );
};

export default React.memo(SuggestInput) as typeof SuggestInput;
