import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalContent from 'common/components/Modal/ModalContent/ModalContent';
import ModalDialog, { DEFAULT_MODAL_DIALOG_WIDTH } from 'common/components/Modal/ModalDialog/ModalDialog';
import { ButtonThemeEnum } from 'common/components/Button/Button';
import Modal from 'common/components/Modal/Modal';
import { RequestStatusT } from 'common/utils/request-status';
import BigTrashIcon from 'common/icons/BigTrashIcon';

export type RejectBidConfirmationDataT = {
    spotRequestId: SpotRequestIdT;
    selectedBidId: BidIdT;
};

type PropsT = {
    requestStatus: RequestStatusT;
    data: RejectBidConfirmationDataT | null;
    onConfirmation: (data: RejectBidConfirmationDataT) => void;
    onCancel: () => void;
    onClose: () => void;
};

const TEST_SELECTOR = 'reject-bid-confirmation';

const RejectBidConfirmation: React.FC<PropsT> = React.memo((props) => {
    const { requestStatus, data, onConfirmation, onCancel, onClose } = props;

    const { t } = useTranslation();

    if (!data) {
        return null;
    }

    return (
        <Modal>
            <ModalContent onClose={onClose}>
                <ModalDialog
                    width={DEFAULT_MODAL_DIALOG_WIDTH}
                    testSelector={TEST_SELECTOR}
                    actions={[
                        {
                            children: t('common:error-modal.actions.keep'),
                            theme: ButtonThemeEnum.secondary,
                            testSelector: `${TEST_SELECTOR}_cancel`,
                            isDisabled: requestStatus.loading,
                            onClick: () => {
                                onCancel();
                            },
                        },
                        {
                            children: t('common:error-modal.actions.reject'),
                            theme: ButtonThemeEnum.danger,
                            testSelector: `${TEST_SELECTOR}_ok`,
                            isLoading: requestStatus.loading,
                            isDisabled: requestStatus.loading,
                            onClick: () => {
                                onConfirmation(data);
                            },
                        },
                    ]}
                    icon={<BigTrashIcon />}
                    title={t('spot-request-details.dialogs.reject-bid-confirmation.title')}
                    message={t('spot-request-details.dialogs.reject-bid-confirmation.description')}
                />
            </ModalContent>
        </Modal>
    );
});

export default RejectBidConfirmation;
