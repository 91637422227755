import { put, select, takeEvery } from 'redux-saga/effects';
import {
    DELETE_PAPER_REQUEST,
    DeletePaperActionT,
    FETCH_PAPERS_REQUEST,
    FetchPapersActionT,
    UPLOAD_PAPER_REQUEST,
    UploadPaperActionT,
} from './types';
import {
    deletePaperBegin,
    deletePaperError,
    deletePaperSuccess,
    fetchPapers,
    fetchPapersBegin,
    fetchPapersError,
    fetchPapersSuccess,
    uploadPaperBegin,
    uploadPaperError,
    uploadPaperSuccess,
} from './actions';
import commonTranziitApi from 'common/utils/api/tranziit/common-tranziit-api';
import brokerTranziitApi from 'broker-admin/utils/api/broker-tranziit/api';
import { getPapersHash } from 'common/store/papers/utils';
import { DEFAULT_DICT_DOCUMENT_ID, PAPERS_TTL } from 'common/store/papers/constants';
import {
    selectDeletePaperRequest,
    selectFetchPapersRequest,
    selectUploadPaperRequest,
} from 'common/store/papers/selectors';
import { logWarning } from 'common/utils/logger';
import checkNeedRequest from 'common/utils/check-need-request';
import { addAlert } from 'common/store/alerts/actions';
import { CommonAlertTypeEnum, CommonAnyAlert } from 'common/components/toasts/AlertToastsManager/models';

function* fetchPapersSaga(action: FetchPapersActionT): WrapGeneratorT<void> {
    const { isBroker, tourId, options } = action;

    const requestStatus: ReReturnT<typeof selectFetchPapersRequest> = yield select(selectFetchPapersRequest(tourId));

    const isNeedRequest = checkNeedRequest(requestStatus, options, PAPERS_TTL);
    if (!isNeedRequest) {
        return;
    }

    const hash = getPapersHash(tourId);

    yield put(fetchPapersBegin(hash));

    let response: ReturnApiT<typeof commonTranziitApi.fetchPapers | typeof brokerTranziitApi.fetchPartnerPapers>;
    if (isBroker) {
        response = yield brokerTranziitApi.fetchPartnerPapers(tourId);
    } else {
        response = yield commonTranziitApi.fetchPapers(tourId);
    }

    const [error, result] = response;

    if (error) {
        yield put(fetchPapersError(error, hash));
        return;
    }

    yield put(fetchPapersSuccess(hash, result || []));
}

function* refreshPapersSaga(tourId: TourIdT, isBroker: boolean): WrapGeneratorT<void> {
    yield put(
        fetchPapers(tourId, isBroker, {
            isForceUpdate: true,
        }),
    );
}

function* uploadPaperSaga(action: UploadPaperActionT): WrapGeneratorT<void> {
    const { isBroker, tourId, file } = action;

    const requestStatus: ReReturnT<typeof selectUploadPaperRequest> = yield select(selectUploadPaperRequest(tourId));
    if (requestStatus.loading) {
        logWarning('already uploading paper, please wait!');
        return;
    }

    const hash = getPapersHash(tourId);

    yield put(uploadPaperBegin(hash));

    let response: ReturnApiT<typeof commonTranziitApi.uploadPaper | typeof brokerTranziitApi.uploadPartnerPaper>;
    if (isBroker) {
        response = yield brokerTranziitApi.uploadPartnerPaper(tourId, DEFAULT_DICT_DOCUMENT_ID, file);
    } else {
        response = yield commonTranziitApi.uploadPaper(tourId, DEFAULT_DICT_DOCUMENT_ID, file);
    }
    const [error] = response;

    if (error) {
        yield put(uploadPaperError(error, hash));
        return;
    }

    yield refreshPapersSaga(tourId, isBroker);

    yield put(uploadPaperSuccess(hash));

    yield put(
        addAlert(
            new CommonAnyAlert({
                type: CommonAlertTypeEnum.paperAdded,
                data: {},
            }),
        ),
    );
}

function* deletePaperSaga(action: DeletePaperActionT): WrapGeneratorT<void> {
    const { isBroker, tourId, paperId } = action;

    const requestStatus: ReReturnT<typeof selectDeletePaperRequest> = yield select(selectDeletePaperRequest(tourId));
    if (requestStatus.loading) {
        logWarning('already deleting paper, please wait!');
        return;
    }

    const hash = getPapersHash(tourId);

    yield put(deletePaperBegin(hash, paperId));

    let response: ReturnApiT<typeof commonTranziitApi.deletePaper | typeof brokerTranziitApi.deletePartnerPaper>;
    if (isBroker) {
        response = yield brokerTranziitApi.deletePartnerPaper(tourId, paperId);
    } else {
        response = yield commonTranziitApi.deletePaper(tourId, paperId);
    }
    const [error] = response;

    if (error) {
        yield put(deletePaperError(error, hash));
        return;
    }

    yield refreshPapersSaga(tourId, isBroker);

    yield put(deletePaperSuccess(hash));

    yield put(
        addAlert(
            new CommonAnyAlert({
                type: CommonAlertTypeEnum.paperDeleted,
                data: {},
            }),
        ),
    );
}

function* papersSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_PAPERS_REQUEST, fetchPapersSaga);
    yield takeEvery(UPLOAD_PAPER_REQUEST, uploadPaperSaga);
    yield takeEvery(DELETE_PAPER_REQUEST, deletePaperSaga);
}

export default papersSaga;
