import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './TimeLeftForStart.scss';
import { useTranslation } from 'react-i18next';
import { formatDiff, getTimeLeft, MS_IN_HOUR, parseDiff } from 'common/utils/time';

const cx = classNames.bind(styles);

type StatusMessagePropsT = {
    diff: number | null;
    alreadyStartedLabel: React.ReactNode;
    deadlineLabel: React.ReactNode;
};

const ASSIGNMENT_WARNING = 24 * MS_IN_HOUR;

const StatusMessage: React.FC<StatusMessagePropsT> = (props) => {
    const { diff, alreadyStartedLabel, deadlineLabel } = props;

    const { t } = useTranslation();

    if (diff === null) {
        return null;
    }

    if (diff <= 0) {
        return <div className={cx('already-started')}>{alreadyStartedLabel}</div>;
    }

    const parsedDiff = parseDiff(diff);

    const formattedDiff = formatDiff(t, parsedDiff);

    return (
        <div
            className={cx('status', {
                'status--is-warning': diff <= ASSIGNMENT_WARNING,
            })}
        >
            <div className={cx('time')}>{formattedDiff}</div>
            <div className={cx('message')}>{deadlineLabel}</div>
        </div>
    );
};

type PropsT = {
    className?: string;
    deadline: string | null | undefined;
    alreadyStartedLabel: React.ReactNode;
    deadlineLabel: React.ReactNode;
    renderActions: (isDisabled: boolean) => React.ReactNode;
    shouldDisableAfterDeadline?: boolean;
};

const TimeLeftForStart: React.FC<PropsT> = React.memo((props) => {
    const { deadline, alreadyStartedLabel, deadlineLabel, shouldDisableAfterDeadline, renderActions } = props;

    const timeLeft = getTimeLeft(deadline);
    const diff = timeLeft !== null ? timeLeft : null;

    const isDisabled = !!shouldDisableAfterDeadline && diff !== null && diff <= 0;

    return (
        <div className={cx('content')}>
            <div className={cx('status-wrap')}>
                {diff !== null && (
                    <StatusMessage
                        diff={diff}
                        alreadyStartedLabel={alreadyStartedLabel}
                        deadlineLabel={deadlineLabel}
                    />
                )}
            </div>
            <div className={cx('actions')}>{renderActions(isDisabled)}</div>
        </div>
    );
});

export default TimeLeftForStart;
