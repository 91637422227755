import { FetchSpotCarrierLanesPageQueryT, SpotCarrierLaneT } from './models';

import { PageResponseT, PaginationStateT } from 'common/utils/pagination/models';

export const FETCH_SPOT_CARRIER_LANES_PAGE_REQUEST = 'carrier/spot-carrier-lanes/FETCH_SPOT_CARRIER_LANES_PAGE_REQUEST';
export const FETCH_SPOT_CARRIER_LANES_PAGE_REQUEST_BEGIN =
    'carrier/spot-carrier-lanes/FETCH_SPOT_CARRIER_LANES_PAGE_REQUEST_BEGIN';
export const FETCH_SPOT_CARRIER_LANES_PAGE_REQUEST_ERROR =
    'carrier/spot-carrier-lanes/FETCH_SPOT_CARRIER_LANES_PAGE_REQUEST_ERROR';
export const FETCH_SPOT_CARRIER_LANES_PAGE_REQUEST_SUCCESS =
    'carrier/spot-carrier-lanes/FETCH_SPOT_CARRIER_LANES_PAGE_REQUEST_SUCCESS';

export const RESET_SPOT_CARRIER_LANES = 'carrier/spot-carrier-lanes/RESET_SPOT_CARRIER_LANES';

export type SpotCarrierLanesStateT = PaginationStateT<SpotCarrierLaneT, FetchSpotCarrierLanesPageQueryT>;

export type FetchSpotCarrierLanesPageActionT = {
    type: typeof FETCH_SPOT_CARRIER_LANES_PAGE_REQUEST;
    pageNumber: PageNumberT;
    query: FetchSpotCarrierLanesPageQueryT;
    options?: FetchOptionsT;
};

export type FetchSpotCarrierLanesPageBeginActionT = {
    type: typeof FETCH_SPOT_CARRIER_LANES_PAGE_REQUEST_BEGIN;
    pageNumber: PageNumberT;
    query: FetchSpotCarrierLanesPageQueryT;
};

export type FetchSpotCarrierLanesPageSuccessActionT = {
    type: typeof FETCH_SPOT_CARRIER_LANES_PAGE_REQUEST_SUCCESS;
    pageNumber: PageNumberT;
    pageResponse: PageResponseT<SpotCarrierLaneT> | null;
    query: FetchSpotCarrierLanesPageQueryT;
};

export type FetchSpotCarrierLanesPageErrorActionT = {
    type: typeof FETCH_SPOT_CARRIER_LANES_PAGE_REQUEST_ERROR;
    query: FetchSpotCarrierLanesPageQueryT;
    pageNumber: PageNumberT;
    error: Error;
};

export type ResetSpotCarrierLanesActionT = {
    type: typeof RESET_SPOT_CARRIER_LANES;
    mutationListOptions: MutationListOptionsT;
};

export type SpotCarrierLanesActionT =
    | FetchSpotCarrierLanesPageActionT
    | FetchSpotCarrierLanesPageBeginActionT
    | FetchSpotCarrierLanesPageSuccessActionT
    | FetchSpotCarrierLanesPageErrorActionT
    | ResetSpotCarrierLanesActionT;
