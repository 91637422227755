import * as React from 'react';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';

type PropsT = {
    size: number;
    strokeColor: StyleGuideColorsEnum;
};

const RadarIcon: React.FC<PropsT> = (props) => {
    const { strokeColor, size, ...rest } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" {...rest}>
            <g fill="none" fillRule="evenodd">
                <path d="M1 1h18v18H1z" />
                <path
                    fill={strokeColor}
                    d="M15.805 14.748A7.488 7.488 0 0017.5 10c0-4.14-3.36-7.5-7.5-7.5-4.14 0-7.5 3.36-7.5 7.5 0 4.14 3.36 7.5 7.5 7.5a7.488 7.488 0 005.332-2.227c.023-.023.038-.046.053-.06.15-.15.293-.308.42-.465zM10 16c-3.308 0-6-2.693-6-6 0-3.308 2.692-6 6-6 3.307 0 6 2.692 6 6a5.943 5.943 0 01-1.268 3.675l-1.072-1.073a4.49 4.49 0 00.825-2.595c0-2.482-2.018-4.5-4.5-4.5a4.504 4.504 0 00-4.5 4.5c0 2.483 2.018 4.5 4.5 4.5.975 0 1.883-.314 2.617-.847l1.066 1.065A5.867 5.867 0 0110 16zm1.44-5.617a1.485 1.485 0 00-.367-1.425l-.016-.016a1.492 1.492 0 00-2.085-.03c-.007.008-.022.015-.037.03a1.503 1.503 0 000 2.123l.015.015c.39.383.938.502 1.433.367l1.132 1.133c-.45.27-.968.435-1.53.435a3 3 0 110-6 3 3 0 013 3 2.93 2.93 0 01-.42 1.5l-1.125-1.133z"
                />
            </g>
        </svg>
    );
};

RadarIcon.displayName = 'RadarIcon';

const storyProps: PropsT = {
    size: DEFAULT_ICON_SIZE,
    strokeColor: StyleGuideColorsEnum.brandDark,
};

export { storyProps };
export default RadarIcon;
