import * as React from 'react';

import cs from 'classnames';
import classNames from 'classnames/bind';
import styles from './AssetPickUpLocationPin.scss';
import { AssetTypeEnum } from 'common/constants';
import MapPin, { MapPinThemeEnum } from 'common/components/maps/MapPin/MapPin';
import TrailerPickUpIcon, { getTrailerPickUpIconProps } from 'common/icons/TrailerPickUpIcon';
import TruckPickUpIcon, { getTruckPickUpIconProps } from 'common/icons/TruckPickUpIcon';
import LinkPickUpIcon, { getLinkPickUpIconProps } from 'common/icons/LinkPickUpIcon';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    lat: number | undefined;
    lng: number | undefined;
    assetType: AssetTypeEnum | null;
};

const AssetPickUpLocationPin: React.FC<PropsT> = React.memo((props) => {
    const { className, lat, lng, assetType } = props;

    let iconNode: React.ReactNode | undefined;

    if (!iconNode && !assetType) {
        iconNode = <LinkPickUpIcon {...getLinkPickUpIconProps('assignment-normal')} />;
    }

    if (!iconNode && assetType === AssetTypeEnum.trailer) {
        iconNode = <TrailerPickUpIcon {...getTrailerPickUpIconProps('assignment-normal')} />;
    }

    if (!iconNode && assetType === AssetTypeEnum.truck) {
        iconNode = <TruckPickUpIcon {...getTruckPickUpIconProps('assignment-normal')} />;
    }

    if (!iconNode) {
        return null;
    }

    return (
        <MapPin
            hasPaddings
            className={cs(cx('asset-tooltip'), className)}
            lat={lat}
            lng={lng}
            theme={MapPinThemeEnum.charcoal}
        >
            {iconNode}
        </MapPin>
    );
});

export default AssetPickUpLocationPin;
