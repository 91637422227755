import {
    FETCH_ORDERS_PAGE_REQUEST,
    FETCH_ORDERS_PAGE_REQUEST_BEGIN,
    FETCH_ORDERS_PAGE_REQUEST_ERROR,
    FETCH_ORDERS_PAGE_REQUEST_SUCCESS,
    FetchTransportOrdersPageActionT,
    FetchTransportOrdersPageBeginActionT,
    FetchTransportOrdersPageErrorActionT,
    FetchTransportOrdersPageSuccessActionT,
    RESET_ORDERS,
    ResetTransportOrdersActionT,
} from './types';
import { FetchTransportOrdersPageQueryT, TransportOrderT } from './models';
import { PageResponseT } from 'common/utils/pagination/models';

export const fetchTransportOrdersPage = (
    pageNumber: PageNumberT,
    query: FetchTransportOrdersPageQueryT,
    companyId: CompanyIdT,
    options?: FetchOptionsT,
): FetchTransportOrdersPageActionT => ({
    type: FETCH_ORDERS_PAGE_REQUEST,
    query,
    pageNumber,
    companyId,
    options,
});

export const fetchTransportOrdersPageBegin = (
    query: FetchTransportOrdersPageQueryT,
    pageNumber: PageNumberT,
    companyId: CompanyIdT,
): FetchTransportOrdersPageBeginActionT => ({
    type: FETCH_ORDERS_PAGE_REQUEST_BEGIN,
    query,
    pageNumber,
    companyId,
});

export const fetchTransportOrdersPageSuccess = (
    query: FetchTransportOrdersPageQueryT,
    pageNumber: PageNumberT,
    pageResponse: PageResponseT<TransportOrderT> | null,
    companyId: CompanyIdT,
): FetchTransportOrdersPageSuccessActionT => ({
    type: FETCH_ORDERS_PAGE_REQUEST_SUCCESS,
    query,
    pageResponse,
    pageNumber,
    companyId,
});

export const fetchTransportOrdersPageError = (
    query: FetchTransportOrdersPageQueryT,
    pageNumber: PageNumberT,
    error: Error,
    companyId: CompanyIdT,
): FetchTransportOrdersPageErrorActionT => ({
    type: FETCH_ORDERS_PAGE_REQUEST_ERROR,
    query,
    error,
    pageNumber,
    companyId,
});

export const resetTransportOrders = (
    companyId: CompanyIdT,
    mutationListOptions: MutationListOptionsT,
): ResetTransportOrdersActionT => ({
    type: RESET_ORDERS,
    companyId,
    mutationListOptions,
});
