import { SpotLoadBoardRequestsStateT } from './types';
import { StateT } from '../models';

export const selectSpotLoadBoardRequestsPages = (state: StateT): SpotLoadBoardRequestsStateT['pages'] =>
    state.spotLoadBoardRequests.pages;

export const selectSpotLoadBoardRequestsQuery = (state: StateT): SpotLoadBoardRequestsStateT['query'] =>
    state.spotLoadBoardRequests.query;

export const selectSpotLoadBoardRequestsTotal = (state: StateT): SpotLoadBoardRequestsStateT['total'] =>
    state.spotLoadBoardRequests.total;

export const selectSpotLoadBoardRequestsByIds = (state: StateT): SpotLoadBoardRequestsStateT['byId'] =>
    state.spotLoadBoardRequests.byId;
