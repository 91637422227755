import * as React from 'react';
import { Trans } from 'react-i18next';
import DropdownControlOptionLabel from 'design-system/components/dropdowns/option/DropdownControlOptionLabel/DropdownControlOptionLabel';
import CircleIcon from 'common/icons/CircleIcon';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import DoneIcon from 'common/icons/DoneIcon';
import SmallCloseIcon from 'common/icons/SmallCloseIcon';

type PropsT = {
    statusNode: React.ReactNode;
    isPositiveIconStatus?: boolean;
    isNegativeIconStatus?: boolean;
};

const ChangeBackwardStatusControlOptionLabel: React.FC<PropsT> = (props) => {
    const { statusNode, isPositiveIconStatus, isNegativeIconStatus } = props;

    let iconNode = <CircleIcon borderColor={StyleGuideColorsEnum.slate} />;

    if (isPositiveIconStatus) {
        iconNode = <DoneIcon size={DEFAULT_ICON_SIZE} fillColor={StyleGuideColorsEnum.brandAccent} />;
    }

    if (isNegativeIconStatus) {
        iconNode = <SmallCloseIcon size={DEFAULT_ICON_SIZE} fillColor={StyleGuideColorsEnum.tomatoRed} />;
    }

    return (
        <DropdownControlOptionLabel
            icon={iconNode}
            label={
                <Trans
                    i18nKey="common:change-status-control.back"
                    components={{
                        status: statusNode,
                    }}
                />
            }
        />
    );
};

export default ChangeBackwardStatusControlOptionLabel;
