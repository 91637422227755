import {
    DropdownControlOptionT,
    SpecialOptionEnum,
} from 'design-system/components/dropdowns/DropdownControl/DropdownControl';
import { isNonNil } from 'common/utils';

export const addSeparatorAfterEachOption = (
    options: Array<DropdownControlOptionT | SpecialOptionEnum | null | undefined>,
) => {
    return options.filter(isNonNil).flatMap((option, index) => {
        const isLastOption = options.length - 1 === index;

        return [option, isLastOption ? null : SpecialOptionEnum.separator];
    });
};
