import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './StartPlaceBidFooterContent.scss';
import Button, { ButtonThemeEnum } from 'common/components/Button/Button';
import { useTranslation } from 'react-i18next';
import TimeLeftForStart from 'common/layouts/sidebar-footers/TimeLeftForStart/TimeLeftForStart';
import { SpotRequestDetailsT } from 'carrier/store/spot-request-details/models';

const cx = classNames.bind(styles);

type PropsT = {
    className?: string;
    details: SpotRequestDetailsT | null;
    onStartChangeBid: (spotRequestId: SpotRequestIdT) => void;
};

const StartPlaceBidFooterContent: React.FC<PropsT> = React.memo((props) => {
    const { details, onStartChangeBid } = props;

    const { t } = useTranslation();

    const handleStartChangeBid = React.useCallback(() => {
        if (!details?.id) {
            return;
        }

        onStartChangeBid(details.id);
    }, [details, onStartChangeBid]);

    const hasMissingCertificates = !!details?.missingCertificates?.length;

    if (!details) {
        return null;
    }

    return (
        <TimeLeftForStart
            deadline={details?.expiresAt}
            alreadyStartedLabel={t('spot-request-details.start-change-bid.expired')}
            deadlineLabel={t('spot-request-details.start-change-bid.to-bid')}
            renderActions={(isDisabled) => (
                <>
                    <Button
                        theme={ButtonThemeEnum.primary}
                        className={cx('action', 'action--start')}
                        onClick={handleStartChangeBid}
                        isDisabled={isDisabled || hasMissingCertificates}
                    >
                        {t('spot-request-details.start-change-bid.actions.place')}
                    </Button>
                </>
            )}
        />
    );
});

export default StartPlaceBidFooterContent;
