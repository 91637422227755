import { put, select, takeEvery } from 'redux-saga/effects';
import { FETCH_SPOT_CARRIER_LANES_PAGE_REQUEST, FetchSpotCarrierLanesPageActionT } from './types';
import {
    fetchSpotCarrierLanesPageBegin,
    fetchSpotCarrierLanesPageError,
    fetchSpotCarrierLanesPageSuccess,
    resetSpotCarrierLanes,
} from './actions';
import { selectSpotCarrierLanesPages, selectSpotCarrierLanesQuery } from './selectors';
import checkNeedRequest from 'common/utils/check-need-request';
import { checkIsSameQuery, checkShouldEmitChangePage, convertPageEntities } from 'common/utils/pagination/utils';
import spotCarrierTranziitApi from 'carrier/utils/api/spot-carrier-tranziit/api';
import { SpotCarrierLaneT } from 'carrier/store/spot-carrier-lanes/models';
import { ApiSpotCarrierLaneT } from 'carrier/utils/api/spot-carrier-tranziit/models';
import { v4 as uuidv4 } from 'uuid';
import { spotCarrierLanesPaginationChannel } from 'carrier/store/spot-carrier-lanes/channels';

function* fetchSpotCarrierLanesSaga(action: FetchSpotCarrierLanesPageActionT): WrapGeneratorT<void> {
    const { pageNumber, query, options } = action;

    const prevQuery: ReturnType<typeof selectSpotCarrierLanesQuery> = yield select(selectSpotCarrierLanesQuery);
    const pages: ReturnType<typeof selectSpotCarrierLanesPages> = yield select(selectSpotCarrierLanesPages);
    const isSameQuery = checkIsSameQuery(query, prevQuery);
    const isNeedRequest = checkNeedRequest(pages[pageNumber]?.requestStatus, options);
    if (isSameQuery && !isNeedRequest) {
        return;
    }
    if (!isSameQuery) {
        yield put(resetSpotCarrierLanes({ savingPageNumber: pageNumber }));
    }

    yield put(fetchSpotCarrierLanesPageBegin(query, pageNumber));

    const [error, response]: ReturnApiT<typeof spotCarrierTranziitApi.fetchCarrierLanes> =
        yield spotCarrierTranziitApi.fetchCarrierLanes({
            ...query,
            page: pageNumber,
        });

    if (error) {
        yield put(fetchSpotCarrierLanesPageError(query, pageNumber, error));
        return;
    }

    const preparedResponse = convertPageEntities<ApiSpotCarrierLaneT, SpotCarrierLaneT>(response, (item) => {
        return {
            ...item,
            id: `fake-id-${uuidv4()}`,
        };
    });

    yield put(fetchSpotCarrierLanesPageSuccess(query, pageNumber, preparedResponse));

    checkShouldEmitChangePage(pageNumber, response, spotCarrierLanesPaginationChannel);
}

function* spotCarrierLanesSaga(): WrapGeneratorT<void> {
    yield takeEvery(FETCH_SPOT_CARRIER_LANES_PAGE_REQUEST, fetchSpotCarrierLanesSaga);
}

export default spotCarrierLanesSaga;
