import { formatPageQuerySort } from 'common/utils/page-sort';
import { FetchSpotInboxRequestsPageQueryT } from 'carrier/store/spot-inbox-requests/models';
import { SpotRequestsInboxQuickFilterEnum } from 'carrier/layouts/SpotRequestsPage/SpotRequestListPage/constants';
import { QueryFiltersKeysEnum, QueryFiltersT } from 'carrier/layouts/SpotRequestsPage/SpotRequestListPage/query-models';
import { FetchSpotLoadBoardRequestsPageQueryT } from 'carrier/store/spot-load-board-requests/models';
import { BidStatusEnum, SpotRequestsSortEnum } from 'carrier/utils/api/spot-carrier-tranziit/models';
import { PageSortT } from 'common/utils/query';

const getQueryFromQuickFilters = (
    quickFilter: SpotRequestsInboxQuickFilterEnum | null | undefined,
): FetchSpotInboxRequestsPageQueryT => {
    const statusMap: Record<SpotRequestsInboxQuickFilterEnum, BidStatusEnum> = {
        [SpotRequestsInboxQuickFilterEnum.new]: BidStatusEnum.new,
        [SpotRequestsInboxQuickFilterEnum.open]: BidStatusEnum.open,
        [SpotRequestsInboxQuickFilterEnum.expired]: BidStatusEnum.expired,
        [SpotRequestsInboxQuickFilterEnum.accepted]: BidStatusEnum.accepted,
        [SpotRequestsInboxQuickFilterEnum.rejected]: BidStatusEnum.rejected,
        [SpotRequestsInboxQuickFilterEnum.won]: BidStatusEnum.won,
        [SpotRequestsInboxQuickFilterEnum.lost]: BidStatusEnum.lost,
        [SpotRequestsInboxQuickFilterEnum.missed]: BidStatusEnum.missed,
        [SpotRequestsInboxQuickFilterEnum.canceled]: BidStatusEnum.canceled,
    };

    return {
        bidStatus: quickFilter ? statusMap[quickFilter] : undefined,
    };
};

const prepareBaseFetchPageQuery = (
    queryFilters: QueryFiltersT,
    sort: PageSortT<SpotRequestsSortEnum> | null,
): FetchSpotInboxRequestsPageQueryT | FetchSpotLoadBoardRequestsPageQueryT => {
    const dictTrailerTypeId = queryFilters[QueryFiltersKeysEnum.dictTrailerTypeId];

    return {
        createdDateFrom: queryFilters[QueryFiltersKeysEnum.createdDateFrom] || undefined,
        createdDateTo: queryFilters[QueryFiltersKeysEnum.createdDateTo] || undefined,

        trailerTypeId: dictTrailerTypeId ? +dictTrailerTypeId : undefined,

        textSearch: queryFilters[QueryFiltersKeysEnum.searchText] || undefined,

        originAddress: queryFilters[QueryFiltersKeysEnum.pickupAddress] || undefined,
        originDateFrom: queryFilters[QueryFiltersKeysEnum.pickupDateFrom] || undefined,
        originDateTo: queryFilters[QueryFiltersKeysEnum.pickupDateTo] || undefined,

        destinationAddress: queryFilters[QueryFiltersKeysEnum.dropOffAddress] || undefined,
        destinationDateFrom: queryFilters[QueryFiltersKeysEnum.dropOffDateFrom] || undefined,
        destinationDateTo: queryFilters[QueryFiltersKeysEnum.dropOffDateTo] || undefined,

        sort: formatPageQuerySort(sort),
    };
};

const prepareFetchInboxPageQuery = (
    queryFilters: QueryFiltersT,
    sort: PageSortT<SpotRequestsSortEnum> | null,
): FetchSpotInboxRequestsPageQueryT => {
    return {
        ...prepareBaseFetchPageQuery(queryFilters, sort),
        ...getQueryFromQuickFilters(queryFilters[QueryFiltersKeysEnum.inboxQuickFilter]),
    };
};

const prepareFetchLoadBoardPageQuery = (
    queryFilters: QueryFiltersT,
    sort: PageSortT<SpotRequestsSortEnum> | null,
): FetchSpotLoadBoardRequestsPageQueryT => {
    return {
        ...prepareBaseFetchPageQuery(queryFilters, sort),
        availableToAccept: queryFilters[QueryFiltersKeysEnum.loadBoardOnlyAvailableToAccept] || undefined,
    };
};

export { prepareFetchInboxPageQuery, prepareFetchLoadBoardPageQuery };
