import { CountryCodeT } from 'common/store/countries-dict/models';

export enum QueryKeysEnum {
    spotLanesPage = 'spot-lanes-page',
    spotLanesFilters = 'spot-lanes-filters',
}

export enum QueryFiltersKeysEnum {
    fromCountryCode = 'from-country-code',
    toCountryCode = 'to-country-code',
}

export type QueryFiltersT = Partial<{
    [QueryFiltersKeysEnum.fromCountryCode]?: CountryCodeT | null | undefined;
    [QueryFiltersKeysEnum.toCountryCode]?: CountryCodeT | null | undefined;
}>;
