import {
    CHECK_GPS_STATUS_REQUEST_BEGIN,
    CHECK_GPS_STATUS_REQUEST_ERROR,
    CHECK_GPS_STATUS_REQUEST_SUCCESS,
    GPSTrackingStatusActionT,
    GPSTrackingStatusStateT,
    RESET,
} from './types';
import requestStatus from 'common/utils/request-status';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';
import { checkIsSameQuery } from 'common/utils/pagination/utils';

const initialState: GPSTrackingStatusStateT = {
    query: null,
    requestStatus: requestStatus.initial,
    data: null,
};

export default (
    state = initialState,
    action: GPSTrackingStatusActionT | DestroySessionActionT,
): GPSTrackingStatusStateT => {
    switch (action.type) {
        case CHECK_GPS_STATUS_REQUEST_BEGIN: {
            const { query } = action;

            return {
                ...state,
                query,
                requestStatus: requestStatus.loading,
            };
        }

        case CHECK_GPS_STATUS_REQUEST_SUCCESS: {
            const { query, data } = action;

            if (!checkIsSameQuery(query, state.query)) {
                return state;
            }

            return {
                ...state,
                data,
                requestStatus: requestStatus.ok,
            };
        }

        case CHECK_GPS_STATUS_REQUEST_ERROR: {
            const { error, query } = action;

            if (!checkIsSameQuery(query, state.query)) {
                return state;
            }

            return {
                ...state,
                data: null,
                requestStatus: requestStatus.setError(error),
            };
        }

        case DESTROY_SESSION:
        case RESET: {
            return {
                ...initialState,
            };
        }

        default:
            return state;
    }
};
