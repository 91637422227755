import requestStatus from 'common/utils/request-status';
import {
    ASSIGNMENT_REQUEST_BEGIN,
    ASSIGNMENT_REQUEST_ERROR,
    ASSIGNMENT_REQUEST_SUCCESS,
    DriverAssigmentActionT,
    DriverAssigmentStateT,
    RESET,
    RE_ASSIGNMENT_REQUEST_BEGIN,
    RE_ASSIGNMENT_REQUEST_SUCCESS,
    RE_ASSIGNMENT_REQUEST_ERROR,
} from './types';
import { DESTROY_SESSION, DestroySessionActionT } from 'common/store/auth/types';

const initialState: DriverAssigmentStateT = {
    tourId: null,
    assigmentStatus: requestStatus.initial,
    reAssigmentStatus: requestStatus.initial,
};

export default (
    state = initialState,
    action: DriverAssigmentActionT | DestroySessionActionT,
): DriverAssigmentStateT => {
    switch (action.type) {
        case ASSIGNMENT_REQUEST_BEGIN: {
            return {
                ...state,
                assigmentStatus: requestStatus.loading,
            };
        }

        case ASSIGNMENT_REQUEST_SUCCESS: {
            return {
                ...state,
                assigmentStatus: requestStatus.ok,
            };
        }

        case ASSIGNMENT_REQUEST_ERROR: {
            const { error } = action;
            return {
                ...state,
                assigmentStatus: requestStatus.setError(error),
            };
        }

        case RE_ASSIGNMENT_REQUEST_BEGIN: {
            return {
                ...state,
                reAssigmentStatus: requestStatus.loading,
            };
        }

        case RE_ASSIGNMENT_REQUEST_SUCCESS: {
            return {
                ...state,
                reAssigmentStatus: requestStatus.ok,
            };
        }

        case RE_ASSIGNMENT_REQUEST_ERROR: {
            const { error } = action;

            return {
                ...state,
                reAssigmentStatus: requestStatus.setError(error),
            };
        }

        case DESTROY_SESSION:
        case RESET: {
            return {
                ...initialState,
            };
        }

        default:
            return state;
    }
};
